import React, { FC, useEffect, useState } from "react";
import { DropDown } from "../../../../components/dropdown/dropdown.component";
// import { DropDownMultiple } from '../../../../components/dropdown/dropdown-multiple.component';
import { InputObligatory, InputValue } from "../../../../components/inputs/input.component";
import { SpacerVertical } from "../../../../components/spacer/spacer.component";
import { sub_interests } from "../../../../shared/interfaces";
import { getSubSpecOpt } from "../../../../shared/utils";
import { yearsData } from "../../reviewers-signup/reviewers-signup.page";

interface IMoreExp {
    register : any;
    errors : any;
    handleData : (data : any) => void;
    id : number;
    subSpecOpt : sub_interests | undefined;
}

export const MoreExp:FC<IMoreExp> = (props) => {

    const {register, errors, handleData, id, subSpecOpt} = props;

    // const [subSpecialisations, setSubSpecialisations]  = useState<string[]>([]);
    const [subSpecialisations, setSubSpecialisations] = useState("");
    const [yearsOfExperience, setYearsOfExperience] = useState("");
    const [institution, setInstitution] = useState("");
    const [idItem] = useState(id);

    const handleInstitution = (value : InputValue) => {
        setInstitution(value.value);
    }

    useEffect(() => {

        const subSpecItem = subSpecOpt?.find((item) => item.title === subSpecialisations);

        delete subSpecItem?.description
        // let subSpecItem = subSpecOpt?.filter((obj) => { return subSpecialisations.indexOf(obj.title) >= 0; });
        // subSpecItem?.forEach((item) => {
            // delete item["description"]
        // });

        const extraStepData = {
            id : idItem,
            institution: institution,
            years_of_experience : +yearsOfExperience,
            sub_specialisations : [subSpecItem]
        }
        handleData(extraStepData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [institution, yearsOfExperience, subSpecialisations])

    return(
        <>
            <DropDown value={subSpecialisations} options={getSubSpecOpt(subSpecOpt)} label={"مجالات مراجعة فرعية"} onChange={setSubSpecialisations} placeholder="الرجاء اختيار مجال فرعي" name="sub_specialisations" register={register} errors={errors} errorMessage="" required/>
            <SpacerVertical height={"2.286em"}/>
            <DropDown value={yearsOfExperience} options={yearsData} label="سنوات الخبرة" onChange={setYearsOfExperience} placeholder="الرجاء تحديد سنوات الخبرة " name="years_of_experience" register={register} errors={errors} errorMessage="الرجاء إدخال سنوات الخبرة" required/>
            <SpacerVertical height={"2.286em"}/>
            <InputObligatory 
            name="institution" 
            label="الجهة" 
            placeholder="اسم الجهة التي عملت معها كمراجع"
            register={register} 
            errors={errors} 
            errorMessage="الرجاء كتابة الجهة التي عملت معها" 
            value={institution}
            required
            inputPrompt="الرجاء إدخال اسم جهة الخبرة"
            errorMessagePattern="الرجاء كتابة الجهة التي عملت معها"
            onChange={handleInstitution}/>
            <SpacerVertical height={"1.286em"}/>
        </>
    )
}