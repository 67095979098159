import { ArticleData, CommentApiData, FormatSearch, IFormValues, WriterThirdStepData } from "../interfaces";
import { axiosServiceAuth, axiosServiceFileVersion1Token, axiosServiceVersion1, axiosServiceVersion1Token } from "./axios.service";
import { deleteCookie, getCookie, setCookie } from "./cookie.service";

class SearchService {
  getSearchData(offset: number, limit: number, text: string, format: FormatSearch) {
    return axiosServiceVersion1(`articles/search?offset=${offset}&limit=${limit}&text=${text}&format=${format}&authors=true`);
  }
}

class SocialService {
  getRequestToken() {
    return axiosServiceVersion1.get(`social/twitter/request-token`);
  }
  getAccessToken(request_token?: string, request_token_secret?: string, verifier?: string | null) {
    return axiosServiceVersion1.get(`social/twitter/access-token?request_token=${request_token}&request_token_secret=${request_token_secret}&verifier=${verifier}`);
  }
  postSocialNetworkWithUser(user_id: string, data: any){
    return axiosServiceVersion1Token.post(`social/${user_id}/link`, data);
  }
  ShareArticleSocial(articleId: string, url: string, social: string){
    const data = {
      "message":"Hey, take a look at this amazing article",
      "post_link": url,
      "social_network": social
    }
    return axiosServiceVersion1Token.post(`articles/${articleId}/shared`, data);
  }
}
class UserService {
  getUserSpecialisations(uuid: string) {
    return axiosServiceVersion1Token.get('users/' + uuid + '/specialisations');
  }
  getRandomWriters(limit : number) {
    if(apiService.auth.isAuthenticated()) {
      return axiosServiceVersion1Token.get(`authors/random?limit=${limit}`);
    }
    return axiosServiceVersion1.get(`authors/random?limit=${limit}`);
  }
  getMostFollowedWriters(offset: number, limit: number) {
    return axiosServiceVersion1Token.get(`authors/most-followed?offset=${offset}&limit=${limit}`)
  }
  getMyMostFollowedWriters(offset: number, limit: number, uuid: string) {
    return axiosServiceVersion1Token.get(`users/${uuid}/authors/followed?offset=${offset}&limit=${limit}`)
  }
  followAuthor(userId: string, authorId: string){
    return axiosServiceVersion1Token.put(`users/${userId}/authors/${authorId}/followed`)    
  }
  unFollowAuthor(userId: string, authorId: string) {
    return axiosServiceVersion1Token.delete(`users/${userId}/authors/${authorId}/followed`)
  }
  getAuthorData(userId: string){
    if(apiService.auth.isAuthenticated()){
      return axiosServiceVersion1Token.get(`authors/${userId}`);
    }
    return axiosServiceVersion1.get(`authors/${userId}`);
  }
  getArticlesByUserInterests(userId: string, offset: number, limit: number) {
    return axiosServiceVersion1Token.get(`/users/${userId}/articles?offset=${offset}&limit=${limit}`);
  }
  getCurrentUserProfile(userId: string) {
    return axiosServiceVersion1Token.get(`users/${userId}/profile`);
  }
  UploadProfilePhoto(image: File, userId: string) {
    let formData = new FormData();
      formData.append("file", image);
    return axiosServiceFileVersion1Token.post(`users/${userId}/profile/image`, formData);
  }
  UpdateProfileData(userId: string, first_name: string | undefined = "", last_name: string | undefined = "", country: string, bio: string = "") {
    const data = {
      first_name,
      last_name,
      country,
      bio
    }
    return axiosServiceVersion1Token.patch(`users/${userId}/profile`, data);
  }
  getAllAuthors(limit: number, offset: number) {
    if(apiService.auth.isAuthenticated())   return axiosServiceVersion1Token.get(`authors?limit=${limit}&offset=${offset}`);
    return axiosServiceVersion1.get(`authors?limit=${limit}&offset=${offset}`);
  }
  getMostViewedAuthors(limit: number, offset: number) {
    if(apiService.auth.isAuthenticated())  return axiosServiceVersion1Token.get(`authors/most-viewed?limit=${limit}&offset=${offset}`);
    return axiosServiceVersion1.get(`authors/most-viewed?limit=${limit}&offset=${offset}`);
  }
  getLatestAuthors(limit: number, offset: number) {
    if(apiService.auth.isAuthenticated())   return axiosServiceVersion1Token.get(`authors/latest?limit=${limit}&offset=${offset}`);
    return axiosServiceVersion1.get(`authors/latest?limit=${limit}&offset=${offset}`);
  }
  getMostViewedInterests  (limit: number, offset: number) {
    if(apiService.auth.isAuthenticated())    return axiosServiceVersion1Token.get(`interests/most-viewed?limit=${limit}&offset=${offset}`);
    return axiosServiceVersion1.get(`interests/most-viewed?limit=${limit}&offset=${offset}`);
  }
  getMostFollowedInterests  (limit: number, offset: number) {
    if(apiService.auth.isAuthenticated())   return axiosServiceVersion1Token.get(`interests/most-followed?limit=${limit}&offset=${offset}`);
    return axiosServiceVersion1.get(`interests/most-followed?limit=${limit}&offset=${offset}`);
  }
}
class InterestsService {
   getAllInterests(offset: number, limit: number, isAuth: boolean) {
     if(isAuth){
      return axiosServiceVersion1Token.get("interests?offset="+ offset + "&limit=" + limit)
     }
      return axiosServiceVersion1.get("interests?offset="+ offset + "&limit=" + limit)
   }

   getGeneralInformation(interestId : number) {
     return axiosServiceVersion1.get('/interests/' + interestId);
   }

   getUserInterests(uuid: string) {
     return axiosServiceVersion1Token.get('users/' + uuid + '/interests');
   }

   getUserSubInterests(uuid: string) {
    return axiosServiceVersion1Token.get('users/' + uuid + '/interests/subinterests');
  }

   subscribeInterestsRegistration<T>(uuid : string, interests : T) {
      return axiosServiceVersion1Token.post('users/' + uuid + '/interests/popup', interests);
   }
   subscribeOnInterest(uuid : string, interestId: number | undefined) {
    return axiosServiceVersion1Token.put('users/' + uuid + '/interests/' + interestId);
  }
  unSubscribeFromInterest(uuid : string, interestId: number | undefined) {
    return axiosServiceVersion1Token.delete('users/' + uuid + '/interests/' + interestId);
  }
   subscribeOnSubInterest(uuid : string, subInterestId: number) {
     return axiosServiceVersion1Token.put('users/' + uuid + '/interests/sub_interest/' + subInterestId);
   }
   unSubscribeFromSubInterest(uuid : string, subInterestId: number) {
    return axiosServiceVersion1Token.delete('users/' + uuid + '/interests/sub_interest/' + subInterestId);
  }
  getRandomInterests() {
    return axiosServiceVersion1.get('interests/random');
  }
}

class CommentService {
    createNewComment(data: CommentApiData) {
      return axiosServiceVersion1Token.post('commentaries', data);
    }
    getAllArticleComments(article_id: string) {
      return axiosServiceVersion1Token.get(`articles/${article_id}/commentaries?offset=0&limit=50`);
    }
    saveHighlights(article_id : number, content: string, highlights: string) { 
      const data = {
        article_id: +article_id,
        content: content,
        entries: highlights
      }
      return axiosServiceVersion1Token.post('highlights', data)
    }
    getAllUserHighlights(limit: number) {
      return axiosServiceVersion1Token.get(`highlights?limit=${limit}`);
    }
    getHighlightsByArticleId(article_id: number) {
      return axiosServiceVersion1Token.get(`articles/${article_id}/highlights`);
    }
    deleteHighlights(article_id: number) {
      return axiosServiceVersion1Token.delete(`highlights/${article_id}`);
    }
    deleteCommentaryById(commentId: number) {
      return axiosServiceVersion1Token.delete(`commentaries/${commentId}`);
    }
    createReviewHighlight(article_id: number, content: string, entries: string) {
      const data = {
        article_id,
        content,
        entries
      }
      return axiosServiceVersion1Token.post(`reviews/highlight`, data);
    }
    getReviewsHighlights(article_id: string) {
      return axiosServiceVersion1Token.get(`articles/${article_id}/review/highlights`);
    }
    createCommentaryByReviewHighlight(highlight_id: number, highlight_number: number, content: string, is_fixed: boolean) {
      const data = {
        highlight_id: highlight_id,
        highlight_number: highlight_number,
        content: content,
        is_fixed: is_fixed
    }
      return axiosServiceVersion1Token.post("commentaries", data);
    }
    getCommentariesByHighlightId (highlight_id: number, offset: number, limit: number, highlight_number ?: number) {
      if(highlight_number) {
        return axiosServiceVersion1Token.get(`/highlights/${highlight_id}/commentaries?highlight_number=${highlight_number}&offset=${offset}&limit=${limit}`);
      }
      return axiosServiceVersion1Token.get(`/highlights/${highlight_id}/commentaries?offset=${offset}&limit=${limit}`);
    }
    updateCommentaryByReview(commentary_id: number, content: string, is_fixed: boolean) {
      const data = {
        content,
        is_fixed
      }
      return axiosServiceVersion1Token.patch(`commentaries/${commentary_id}`, data);
    }
    UpdateReviewHighlightByAuthor (highlight_id: number, content: string, entries: string) {
      const data = {
        content: content,
        entries: entries
      }
      return axiosServiceVersion1Token.patch(`/highlights/${highlight_id}/review`, data);
    }
    deleteCommentsByHighlightIdAndNumber(highlight_id: number, highlight_number: number) {
      return axiosServiceVersion1Token.delete(`highlights/${highlight_id}/commentaries?highlight_number=${highlight_number}`);
    }
    createCorrectionHighlight(article_id: number, content: string, entries: string, correction_id: string) {
      const data = {
        article_id,
        content,
        entries
      }
      return axiosServiceVersion1Token.post(`corrections/${correction_id}/highlight`, data);
    }
}

class FileService {
    uploadNewImage(image: File) {
      let formData = new FormData();
      formData.append("file", image);
      return axiosServiceFileVersion1Token.post('images', formData);
    }
}
class ArticleService {
  createNewArticle(articleData : ArticleData) {
    return axiosServiceVersion1Token.post('articles', articleData);
  }
  getAllMyArticles() {
    return axiosServiceVersion1Token.get('author/articles');
  }
  getMyArtilceById(id : string) {
    return axiosServiceVersion1Token.get('author/articles/' + id);
  }
  getTimerEndTimeForArticleApproving(id : string) {
    return axiosServiceVersion1Token.get(`author/articles/${id}/expired_time`);
  }
  updateArticle(id : string, articleData: ArticleData) {
    return axiosServiceVersion1Token.patch('articles/' + id, articleData);
  }
  getPublishedArticleById(id: number, isAuth: boolean){
    if(isAuth){
      return axiosServiceVersion1Token.get(`articles/${id}`);
    }
    return axiosServiceVersion1.get(`articles/${id}`);
  }
  getAllPublishedArticles(offset: number, limit: number,  isAuth: boolean, id?: number, subInterestId?: number) {
    if(isAuth) {
      if(id && id !== 0) {
        return axiosServiceVersion1Token.get(`articles?offset=${offset}&limit=${limit}&interest_id=${id}`);
      }else if(subInterestId){
        return axiosServiceVersion1Token.get(`articles?offset=${offset}&limit=${limit}&subinterest_id=${subInterestId}`);
      }
      return axiosServiceVersion1Token.get(`articles?offset=${offset}&limit=${limit}`);
    } else {
      if(id && id !== 0) {
        return axiosServiceVersion1.get(`articles?offset=${offset}&limit=${limit}&interest_id=${id}`);
      }else if(subInterestId){
        return axiosServiceVersion1.get(`articles?offset=${offset}&limit=${limit}&subinterest_id=${subInterestId}`);
      }
      return axiosServiceVersion1.get(`articles?offset=${offset}&limit=${limit}`);
    }
  }
  getRandomArticles(limit : number, isAuth: boolean) {
    if(isAuth){
      return axiosServiceVersion1Token.get(`articles/random?limit=${limit}`);
    } 
    return axiosServiceVersion1.get(`articles/random?limit=${limit}`);
  }
  initArticleReview(article_id: number){
    return axiosServiceVersion1Token.post(`articles/${article_id}/reviews`);
  }
  getReviewsByArticle(article_id: number) {
    return axiosServiceVersion1Token.get(`articles/${article_id}/reviews`);
  }
  getArticlesToReview() {
    return axiosServiceVersion1Token.get('reviews/articles');
  }
  getArticleToReviewById(article_id: number) {
    return axiosServiceVersion1Token.get(`reviews/articles/${article_id}`);
  }

  getArticleInfo(article_id: number) {
    return axiosServiceVersion1.get(`articles/${article_id}/info`);
  }

  UpdateReviewStatus(article_id: number, reviewStatus: string) {
    const data = {
      "review_status": reviewStatus
    }
    
    return axiosServiceVersion1Token.patch(`reviews/${article_id}`, data);
  }
  publishArticle(article_id: number) {
    return axiosServiceVersion1Token.put(`articles/${article_id}/published`);
  }
  addArticleToSave(article_id: number) {
    return axiosServiceVersion1Token.put(`articles/${article_id}/saved`);
  }
  removeArticleFromSaved(article_id: number) {
    return axiosServiceVersion1Token.delete(`articles/${article_id}/saved`);
  }
  getAllSavedArticles(offset: number, limit: number) {
    return axiosServiceVersion1Token.get(`articles/saved?offset=${offset}&limit=${limit}`);
  }
  deleteArticleById(article_id: number) {
    return axiosServiceVersion1Token.get(`articles/${article_id}`);
  }
  getNewestArticlesByAuthorId(authorId : string, offset: number, limit: number){
    if(apiService.auth.isAuthenticated()) {
      return axiosServiceVersion1Token.get(`author/${authorId}/articles/newest?offset=${offset}&limit=${limit}`);
    }
    return axiosServiceVersion1.get(`author/${authorId}/articles/newest?offset=${offset}&limit=${limit}`);
  }
  getTrendingArticles(offset: number, limit: number) {
    if(apiService.auth.isAuthenticated()) {
        return axiosServiceVersion1Token.get(`articles/trending?offset=${offset}&limit=${limit}`);
    }
    return axiosServiceVersion1.get(`articles/trending?offset=${offset}&limit=${limit}`);
  }
  getTrendingAuthors(offset: number, limit: number) {
    if(apiService.auth.isAuthenticated()) {
      return axiosServiceVersion1Token.get(`authors/trending?offset=${offset}&limit=${limit}`);
    }
    return axiosServiceVersion1.get(`authors/trending?offset=${offset}&limit=${limit}`);
  }
  likeArticle(articleId: string) {
    return axiosServiceVersion1Token.put(`articles/${articleId}/liked`);
  }
  removeLikeFromArticle(articleId: string) {
    return axiosServiceVersion1Token.delete(`articles/${articleId}/liked`);
  }
  getAllArchivedVersionsOfArticle(articleId: string){
    return axiosServiceVersion1.get(`/articles/${articleId}/archived`);
  }
  getSpecifiedArchivedVersionOfArticle(articleId: string, archivedArticleId: number) {
    return axiosServiceVersion1.get(`/articles/${articleId}/archived/${archivedArticleId}`);
  }
  addRejectedReasonToReview(reject_id: string, id: string, comment: string) {
    const data = {
        reason_id: +id,
        commentary: comment
    }
    return axiosServiceVersion1Token.patch(`/reviews/${reject_id}/reject_reason`, data);
  }
  getArticlesToCorrect() {
    return axiosServiceVersion1Token.get(`corrections/articles`);
  }
  getCorrectionById(correction_id: string) {
    return axiosServiceVersion1Token.get(`corrections/${correction_id}`);
  }
  getCorrectionHighlightsByArticleId(article_id: string) {
    return axiosServiceVersion1Token.get(`/articles/${article_id}/correction/highlights`);
  }
  correctArticleContentByEditor(article_id: string, data: any) {
    return axiosServiceVersion1Token.patch(`articles/${article_id}/correction`, data);
  }
  endCorrection(correction_id: string) {
    return axiosServiceVersion1Token.patch(`corrections/${correction_id}`);
  }
  getMostEngagedArticles(offset: number, limit: number) {
    if (apiService.auth.isAuthenticated())  return axiosServiceVersion1Token.get(`articles/most-engaged?offset=${offset}&limit=${limit}`);
    return axiosServiceVersion1.get(`articles/most-engaged?offset=${offset}&limit=${limit}`);
  }
}
class AuthService {
    isAuthenticated() : boolean {
      const access_token = getCookie('mawsuah_access_token');
      const refresh_token = getCookie('mawsuah_refresh_token');
      if(access_token && refresh_token) {
        return true;
      }
      if(refresh_token && !access_token) {
        this.refresh()
          .then(r => {
            setCookie('mawsuah_access_token',r.data.access_token, 1);
            setCookie('mawsuah_refresh_token',r.data.refresh_token, 7);
          }
          )
          .catch((r) => {
            return false
          }) 
          return true
      }
        return false;
      
    }

    signup(data : IFormValues) {
      delete data["confirm_password"];
      return axiosServiceVersion1.post('users', data)
    }

    signupWriterSecondStep<T>(uuid : string, secondStepData : T) {
      return axiosServiceVersion1Token.put('users/' + uuid, secondStepData);
    }

    signupWriterThirdStep(uuid : string, thirdStepData : WriterThirdStepData) {
      return axiosServiceVersion1Token.put('users/' + uuid, thirdStepData);
    }

    signupWriterExtraStep<T>(uuid : string, thirdStepData : T) {
      return axiosServiceVersion1Token.put('users/' + uuid + '/extra', thirdStepData);
    }

    login(loginData : { email : string, password : string}) {
      return axiosServiceAuth.post('login', loginData)
    }

    refresh() {
      const refresh_token = getCookie('mawsuah_refresh_token');
      return axiosServiceAuth.post('refresh', {'refresh_token': refresh_token});
    }
  
    signout() {
      localStorage.removeItem('mawsuah_user_data');
      deleteCookie('mawsuah_access_token');
      deleteCookie('mawsuah_refresh_token');
    }
    initResetPassword(email : string) {
      return axiosServiceAuth.post('password/reset', {"email": email});
    }
    UpdatePassword(token: string, password: string) {
      return axiosServiceAuth.patch('password', {"token": token, "password": password});
    }
  }
  

class ApiService {
    public user = new UserService();
    public auth = new AuthService();
    public interests = new InterestsService();
    public articles = new ArticleService();
    public files = new FileService();
    public comments = new CommentService();
    public search = new SearchService();
    public social = new SocialService();
  }
  
  const apiService = new ApiService();
  
  export { apiService }