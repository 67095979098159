import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
import { Icon } from '../../../components/icons/icon.component';
// import { BtnColorless } from '../../../components/buttons/btn.component';
import partnersBG from '../../../assets/images/StaticPages/PartnersPage/partnersBG.png';
import zenobiaLogo from '../../../assets/images/StaticPages/PartnersPage/zenobiaLogo.png';
import shomanLogo from '../../../assets/images/StaticPages/PartnersPage/shoman.png';
import googleLogo from '../../../assets/images/StaticPages/PartnersPage/google-startup.png';

import "./partners.scss";

export const PartnersPage: FunctionComponent = () => { 

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
        <AppHeader isWhite={true}/>
        <View className="CareersPage">
            <View className="CareersPageHeader" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(${partnersBG})`}}>
                <Container style={{marginTop: "96px"}}>
                    <View className="CareersPageHeaderContent">
                        <Header1 title="قوتنا بشراكتنا" className="header1"/>
                    </View>
                    <View className="CareersPageHeaderContent">
                        <Icon name="iconMawsuahLogo"/>
                        <span className="bigText">موسوعة</span>
                        <Icon name="iconVerifiedSmallWhiteBG"/>
                        {/* <span className="smallText">196 ألف متابع</span> */}
                        {/* <BtnColorless title="تابِع"/> */}
                    </View>
                </Container>
            </View>
            <SpacerVertical height={"4.5em"}/>
            <View className="CareersPageContent">
                <Container>
                    <Row>
                        <Col lg={3} className="CareersPageContentSecondColumn">
                            {/* <View className="CareersPageContentSecondColumnMenu" style={{borderBottom: "0px"}}>
                                <View className="Item">سياسية الخصوصية</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View>
                            <View className="CareersPageContentSecondColumnDisabled">
                                <View className="Item">الشركاء</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المساعدة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المجتمعات</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">معتمدون لدى </View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المدونة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الوظائف</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">سياسية الخصوصية</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">شروط الاستحدام</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">التواصل</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View> */}
                        </Col>
                        <Col className="CareersPageContentFirstColumn" lg={9}>
                            <Header3 className="writersHeader" title="يدٌ بيد نحو التميّز"/>
                            <div className="smallLine"></div>
                            <View className="CareersPageContentFirstColumnContent">
                            <View className="smallHeader">
                            لأننا نؤمن بأن مشاركة الخبرات هي من أهم عوامل التطوّر والنجاح، عملنا بجهد كي نكسب شركاء ذو قيمة مضافة بما يحقق الفائدة لكل مستخدمي موسوعة حول العالم بمختلف الخدمات الرقمية والتكنولوجية.
                            </View>
                            <SpacerVertical height={"2.3em"}/>
                            {/* <View style={{borderBottom: "1px solid var(--grayColorMain)"}}> */}
                            <View>                        
                                <View className="PartnerBlock">
                                    <View className="PartnerBlockText">
                                        <p>شركة إنتاج إبداعية رقمية، تنتج أفكار وقصص ترويجية وإعلامية منذ العام 2008، تبتكر زنوبيا تجارب رقمية استثنائية ذات تأثير مستمر بإنشاء خطط استراتيجية مدروسة، وعملت مع كبرى الشركات العالمية في المنطقة والكثير من القنوات الإخبارية مثل: سامسونج، أدوية الحكمة، قناة الجزيرة.</p>
                                        <p style={{textAlign: "left"}}><a href="https://zenobia.pro/" target="_blank" rel="noreferrer">zenobia.pro</a></p>
                                    </View>
                                    <View className="PartnerBlockLogo">
                                        <img src={zenobiaLogo} alt="zenobiaLogo" />
                                    </View>
                                </View>
                            </View>
                            <View>                        
                                <View className="PartnerBlock">
                                    <View className="PartnerBlockText">
                                    <p>تأسست مؤسسة عبد الحميد شومان عام 1978، بمبادرة غير ربحية من قبل البنك العربي عبر تخصيص جزء من أرباحه السنوية لإنشائها، إيمانًا منه بأهمية بذل الجهد في بناء الأرضية للتقدم العربي، من خلال دعم الاقتصاد الوطني بالتوازي مع الاعتناء الجاد بتشجيع البحث العلمي والدراسات الإنسانية والتنوير الثقافي والابتكار.</p>
                                        <p style={{textAlign: "left"}}><a href="https://shoman.org/" target="_blank" rel="noreferrer">shoman.org</a></p>
                                    </View>
                                    <View className="PartnerBlockLogo">
                                    <img src={shomanLogo} alt="shomanLogo" />
                                    </View>
                                </View>
                            </View>
                            <View>                        
                                <View className="PartnerBlock">
                                    <View className="PartnerBlockText">
                                        <p>"مسرّعة Google للأعمال الناشئة" في منطقة الشرق الأوسط وشمال أفريقيا، وهي عبارة عن برنامج رقمي لتسريع الأعمال مدته ثلاثة أشهر ويستهدف الشركات الناشئة التقنية التي لا تزال في مراحل التمويل الأوّلي (Seed) والاستثمار الأوّلي (Series A) في المنطقة.</p>                                        
                                        <p style={{textAlign: "left"}}><a href="https://startup.google.com/" target="_blank" rel="noreferrer">startup.google.com</a></p>
                                    </View>
                                    <View className="PartnerBlockLogo">
                                        <img src={googleLogo} alt="googleLogo" />
                                    </View>
                                </View>
                            </View>
                            {/* <View className="moreCareers">
                                <div>استكشاف المزيد</div>
                                <Icon name="iconArrowLeft" />
                            </View> */}
                            
                            </View>
                        </Col>
                    </Row>
                </Container>
                <SpacerVertical height={"3.3em"}/>
            </View>
            <AppFooter />
        </View>
    </View>
    )
}