import React, { FunctionComponent } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Btn } from '../buttons/btn.component';
import { Icon } from '../icons/icon.component';
import { SpacerVertical } from '../spacer/spacer.component';
import { Header1, Header2 } from '../typo/header.component';
import { View } from '../view/view.component';

import "./telescope-banner.scss";

interface TelescopeBannerProps {

}

export const TelescopeBanner: FunctionComponent<TelescopeBannerProps> = (props) => {

    const history = useHistory();

    return (
        <View className="telescopeBanner">
            <Container className="telescopeBanner">
                <Row className="telescopeBannerDirection">
                    <Col lg={6}>
                        <View className="bannerContent">
                            <Header1 title="كُن جزءاً في بناء مجتمع معرفي مختّص" className="header1"></Header1>
                            <Header2 title="موسوعة هي نقطة الالتقاء مع العقول المتشابهة والتفاعل مع القرّاء لتأسيس مجتمعا تشاركهم الاهتمام والتخصص." className="header2"></Header2>
                            <SpacerVertical height={"4.071em"}/>
                            <Btn title="أنشئ حساباً" className="" onClick={() => {history.push("/main_signup")}}/>
                        </View>
                    </Col>
                    <Col lg={6}>
                        <Icon className="iconTelescope" name="iconTelescope" alt="telescope_picture"/>
                    </Col>
                </Row>
            </Container>
        </View>    
    )
}