import React, { FunctionComponent } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { WriterInfo } from '../../shared/interfaces';
import { apiService } from '../../shared/services/api.service';
import { getUserRole } from '../../shared/utils';
import { Icon } from '../icons/icon.component';
import { SpacerVertical } from '../spacer/spacer.component';
import { Header3 } from '../typo/header.component';
import { View } from '../view/view.component';

import "./writers-worth-following.scss"
interface WritersWorthFollowingProps {
    isNoHeader ?: boolean;
    randomWriters ?: WriterInfo[];
    title?: string
}

export const WritersWorthFollowing: FunctionComponent<WritersWorthFollowingProps> = (props) => {

    const { isNoHeader, randomWriters, title } = props;

    const history = useHistory();

    return (
        <View className="WritersWorthFollowing">
            <Container>
            {!isNoHeader && 
            <>
            <Header3 className="writersHeader" title={title ? title : "كتّاب جديرون بالمتابعة"} onClick={() => {
                if(apiService.auth.isAuthenticated()) {
                    history.push({
                        pathname: "/user_profile_auth/trending",
                        state : {
                            tabName : "authors"
                        }});
                }
            }}/>
            <div className="smallLine"></div>
            </>}
                <div className="row">
                    {randomWriters?.map((item) => {
                        return(
                        <div className="col-xs-12 col-md-6 col-lg-3" key={item.id} onClick={() => {history.push(`/user_profile/${item.id}`)}}>
                            <View className="writersContent">
                                <View className="iconCircle">
                                    <Icon src={item.image_url} name="iconProfilePlaceholder" alt="author_profile_photo"/>
                                </View>
                                <View className="textFlex">
                                    <p><span>{item.first_name} {item.last_name}</span> <Icon name="iconVerifiedLarge"/></p>
                                </View>
                                <SpacerVertical height={"0.571em"}/>
                                <View className="text">
                                    <p>{getUserRole(item.role)}  <span onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(`/interests_tabs/${item.specialization?.id}`)
                                    }}>{item.specialization?.title}</span></p>
                                </View>
                                <SpacerVertical height={"0.286em"}/>
                                <View style={{marginTop: "0px"}} className="textFlex">
                                <p>{item.articles_count} {"مقال"} <Icon className="iconGrayCircle" name="iconGrayCircle"/> {item.followers_count} {"متابَع"}</p>
                                </View>
                            </View>
                        </div>
                    )})}
                </div> 
            </Container>
        </View>
    );
  }