import { AxiosError } from 'axios';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { AlertModal } from '../../../components/alert-modal/alert-modal.component';
import { Btn } from '../../../components/buttons/btn.component';
import { DropDownMultiple } from '../../../components/dropdown/dropdown-multiple.component';
import { DropDown } from '../../../components/dropdown/dropdown.component';
import { Icon } from '../../../components/icons/icon.component';
import { InputObligatory, InputRadioBox, InputValue } from '../../../components/inputs/input.component';
import { InterestsModal } from '../../../components/interests-modal/interests-modal';
import { useModal } from '../../../components/modal/modal.component';
import { SpacerHorizontal, SpacerVertical } from '../../../components/spacer/spacer.component';
import { View } from '../../../components/view/view.component';
import { EDUCATION, EXPERIENCE, LEVEL_OF_EDUCATION } from '../../../shared/constants';
import { useFetch } from '../../../shared/hooks/fetch.hook';
import { IFormValues, InterestsData, SecondStepData, sub_interests, WriterThirdStepData } from '../../../shared/interfaces';
import { apiService } from '../../../shared/services/api.service';
import { setCookie } from '../../../shared/services/cookie.service';
import { getSubSpecOpt } from '../../../shared/utils';
import { country } from '../reviewer-editor-signup/reviewer-editor-signup.page';
import { yearsData } from '../reviewers-signup/reviewers-signup.page';

import "./writers-signup.scss";




export const WritersSignupPage: FunctionComponent = () => {

    const {response, isLoading} = useFetch(() =>  apiService.interests.getAllInterests(0, 30, apiService.auth.isAuthenticated()));

    const { showModal, RenderModal } = useModal();
    const {showModal: showModalError, RenderModal: RenderModalError, hideModal: hideModalError} = useModal();

    const { register, handleSubmit, errors} = useForm<IFormValues>();

    const history = useHistory();

    const ref = useRef<any>(null);
    const ref1 = useRef<any>(null); 

    const [errorText, setErrorText] = useState("Something went wrong...");
    const [value,setValue] = useState('');
    const [value1,setValue1] = useState('');
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [isSecondStep, setIsSecondStep] = useState(false);
    const [isThirdStep, setIsThirdStep] = useState(false);
    const [ExperienceField, setExperienceField] = useState('');
    const [YearsofExperience, setYearsofExperience] = useState('');
    const [Education, setEducation] = useState('');
    const [LevelofEducation, setLevelofEducation] = useState('');
    const [Languages, setLanguages] = useState<string[]>([]);
    const [WritingSpecialization, setWritingSpecialization] = useState('');
    const [SubWritingSpecialization, setSubWritingSpecialization] = useState<string[]>([]);
    const [PreferedWritingLength, setPreferedWritingLength] = useState('');
    const [Expectednumberofwrittenarticlespermonth, setExpectednumberofwrittenarticlespermonth] = useState('');

    const [optionSpecialisation, setOptionSpecialisation] = useState<string[]>([""]);
    const [optionSubSpecialisation, setOptionSubSpecialisation] = useState<sub_interests>();
    const [status, setStatus] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        if(!isLoading) {
            let arr : string[] = []
            response?.data?.interests?.forEach((item : any) => {
                if(item?.sub_interests?.length !== 0 ) {
                    arr.push(item.title)
                }
            })
            setOptionSpecialisation(arr);
        }
    }, [response, isLoading]);

    useEffect(() => {
        setLengthContainer(ref?.current?.offsetHeight);
        setLengthContainer1(ref1?.current?.offsetHeight);
    }, [ref?.current?.offsetHeight, ref1?.current?.offsetHeight]);

    const handlePassword = (value: InputValue) => {
        setPassword(value.value);
    }
    
    const handleChange = (str: string) => {
        setValue(str);
    }
    const handleChange1 = (str: string) => {
        setValue1(str);
    }

    const handleSpecialisation = (value : string) => {
        setWritingSpecialization(value);
        const elemArr : InterestsData  = response?.data.interests.find((item: InterestsData) => item.title === value);
        setOptionSubSpecialisation(elemArr?.sub_interests);
    }

    const handleCheck = (e : React.ChangeEvent<HTMLInputElement>) => {
        setStatus(e.currentTarget.id);
    }
    const [lengthContainer, setLengthContainer] = useState(0);
    const [lengthContainer1, setLengthContainer1] = useState(0);

    const onSubmit = (data: any) => {
        if(data.gender === "أنثى") {
            data.gender = "FEMALE"
        } else {
            data.gender = "MALE"
        }
        data["role"] = "WRITER";
        apiService.auth.signup(data)
            .then(r => {
                setCookie('mawsuah_access_token',r.data.access_token, 1);
                setCookie('mawsuah_refresh_token',r.data.refresh_token, 7);
                const myStorage = window.localStorage;
                myStorage.setItem("mawsuah_user_data", JSON.stringify(r.data.user));
                window.scrollTo(0, 0);
                setIsSecondStep(true);
                setIsFirstStep(false);
            })
            .catch((err : AxiosError) => {
                if(err?.response?.data?.message.indexOf(`User with '${data.email}' email already exists`) === 0) {
                    setErrorText("لقد تم التسجيل بهذا البريد الإلكتروني من قبل. الرجاء إدخال بريد إلكتروني مختلف");
                    showModalError();
                } else if (err?.response?.data?.message.indexOf(`User with '${data.username}' username already exists`) === 0) {
                    setErrorText("اسم مستخدم غير متاح. الرجاء إدخال اسم مستخدم آخر");
                    showModalError();
                } else {
                    setErrorText(err?.response?.data?.message);
                    showModalError();
                }
            })
    };

    const onSubmitSecondStep = (data : SecondStepData) => {

        const specialisationVal : InterestsData = response?.data.interests.find((item: InterestsData) => item.title === data.specialisation);

        let arr = optionSubSpecialisation?.filter((obj) => { return SubWritingSpecialization.indexOf(obj.title) >= 0; });
        arr?.forEach((item) => {
            delete item["description"]
        });
        
        const secondStepData = {
            education : data.education,
            level_of_education : data.level_of_education,
            languages : Languages.join(", "),
            experiences : [
                {
                    experience : data.experience,
                    years_of_experience : +data.years_of_experience,
                    specialisation : {
                        id : +specialisationVal?.id
                    },
                    sub_specialisations : arr
                }
            ]
        }
        if(localStorage.getItem("mawsuah_user_data")) {
            apiService.auth.signupWriterSecondStep(JSON.parse(localStorage.getItem("mawsuah_user_data") as string).uuid, secondStepData)
                .then((r) => {
                    setCookie('mawsuah_access_token',r.data.access_token, 1);
                    setCookie('mawsuah_refresh_token',r.data.refresh_token, 7);
                    window.scrollTo(0, 0);
                    setIsSecondStep(false);
                    setIsThirdStep(true);
                })
        }
    };

    const handleSubmitAllForm = (data : WriterThirdStepData) => {
        if(status !== "Dont") {
            data["role"] = status
        }
        if(localStorage.getItem("mawsuah_user_data")) {
            apiService.auth.signupWriterThirdStep(JSON.parse(localStorage.getItem("mawsuah_user_data") as string).uuid, data)
                .then((r) => {
                    setCookie('mawsuah_access_token',r.data.access_token, 1);
                    setCookie('mawsuah_refresh_token',r.data.refresh_token, 7);
                    switch (status) {
                        case "WRITER_EDITOR" : 
                            history.push("/signup_writer_editor") 
                            break;
                        case "Dont" : 
                            window.scrollTo(0, 0)
                            showModal();
                            break;
                        case "WRITER_REVIEWER" : 
                            history.push("/signup_writer_reviewer")
                            break;
                        default: break;
                    } 
                })
        }
    };

    return (
        <View className="App">
            <View className="writersSignup">
                <View className="writersSignupContent">
                    <View className="writersSignupContentLogos" onClick={() => {history.push("/homepage")}}>
                        {/* <View className="Logo">
                            <Icon name="iconLogoBig1" alt="logo_picture"/>
                        </View> */}
                        <Icon name="iconGroupBlack" alt="logo_picture"/>
                    </View>
                    <View className="writersSignupContentText">
                        {/* <View className="subText"><span>معاً نبني أكبر مجتمع معرفيّ موثوق عربياً</span></View> */}
                        <SpacerVertical height={"2.222em"}/>
                        <View className="textTitleSub"><span>الرجاء تعبئة بياناتك الشخصية، وسيتم التواصل معك مباشرة من قِبل فريق موسوعة!
علماً أننا نطلق إليكم منصتنا بنسختها التجريبية لضمان أفضل جودة</span></View>
                        <SpacerVertical height={"2.222em"}/>
                        <View className="textTitle"><span>أنشئ حساب كاتب</span></View>
                        <SpacerVertical height={"2.357em"}/>
                        <View className="textTitleSub" >
                            {isFirstStep ?
                             <div className="StepActive">1</div> 
                            : <Icon className="iconStepper" name="iconSuccess" />}
                            <span>بياناتك في موسوعة</span>
                        </View>
                    </View>
                    {isFirstStep ? <SpacerVertical height={"1.857em"}/> :
                        <SpacerVertical height={"2.857em"}>
                            <div className="line" style={{height: "1.714em", top: "0.714em"}}></div>
                        </SpacerVertical>
                    }
                    <View className="writersSignupContentInputs">
                        {isFirstStep && 
                            <div className="form1" ref={ref}>
                            <div className="line" style={{height: lengthContainer, top: "-0.857em"}}></div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                            <View className="inlineFlex">
                                <InputObligatory name="first_name"  
                                label="الاسم الأول" 
                                placeholder="الرجاء إدخال اسمك الأول" 
                                maxLength={20} 
                                minLength={3}
                                pattern={/^[ \u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]*$/}  
                                register={register} 
                                errors={errors} 
                                inputPrompt="الرجاء إدخال اسمك الأول. يجب أن يتكوّن الاسم من حروف عربية من دون فواصل  أو رموز"
                                required 
                                errorMessage="الاسم الأول غير صحيح، الرجاء إدخال الاسم الأول مع مراعاة الشروط"
                                errorMessagePattern="الاسم الأول غير صحيح، الرجاء إدخال الاسم الأول مع مراعاة الشروط"/>
                                <InputObligatory name="last_name" 
                                label="الاسم الأخير" 
                                placeholder="الرجاء إدخال الاسم الأخير"  
                                minLength={3}
                                maxLength={20}  
                                pattern={/^[ \u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]*$/} 
                                register={register} 
                                errors={errors} 
                                errorMessage="الاسم الأخير غير صحيح، الرجاء إدخال الاسم الأخير مع مراعاة الشروط"
                                required
                                inputPrompt="الرجاء إدخال اسمك الأخير. يجب أن يتكوّن الاسم من حروف عربية من دون فواصل أو رموز"
                                errorMessagePattern="الاسم الأخير غير صحيح، الرجاء إدخال الاسم الأخير مع مراعاة الشروط"/>
                            </View>
                            <InputObligatory 
                            name="username" 
                            autocomplete="off" 
                            label="اسم المستخدم" 
                            placeholder="الرجاء إدخال اسم المستخدم"
                            minLength={4}
                            icon="iconExclamation" 
                            errorMessage="اسم مستخدم غير صحيح، يجب أن يتكوّن اسم المستخدم من أحرف إنجليزية فقط من دون فواصل أو فراغات أو رموز وعلى الأقل 4 أحرف. تأكد من استخدام اسم واضح وسهل للقراءة يستمر معك طيلة الرحلة"
                            inputPrompt="يجب أن يتكوّن اسم المستخدم من أحرف إنجليزية فقط من دون فواصل أو فراغات أو رموز وعلى الأقل 4 أحرف. تأكد من استخدام اسم واضح وسهل للقراءة يستمر معك طيلة الرحلة"
                            register={register} 
                            errors={errors} 
                            required
                            pattern={/^[a-zA-Z]+$/}
                            errorMessagePattern="اسم مستخدم غير صحيح، يجب أن يتكوّن اسم المستخدم من أحرف إنجليزية فقط من دون فواصل أو فراغات أو رموز وعلى الأقل 4 أحرف. تأكد من استخدام اسم واضح وسهل للقراءة يستمر معك طيلة الرحلة"
                            />
                            <InputObligatory 
                            name="email" 
                            label="البريد الإلكتروني" 
                            placeholder="الرجاء إدخال البريد الإلكتروني" 
                            register={register} 
                            errors={errors} 
                            errorMessage="الرجاء التحقق من البريد الإلكتروني"
                            required
                            minLength={8}
                            pattern={/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/}
                            errorMessagePattern="الرجاء التحقق من البريد الإلكتروني"/>
                            <InputObligatory 
                            name="password"
                            type="password"
                            autocomplete="off" 
                            label="كلمة السر" 
                            placeholder="الرجاء إدخال كلمة السر" 
                            register={register} 
                            errors={errors} 
                            inputPrompt="يجب أن تتكوّن على الأقل من 8 أحرف إنجليزية، حرف كبير واحد،  رقم واحد، و رمز خاص"
                            errorMessage="كلمة سر غير مقبولة، يجب أن تتكوّن على الأقل من 8 أحرف إنجليزية، حرف كبير واحد و رقم واحد ورمز خاص"
                            required
                            pattern={/(?=.*[0-9])(?=.*[!@#$%^_\-.&\]\\*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^_\-.&\]\\*]{8,}/}
                            errorMessagePattern="كلمة سر غير مقبولة، يجب أن تتكوّن على الأقل من 8 أحرف إنجليزية، حرف كبير واحد و رقم خاص"
                            onChange={handlePassword}/>
                            <InputObligatory 
                            name="confirm_password" 
                            autocomplete="off" 
                            type="password"
                            label="تأكييد كلمة السر" 
                            placeholder="الرجاء إعادة كتابة كلمة السر" 
                            register={register}  
                            errors={errors} 
                            errorMessage="كلمة سر غير مطابقة، الرجاء إعادة الكتابة"
                            required
                            password={password}
                            errorMessagePattern="كلمة سر غير مطابقة، الرجاء إعادة الكتابة"
                            />
                            <DropDown 
                            name="country" 
                            placeholder='الرجاء اختيار البلد' 
                            value={value} 
                            options={country} 
                            label={"البلد"} 
                            onChange={handleChange} 
                            register={register}
                            errors={errors} 
                            errorMessage="الرجاء اختيار البلد" 
                            required/>
                            <SpacerVertical height={"2em"}/>
                            <DropDown 
                            name="gender" 
                            placeholder='الرجاء اختيار الجنس' 
                            value={value1} 
                            options={['ذكر','أنثى']} 
                            label="الجنس" 
                            onChange={handleChange1} 
                            register={register} 
                            errors={errors} 
                            errorMessage="الرجاء اختيار الجنس" 
                            required/>
                            <SpacerVertical height={"2.286em"}/>
                                <Btn type="submit" className="writers-signupBtn" title="التالي" />
                            </form>
                            <SpacerVertical height={"1.571em"}/>
                            </div>
                        }
                        <View className="nextStepText" style={(isSecondStep || isThirdStep) ? {color: "var(--black)"} : {}}>
                           {isFirstStep ? <div className="StepDisabled">2</div> : 
                            isSecondStep ?  <div className="StepActive">2</div> : <Icon className="iconStepper" name="iconSuccess"/>}
                            <span>خبرات عملية</span>
                        </View>
                        <SpacerVertical height={!isSecondStep ? "2.857em" : "1.714em"}>
                            {!isSecondStep ? <div className="line" style={{height: "1.714em", top: "0.714em"}}></div> 
                            : <div className="line" style={{height: "1.714em", top: "1.714em"}}></div>}
                        </SpacerVertical>
                        {isSecondStep && 
                            <>
                                <div className="form1" ref={ref1}>
                                <div className="line" style={{height: lengthContainer1, top: "-0.857em"}}></div>
                                <form onSubmit={handleSubmit(onSubmitSecondStep)}>
                                <DropDown placeholder="الرجاء اختيار المجال الرئيسي" value={WritingSpecialization} options={optionSpecialisation} label={"مجال الكتابة الرئيسي"} subLabel={"(مجال واحد)"} onChange={handleSpecialisation} name="specialisation" register={register} errors={errors} errorMessage="الرجاء اختيار المجال الرئيسي" required/>
                                <SpacerVertical height={"2.286em"}/>
                                <DropDownMultiple placeholder="الرجاء اختيار المجالات الفرعي" value={SubWritingSpecialization} options={getSubSpecOpt(optionSubSpecialisation)} label={"مجالات كتابة فرعية"} subLabel={"(اختيار مجالات معينة)"} onChange={setSubWritingSpecialization} name="sub_specialisations" register={register} errors={errors} errorMessage="الرجاء اختيار المجال فرعي" required/>
                                <SpacerVertical height={"2.286em"}/>
                                <View className="inlineFlex">
                                    <DropDown className="inlineFlexDropdown" value={ExperienceField} options={EXPERIENCE} label={"مجال الخبرة العملية"} onChange={setExperienceField} placeholder="الرجاء إدخال مجال الخبرة العملية" name="experience" register={register} errors={errors} errorMessage="الرجاء إدخال مجال الخبرة العملية" required/>
                                    <SpacerHorizontal width={"2.145em"}/>
                                    <DropDown className="inlineFlexDropdown" value={YearsofExperience} options={yearsData} label="سنوات الخبرة العملية" onChange={setYearsofExperience} placeholder="الرجاء تحديد سنوات الخبرة العملية" name="years_of_experience" register={register} errors={errors} errorMessage="الرجاء تحديد سنوات الخبرة" required/>
                                </View>
                                <SpacerVertical height={"2.286em"}/>
                                <DropDown value={Education} options={EDUCATION} label={"تخصص التعليم"} onChange={setEducation} placeholder="الرجاء اختيار تخصص التعليم" name="education" register={register} errors={errors} errorMessage="الرجاء اختيار تخصص التعليم" required/>
                                <SpacerVertical height={"2.286em"}/>
                                <DropDown value={LevelofEducation} options={LEVEL_OF_EDUCATION} label="مستوى التعليم" onChange={setLevelofEducation} placeholder="الرجاء اختيار مستوى التعليم" name="level_of_education" register={register} errors={errors} errorMessage="الرجاء اختيار مستوى التعليم" required/>
                                <SpacerVertical height={"2.286em"}/>
                                <DropDownMultiple value={Languages} options={['العربية','الانجليزية','الفرنسية']} label="اللغات" onChange={setLanguages} placeholder="خلافاً عن اللغة العربية، ما اللغات الأخرى التي تفضل الكتابة فيها"  name="languages" register={register} errors={errors} errorMessage="الرجاء تحديد اللغات" required/>
                                <SpacerVertical height={"2.591em"}/>
                                <Btn type="submit" className="writers-signupBtn" title="التالي" />
                                </form>
                                <SpacerVertical height={"2.286em"}/>
                                </div>
                            </>
                        }
                        <View className="nextStepText" style={isThirdStep ? {color: "var(--black)"} : {}}>
                            {!isThirdStep ? <div className="StepDisabled">3</div> : <div className="StepActive">3</div>}
                           <span>معلومات كتابية</span>
                        </View>
                        {isThirdStep && 
                            <>
                            <form onSubmit={handleSubmit(handleSubmitAllForm)}>
                            <SpacerVertical height={"2.071em"}/>
                            <DropDown value={PreferedWritingLength} options={["150-500 كلمة",
                                "500- 1600 كلمة",
                                "1600 - 3000 كلمة"]} label={"ما هو طول المقال المفّضل لديك للكتابة؟"} onChange={setPreferedWritingLength} placeholder="الرجاء تحديد طول المقال" name="expected_article_length" register={register} errors={errors} errorMessage="الرجاء تحديد طول المقال المفّضل لديك للكتابة" required/>
                            <SpacerVertical height={"2.286em"}/>
                            <DropDown value={Expectednumberofwrittenarticlespermonth} options={['1','2','3','4']} isLastWordBold label="كم عدد المقالات المتوقع منك كتابتها شهرًّيا ؟" onChange={setExpectednumberofwrittenarticlespermonth} placeholder="الرجاء تحديد عدد المقالات" name="expected_number_of_articles" register={register} errors={errors} errorMessage="الرجاء تحديد عدد المقالات" required/>
                            <SpacerVertical height={"2.286em"}/>
                            <View className="thirdStepText">هل أنت قادر وراغب في أخذ دور مراجع؟</View>
                            <SpacerVertical height={"1.286em"}/>
                            <View className="Flex">
                                <InputRadioBox name="radio1" id="WRITER_REVIEWER" label="نعم"  handlecheck={(e: any) => handleCheck(e)} style={{marginLeft: "3.071em"}}/>
                                {/*<InputRadioBox name="radio1" id="WRITER_EDITOR" label="محرر"  handlecheck={(e: any) => handleCheck(e)} style={{marginLeft: "3.071em"}}/>*/}
                                <InputRadioBox name="radio1" id="Dont" label="لا"  handlecheck={(e: any) => handleCheck(e)}/>
                            </View>
                            <SpacerVertical height={"1.714em"}/>
                            <Btn type="submit" className="writers-signupBtn" title="تأكيد" />
                            </form>
                            </>
                        }
                        <SpacerVertical height={"21.8em"}/>
                    </View>
                </View>
                <View title="signup_picture" className={isFirstStep ? "writersSignupPicture" : isSecondStep ? "writersSignupPicture SecondPicture" : "writersSignupPicture ThirdPicture"}>
                        <View className="writersSignupPictureContent">
                            <View className="icon">
                                <Icon name="iconLogoBigLogin" alt="logo_picture" />
                            </View>
                            <SpacerHorizontal width={"1.8em"}/>
                            <View className="text">
                            <span>"لتكن موسوعة طريقك إلى نشر مقالاتك العلمية وجسر يصلك بالقرّاء! قرّاء كثر ينتظرون أن يقرؤوا ما تكتب
"</span>
                            </View>
                        </View>
                </View>
            </View>
            <RenderModal>
                <InterestsModal/>
            </RenderModal>
            <RenderModalError>
                <AlertModal alertText={errorText} alertHeader="" hideModal={hideModalError}/>
            </RenderModalError>
        </View>
    )
}