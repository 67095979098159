import React, { FunctionComponent, useEffect, useState } from 'react';
import { Btn } from '../../../../components/buttons/btn.component';
import { Loader } from '../../../../components/loader/loader.component';
import { SpacerVertical } from '../../../../components/spacer/spacer.component';
import { SuggestedArticlesItem } from '../../../../components/suggested-articles/nested/suggested-articles-item.component';
import { View } from '../../../../components/view/view.component';
import { useFetch } from '../../../../shared/hooks/fetch.hook';
import { ArtcileInfo } from '../../../../shared/interfaces';
import { apiService } from '../../../../shared/services/api.service';
import { activeClass } from '../../../../shared/utils';
import { ContentNotFound } from '../../../search/nested/content-not-found.component';

type userHighlights = {
    textHighlight: string;
    article: ArtcileInfo;
}


export const UserProfileAuthReadingList: FunctionComponent = () => {

    const {response: saverArticles, isLoading: saverArticlesLoading} = useFetch(() => apiService.articles.getAllSavedArticles(0, 11));
    const {response: allUserHighlights} = useFetch(() => apiService.comments.getAllUserHighlights(10));
    const {response: newestArticles} = useFetch(() => apiService.articles.getAllPublishedArticles(0, 10, apiService.auth.isAuthenticated()));
    
    

    const [isSavedArticles, setIsSavedArticles] = useState(true);
    const [isHighlighted , setIsHighlighted ] = useState(false);
    const [isNewest, setIsNewest] = useState(false);

    const [saveArticles, setSaveArticles] = useState<ArtcileInfo[]>([]);
    const [userHighlights, setUserHighlights] = useState<userHighlights[]>([]);
    const [statusFilter, setStatusFilter] = useState('Newest');

    const handleFilter = (status : string) => {
        if(status === "Newest") {
            setStatusFilter("Newest");
        }
        if(status === "MostRead") {
            setStatusFilter("MostRead");
        }
        if(status === "MostEngaged") {
            setStatusFilter("MostEngaged");
        }
    }

    useEffect(() => {
        setSaveArticles(saverArticles?.data?.articles);
    }, [saverArticles?.data?.articles])

    useEffect(() => {
        if(allUserHighlights?.data?.highlights.length) {
            const arr = allUserHighlights?.data?.highlights.map((item: any) => {
                const highlightsStrings = JSON.parse(item.entries);
                const finalAnswer = highlightsStrings?.map((el : string) => {
                    return {
                        textHighlight: el,
                        article: item.article
                    }
                })
                return finalAnswer
            })
            setUserHighlights(arr?.flat());
        }
    }, [allUserHighlights?.data?.highlights]); 

    return (
            <View className="UserProfileAuthHomePage">
                {/* <SpacerVertical height={"2.286em"} /> */}
            <View className="searchPageMenu">
                <View className={activeClass("searchPageMenuItem", isSavedArticles)}
                    onClick={() => {
                        setIsSavedArticles(true);
                        setIsHighlighted(false);
                        setIsNewest(false);
                    }}>
                مقالات المحفوظة  
                </View>
                {/* <View className={activeClass("searchPageMenuItem", isHighlighted)}
                        onClick={() => {
                            setIsSavedArticles(false);
                            setIsHighlighted(true);
                            setIsNewest(false);
                        }}>
                أبرز قراءتي
                </View> */}
                <View className={activeClass("searchPageMenuItem", isNewest)} 
                    onClick={() => {
                        setIsSavedArticles(false);
                        setIsHighlighted(false);
                        setIsNewest(true);
                    }}>
                أحدث قراءاتي
                </View> 
            </View>
            <SpacerVertical height={"2em"} />
            <View className="searchPageFilter">
                <View className={statusFilter === "Newest" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("Newest")}>
                الأحدث  
                </View>
                <View className={statusFilter === "MostRead" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostRead")} >
                الأكثر تصفحاً
                </View>
                <View className={statusFilter === "MostEngaged" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostEngaged")}>
                الأكثر تفاعلاً
                </View>
            </View>
            <SpacerVertical height={"3.143em"} />
            {!saverArticlesLoading ? <View className="searchPageContent">
                {isSavedArticles && 
                <>
                    {saveArticles?.length ? saveArticles?.map((item) => {
                        return (
                            <SuggestedArticlesItem 
                                published_at={item.published_at}
                                toSave
                                key={item.id}
                                title={item.title}
                                header={item.header}
                                image_url={item.image_url}
                                interest={item.interest}
                                author={item.author}
                                created_at={item.created_at}
                                minutes_for_reading={item.minutes_for_reading}
                                articleId={item.id}
                                status={item.status.name}
                                isSaved={item.is_saved}
                                updated_at={item.updated_at}
                            />
                        )
                    }) : <ContentNotFound />}
                </>}
                {isHighlighted && 
                    <>
                        {userHighlights?.length ? userHighlights?.map((item, indx) => {
                            return (
                                <View key={indx}>
                                    <View className="ArticleHighlited">{item.textHighlight}</View>
                                    <SpacerVertical height={"1.143em"}/>
                                    <SuggestedArticlesItem 
                                    toSave
                                    published_at={item.article.published_at}
                                    key={item.article.id}
                                    title={item.article.title}
                                    header={item.article.header}
                                    image_url={item.article.image_url}
                                    interest={item.article.interest}
                                    author={item.article.author}
                                    created_at={item.article.created_at}
                                    minutes_for_reading={item.article.minutes_for_reading}
                                    articleId={item.article.id}
                                    status={item.article.status.name}
                                    isSaved={item.article.is_saved}
                                    updated_at={item.article.updated_at}
                                    />
                                </View>
                            )
                        }) : <ContentNotFound />}
                    </>}
                {isNewest && 
                    <>
                    {newestArticles?.data?.articles?.map((item: ArtcileInfo) => {
                        return (
                            <SuggestedArticlesItem 
                            toSave
                            published_at={item.published_at}
                            key={item.id}
                            title={item.title}
                            header={item.header}
                            image_url={item.image_url}
                            interest={item.interest}
                            author={item.author}
                            created_at={item.created_at}
                            minutes_for_reading={item.minutes_for_reading}
                            articleId={item.id}
                            status={item.status.name}
                            isSaved={item.is_saved}
                            updated_at={item.updated_at}
                            />
                        )
                    })}
                    </>}
                <Btn title="استكشف أكثر" className="searchPageContentBtn"/>
            </View> : <Loader />}
            </View>
    )
}