import React, { FunctionComponent } from 'react';
import { Icon } from '../icons/icon.component';

import './btn.scss';

interface BtnProps {
    style?: any;
    title ?: string;
    disabled?: boolean;
    className ?: string;
    onClick ?: () => void;
    type ?: string;
}

export const Btn: FunctionComponent<BtnProps> = (props) => {
    const { title, disabled, className, onClick, style} = props;
    return (
        <button className={'Btn ' + className} disabled={disabled} onClick={onClick} style={style}>
            {title}
        </button>
    )
}
export const BtnMiddle: FunctionComponent<BtnProps> = (props) => {
    const { title, disabled, className, onClick} = props;
    return (
        <button className={'Btn BtnMiddle ' + className} disabled={disabled} onClick={onClick}>
            {title}
        </button>
    )
}
export const BtnMiddleIcons: FunctionComponent<BtnProps> = (props) => {
    const { title, disabled, className, onClick} = props;
    return (
        <button className={'Btn BtnMiddle ' + className} disabled={disabled} onClick={onClick}>
            <Icon name="iconAddMember"/>{title}
        </button>
    )
}
export const BtnColorlessMiddleIcons: FunctionComponent<BtnProps> = (props) => {
    const { title, disabled, className, onClick} = props;
    return (
        <button className={'BtnColorless BtnMiddle ' + className} disabled={disabled} onClick={onClick}>
            <Icon name="iconAddMemberBlue"/>{title}
        </button>
    )
}
export const BtnMiddleColorless: FunctionComponent<BtnProps> = (props) => {
    const { title, disabled, className, onClick} = props;
    return (
        <button className={'Btn BtnMiddle BtnMiddleColorless ' + className} disabled={disabled} onClick={onClick}>
            {title}
        </button>
    )
}

export const BtnIcons: FunctionComponent<BtnProps> = (props) => {
    const { title, disabled, className, onClick} = props;
    return (
        <button className={'Btn ' + className} disabled={disabled} onClick={onClick}>
            <Icon name="iconColorlessBook"/>{title}<Icon name="iconColorlessBook"/>
        </button>
    )
}

export const BtnUltraSmall: FunctionComponent<BtnProps> = (props) => {
    const { className, type } = props;
    return (
        <Btn {...props} type={type} className={'BtnColorlessUltraSmall ' + className} />
    )   
}

export const BtnColorless: FunctionComponent<BtnProps> = (props) => {
    const {  className, type } = props;
    return (
        <Btn {...props} type={type} className={'BtnColorless ' + className} />
    )
  }

export const BtnSmall: FunctionComponent<BtnProps> = (props) => {
    const {  className, type } = props;
    return (
        <Btn {...props} type={type} className={'BtnSmall ' + className} />
    )
}

export const BtnSmallIcons: FunctionComponent<BtnProps> = (props) => {
    const {  className, type } = props;
    return (
        <BtnIcons {...props} type={type} className={'BtnSmall ' + className} />
    )
}
export const BtnSmallColorless: FunctionComponent<BtnProps> = (props) => {
    const {  className, type } = props;
    return (
        <Btn {...props} type={type} className={'BtnSmall BtnSmallColorless ' + className} />
    )
}

export const BtnSmallColorlessIcons: FunctionComponent<BtnProps> = (props) => {
    const {  className, type } = props;
    return (
        <BtnIcons {...props} type={type} className={'BtnSmall BtnSmallColorless ' + className} />
    )
}

export const BtnSmallOpacity: FunctionComponent<BtnProps> = (props) => {
    const {  className, type } = props;
    return (
        <Btn {...props} type={type} className={'BtnSmall BtnSmallOpacity ' + className} />
    )
}

export const BtnSmallGray: FunctionComponent<BtnProps> = (props) => {
    const {  className, type } = props;
    return (
        <Btn {...props} type={type} className={'BtnSmall BtnSmallGray ' + className} />
    )
}

export const BtnRegister: FunctionComponent<BtnProps> = (props) => {
    const { title, disabled, className, onClick} = props;
    return (
        <button className={'Btn BtnRegister' + className} disabled={disabled} onClick={onClick}>
            {title}
        </button>
    )
}

export const BtnLogIn: FunctionComponent<BtnProps> = (props) => {
    const { title, disabled, className, onClick} = props;
    return (
        <button className={'Btn BtnLogIn' + className} disabled={disabled} onClick={onClick}>
            {title}
        </button>
    )
}