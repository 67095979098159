import React, {FunctionComponent, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useOnClickOutside } from '../../shared/hooks/click-outside.hook';
import useDebounce from '../../shared/hooks/debounce.hook';
import { AuthorData } from '../../shared/interfaces';
import { apiService } from '../../shared/services/api.service';
import { getUserId } from '../../shared/utils';
import { Btn } from '../buttons/btn.component';
import { Icon } from '../icons/icon.component';
import { Input, InputValue } from '../inputs/input.component';
import { View } from '../view/view.component';
import { useModal } from "../modal/modal.component";
import { LogoutModel } from "../logout-model/logout.model";

import './app-header.scss';
import { SearchDropdown } from './nested/searchDropdown';

interface AppHeaderProps {
    getSearchResults ?: ( searchResults: any) => void;
    isWhite?: boolean;
}

export const AppHeader: FunctionComponent<AppHeaderProps> = (props) => {

    const {getSearchResults, isWhite} = props;

    const [isSearch, setIsSearch] = useState(false);
    const [isAuth] = useState(apiService.auth.isAuthenticated());
    const [userData, setUserData] = useState<AuthorData>();
    const [role, setRole] = useState("");

    useEffect(() => {
        if(apiService.auth.isAuthenticated()){
            apiService.user.getCurrentUserProfile(getUserId())
            .then((r) => {
                setUserData(r?.data)
            })
        }
    }, [])

    useEffect(() => {
        if(localStorage.getItem("mawsuah_user_data")) {
            setRole(JSON.parse(localStorage.getItem("mawsuah_user_data") as string).role.name);
        } 
    }, []);

    
    const history = useHistory();
    const location : any = useLocation();
    const ref = useRef<HTMLDivElement>(null);
    useOnClickOutside(ref, () => {
        setIsSearch(false)
        setResults(null);
    });

    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState<any | null>(null);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect( () => {
          if (debouncedSearchTerm && location.pathname.split('/')[1] !== "search") {
            apiService.search.getSearchData(0, 3, debouncedSearchTerm, "short")
            .then(results => { 
                setResults(results as any); }) 
          }
          if(debouncedSearchTerm && location.pathname.split('/')[1] === "search") {
            apiService.search.getSearchData(0, 9, debouncedSearchTerm, "block")
            .then(results => {
                !!getSearchResults && getSearchResults(results);
            })}
          
    }, [debouncedSearchTerm, location.pathname, getSearchResults]);

    const handleChange = (e : InputValue) => {
        setSearchTerm(e.value);
        setIsSearch(true);
    }  

    const {showModal, RenderModal, hideModal} = useModal();
    

  return (
    <header style={isWhite ? {backgroundColor: "white", color: "black"} : {}} className="AppHeader">
        <View className="Flex">
            <View className="AppHeaderContent">
                <View style={{cursor: "pointer"}} onClick={() => {history.push("/homepage")}}>
                    <Icon name={isWhite ? "iconLogoBlack" : "iconGroupSmall"} />
                </View>
                <View className="AppHeaderContentMenu hello">
                    <p style={location.pathname.split("/")[1] === "about" ? {color: "var(--blueColor)"} : {}} onClick={() => {history.push("/about")}}>بانوراما</p>
                    <View className="border"></View>
                    <p style={location.pathname.split("/")[1] === "explore" ? {color: "var(--blueColor)"} : {}} onClick={() => {history.push("/explore")}}>استكشاف</p>
                    <View className="border"></View>
                    <p  style={location.pathname.split("/")[1] === "interests_landing" ? {color: "var(--blueColor)"} : {}} onClick={() => {history.push("/interests_landing")}}>اهتمامات</p>
                </View>
            </View>
            <div className="inputSearch" ref={ref} onKeyPress={(e) => {
               if(e.key === "Enter") {
                history.push({
                pathname: '/search',
                state: {
                    searchTerm: searchTerm,
                    string: "isArticles",
                    results: JSON.stringify(results?.data)
                }})
               }
            }}>
                <Input name="" 
                    value={searchTerm}
                    placeholder="ابحث عن" 
                    onChange={(e) => {handleChange(e)}} 
                    style={ isSearch ? 
                    {backgroundPosition: "29em 50%",
                    paddingRight: "3em",
                    border:"1px solid #D8DCE6",
                    borderBottom: "none",
                    borderRadius: "4px 4px 0 0"
                    } 
                    : isWhite ? {backgroundColor: "#F1F1F1"} : {}}/>
                {(isSearch && location.pathname.split('/')[1] !== "search") && <SearchDropdown results={results} searchTerm={searchTerm}/> }
            </div>
        </View>
        <View className="RegisterButtons">              
            <View className="RequestContent">
                <p style={location.pathname.split("/")[1] === "content_request" ? {color: "var(--blueColor)"} : {}} onClick={() => {history.push("/content_request")}}>اطلب كاتب</p>
            </View>
        </View>
        {isAuth ?
        <>
        <View className="LoggedIn">   
            {( role === "WRITER" || role === "WRITER_EDITOR" || role === "WRITER_REVIEWER") &&   
                <Icon style={{width: "20px", height: "20px", marginRight: "10px", cursor: "pointer"}} name={isWhite?"iconWriteBlackHeader" : "iconWriteHeader"} onClick={() => {history.push("/user_profile_auth/writer")}} alt="اكتب مقال"/>
            }
            {( role === "REVIEWER_EDITOR" || role === "REVIEWER" || role === "WRITER_REVIEWER") &&   
                <Icon style={{width: "20px", height: "20px", marginRight: "10px", cursor: "pointer"}} name={isWhite?"iconReviwBlackHeader" : "iconReviewHeader"} onClick={() => {history.push("/user_profile_auth/reviewer")}} alt="ركن المراجعة" />
            }
            {( role === "EDITOR" || role === "WRITER_EDITOR" || role === "REVIEWER_EDITOR") &&   
                <Icon style={{width: "20px", height: "20px", marginRight: "10px", cursor: "pointer"}} name={isWhite?"iconEditHeaderBlack" : "iconEditHeader"} onClick={() => {history.push("/user_profile_auth/editor")}} alt="ركن التحرير" />
            }
            <Icon src={userData?.image_url} style={{width: "32px", height: "32px", marginRight: "10px", borderRadius: "50%", cursor: "pointer", objectFit: "cover"}} onClick={() => {history.push('/user_profile_auth/profile')}} name="iconProfilePlaceholder" alt=""/>   
            <div className="userName" style={{cursor: "pointer"}} onClick={() => {history.push('/user_profile_auth/profile')}} >{userData?.first_name} {userData?.last_name}</div>            
            <Icon style={{width: "20px", height: "20px", marginRight: "10px", cursor: "pointer"}} name={isWhite?"iconLogOutBlack" : "iconLogOut"} onClick={() => {showModal()}} alt="تسجيل الخروج"
            // onClick={() => {
            //     apiService.auth.signout();
            //     setIsAuth(false);
            //     window.location.reload();
            // }}
            />
            <RenderModal style={{padding: "0px", width: "32.429em", top: "300px"}}>
                <LogoutModel hideModal={hideModal}/>
            </RenderModal>
        </View>
        </> : 
        <View className="RegisterButtons">            
            {/* {window.innerWidth < 768 ? <Icon style={{marginLeft: "1em"}} name="iconSearchWhite"/> : null} */}
            <Btn className="StartNow" title="ابدأ الآن" onClick={() => {history.push("/login")}}/>
            <Btn className="LogIn" title="تسجيل دخول" onClick={() => {history.push("/login")}}/>
            <Btn className="Register" title="أنشئ حساباً" onClick={() => {history.push("/main_signup")}}/>
        </View>
        }
    </header>
  )
}