import React, { FunctionComponent } from "react";

interface SpacerVerticalProps {
  height: number | string;
}

interface SpacerHorizontalProps {
  width: number | string;
}

export const SpacerVertical: FunctionComponent<SpacerVerticalProps> = (props) => {
  const { height } = props;
  return (
    <div className="Spacer" style={{ height: `${height}` }}>
      {props.children}
    </div>
  );
}

export const SpacerHorizontal: FunctionComponent<SpacerHorizontalProps> = (props) => {
  const { width } = props;
  return (
    <div className="Spacer" style={{ width: `${width}` }}></div>
  );
}