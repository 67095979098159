import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
import { Icon } from '../../../components/icons/icon.component';
import { BtnColorless } from '../../../components/buttons/btn.component';
import privacyBG from '../../../assets/images/PrivacyPage/privacyBG.png';

import "./privacy.scss";

export const PrivacyPage: FunctionComponent = () => { 

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
        <AppHeader isWhite={true}/>
        <View className="CareersPage">
            <View className="CareersPageHeader" title="careers_photo" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(${privacyBG})`}}>
                <Container style={{marginTop: "96px"}}>
                    <View className="CareersPageHeaderContent">
                        <Header1 title="خصوصيتك مسؤوليتنا" className="header1"/>
                    </View>
                    <View className="CareersPageHeaderContent">
                        <Icon name="iconMawsuahLogo"/>
                        <span className="bigText">موسوعة</span>
                        <Icon name="iconVerifiedSmallWhiteBG"/>
                    </View>
                </Container>
            </View>
            <SpacerVertical height={"4.5em"}/>
            <View className="CareersPageContent">
                <Container>
                    <Row>
                        <Col lg={3} className="CareersPageContentSecondColumn">
                            {/* <View className="CareersPageContentSecondColumnMenu" style={{borderBottom: "0px"}}>
                                <View className="Item">سياسية الخصوصية</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View>
                            <View className="CareersPageContentSecondColumnDisabled">
                                <View className="Item">المساعدة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المجتمعات</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">معتمدون لدى </View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الشركاء</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المدونة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الوظائف</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">شروط الاستحدام</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">التواصل</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View> */}
                        </Col>
                        <Col className="CareersPageContentFirstColumn" lg={9}>
                            <Header3 className="writersHeader" title="سياسة الخصوصية"/>
                            <div className="smallLine"></div>
                            <View className="CareersPageContentFirstColumnContent">
                            <View className="smallHeader">
                                <p>سياسة خصوصية "الملف الشخصي للأعمال" في موقع "موسوعة" www.mawsuah.com </p>
                                <p>أهلاً بك في سياسة خصوصية "الملف الشخصي للأعمال" في موقع "موسوعة" www.mawsuah.com يتم تشغيل وإدارة هذا الموقع من قِبل الشركة المالكة له (شركة التشعبية الرقمية للأفكار التقنية ذ.م.م " موسوعة"و/أو الشركات التابعة له و/أو الشركات المملوكة له). </p>                                                                            
                            </View>
                            <View className="CareersAuthor">
                                <View className="CareersAuthorPhoto">
                                    <div style={{cursor: "pointer"}}>
                                      <Icon name="iconProfilePlaceholder" alt="author_profile_photo" className="AuthorDataImage"/>
                                      <span className="AuthorPhotoSpan">موسوعة</span>
                                      <Icon style={{marginRight: "0.179em"}} name="iconVerifiedSmall"/>
                                    </div>
                                     <BtnColorless title="تابِع"/>
                                </View> 
                                <View className="CareersAuthorText">
                                    <span className="spanBold">تكنولوجيا</span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>20 تموز </span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>4 دقائق قراءة</span>
                                </View>
                            </View>
                            <SpacerVertical height={"2.3em"}/>
                            <View className="PrivacyContent">
                                <h4>
                                مقدمة:
                                </h4>
                                <p>
                                إن سياسة الخصوصية هذه تعلمك بسياستنا والطرق التي نجمع بها معلوماتك الشخصية، ونخزنها ونحميها وخصوصاً ما يتعلق باستعمال وإفشاء المعلومات الشخصية أثناء استعمالك للموقع بالإضافة للخيارات المتاحة لديك والمتعلقة بهذه المعلومات بموجب سياسة الخصوصية هذه؛ حيث إن أولى اهتماماتنا هو حفاظ وضمان خصوصية استعمالك للموقع وخصوصية المعلومات المقدمة من قِبلك على الموقع                                
                                </p>
                                <p>
                                نحن نقوم باستعمال معلوماتك الشخصية لتقديم الخدمات المتاحة في الموقع وتحسينها و/أو تحسين استعمالك للموقع. بمجرد استعمالك الموقع، أنت توافق على جمعنا للمعلومات واستعمالها وفقاً لهذه السياسة. 
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                تعريفات:
                                </h4>

                                  <p>
                                  لغايات هذه السياسة، يكون للمصطلحات التالية المعاني المحددة لها كما يلي ما لم تنص سياسة الخصوصية على خلاف ذلك:
                                </p>
                                <p>
                                مراقب المعلومات يعني نحن أو أي شخص طبيعي أو معنوي يقرر (لوحده أو مع الغير) الغاية أو الهدف من معالجة المعلومات الشخصية أو الطريقة التي تعالج بها، أو التي سيتم معالجتها بها.

</p>
<p>
بيانات الاستعمال تعني البيانات المجمعة بشكل تلقائي سواء كانت ناتجة عن استعمال الموقع و/أو ناتجة عن بنية الموقع ذاتها (على سبيل المثال مدة زيارة الصفحة).
</p>
<p>
مراقب المعلومات يعني نحن أو أي شخص طبيعي أو معنوي الذي يقرر (لوحده أو مع الغير) الغاية من معالجة المعلومات الشخصية أو الطريقة التي تعالج بها أو التي سيتم معالجتها بها.
</p>
<p>
معالجو المعلومات (أو مقدمي الخدمات) تعني أي شخص طبيعي أو معنوي يعالج المعلومات بالنيابة عن مراقب المعلومات.
</p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                تجميع المعلومات واستعمالها:
                                </h4>
                                <p>
                                نحن نقوم باستعمال خدمات تجديد النشاط التسويقي للإعلان على المواقع الإلكترونية للأطراف الثالثة بعد قيامك بزيارة الموقع. نحن والطرف الثالث نستعمل الكوكيز لتتبع النشاط على الموقع ولتحسين وتقديم الإعلانات وفقاً لزياراتك السابقة لموقعنا. تشمل الأطراف الثالثة على سبيل المثال وليس الحصر:
<ul>
<li>البريد الإلكتروني.</li>
<li>الاسم الأول والاسم الأخير.</li>
<li>رقم الهاتف.</li>
<li>العنوان، المنطقة، المحافظة، الرمز البريدي، المدينة.</li>
<li>الكوكيز وبيانات الاستعمال.</li>
</ul>

قد نستخدم معلوماتك الشخصية لايصالك بالخدمات والمواد الإعلانية والترويجية ومعلومات أخرى أياً كان نوعها التي قد تكون من ضمن اهتماماتك سواء تم تطويرها من قِبلنا لوحدنا أو من قِبل أطراف ثالثة أو بالتعاون فيما بيننا وبين أي طرف ثالث. 
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                بيانات الاستعمال:
                                </h4>
                                <p>
                                قد نعمل على تجميع المعلومات التي يقوم المتصفح الخاص بك بإرسالها عندما تقوم بزيارة الموقع أو دخولك له من خلال أو بواسطة جهاز هاتف نقال.
</p>
<p>
بيانات الاستعمال هذه قد تتضمن معلومات مثل معلومات عنوان الإنترنت البروتوكول لجهاز حاسوبك ونوع المتصفح وإصدار المتصفح وصفحات الموقع التي تقوم بزيارتها ووقت وتاريخ زيارتك والوقت الذي استغرقته على هذه الصفحات ومعرفات الجهاز النادرة وغيرها من البيانات التشخيصية.
</p>
<p>
عندما تقوم بالدخول للموقع من خلال جهاز هاتف محمول، بيانات الاستعمال هذه قد تتضمن معلومات مثل نوع جهاز الهاتف المحمول الذي تستعمله ومعرف الهاتف الفريد الخاص بهاتفك المحمول وعنوان الإنترنت والبروتوكول الخاص بهاتفك المحمول ونوع متصفح الإنترنت عبر الهاتف المحمول المستعمل من قِبلك ومعرفات الجهاز النادرة وغيرها من البيانات التشخيصية.
 </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                بيانات الموقع:
                                </h4>
                                <p>
                                قد نستعمل ونخزن معلومات عن موقعك إذا قمت بالسماح لنا بالقيام بذلك. نستعمل هذه البيانات لتقديم مميزات متعلقة بخدمتنا ولتحسين الخدمة وتكييفها تباعاً لذلك. أنت تستطيع تفعيل أو عدم تفعيل خدمات الموقع عند استعمالك للخدمة بأي وقت من خلال إعدادات جهازك.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                بيانات الكوكيز والتتبع:
                                </h4>
                                <p>
                                نحن نقوم باستعمال الكوكيز وتكنولوجيا تتبع مماثلة لتتبع النشاط على الموقع ونقوم بالاحتفاظ بمعلومات معينة.
</p>
<p>
الكوكيز هي ملفات تحتوي بيانات قليلة وقد تتضمن أدوات تعريف نادرة وغامضة. يتم إرسال الكوكيز للمتصفح الخاص بك من موقع إلكتروني ويتم تخزينها على جهازك. يتم استعمال تكنولوجيا تتبع من أنواع أخرى مثل البيكونات والعلامات المميزة والنصوص وذلك لغايات تجميع المعلومات وتتبعها ولتطوير الخدمات وتحليلها.
</p>
<p>
بإمكانك تقييد المتصفح الخاص بك ليقوم برفض الكوكيز أو ليقوم بتحديد والإشارة إلى عملية إرسال كوكي. بأي حال من الأحوال، إذا كنت لا توافق على الكوكيز فإنك قد لا تتمكن من استعمال أجزاء معينة من الموقع.

                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                استعمال البيانات:
                                </h4>
                                <p>
                                نحن نقوم باستعمال البيانات المجمعة لغايات متنوعة، تشمل على سبيل المثال وليس الحصر:
<ul>
<li>لغايات تقديم خدماتنا.</li>
<li>لغايات إشعارك عن أي تغييرات بخدماتنا.</li>
<li>لتمكينك بناء على اختيارك من المشاركة في الخاصيات التفاعلية لخدمتنا.</li>
<li>لتقديم خدمة دعم العملاء.</li>
<li>لتجميع تحليلات أو معلومات قيمة تمكننا من تطوير خدماتنا و/أو الموقع.</li>
<li>لمراقبة استعمال خدماتنا و/أو الموقع.</li>
<li>لكشف ومعالجة المشاكل التقنية والحد منها.</li>
<li>لتزويدك بالأخبار والعروض الخاصة ومعلومات عامة عن بضائع وخدمات ومناسبات أخرى التي نقدمها والتي تتشابه مع البضائع والخدمات التي قمت بشرائها أو الاستفسار عنها إلا إذا قمت بالانسحاب من تلقي مثل هذه المعلومات.</li>
<li>نحن نستخدم شركات أخرى وأفراد لأداء بعض المهام نيابة عنا. مثل إرسال رسائل ترويجية في البريد الإلكتروني، وتحليل البيانات وتقديم المساعدة في مجال العقارات ومعالجة طريقة الدفع وغيرها من الخدمات، وبحسب ما يقتضي الطلب.</li>
 
</ul>
</p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                ضمان معلوماتك الخاصة:
                                </h4>
                                <p>
                                من المهم بالنسبة لك الحماية ضد الوصول غير المصرح به إلى كلمة السر الخاصة بك وإلى جهاز الكمبيوتر الخاص بك. ويجب أن تكون على يقين من أنك قمت بتسجيل الخروج بعد الانتهاء من استخدام أي جهاز كمبيوتر مشترك.
                            </p>
                            </View>

                            <View className="PrivacyContent">
                                <h4>
                                الأسس القانونية لمعالجة البيانات وفقاً لأحكام النظام العام لحماية البيانات (GDPR):
                                </h4>
                                <p>
                                إذا كنت من المنطقة الاقتصادية الأوروبية، فإن الأسس القانونية المتبعة من قِبل الموقع في جمع المعلومات الشخصية واستعمالها كما هي موصوفة في سياسة الخصوصية تعتمد على المعلومات الشخصية التي نقوم بجمعها والسياق المحدد الذي تم جمعها به.
</p>
<p>
قد نقوم بمعالجة معلوماتك الشخصية لأي من تلك الأسباب:
<ul>
 حاجتنا للاتصال بك.
<li>قد قمت بالسماح لنا بالقيام بذلك.</li>
<li>إذا كانت المعالجة لمصلحتنا المشروعة ولا تتجاوز حقوقك.</li>
<li>لغايات عمليات الدفع.</li>
<li>للالتزام بأحكام القانون.</li>
</ul>
</p>
<p>
الرجاء الاتصال بنا إذا كنت ترغب بمعرفة أي معلومات شخصية عنك نحتفظ بها وإذا كنت ترغب بأن تخفي هذه المعلومات من أنظمتنا. يكون لديك حقوق حماية البيانات التالية:
<ul>
<li>الحق بالدخول أو تحديث المعلومات التي نملكها عنك. بأي وقت ممكن. الرجاء الاتصال بنا لمساعدتك في حال عدم تمكنك من ممارسة هذه الأفعال.</li>
<li>الحق بالتصحيح. لديك الحق بتصحيح معلوماتك بحال إن كانت هذه المعلومات غير دقيقة أو ناقصة.</li>
<li>الحق بالاعتراض. لديك الحق بالاعتراض على قيامنا بمعالجة معلوماتك الشخصية.</li>
<li>الحق بالتقييد. لديك الحق بأن تقدم لنا طلب بتقييد معالجة معلوماتك الشخصية.</li>
<li>الحق بنقل البيانات. لديك الحق بأن يتم تزويدك بنسخة عن المعلومات التي بحوزتنا عنك وذلك بشكل منظم، مقروء ومستعمل بشكل متعارف عليه.</li>
<li>الحق بسحب الموافقة. لديك أيضاً الحق بأي وقت بسحب موافقتك التي اعتمدنا عليها وبناءً عليها قمنا بمعالجة معلوماتك الشخصية.</li>
</ul>
</p>
<p>
قد نطلب منك تثبيت هويتك قبل الرد على أي من هذه الطلبات.
</p>
<p>
لديك الحق بأن تشكو لسلطة حماية البيانات عن قيامنا بتجميع معلوماتك الشخصية واستعمالنا لها. لمزيد من المعلومات، الرجاء الاتصال بسلطة حماية البيانات المحلية لديك في المنطقة الاقتصادية الأوروبية.

                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                الاحتفاظ بالمعلومات:
                                </h4>
                                <p>
                                سنحتفظ بمعلوماتك الشخصية فقط خلال الوقت الذي يكون به هذا الأمر ضروري للغايات المحددة في سياسة الخصوصية. سنحتفظ بمعلوماتك الشخصية ونستعملها فقط للحد الضروري لتوافقنا مع التزاماتنا القانونية (على سبيل المثال، إذا كان من المطلوب منا أن نعمل على الاحتفاظ ببياناتك التزاما بالقوانين المطبقة) ولحل النزاعات ولتطبيق اتفاقياتنا القانونية وسياساتنا. 
                                </p>
                                <p>سنعمل أيضاً على الاحتفاظ ببيانات الاستعمال للغايات التحليلية الداخلية. بشكل عام، بيانات الاستعمال يتم الاحتفاظ بها لوقت أقصر، فيما عدا الحالات التي تكون فيها البيانات مستعملة لتقوية الأمن وتطوير وظائف خدماتنا و/أو الموقع أو الحالات التي نكون فيها ملزمون قانونياً بالاحتفاظ بهذه البيانات لوقت أطول.
</p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                تحويل البيانات:
                                </h4>
                                <p>
                                من الممكن تحويل وحفظ معلوماتك، بما في ذلك المعلومات الشخصية، على أجهزة حاسوب متواجدة خارج اختصاصك الجغرافي أو أي اختصاص حكومي آخر يكون فيها قوانين حماية البيانات تختلف عن تلك الخاصة بمنطقتك واختصاصك.
</p>
<p>
إن موافقتك على سياسة الخصوصية هذه وقيامك تبعاً لذلك بتقديم المعلومات يمثل موافقتك على تحويل بياناتك لأي دولة أخرى يتواجد فيها أي من شركاتنا التابعة و/أو الحليفة.
</p>
<p>
نحن نقوم باتخاذ كافة الإجراءات الضرورية بشكل معقول للتأكيد على أنه يتم التعامل مع بياناتك بطريقة آمنة وبما يتوافق مع سياسة الخصوصية هذه وأنه لن يتم تحويل بياناتك الشخصية لأي منظمة أو دولة إلا بحالة توافر ضوابط كافية بما في ذلك أمن بياناتك والمعلومات الشخصية الأخرى.

                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                الإفشاء تطبيقاً لأحكام القانون:
                                </h4>
                                <p>
                                في ظروف معينة، قد يكون من المطلوب منا أن نقوم بالإفشاء عن معلوماتك الشخصية بحال تطلب ذلك القانون أو للإجابة على طلبات السلطات الحكومية النافذة (على سبيل المثال المحكمة أو جهة حكومية). 
قد نقوم بالإفشاء عن معلوماتك الشخصية بحسن نية إذا كان هذا الفعل ضرورياً لـ:
<ul>
<li>تطبيق الالتزامات القانونية.</li>
<li>لحماية حقوق موضوع ليمتد و/أو أي من شركاتها التابعة و/أو الحليفة أو أي من ممتلكاتها والدفاع عنها.</li>
<li>للحد من أي فعل خاطئ متعلق بالموقع.</li>
<li>لحماية الأمن الشخصي لمستخدمي الموقع و/أو العامة.</li>
<li>للحماية في مواجهة المسؤولية القانونية.</li>
</ul>
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                أمن البيانات:

                                </h4>
                                <p>
                                إن أمن بياناتك مهم لدينا ولكن لا بد من التذكر بأن وسائل النقل عبر الإنترنت ووسائل التخزين الإلكتروني ليست 100% آمنة، في الوقت الذي نسعى به لاستعمال الوسائل التجارية المقبولة لحماية معلوماتك الشخصية إلا أننا لا نضمن أنها آمنة بشكل مطلق حيث أن بعض الأحداث قد تقع خارج سيطرتنا ولا تكون متعلقة بسلوك متعمد و/أو الإهمال المتعمد من قِبلنا.

</p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                الإعلانات:
                                </h4>
                                <p>
                                قد نعمل على استعمال أطراف ثالثة من مقدمي الخدمات لعرض الإعلانات لك للمساعدة على دعم الخدمات و/أو الموقع والمحافظة عليهم. تشمل الأطراف الثالثة على سبيل المثال وليس الحصر:
<ul>
<li>Google AdSense DoubleClick Cookie</li>
<li>AdMob by Google</li>
</ul>


                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                تجديد النشاط التسويقي السلوكي:

                                </h4>
                                <p>
                                نحن نقوم باستعمال خدمات تجديد النشاط التسويقي للإعلان على المواقع الإلكترونية للأطراف الثالثة بعد قيامك بزيارة الموقع. نحن والطرف الثالث نستعمل الكوكيز لأعلام وتحسين وتقديم الإعلانات وفقاً للزيارات السابقة لديك لموقعنا. تشمل الأطراف الثالثة على سبيل المثال وليس الحصر:
                                <ul>
                                <li>إعلانات جوجل.</li>
                                <li>تويتر.</li>
                                <li> فيسبوك.</li>
                                </ul>
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                الدفع:
                                </h4>
                                <p>
                                قد نقوم بتزويدك بمنتجات بأجر و/أو خدمات مشمولة بالموقع. بهذه الحالة، نحن نقوم باستعمال خدمات أطراف ثالثة لعمليات الدفع (مثل معالجي الدفع). 
</p>
<p>
لن نقوم بتخزين أو جمع معلومات بطاقة الدفع الخاصة بك. يتم تزويد هذه المعلومات للطرف الثالث بها مباشرة بحيث يكون استعمالهم لمعلوماتك الشخصية معالج بسياسة الخصوصية الخاصة بهم.


                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                الروابط لمواقع أخرى:

                                </h4>
                                <p>قد يحتوي الموقع على روابط لمواقع أخرى التي لا يتم تشغيلها من قِبلنا. نحن ننصحك بأن تعمل على مراجعة سياسية الخصوصية لكل موقع إلكتروني تزوره.
                                </p>
<p>
نحن لا نملك السيطرة ولا نتحمل المسؤولية على المحتوى، سياسيات الخصوصية أو الممارسات لمواقع الأطراف الثالثة وخدماتهم.

                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                التعديل على سياسة الخصوصية:

                                </h4>
                                <p>
                                قد نعمل على تحديث سياسة الخصوصية هذه من وقت لآخر دون إشعار. ننصحك بأن تعمل على قراءة ومراجعة سياسة الخصوصية هذه بشكل دوري. إن أي تغييرات على سياسة الخصوصية هذه يكون معمولاً بها من تاريخ نشرها على الموقع.


                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                للاتصال بنا:

                                </h4>
                                <p>
                                الرجاء الاتصال بنا بحال وجود أي استفسار لديكم يتعلق بسياسة الخصوصية هذه عن طريق البريد الإلكتروني: ask@mawsuah.com


                                </p>
                            </View>
                            {/* <View className="PrivacyInterests">
                                <View>الذكاء الاصطناعي</View>
                                <View>شركات ناشئة</View>
                                <View>تكنولوجيا</View>
                                <View>ادارة اعمال</View>
                            </View> */}
                            {/* <View className="PrivacyLike">
                                    <Icon name="iconLikeBlue"/>
                                    <span>546,932</span>
                            </View> */}
                             {/* 
                             <View className="PrivacyViewers">
                                <View>
                                    <Icon name="iconViewers"/>
                                    <span style={{marginRight: "8px"}}>897,538</span>
                                </View>
                                <View>
                                    <Icon name="iconBookmarkRounded" alt="bookmark_picture" />
                                    <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                                    <Icon name="iconShare"/>
                                    <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                                    <Icon name="iconMore"/>
                                </View>
                            </View>
                            */}
                             {/* 
                            <View className="PrivacySubAuthorData">
                                <View>
                                    <Icon name="iconProfilePlaceholder"/>
                                </View>
                                <View>
                                    <View className="PrivacySubAuthorDataInfo">
                                        <div style={{cursor: "pointer"}}>
                                        <span className="AuthorPhotoSpan">موسوعة</span>
                                        <Icon style={{marginRight: "0.179em"}} name="iconVerifiedSmall"/>
                                        </div>
                                        <BtnColorless title="تابِع"/>
                                    </View>
                                    <View>
                                        <span>323 مقال</span>
                                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                                        <span>116 ألف متابَع</span>
                                    </View>
                                </View>
                            </View>
                        */}
                            </View>
                        </Col>
                    </Row>
                </Container>
                <SpacerVertical height={"3.3em"}/>
            </View>
            <AppFooter />
        </View>
    </View>
    )
}