import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
import { Icon } from '../../../components/icons/icon.component';
// import { BtnColorless } from '../../../components/buttons/btn.component';
import privacyBG from '../../../assets/images/StaticPages/FaqPage/faqBG.png';

import "./faq.scss";

export const FaqPage: FunctionComponent = () => { 

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
        <AppHeader isWhite={true}/>
        <View className="CareersPage">
            <View className="CareersPageHeader" title="careers_photo" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(${privacyBG})`}}>
                <Container style={{marginTop: "96px"}}>
                    <View className="CareersPageHeaderContent">
                        <Header1 title="الأسئلة الأكثر شيوعاً" className="header1"/>
                    </View>
                    <View className="CareersPageHeaderContent">
                        <Icon name="iconMawsuahLogo"/>
                        <span className="bigText">موسوعة</span>
                        <Icon name="iconVerifiedSmallWhiteBG"/>
                    </View>
                </Container>
            </View>
            <SpacerVertical height={"4.5em"}/>
            <View className="CareersPageContent">
                <Container>
                    <Row>
                        <Col lg={3} className="CareersPageContentSecondColumn">
                            {/* <View className="CareersPageContentSecondColumnMenu" style={{borderBottom: "0px"}}>
                                <View className="Item">سياسية الخصوصية</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View>
                            <View className="CareersPageContentSecondColumnDisabled">
                                <View className="Item">المساعدة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المجتمعات</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">معتمدون لدى </View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الشركاء</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المدونة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الوظائف</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">شروط الاستحدام</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">التواصل</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View> */}
                        </Col>
                        <Col className="CareersPageContentFirstColumn" lg={9}>
                            <Header3 className="writersHeader" title="الأسئلة الأكثر شيوعاً"/>
                            <div className="smallLine"></div>
                            <View className="CareersPageContentFirstColumnContent">
                            <View className="smallHeader">
                           
                            </View>
                            <View className="CareersAuthor">
                                <View className="CareersAuthorPhoto">
                                    <div style={{cursor: "pointer"}}>
                                      <Icon name="iconProfilePlaceholder" alt="author_profile_photo" className="AuthorDataImage"/>
                                      <span className="AuthorPhotoSpan">موسوعة</span>
                                      <Icon style={{marginRight: "0.179em"}} name="iconVerifiedSmall"/>
                                    </div>
                                     {/* <BtnColorless title="تابِع"/> */}
                                </View> 
                                <View className="CareersAuthorText">
                                    <span className="spanBold">تكنولوجيا</span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>٢٩ يناير </span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>4 دقائق قراءة</span>
                                </View>
                            </View>
                            <SpacerVertical height={"2.3em"}/>
                            <View className="PrivacyContent">
                                <h4>
                                1) هل هناك شروط لدور المراجع في موسوعة؟
                                </h4>
                                <p>
                                بكل تأكيد، فعند تسجيل المستخدم بدور مراجع، يجب أن يكون أيضاً مراجعاً مختصاً في اهتمام معيّن، ليقوم بدوره التأكد من صحة ودقة معلومات المقال المكتوب من قِبل الكاتب.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                2) هل هناك خاصية للتواصل بين الكاتب والمراجع؟
                                </h4>
                                <p>
                                خلال نظام المراجعة، يكون هناك فرصة للنقاش بكل شفافية ما بين الكاتب والمراجع لاعتماد صيغة نهائية للمقال.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                3) هل اختيار اهتمامات فرعية يعد شرطاً إجبارياً؟
                                </h4>
                                <p>
                                نعم، فإن هذا يساهم في:
                                </p>
                                <ol>
                                    <li>وصول المقال للمراجعين ضمن ذات الاهتمام. </li>
                                    <li>وصول المقال لعدد أكبر من القراء المهتمين في المجال الرئيسي.</li>
                                </ol>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                4) ماذا يعني كتابة 3 أقسام أو أكثر داخل المقال؟
                                </h4>
                                <p>
                                يعني أن يقوم الكاتب بتقسيم مقاله إلى عدة فقرات ليكون أكثر وضوحاً وتنظيماً، مما يمنح المقال قوة أكبر. حيث يساعد ذلك في وصول مقالات الكتّاب إلى عدد أكبر من القرّاء والاستفادة من نظام المكافآت لاحقاً، ونعني هنا بجعل المقال مقسماً ما بين نص، صورة، فيديو، اقتباسات.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                5) هل يستطيع الكاتب المباشرة فوراً بإنتاج المحتوى بمجرد انضمامه؟ 
                                </h4>
                                <p>
                                بكل تأكيد يمكن للكاتب المباشرة فوراً بالكتابة بمجرد إنشائه حسابه الشخصي في موسوعة. 

                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                6) ماذا لو واجه الكاتب مشكلة خلال عملية كتابة المقال؟
                                </h4>
                                <p>
                                إذا واجه الكاتب أي خلل تقني أو صعوبة في الكتابة والنشر على المنصة، فإن فريق موسوعة جاهز دائماً للمساعدة والإجابة عن أي استفسار.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                7) هل يوجد أية قيود على منتجي المحتوى المنضمين لموسوعة؟
                                </h4>
                                <p>
                                موسوعة هي منصة لامركزية حرة لا تفرض قيوداً على منتجي المحتوى، حيث يمكن للمستخدم إنشاء صفحة شخصية خاصة له، ودورنا في موسوعة تسليط الضوء على منتجي المحتوى ومنحهم التقدير الذي يستحقونه.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                8) هل هناك أي تعارض بين إنتاج للمحتوى في موسوعة، والعمل مع أي منصة أخرى في الوقت ذاته؟
                                </h4>
                                <p>
                                لا يوجد تعارض، حيث إن موسوعة ليست مؤسسة عمل، وإنما هي منصة حرة لا مركزية، لكن هناك شروطاً لكتابة المقالات حفاظاً على الخصوصية والسرية، وللمزيد من المعلومات يمكن تتبع الرابط التالي: 
                                <br/>
                                <a href="https://mawsuah.com/rules">https://mawsuah.com/rules</a>
                                </p>
                            </View>                                                 
                            <View className="PrivacyContent">
                                <h4>
                                9) ما هي شروط كتابة المقال في موسوعة؟
                                </h4>
                                <p>
                                من شروط كتابة أي مقال في موسوعة أن يكون مقالاً أصلياً باللغة العربية (غير منسوخ من أي موقع آخر)، مع وجود 3 مراجع على الأقل، وللمزيد من المعلومات يمكن تتبع الرابط التالي: 
                                <br/>
                                <a href="https://mawsuah.com/rules">https://mawsuah.com/rules</a>                                
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                10) هل الالتزام بشروط كتابة المحتوى تعود بالفائدة على الكاتب؟
                                </h4>
                                <p>
                                إن الالتزام بشروط كتابة المقال يمنح الكاتب موثوقية أعلى وسرعة انتشار ووصول أكبر للباحثين عن المعلومة المختّصة والموثوقة.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                11) هل سيتم تدريب منتجي المحتوى على قواعد الـ SEO واختيار الكلمات المفتاحية الأكثر بحثاً وكيفية تحسين ظهور المقال على محركات البحث؟
                                </h4>
                                <p>
                                موسوعة تعتمد في استراتيجية عملها على الـ SEO "تحسين محركات البحث"، ولأننا ندعم منتجي المحتوى سنقوم بإرسال قائمة بالمواضيع والعناوين المقترحة الأكثر بحثاً على محركات البحث، لكتابة مقالات تصل بشكل أسرع للباحثين عن المعلومة. 
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                12) هل الكاتب ملزم في الكتابة ضمن قائمة المواضيع المقترحة من قِبل موسوعة؟
                                </h4>
                                <p>
                                الكاتب غير ملزم بالكتابة من ضمن قائمة المواضيع المقترحة، وإنما هو جزء من الدعم المعنوي الذي تقدمه موسوعة لمنتجي المحتوى. 
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                13) هل هناك أية خبرات أخرى سيكتسبها منتج المحتوى في موسوعة؟ 
                                </h4>
                                <p>
                                لاحقاً، سنقوم بإطلاق أكاديمية تدريب تمنح منتج المحتوى الفرصة لاكتساب خبرات متنوعة من خلال برامج تدريبية مدفوعة.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                14) هل يمكن لمنتج المحتوى اختيار أكثر من اهتمام رئيسي؟
                                </h4>
                                <p>
                                في الوقت الحالي، على الكاتب اختيار الاهتمام الرئيسي الأقرب إلى تخصصه في الكتابة، لكن لاحقاً سيتم العمل على إمكانية اختيار أكثر من اهتمام رئيسي لمنتج المحتوى.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                15) كيف تتم آلية عملية نظام المكافآت المادية والمعنوية في موسوعة؟
                                </h4>
                                <p>
                                نظام المكافآت المعنوي قائم على الأوسمة، التسويق والتشبيك، أما نظام المكافآت المادي قائم على النقاط، حيث يتم بناؤه حالياً وفقاً لمعايير محددة ومتنوعة، لكن تفعيله يعتمد على مدى تفاعل المستخدمين في المنصة.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                16) ماذا يميز منصة موسوعة عن منصة فيسبوك أو يوتيوب، ومنصات التواصل الاجتماعي إجمالاً؟ 
                                </h4>
                                <p>
                                موسوعة هي منصة مختّصة، تساعد الكاتب في التسويق لاسمه بطريقة محترفة تساعده في الظهور بشكل أكبر على محركات البحث، إلى جانب كونها مصدراً موثوقاً، يعتمد على آلية نظام مراجعة مجتمعي غير متحيّزـ مبني على آلية عمل النظير للنظير (peer to peer)، كما تساعد موسوعة منتجي المحتوى على تدقيق محتوياتهم لغوياً.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                17) هل سيكون هناك عقد التزام بين الكاتب ومنصة موسوعة؟
                                </h4>
                                <p>
                                موسوعة هي منصة حرة لا مركزية، وليست مؤسسة عمل حيث أنه وبمجرد إنشاء منتج المحتوى حساب شخصي له يستطيع كتابة ما يرغب من مقالات عبر مساحته الخاصة ليوفر بذلك مواد مختصة تصله بالباحثين عن المعلومة. 
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                18) كيف تستخدم موسوعة النظام اللامركزي وآلية البلوكتشين التي تعمل بها موسوعة، وماذا يستفيد الكاتب من هذه الخاصية؟
                                </h4>
                                <p>
                                استندت موسوعة إلى فكرة "الويب 03 Web" التي تضمن حماية أفضل لخصوصية المستخدمين من خلال اعتمادها على الأنظمة اللامركزية مثل تقنية البلوكشين Blockchain التي توفر التتبع، الشفافية، والموثوقية. حيث تتيح موسوعة لمنتجي المحتوى العربي المختّصين مساحة خاصة؛ ليصبح مجتمع موسوعة هو المتحكم بإصدار المعلومة والمسؤول عنها، وليمثل بدوره المرجع الموثوق لكل الباحثين عن المعلومة باللغة العربية.
                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                19) هل يمكن للمستخدم تعديل معلوماته وأدواره المختارة مسبقاً عبر منصة موسوعة؟
                                </h4>
                                <p>
                                أجل، يمكن لأي مستخدم عبر منصة موسوعة تعديل معلومات حسابه عبر صفحته الشخصية.
لكن فيما يتعلّق بتعديل الأدوار، في الوقت الحالي على منتج المحتوى اختيار الدور الأنسب له، لكننا نعمل على آلية تحديث الأدوار في المستقبل.

                                </p>
                            </View>
                            </View>
                        </Col>
                    </Row>
                </Container>
                <SpacerVertical height={"3.3em"}/>
            </View>
            <AppFooter />
        </View>
    </View>
    )
}