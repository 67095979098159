import React, { FunctionComponent } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { ArtcileInfo } from '../../shared/interfaces';
import { Icon } from '../icons/icon.component';
import { SpacerVertical } from '../spacer/spacer.component';
import { View } from '../view/view.component';

import "./latest-random-article.scss"

interface LatestRandomArticleProps {
    lgPicture: number;
    lgText: number;
    articleData: ArtcileInfo;
}

export const LatestRandomArticle: FunctionComponent<LatestRandomArticleProps> = (props) => {

    const {lgPicture, lgText, articleData} = props;
    const history = useHistory();

    return (
        <View className="LatestRandomArticle">
            <Container>
                <Row>
                    <Col lg={lgText} className="LatestRandomArticleContent">
                        <SpacerVertical height={"4.14em"} />
                        <View className="contentTitleBlue" onClick={() => {history.push(`/interests_tabs/${articleData?.interest?.id}`)}}>{articleData?.interest?.title}</View>
                        <View className="contentTitleBlack" onClick={() => {history.push(`/user_profile/${articleData?.author?.id}`)}}>{articleData?.author?.first_name} {articleData?.author?.last_name}</View>
                        <SpacerVertical height={"1.1em"} />
                        <View className="contentText">
                        {articleData?.header}
                        </View>
                        <SpacerVertical height={"1.1em"} />
                        <View style={{cursor: "pointer"}} className="moreArticles" onClick={() => history.push(`/article/${articleData?.id}`)}>
                            <div>استكشاف المزيد</div>
                            <Icon name="iconArrowLeft" />
                        </View>
                    </Col>
                    <Col lg={lgPicture} className="LatestRandomArticlePhoto">
                        <Icon src={articleData?.image_url}  name="iconMoon" alt={articleData?.title}/>
                    </Col>
                </Row>
                
            </Container>
        </View>
    )
}