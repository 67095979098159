import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
// import { BtnColorless, BtnUltraSmall } from '../../../components/buttons/btn.component';
import { Icon } from '../../../components/icons/icon.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
// import Block1 from "../../../assets/images/StaticPages/Careers/Block1.png";
// import Block2 from "../../../assets/images/StaticPages/Careers/Block2.png";
// import Block3 from "../../../assets/images/StaticPages/Careers/Block3.png";
// import Block4 from "../../../assets/images/StaticPages/Careers/Block4.png";
// import Block5 from "../../../assets/images/StaticPages/Careers/Block5.png";


import "./thankyou.scss";

export const ThankYouPage: FunctionComponent = () => { 
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
            <AppHeader isWhite={true}/>
            <View className="CareersPage">
                <View className="CareersPageHeader" title="careers_photo">
                    <Container style={{marginTop: "96px"}}>
                        <View className="CareersPageHeaderContent">
                            <Header1 title="شكراً لتواصلك معنا" className="header1"/>
                        </View>
                        <View className="CareersPageHeaderContent">
                            <Icon name="iconMawsuahLogo"/>
                            <span className="bigText">موسوعة</span>
                            <Icon name="iconVerifiedSmallWhiteBG"/>
                            {/* <span className="smallText">196 ألف متابع</span> */}
                            {/* <BtnColorless title="تابِع"/> */}
                        </View>
                    </Container>
                </View>
                <SpacerVertical height={"4.5em"}/>
                <View className="CareersPageContent">
                    <Container>
                        <Row>
                            <Col lg={3} className="CareersPageContentSecondColumn">
                                
                            </Col>
                            <Col className="CareersPageContentFirstColumn" lg={9}>
                                <Header3 className="writersHeader" title="شكراً لتواصلك معنا."/>
                                <div className="smallLine"></div>
                                <View className="CareersPageContentFirstColumnContent">
                                <View className="smallHeader">
                                لقد تلقينا طلبك وسيتم الرد عليك في أقرب وقت.
                                </View>
                           
                                                            
                                </View>                        
                            </Col>
                        </Row>
                    </Container>
                    <SpacerVertical height={"3.3em"}/>
                </View>
                <AppFooter />
            </View>
        </View>
    )
}