import React, { FunctionComponent, useEffect } from 'react';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { View } from '../../../components/view/view.component';
import image from "../../../assets/images/StaticPages/Community.png";

export const CommunityPage: FunctionComponent = () => { 

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
        <AppHeader isWhite={true}/>
        <View>
            <img style={{objectFit: "cover", width: "100%", height:"100%"}} src={image} alt="imageData"/>
        </View>   
        <AppFooter />
        </View>
    )
}