import React, { FunctionComponent } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { ArtcileInfo } from '../../shared/interfaces';
import { Icon } from '../icons/icon.component';
import { SpacerVertical } from '../spacer/spacer.component';
import { View } from '../view/view.component';

import "./trending-mawsuah.scss"

interface TrendingMawsuahProps {
    lgPicture: number;
    lgText: number;
    info: ArtcileInfo[] | undefined;
}

export const TrendingMawsuah: FunctionComponent<TrendingMawsuahProps> = (props) => {

    const {info} = props;
    
    const history = useHistory();

    return (
        <View className="TrendingMawsuah">
            <Container>
                <View className="Flex" style={{cursor: "pointer"}} onClick={() => {
                    if(info?.length) {
                        history.push(`/article/${info[0].id}`)
                    }
                    }}>
                    <div className="TrendingMawsuahContent">
                        <SpacerVertical height={"3.071em"} />
                        <View className="contentTitle">شائع في موسوعة</View>
                        <SpacerVertical height={"1.160em"} />
                        <View className="contentText">
                        {(info && info?.length !== 0) && info[0]?.title}
                        </View>
                        <SpacerVertical height={"1.384em"} />
                        <View className="moreArticles">
                            <div>استكشاف المزيد</div>
                            <Icon name="iconArrowLeftBlack" />
                        </View>
                        <SpacerVertical height={"1.714em"} />
                        <View className="Flex">
                            <View className="iconPhoto">
                                <Icon name="iconPhoto100" />
                            </View>
                            <View>
                                <View className="Flex">
                                <View className="item1"><span>{(info && info?.length !== 0) && info[0]?.author.first_name} {(info && info?.length !== 0) && info[0]?.author.last_name}</span></View>
                                <Icon name="iconVerifiedSmallWhite" />
                                </View>
                                <View className="item2">{(info && info?.length !== 0) && info[0]?.interest.title}</View>
                            </View>
                        </View>
                    </div>
                    <div className="TrendingMawsuahPhoto">
                        <Icon  name="iconTrendingMawsuah" />
                    </div>
                </View>
            </Container>
        </View>
    )
}