import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Editable, withReact, useSlate, Slate, useEditor,  ReactEditor } from 'slate-react';
import isUrl from 'is-url';
import {
  Range,
  Editor,
  Transforms,
  createEditor,
  Node,
  Element as SlateElement,
} from 'slate';
import { css } from '@emotion/css';
import { withHistory, HistoryEditor } from 'slate-history';
import { View } from '../view/view.component';
import { Icon } from '../icons/icon.component';
import { ITextEditorData } from '../../shared/interfaces';
import { BtnColorless } from '../buttons/btn.component';
import { DragAndDropImages } from '../drag-and-drop/drag-and-drop.component';
import { useOnClickOutside } from '../../shared/hooks/click-outside.hook';
import { Menu, Portal } from '../../pages/article/article.page';
import { nanoid } from 'nanoid';
import { Text } from 'slate';


const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

interface RichTextEditorProps  {
  numberOfReferences: number;
  handleReferenceData: (data: string, id: number, numberOfReferences: number, refId: any) => void;
  handleTextEditor: (data: Node[], id: number) => void;
  deleteTextEditor?: (id: number) => void;
  addTextEditorBefore?: (id: number) => void;
  addTextEditorAfter?: (id: number) => void;
  addCopiedTextEditor?: (data: ITextEditorData) => void;
  isParagraphProp: boolean;
  isListProp: boolean;
  editorData: Node[];
  id: number;
  iconsItemProp: string;
  textAlignIconProp: string;
  isWithSettings?:boolean;
  deletedRef?: string;
}

export const RichTextEditor:FC<RichTextEditorProps> = (props) => {
  const {numberOfReferences, deletedRef, handleTextEditor, textAlignIconProp, iconsItemProp, isParagraphProp, isListProp, id, editorData, handleReferenceData, deleteTextEditor, addTextEditorBefore, addTextEditorAfter, addCopiedTextEditor, isWithSettings} = props;

  const [iconsItem, setIconsItem] = useState("iconParagraph");
  const [textAlignIcon, setTextAlignIcon] = useState("iconTextAlignRight");
  const [value, setValue] = useState<Node[]>(editorData[0] ? editorData : initialValue);
  const [items, setItems] = useState(false);
  const [fontSize, setFontSize] = useState(false);
  const [textAlign, setTextAlign] = useState(false);
  const [isParagraph, setIsParagraph] = useState(isParagraphProp);
  const [isList, setIsList] = useState(isListProp);
  const [isSettings, setIsSettings] = useState(false);
  const [placeHolder, setPlaceHolder] = useState("ابدأ بالكتابة أو اختار مكون");
  const [activeButton, setActiveButton] = useState(1);
  const [withEnter, setWithEnter] = useState(true);
  // const [paragraphButton,setIsParagraphButton] =  useState(true);
  // const [photoButton,setPhotoButton] =  useState(false);
  // const [videoButton,setVideoButton] =  useState(false);
  const [textAlignButton,setTextAlignButton] =  useState(false);
  const [fontSizeButton,setFontSizeButton] =  useState(false);
  const [boldButton,setBoldButton] =  useState(false);
  const [underlineButton,setUnderlineButton] =  useState(false);
  const [numberListButton,setNumberListButton] =  useState(false);
  const [bulletListButton,setBulletListButton] =  useState(false);
  const [quoteButton,setQuoteButton] =  useState(false);
  const [linkButton,setLinkButton] =  useState(false);
  const [noteButton,setNoteButton] =  useState(false);

    const refTextAlign = useRef<HTMLDivElement>(null);
    const refItems = useRef<HTMLDivElement>(null);
    const refSettings = useRef<HTMLDivElement>(null);
    const refFontSize = useRef<HTMLDivElement>(null);
    useOnClickOutside(refTextAlign, () => setTextAlign(false));
    useOnClickOutside(refItems, () => setItems(false));
    useOnClickOutside(refSettings, () => setIsSettings(false));
    useOnClickOutside(refFontSize, () => setFontSize(false));

  useEffect(() => {  
    setIconsItem(iconsItemProp);
    setIsParagraph(isParagraphProp);
    setTextAlignIcon(textAlignIconProp);
    setIsList(isListProp);
  }, [isListProp, isParagraphProp, iconsItemProp, textAlignIconProp])

  const renderElement = useCallback(props => <Element {...props}/>, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() =>  withEmbeds(withHistory(withLinks(withImages(withReact(createEditor()))))), []);

  useEffect(() => {
    if(deletedRef) {
      const deleteNode = (node : any, deletedRef: any) => {
      if (Text?.isText(node)) {
        return node
      }

      const children = node?.children?.map((n : any) => {
        
        return deleteNode(n, deletedRef)}).filter((n : any) => n && n);

      if(node?.refId === deletedRef) {
        
        return 
      }
      if(node?.type === "list-item") {
        return {
          type: "list-item",
          children: children
        }
      } 
      if(node?.refId) {
        return node
      }
      return children
    }
    
    let newValue = [];
    for(var i = 0 ; i< value.length; i++){
      const childs = deleteNode(value[i], deletedRef);
      const realChild = childs?.filter((item: any) => item && item)
        newValue[i] = {
        ...value[i],
        children: realChild
      }      
    }
    setValue(newValue);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedRef])

  const handleChange = (data : Node[]) => {
    
    setValue(data);
    handleTextEditor(data, id);
  }

  const handleReference = (data : string, select: any) => {
    const refId = nanoid();
    handleReferenceData(data, id, numberOfReferences, refId);
    const link = {
      type: 'link',
      url: "references",
      refId: refId,
      children: [{text: `[${numberOfReferences}]`}]
    }
    
    Transforms.insertNodes(editor, link, {
      at: { path: select.current.anchor.path, offset: select.current.anchor.offset },
    });
  }
  
  
  return (
    <Slate editor={editor} value={value} onChange={data => handleChange(data)} >
      <HoveringToolbar handleReference={handleReference} isWithSettings={isWithSettings}/>
        <View className="TextEditorButtons">
            <View style={{display: "flex"}}>
              <div ref={refItems} style={activeButton === 1 ? {backgroundColor: "var(--blueColor)"} : undefined} className="DropDownItems" onClick={() => { setActiveButton(1); setItems(!items)}}>
                <Icon name={activeButton === 1 ? iconsItem + "White" : iconsItem}/>
                <Icon name={activeButton === 1 ? "iconMenuDownWhite" : "iconMenuDown"}/>
                <View className="DropDownItemsAbsolute" style={{display: items ? "flex" : "none", width: "13.571em", right: 0, flexDirection: "column"}}>
                  <BlockButton setPlaceHolder={setPlaceHolder} placeHolder={"ابدأ بالكتابة أو اختار مكون"} format="paragraph" icon="iconParagraph" text="نص عربي" onClick={() => {
                    setIconsItem("iconParagraph");
                    setIsParagraph(true); 
                    setIsList(true);
                    setWithEnter(true);
                    setValue([
                      {
                        type: 'paragraph',
                        children: [
                          {
                            text:
                              "",
                          },
                        ],
                      }
                    ]);
                    }}/>
                    <InsertImageButton onClick={() => {
                      setIconsItem("iconInsertPhoto");
                      setIsParagraph(false); 
                      setTextAlignButton(false);
                      setFontSizeButton(false);
                      setBoldButton(false);
                      setUnderlineButton(false);
                      setNumberListButton(false);
                      setBulletListButton(false);
                      setLinkButton(false);
                      setQuoteButton(false); 
                      setIsList(false);
                      setNoteButton(false);
                      setValue([
                        {
                          type: 'image',
                          url: "",
                          children: [
                            {
                              text:
                                "",
                            },
                          ],
                        }
                      ]);
                      }}
                      />
                    <BlockButton format="video" icon="iconYoutube" text="فيديو" onClick={() => {
                    setIconsItem("iconYoutube");
                    setIsParagraph(false);
                    setTextAlignButton(false);
                    setFontSizeButton(false);
                    setBoldButton(false);
                    setUnderlineButton(false);
                    setNumberListButton(false);
                    setBulletListButton(false);
                    setLinkButton(false);
                    setQuoteButton(false); 
                    setIsList(false);
                    setNoteButton(false);
                    setValue([
                      {
                        type: 'video',
                        url: '',
                        children: [{ text: '' }],
                      }
                    ]);
                    }}/> 
                  {/* <BlockButton setPlaceHolder={setPlaceHolder} placeHolder={"ابدأ بالكتابة"} format="numbered-list" icon="iconNumberedList" text="قائمة مرقمة" onClick={() => {
                    setIconsItem("iconNumberedList");
                    setIsParagraph(false); 
                    setIsList(true);
                    setWithEnter(true);
                    setValue([
                      {
                        type: 'numbered-list',
                        children: [
                          {
                            type: 'list-item',
                            children: [
                              {
                                text: ''
                              }
                            ]
                          },
                        ],
                      }
                    ]);
                    }}/> */}
                  {/* <BlockButton setPlaceHolder={setPlaceHolder} placeHolder={"ابدأ بالكتابة"} format="bulleted-list" icon="iconBulletedList" text="قائمة عادية" onClick={() => {
                    setIconsItem("iconBulletedList");
                    setIsParagraph(false); 
                    setIsList(true);
                    setWithEnter(true);
                    setValue([
                      {
                        type: 'bulleted-list',
                        children: [
                          {
                            type: 'list-item',
                            children: [
                              {
                                text: ''
                              }
                            ]
                          },
                        ],
                      }
                    ]);
                    }}/> */}
                  {/* <BlockButton setPlaceHolder={setPlaceHolder} placeHolder={"“ابدأ بالكتابة”"} format="block-quote" icon="iconQuote" text="اقتباس" onClick={() => {
                    setIconsItem("iconQuote");
                    setIsParagraph(false); 
                    setIsList(false);
                    setValue([
                      {
                        type: 'block-quote',
                        children: [
                          {
                            text:
                              "",
                          },
                        ],
                      }
                    ]);
                    }}/> */}
                </View>
              </div>
              {isParagraph && <div ref={refTextAlign} style={textAlignButton === true ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined} className="DropDownItems" onClick={() => {textAlignButton === true ? setTextAlignButton(false):setTextAlignButton(true);setTextAlign(!textAlign)}}>
                <Icon name={textAlignButton === true ? textAlignIcon + "White" : textAlignIcon}/>
                <Icon name={textAlignButton === true ? "iconMenuDownWhite" : "iconMenuDown"}/>
                <View className="DropDownItemsAbsolute" style={{display: textAlign ? "block" : "none", width: "13.571em", right: 0}}>
                  <BlockButton format="text-align-right" icon="iconTextAlignRight" text="محاذاة إلى اليمين" onClick={() => {setTextAlignIcon("iconTextAlignRight")}}/>
                  <BlockButton format="text-align-center" icon="iconTextAlignCenter" text="محاذاة إلى الوسط"  onClick={() => {setTextAlignIcon("iconTextAlignCenter")}}/>
                  <BlockButton format="text-align-left" icon="iconTextAlignLeft" text="محاذاة إلى اليسار"  onClick={() => {setTextAlignIcon("iconTextAlignLeft")}}/>
                </View>
              </div>}
              {isParagraph && <div ref={refFontSize} style={fontSizeButton === true ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined} className="DropDownItems" onClick={() => {fontSizeButton === true ? setFontSizeButton(false):setFontSizeButton(true);setFontSize(!fontSize)}}>
                <Icon name={fontSizeButton === true ? "iconFontSizeWhite" : "iconFontSize"}/>
                <Icon name={fontSizeButton === true ? "iconMenuDownWhite" : "iconMenuDown"}/>
                <View className="DropDownItemsAbsolute" style={{display: fontSize ? "block" : "none", width: "13.571em", right: 0}}>
                  <MarkButton format="fontSize18" icon="iconFontSize" text="نص بحجم 18"/>
                  <MarkButton format="fontSize22" icon="iconFontSize" text="نص بحجم 22"/>
                  <MarkButton format="fontSize24" icon="iconFontSize" text="نص بحجم 24"/>
                </View> 
              </div>}
              {isList && 
              <>
              <MarkButton onClick={() => {boldButton === true ? setBoldButton(false) : setBoldButton(true)}} format="bold" icon={boldButton === true ? "iconBoldWhite" : "iconBold"} style={boldButton === true ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined}/>
              <MarkButton onClick={() => {underlineButton === true ? setUnderlineButton(false) : setUnderlineButton(true)}} format="underline" icon={underlineButton === true ? "iconUnderlineWhite" : "iconUnderline"} style={underlineButton === true ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined}/>
              <BlockButton onClick={() => {numberListButton === true ? setNumberListButton(false): setNumberListButton(true) }} format="numbered-list" icon={numberListButton === true ? "iconNumberedListWhite" : "iconNumberedList"}   style={numberListButton === true ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined}/>
              <BlockButton onClick={() => {bulletListButton === true ? setBulletListButton(false) : setBulletListButton(true) }} format="bulleted-list" icon={bulletListButton === true ? "iconBulletedListWhite" : "iconBulletedList"}   style={bulletListButton === true ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined}/>
              <BlockButton onClick={() => {quoteButton === true ? setQuoteButton(false): setQuoteButton(true)}} format="block-quote" icon={quoteButton === true ? "iconQuoteWhite" : "iconQuote"}   style={quoteButton === true ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined}/>
              <LinkButton name={linkButton === true ? "iconLinkWhite" : "iconLink"}  onClick={() => {linkButton === true ? setLinkButton(false): setLinkButton(true)}} style={linkButton === true ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined}/>
              <BlockButton onClick={() => {noteButton === true ? setNoteButton(false): setNoteButton(true)}} format="note" icon={noteButton === true ? "iconNotewhite" : "iconNote"}   style={noteButton === true ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined}/>
              {/* <MarkButton onClick={() => {setActiveButton(6)}} format="bold"icon={activeButton === 6 ? "iconSpeakerNotesWhite" : "iconSpeakerNotes"} style={activeButton === 6 ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined}/> */}
              </>}
            </View>
                <div ref={refSettings} className="Settings">
                    <button style={activeButton === 8 ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined} onClick={() => {setActiveButton(8); HistoryEditor.undo(editor)}}><Icon name={activeButton === 8 ?"iconUndoWhite" : "iconUndo"}/></button>
                    <button style={activeButton === 9 ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined} onClick={() => {setActiveButton(9); HistoryEditor.redo(editor)}}><Icon name={activeButton === 9 ? "iconRedoWhite" : "iconRedo"}/></button>
                    {!isWithSettings && 
                    <>
                    <button style={activeButton === 10 ? {backgroundColor: "var(--blueColor)", color: "white"} : undefined} onClick={() => {setActiveButton(10);setIsSettings(!isSettings)}}><Icon name={activeButton === 10 ? "iconCogWhite" : "iconCog"}/></button>
                    <View className="DropDownItemsAbsolute SettingsDropDown" style={{display: isSettings ? "flex" : "none" , width: "13.571em"}}>
                        <button onClick={() => {!!addCopiedTextEditor && addCopiedTextEditor({id: id, data: [...value], isParagraph, isList, iconsItem, textAlignIcon})}}>
                          <Icon name="iconCopy"/>
                          تكرار
                        </button>
                        <button onClick={() => {!!addTextEditorBefore && addTextEditorBefore(id)}}>
                          <Icon name="iconAddTextEditor"/>
                          ادراج قبل
                        </button>
                        <button onClick={() => {!!addTextEditorAfter && addTextEditorAfter(id)}}>
                          <Icon name="iconAddTextEditor"/>
                          ادراج بعد
                        </button>
                        <button onClick={() => {!!deleteTextEditor && deleteTextEditor(id)}}>
                          <Icon name="iconDeleteTextEditor"/>
                          ازالة المكون
                        </button>
                    </View>
                    </>}
                </div>
        </View>
          <View className="TextEditor">
              <Editable
                  renderElement={renderElement}
                  renderLeaf={renderLeaf}
                  placeholder={placeHolder}
                  spellCheck
                  autoFocus
                  onKeyDown={event => {
                    if(event.key === "Enter" && !withEnter) {
                      event.preventDefault();
                      editor.splitBlock();
                    }
                  }}
              />
          </View>
    </Slate>
  )
}

const LinkButton = ({onClick, style, name}: any) => {
  const editor = useSlate()
  return (
    <button
    style={style}
      onMouseDown={event => {
        event.preventDefault()
        onClick()
        const url = window.prompt('Enter the URL of the link:')
        if (!url) return
        insertLink(editor, url)
      }}
    >
     <Icon name={name}/>
    </button>
  )
}

const withEmbeds = (editor : any) => {
  const { isVoid } = editor
  editor.isVoid = (element : any) => (element.type === 'video' ? true : isVoid(element))
  return editor
}


const withLinks = (editor : any) => {
  const { insertData, insertText, isInline } = editor
  editor.isInline = (element : any) => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = (text : string) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data : any) => {
    const text = data.getData('text/plain')
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }
  return editor
}

const VideoElement = ({ attributes, children, element } : any) => {
  const editor = useEditor();
  const [isVideo, setIsVideo] = useState(false);
  const  url : string  = element.url;
  let videoId : string = "";
  if(isUrl(url)) {
    const firstIndex = url.indexOf("watch?v=");
    const lastIndex = url.indexOf("&");
    if(lastIndex !== -1) {
      videoId = url.slice(firstIndex+8, lastIndex);
    } else {
      videoId = url.slice(firstIndex+8);
    }
  }

  return (
    <div {...attributes}>
      <div contentEditable={false} style={{textAlign : "center"}}>
        <div
          style={isVideo ? 
          {
            padding: '75% 0 0 0',
            position: 'relative',
          } : {}}
        >
          {!isVideo &&
            <View className="youtubeVideo">
              <Icon name="iconYoutube"/>
              <View>أضف رمز التضمين</View>
            </View>
          }
          {isVideo && 
          // <object  style={{
          //   position: 'absolute',
          //   top: '0',
          //   left: '0',
          //   width: '100%',
          //   height: '100%'
          // }}>
          //   <param name="movie" value={`http://www.youtube.com/v/${videoId}`}></param>
          //   <embed src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`} type="application/x-shockwave-flash"></embed>
          // </object>
          <iframe
            title={url}
            src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1`}
            frameBorder="0"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%'
            }}
          />
          }
        </div>
        <div style={{marginTop: "0.889em", display: "flex", justifyContent: "center"}}>
          <BtnColorless title="اضف" className="videoButton" onClick={() => {
              if (isUrl(url)) {
                setIsVideo(true);
              }}}/>
          <UrlInput
            url={url}
            onChange={(val : any) => {
              const path = ReactEditor.findPath(editor, element);
              const newProperties: Partial<SlateElement> = {
                url: val,
              }
              Transforms.setNodes(editor, newProperties, { at: path })
            }}
          />
        </div>
      </div>
      {children}
    </div>
  )
}

const UrlInput = ({ url, onChange } : any) => {
  const [value, setValue] = React.useState(url)
  return (
    <input
      placeholder="http://youtube.com/"
      value={value}
      onClick={e => e.stopPropagation()}
      style={{
        boxSizing: 'border-box',
        width: "20.857em",
        height: "2.714em",
        direction: 'ltr',
        border: "1px solid #CACACA",
        borderRadius: "4px",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px"
      }}
      onChange={e => {
        const newUrl = e.target.value
        setValue(newUrl)
        onChange(newUrl)
      }}
    />
  )
}

const insertLink = (editor : any, url : any) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

const isLinkActive = (editor : any) => {
  const [link] : any = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
  return !!link
}

const unwrapLink =(editor : any) => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  })
}

const wrapLink = (editor : any, url : any) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  }
  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: 'end' })
  }
}

const toggleBlock = (editor : any, format : any) => {
  // const isActive = false
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>{
      return LIST_TYPES.includes(
        !Editor.isEditor(n as any) && SlateElement.isElement(n as any) && n.type as any
      )},
    split: true,
  })
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }
  Transforms.setNodes(editor, newProperties);
  if (!isActive &&  isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const toggleMark = (editor : any , format : any ) => {
  const isActive = isMarkActive(editor, format)
  if(format === "fontSize22" || format === "fontSize24" || format === "fontSize18") {
    Editor.removeMark(editor, "fontSize22");
    Editor.removeMark(editor, "fontSize24");
    Editor.removeMark(editor, "fontSize18");
    Editor.addMark(editor, format, true);
  } else {
    if (isActive) {
      Editor.removeMark(editor, format)
    } else {
      Editor.addMark(editor, format, true)
    }
  }  
}

const isBlockActive = (editor: any , format: any , blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor : any , format : any) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const Element = ({ attributes, children, element} : any) => {
  const props = {attributes, children, element};
  
  switch (element.type) {
    case 'video':
      return <VideoElement {...props} />
    case 'link':
      return <a {...attributes} href={element.url}>{children}</a>
    case 'block-quote':
      return <blockquote className="TextEditorBlockQuote" {...attributes}>{children}</blockquote>
    case 'note':
      return <p className="TextEditorNote" {...attributes}><Icon name="iconNote" alt="note"/>{children}</p>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'text-align-right':
      return <p style={{textAlign: "right"}} {...attributes}>{children}</p>
    case 'text-align-center':
      return <p style={{textAlign: "center"}} {...attributes}>{children}</p>
    case 'text-align-left':
      return <p style={{textAlign: "left"}} {...attributes}>{children}</p>
    case 'image':
      return <ImageElement {...props} /> 
    case 'paragraph':
      return <p {...attributes}>{children}</p>
    default:
      return <></>
  }
}

const Leaf = ({ attributes, children, leaf } : any) => {
  
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }
  if (leaf.underline) {
    children = <u>{children}</u>
  }
  if (leaf.fontSize24) {
    children = <span style={{fontSize: "24px"}}>{children}</span>
  }
  if (leaf.fontSize22) {
    children = <span style={{fontSize: "22px"}}>{children}</span>
  }
  if (leaf.fontSize18) {
    children = <span style={{fontSize: "18px"}}>{children}</span>
  }
  return <span {...attributes}>{children}</span>
}

const withImages = (editor : any) => {
  const { isVoid } = editor
  editor.isVoid = (element : any) => {
    return element.type === 'image' ? true : isVoid(element)
  }
  return editor
}

const ImageElement = ({ attributes, children, element} : any) => {


  const editor = useEditor();
  
  const handleImageUrl = (url: string) => {
    const path = ReactEditor.findPath(editor, element);
    const newProperties: Partial<SlateElement> = {url: url}
    Transforms.setNodes(editor, newProperties, { at: path })
  }
  
  
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <DragAndDropImages text={"اسحب وأفلت الصورة هنا.. يجب أن يكون حجم الصورة أقل من 2 ميجابايت."} withInputUpload={true} defaultImage={element.url !== "" ? element.url : ""} handleImageUrl={handleImageUrl}/>
      </div>
      {children}
    </div>
  )
}

const InsertImageButton = ({onClick} : any) => {

  const editor = useEditor();

  return (
    <button
      onClick={onClick}
      onMouseDown={event => {
        event.preventDefault();
        Transforms.setNodes(editor, { type: 'image', children: [{text: ""}] })
      }}
    >
      <Icon name="iconInsertPhoto"/>
      <View>صورة</View>
    </button>
  )
}

const BlockButton = ({ format, onClick, icon, text, placeHolder, setPlaceHolder ,style } : any) => {
  const editor = useSlate();
  return (
    <button
      style={style}
      onClick={onClick}
      onMouseDown={event => {
        event.preventDefault();
        !!setPlaceHolder && setPlaceHolder(placeHolder);
        toggleBlock(editor, format)
      }}
    >
     <Icon name={icon} />
     {text}
    </button>
  )
}

const MarkButton = ({ format, icon, text, onClick, style} : any) => {
  const editor = useSlate();
  
  return (
    <button
      style={style}
      onClick={onClick}
      onMouseDown={(event : any)  => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      <Icon name={icon} />
      {text}
    </button>
  )
}

interface HoveringToolbarProps {
  handleReference: (value: string, select: any) => void;
  isWithSettings: boolean | undefined;
}

const HoveringToolbar:FC<HoveringToolbarProps> = (props) => {

  const {handleReference} = props;

  const ref = useRef<any>();
  const select = useRef<any>();
  const editor = useSlate();
  const [onFocus , setOnFocus] = useState(true);
  const [value, setValue] = useState("");
useOnClickOutside(ref, () => {setOnFocus(true)})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;
    if(selection !== null && onFocus) {
      select.current = selection
    }
    

    if (!el) {
      return
    }

    if (
      onFocus &&
      (!selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === '' )
    ) {
      el.removeAttribute('style');
      setValue("");
      return
    }

    const domSelection = window.getSelection();
    const domRange = domSelection?.getRangeAt(0);
    const rect = domRange?.getBoundingClientRect();
    if(rect && onFocus) {
      el.style.display = "block"
      el.style.opacity = "1"
      el.style.top = `${rect.top + window.pageYOffset + 35}px`
      el.style.left = `${rect.left +
        window.pageXOffset -
        el.offsetWidth / 2 +
        rect.width / 2}px`
    }
  });

  const func = () => {
    setOnFocus(true);
    handleReference(value, select);
  }

  return (
    <Portal>
      <Menu
        ref={ref}
        className={css`
          position: absolute;
          z-index: 1;
          display: none;
          margin-top: -6px;
          opacity: 0;
          background-color: #FFFFFF;
          border: 1px solid #CACACA;
          border-radius: 4px;
          transition: visibility 0.75s, opacity 0.75s;
        `}
      >
        <View
          className="SeletectedTextToolTip">
          <textarea value={value} 
            onFocus={() => {setOnFocus(false);}} 
            onChange={(e) => setValue(e.currentTarget.value)}
            placeholder="اضف مرجع"
          />
          <BtnColorless title="اضف" onClick={func}/>
        </View>
      </Menu>
    </Portal>
  )
}

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
]