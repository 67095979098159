import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { Btn, BtnSmallOpacity } from '../../components/buttons/btn.component';
import { Loader } from '../../components/loader/loader.component';
import { ProfileMenu } from '../../components/profile-menu/profile-menu.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { SuggestedArticlesItem } from '../../components/suggested-articles/nested/suggested-articles-item.component';
import { SuggestedArticlesWriters } from '../../components/suggested-articles/nested/suggested-articles-writers.component';
import { View } from '../../components/view/view.component';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { ArtcileInfo, AuthorData, WriterInfo } from '../../shared/interfaces';
import { apiService } from '../../shared/services/api.service';
import { activeClass } from '../../shared/utils';
import { UserProfileCV } from './nested/user-profile-CV.component';
import { EngagementsItem } from './nested/user-profile-engagements.component';
import { UserProfileSmallInfo } from './nested/user-profile-smallinfo.component';

import "./user-profile.scss";

export const UserProfilePage: FunctionComponent = () => {

    const location: any = useLocation();

    const history = useHistory();

    const {response: randomWriters, isLoading: randomWritersLoading} = useFetch(() => apiService.user.getRandomWriters(5));

    const [articlesLoading ,setArticlesLoading] = useState(true);
    const [articlesData, setArticlesData] = useState<ArtcileInfo[]>([]);
    const [AuthorDataLoading, setAuthorDataLoading] = useState(true);
    const [isAuthenticated] = useState(apiService.auth.isAuthenticated());
    const [isArticles, setIsArticles] = useState(true);
    const [isEngagements, setIsEngagements] = useState(false);
    const [isCV, setIsCV] = useState(false);
    const [authorInfo, setAuthorInfo] = useState<AuthorData>();
    const [statusFilter, setStatusFilter] = useState('Newest');

    useEffect(() => {
        setAuthorDataLoading(true);
        setArticlesLoading(true);
        apiService.articles.getNewestArticlesByAuthorId(location.pathname.split('/')[2], 0, 10)
            .then(r => {
                setArticlesData(r?.data?.articles);
                setArticlesLoading(false);
            })
        apiService.user.getAuthorData(location.pathname.split('/')[2])
            .then(r => {
                setAuthorInfo(r?.data);
                setAuthorDataLoading(false);
            });

    }, [location.pathname]);

    const handleFilter = (status : string) => {
        if(status === "Newest") {
            setStatusFilter("Newest");
            const newArr = articlesData.sort((a,b) => Date.parse(b.published_at) - Date.parse(a.published_at));
            setArticlesData([...newArr]);
        }
        if(status === "MostRead") {
            setStatusFilter("MostRead");
            const newArr = articlesData.sort((a, b) => b.views_count - a.views_count);
            setArticlesData([...newArr]);
        }
    }

    const getMoreArticles = () => {
        apiService.articles.getNewestArticlesByAuthorId(location.pathname.split('/')[2], articlesData.length, 10)
            .then(r => {
                if(r.data.articles !== undefined && r.data.articles !== null) {
                    setArticlesData([...articlesData, ...r.data.articles]);
                }
            })
    }
    

    return (
        <View className="App">
            <View className="searchPage">
            <AppHeader /> 
            <Container>
                <Row>
                    <Col lg={4}>
                        {isAuthenticated ? 
                        <ProfileMenu />
                        :
                        <>
                            <View className="whyMawsuah">
                                <View className="whyMawsuahTitle">لماذا موسوعة؟</View>
                                <SpacerVertical height={"1em"}/>
                                <View className="whyMawsuahText">
                                موسوعة نظام تحفيزي مبني على تقنية البلوكتشين، وهي المنصة العربية المفضلة لمنتجي المحتوى المختّص في كتابة مواضيع موثوقة لتصلهم بملايين الباحثين والقرّاء.

                                </View>
                                <SpacerVertical height={"1.714em"}/>
                                <View className="whyMawsuahBtn">
                                    <Btn className="registerBtn" title="أنشئ حساباً" onClick={() => {history.push("/main_signup")}}/>
                                </View>
                            </View>
                                <View className="SuggestedArticlesListMostReadInterests"> 
                                        <BtnSmallOpacity className="MostReadInterestsTitle" title="الكتّاب الأكثر متابعة"  disabled={true}/>
                                        <SpacerVertical height={"2.286em"}/>
                                        <View className="MostFollowedWritersLists">
                                        {!randomWritersLoading ? randomWriters?.data?.authors?.map((item : WriterInfo) => {
                                            return (
                                                <View key={item.id}>
                                                    <SuggestedArticlesWriters userInfo={item}/>
                                                    <SpacerVertical height={"1.714em"}/>
                                                </View>
                                            )
                                        }) : <Loader/>}
                                    </View>
                                </View>
                        </>}
                    </Col>
                    <Col lg={8}>
                   {!AuthorDataLoading ? <UserProfileSmallInfo authorData={authorInfo}/> : <Loader/>}
                    <SpacerVertical height={"2.286em"} />
                    <View className="searchPageMenu">
                        <View className={activeClass("searchPageMenuItem", isArticles)}
                            onClick={() => {
                                setIsArticles(true);
                                setIsEngagements(false);
                                setIsCV(false);
                            }}>
                        مقالات  
                        </View>
                        <View className={activeClass("searchPageMenuItem", isCV)} 
                            onClick={() => {
                                setIsArticles(false);
                                setIsEngagements(false);
                                setIsCV(true);
                            }}>
                        السيرة الذاتية
                        </View>
                    </View>
                    <SpacerVertical height={"2em"} />
                    <View className="searchPageFilter">
                        <View className={statusFilter === "Newest" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("Newest")}>
                        الأحدث 
                        </View>
                        <View className={statusFilter === "MostRead" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostRead")}>
                        الأكثر تصفحاً
                        </View>
                    </View>
                    <SpacerVertical height={"3.143em"} />
                    <View className="searchPageContent">
                        {isArticles && 
                        <>
                        {!articlesLoading ? articlesData?.map((item : ArtcileInfo) => {
                        return (
                            <SuggestedArticlesItem 
                                created_at={item.created_at}
                                toSave
                                key={item.id}
                                title={item.title}
                                header={item.header}
                                image_url={item.image_url}
                                interest={item.interest}
                                author={item.author}
                                published_at={item.published_at}
                                minutes_for_reading={item.minutes_for_reading}
                                articleId={item.id}
                                status={item.status.name}
                                isSaved={item.is_saved}
                                updated_at={item.updated_at}
                            />
                            )
                        }) : <Loader/>}
                        </>}
                        {isEngagements && 
                            <>
                                <EngagementsItem isLiked/>
                                <EngagementsItem isShared/>
                                <EngagementsItem isLiked isShared/>
                                <EngagementsItem isLiked isShared/>
                                <EngagementsItem isLiked isShared/>
                                <EngagementsItem isLiked isShared/>
                                <EngagementsItem isLiked/>
                                <EngagementsItem isLiked isShared/>
                            </>}
                            {isCV && 
                                <UserProfileCV authorData={authorInfo}/>
                            }
                        {!isCV && <Btn title="استكشف أكثر" className="searchPageContentBtn" onClick={getMoreArticles}/>}
                    </View>
                    </Col>
                </Row>
            </Container>
            <SpacerVertical height={"4.571em"} />
            <AppFooter />
            </View>
        </View>
    )
}