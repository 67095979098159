import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthorData } from '../../../shared/interfaces';
import { Icon } from '../../icons/icon.component';
import { Loader } from '../../loader/loader.component';
import { SpacerVertical } from '../../spacer/spacer.component';
import { View } from '../../view/view.component';

import "./searchDropdown.scss"

interface ISearchDropdown {
    results: any;
    searchTerm: string;
}

export const SearchDropdown: FunctionComponent<ISearchDropdown> = (props) => {

    const { results, searchTerm } = props;

    const history = useHistory();

    const [articleResults, setArticleResults] = useState<any>([]);
    const [authorsResults, setAuthorsResults] = useState<any>([]);
    const [noResultes, setNoResults] = useState(false);

    useEffect(() => {
        if(results?.data?.articles_data?.articles?.length > 0){
            setArticleResults(results?.data?.articles_data?.articles);
        } else {
            setArticleResults([]);
        }
        if(results?.data?.authors_data?.authors?.length > 0){
            setAuthorsResults(results?.data?.authors_data?.authors);
        }else {
            setAuthorsResults([]);
        }
    }, [results]);

   useEffect(() => {
       if(articleResults.length === 0 && authorsResults.length === 0 && results) {
        setNoResults(true);   
       } else {
        setNoResults(false);   
       }
   }, [articleResults, authorsResults, results])
    

    return (
        <div className="searchDropdown">
        {!!results ? 
            <>
            {articleResults.length !== 0  && <View className="searchDropdownArticles">               
                <View className="searchDropdownArticlesHeader">
                    <View className="searchDropdownArticlesHeaderTitle">مقالات</View>
                    <div className="searchDropdownArticlesHeaderMore"  onClick={() => {history.push({
                            pathname: '/search',
                            state: {
                                searchTerm: searchTerm,
                                string: "isArticles",
                                results: JSON.stringify(results?.data)
                            }})}}>المزيد</div>
                </View>
                <SpacerVertical height={"1.143em"}/>
                <View className="searchDropdownArticlesContent">
                    {articleResults.map((item : any) => {
                        return(
                            <View key={item.id} onClick={() => {history.push(`/article/${item.id}`)}}>
                                <Icon src={item.image_url} name="iconSearchDropDown" alt="article_image"/>
                                <span>{item.title}</span>
                                <SpacerVertical height={"0.571em"}/>
                            </View>
                        )
                    })}
                    <SpacerVertical height={"0.571em"}/> 
                </View>
            </View>}
            {authorsResults.length !== 0 && 
            <View className="searchDropdownArticles">
            <View className="searchDropdownArticlesHeader">
                    <View className="searchDropdownArticlesHeaderTitle">كتّاب</View>
                    <div className="searchDropdownArticlesHeaderMore" onClick={() => {
                        history.push({
                            pathname: '/search',
                            state: {
                                searchTerm: searchTerm,
                                string: "isWriters",
                                results: JSON.stringify(results?.data)
                            }})}}>المزيد</div>
                </View>
                <SpacerVertical height={"1.143em"}/>
                <View className="searchDropdownArticlesContent">
                    {authorsResults.map((item : AuthorData) => {
                        return(
                            <View key={item.id} onClick={() => {history.push(`/user_profile/${item.id}`)}}>
                                <Icon style={{borderRadius: "50%"}} src={item.image_url} name="iconProfilePlaceholder" alt="writer_image"/>
                                <span>{item.first_name} {item.last_name}</span>
                                <SpacerVertical height={"0.571em"}/>
                            </View>
                        )
                    })}
                </View>
            </View>}
            </> : <Loader />}
            {noResultes && <View className="NoResult">No results</View>}
            <SpacerVertical height={"1.714em"}/>
         </div>
    )
}