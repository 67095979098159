import { FunctionComponent } from "react";
import { Node } from "slate";

// Routes

export interface IComponent {
  routes?: IRoute[];
}

export interface ITextEditorData {
    id: number
    data: Node[]
    isParagraph: boolean
    isList: boolean
    iconsItem: string
    textAlignIcon: string
}

export interface IFormData {
  id: number;
  name: string;
  email: string;
  interest: string;
  role: string;
}

export interface IRoute {
  path: string;
  component: FunctionComponent<IComponent>;
  routes?: IRoute[];
  protected?: boolean;
}

export interface IFormValues {
  FamilyName: string;
  FirstName: string;
  UserName: string;
  Email: string;
  Password: string;
  confirm_password?: string;
  country?: string;
  gender?: string;
  role?: string;
}

export type WriterThirdStepData = {
  expected_number_of_articles?: string; 
  expected_article_length?: string;
  hours_spent_per_day ?: string;
  best_time_for_working?: string;
  role ?: string;
}

export type sub_interests = [
  {
      id: number,
      title: string,
      description ?: string,
      imageUrl ?: string;
  }
]

export type ArticleBlockContent = {
  blockId : number;
  data : Node[];
  activeBlock ?: boolean;
}

export type SecondStepData = {
  education : string;
  experience : string;
  years_of_experience : string;
  specialisation : string;
  level_of_education : string;
  instituion ?: string;
}



export type InterestsData = { 
      id: number,
      title: string,
      description ?: string,
      image_url ?: string,
      articles_count?: number,
      authors_count?: number,
      followers_count?: number,
      sub_interests?: sub_interests
}

export type Badges = {
  id: number,
  image_url: string,
  name: string
}

export type Experiences = {
    experience: string;
    institution: string | null;
    specialisation: string | null;
    sub_specialisations: string | null;
    years_of_experience: number;
}

export type AuthorData = {
    badges?: Badges[];
    email?: string;
    first_name?: string;
    last_name?: string;
    id: string;
    image_url?: string;
    articles_count?: number;
    country?: string;
    followers_count?: number;
    gender?: string;
    is_followed?: boolean;
    role?: string;
    specialization?: string;
    username?: string;
    registration_year?: string;
    languages?: string;
    level_of_education?: string;
    education?: string;
    experiences?: Experiences[];
    specializations?: {
      articles_count: number;
      authors_count: number;
      description: string;
      followers_count: number;
      id: number;
      image_url: string;
      is_followed: boolean;
      title: string;
      sub_interests: {
        description: string;
        id: number;
        image_url: string;
        interest: {
          id: number;
          title: string;
        }
        title: string;
      }[]
    }[];
    sub_specializations?: {
      description: string;
      id: number;
      image_url: string;
      interest: {
        id: number;
        title: string;
      }
      title: string;
    }[];
    bio?: string;
}

export type ArticleData = {
  title : string;
  content: string;
  header: string;
  minutes_for_reading: number;
  image_url : string;
  references : string;
  sub_interests : sub_interests;
  word_count: number;
}

export type CommentApiData = {
    article_id : number;
    content : string;
    is_fixed ?: boolean;
}

export type ArticleBlockContentV1 = {
  blockId : number;
  data : string;
  activeBlock ?: boolean;
}

export type Status = {
  id: number;
  name: string;
  reject_commentary?: string;
  reject_reason?: string
}

export type Review = {
    id: number;
    assigned_at: string;
    end_review_at: string;
}

export interface ArtcileInfo  {
  published_at: string;
  author: AuthorData;
  content: string;
  created_at: string;
  header: string;
  id: number;
  image_url: string;
  interest: InterestsData,
  is_verified: boolean;
  is_saved ?: boolean;
  likes_count: number;
  minutes_for_reading: number;
  referenes: string;
  status: Status;
  sub_interests: {
      id: number,
      title: string,
      description ?: string,
      imageUrl ?: string;
  }[];
  title: string;
  updated_at: string;
  views_count: number;
  word_count: number;
  version: number;
  correction ?: {
    assigned_at: string;
    end_correction_at: string;
    id: number;
  }
  review?: Review;
}

export type Highlights = {
  article: ArtcileInfo,
  content: string,
  entries: string,
  id: number
}

export type Correction = {
  article : ArtcileInfo;
  assigned_at: string;
  end_correction_at: string;
  id: number;
  editor: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
  }
}

export type WriterInfo = {
  country: string;
  email: string;
  first_name: string;
  gender: string;
  id: string;
  last_name: string;
  role: string;
  username: string;
  image_url?: string;
  is_followed?: boolean;
  specialization?: {
    title: string,
    id: number
  };
  articles_count?: number;
  followers_count?: number;
  bio?: string;
}

export type FormatSearch = "short" | "block";

export type References = {
  blockId : number,
  numberOfReferences: number,
  title: string
}

export enum Months {
  "كانون الثاني",
  "شباط",
  "آذار",
  "نيسان",
  "أيار",
  "حزيران",
  "تموز",
  "آب",
  "أيلول",
  "تشرين الأول",
  "تشرين الثاني",
  "كانون الأول",
}