import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Btn, BtnColorless } from '../buttons/btn.component';
import { View } from '../view/view.component';

import "./writer-welcome.scss";

interface WriterWelcomeModalProps{
    hideModal: () => void;
}

export const WriterWelcomeModal:FunctionComponent<WriterWelcomeModalProps> = (props) => {

    const {hideModal} = props;

    const history = useHistory();
    
    return (
        <View className="WriterWelcomeModal">
           <View className="Header">
           كتابة مقال جديد
            </View> 
            <View className="Buttons">
                <Btn title="اكتب مقال جديد" onClick={() => {history.push('/dashboard_article')}}/>
                <BtnColorless title="إغلاق" onClick={hideModal}/>
            </View>
        </View>
    )
}