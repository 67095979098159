import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
// import { BtnColorless, BtnUltraSmall } from '../../../components/buttons/btn.component';
import { Icon } from '../../../components/icons/icon.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
// import Block1 from "../../../assets/images/StaticPages/Careers/Block1.png";
// import Block2 from "../../../assets/images/StaticPages/Careers/Block2.png";
// import Block3 from "../../../assets/images/StaticPages/Careers/Block3.png";
// import Block4 from "../../../assets/images/StaticPages/Careers/Block4.png";
// import Block5 from "../../../assets/images/StaticPages/Careers/Block5.png";


import "./careers.scss";

export const CareersPage: FunctionComponent = () => { 
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
            <AppHeader isWhite={true}/>
            <View className="CareersPage">
                <View className="CareersPageHeader" title="careers_photo">
                    <Container style={{marginTop: "96px"}}>
                        <View className="CareersPageHeaderContent">
                            <Header1 title="نبحث عنكم لنسافر بعيداً" className="header1"/>
                        </View>
                        <View className="CareersPageHeaderContent">
                            <Icon name="iconMawsuahLogo"/>
                            <span className="bigText">موسوعة</span>
                            <Icon name="iconVerifiedSmallWhiteBG"/>
                            {/* <span className="smallText">196 ألف متابع</span> */}
                            {/* <BtnColorless title="تابِع"/> */}
                        </View>
                    </Container>
                </View>
                <SpacerVertical height={"4.5em"}/>
                <View className="CareersPageContent">
                    <Container>
                        <Row>
                            <Col lg={3} className="CareersPageContentSecondColumn">
                                {/* <View className="CareersPageContentSecondColumnMenu">
                                    <View className="Item">الوظائف</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">المقالات</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">الشواغر</View>
                                    <SpacerVertical height={"0.55em"}/>
                                </View>
                                <View className="CareersPageContentSecondColumnDisabled">
                                    <View className="Item">المساعدة</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">المجتمعات</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">معتمدون لدى </View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">الشركاء</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">المدونة</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">سياسية الخصوصية</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">شروط الاستحدام</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">التواصل</View>
                                    <SpacerVertical height={"0.55em"}/>
                                </View> */}
                            </Col>
                            <Col className="CareersPageContentFirstColumn" lg={9}>
                                <Header3 className="writersHeader" title="بصمتكم في موسوعة"/>
                                <div className="smallLine"></div>
                                <View className="CareersPageContentFirstColumnContent">
                                <View className="smallHeader">
                                لأولئك الذين يُشكّل وجودهم فرقاً بحماسهم وإمكانياتهم وإرادتهم، لأولئك الذين يؤمنون بقدراتهم في إحداث تأثير إيجابي وبصمة تميّز ونجاح في ميدان العمل، نحن نبحث عنكم موفّرين لكم وظائف تليق بكم وبطموحاتكم.

                                </View>
                                <SpacerVertical height={"2.286em"}/>                        
                              {/* <View style={{borderBottom: "1px solid var(--grayColorMain)"}}>
                                    <View style={{backgroundImage: `url(${Block1})`}} className="CareersPageContentFirstColumnContentFirstBlock">
                                    </View>
                                    <SpacerVertical height="2.143em"/>
                                    <View style={{display: "flex", justifyContent: "space-between"}}>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block2})`}}>
                                        </View>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block3})`}}> 
                                        </View>
                                    </View>
                                    <SpacerVertical height="2.143em"/>
                                    <View style={{display: "flex", justifyContent: "space-between"}}>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block4})`}}>
                                            
                                        </View>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block5})`}}>
                                            
                                        </View>
                                    </View>
                                    <SpacerVertical height="2.143em"/>
                                </View> */}
                                {/* <View className="moreCareers">
                                    <div>استكشاف المزيد</div>
                                    <Icon name="iconArrowLeft" />
                                </View> */}
                                {/* <SpacerVertical height={"2.3em"}/> */}
                                <Header3 className="writersHeader" title="شواغر فريق موسوعة"/>
                                <div className="smallLine"></div>
                                <View style={{borderBottom: "1px solid var(--grayColorMain)"}}>
                                    <View className="content">
                                        <View className="contentBlock">
                                            <SpacerVertical height={"1.8em"}/>
                                            <View className="contentBlockHeader">مرحباً بكم في موسوعة، مجتمع معرفي مختّص</View>
                                            <SpacerVertical height={"0.6em"}/>
                                            <View className="contentBlockText">شكراً لأنكم وجدتم في موسوعة طموحاتكم! يرجى منكم إرفاق سيرتكم الذاتية على: <a href="mailto:social@mawsuah.com">social@mawsuah.com</a> وسنقوم بالتواصل معكم في أقرب فرصة ممكنة.
                                            نتمنى لكم يوماً سعيداً مع كل النجاح!

                                            </View>
                                            <SpacerVertical height={"0.6em"}/>
                                            {/* <BtnUltraSmall className="contentBlockBtn" title="ليصافت"/> */}
                                        </View>
                                    </View>                                    
                                </View>                               
                                </View>                        
                            </Col>
                        </Row>
                    </Container>
                    <SpacerVertical height={"3.3em"}/>
                </View>
                <AppFooter />
            </View>
        </View>
    )
}