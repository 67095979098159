import escapeHtml from "escape-html";
import { Text } from "slate";
import { jsx } from "slate-hyperscript";
import { Months, sub_interests } from "./interfaces";
import { getCookie } from "./services/cookie.service";

export function activeClass(title: string, flag: boolean) {
    return title + ' ' + (flag ? 'active' : '');
  }

export function getUserId(): string{
  const token = getCookie('mawsuah_access_token');

  const firstIndex = token?.indexOf(".");
  const LastIndex = token?.lastIndexOf(".");

  if(firstIndex) {
      const str = token?.slice(firstIndex+1, LastIndex);
      let decodestr = atob(str as string);
       
      let a = JSON.parse(decodestr);
      let parsedObj = JSON.parse(a.sub);
      return parsedObj.id
  }
  return ""
}

export const getSubSpecOpt = (data : sub_interests | undefined) => {
  const arr = data?.map((item) => {
      return item.title
  })
  return arr || [" "]
}

export const serialize = (node : any,  blockId?: string) => {
  if (Text?.isText(node)  && !node.type){
//  && (!node.bold &&  !node.underline &&  !node.fontSize18 &&  !node.fontSize22 && !node.fontSize24)) {    
    return escapeHtml(node.text)
  }
  
  let children = node?.children?.map((n : any) => {
    return serialize(n)}).join('');

  if(node?.type === 'paragraph'){
    if(node?.bold || node?.underline || node?.fontSize18 || node?.fontSize22 || node?.fontSize24) {
      let fontSize : string = "16px";
      let fontWeight = "normal";
      let textDecoration = "none";
      if(node?.fontSize18 ) {
        fontSize = "18px"
      }
      if(node?.fontSize22 ) {
        fontSize = "22px"
      }
      if(node?.fontSize24 ) {
        fontSize = "24px"
      }
      if(node?.bold) {
        fontWeight = "bold"
      }
      if(node?.underline) {
        textDecoration = "underline"
      }
      return `<span style="font-size :${fontSize};text-decoration:${textDecoration};font-weight:${fontWeight}">${node?.text}</span>`
    }
  }

  // if(children == ''){
  //   children ='TEXT EMPTY';
  // }

  switch (node?.type) {
    case 'bulleted-list':
      return `<ul id=${blockId} class="ArticleNumberedList">${children}</ul>`
    case 'list-item' :
      return `<li >${children}</li>`
    case 'numbered-list':
      return `<ol id=${blockId} class="ArticleNumberedList">${children}</ol>`
    case 'video': 
      const firstIndex = node?.url?.indexOf("watch?v=");
      const lastIndex = node?.url?.indexOf("&");
      let videoId = "";
      if(lastIndex !== -1) {
        videoId = node?.url?.slice(firstIndex+8, lastIndex);
      } else {
        videoId = node?.url?.slice(firstIndex+8);
      }
     return `<iframe id=${blockId} class="videoWrapper" title=${node?.url} src="https://www.youtube.com/embed/${videoId}?enablejsapi=1" frameBorder="0"></iframe>`
    case 'image':
      return `<img class="imageContainer" src=${node?.url} alt=${node?.title} style="margin-bottom: 32px;"></img>`
    case 'text-align-right':
      return `<p id=${blockId} class="ArticleParagraph text-align-right">${children}</p>`
    case 'text-align-center':
      return `<p id=${blockId} class="ArticleParagraph text-align-center">${children}</p>`
    case 'text-align-left':
      return `<p id=${blockId} class="ArticleParagraph text-align-left">${children}</p>`
    case 'block-quote':
      return `<blockquote id=${blockId}>${children}</blockquote>`
    case 'note':
      return `<p class="ArticleNote" id=${blockId}>${children}</p>`
    case 'paragraph':
      return `<p id=${blockId} class="ArticleParagraph">${children}</p>`
    case 'link':
      if(node?.url === 'references') {
        // return `<span  class="ArticleReference">${children}<span class="ArticleReferenceHover"><a href="#${children}References">${escapeHtml(node?.url)}</a></span></span>`
        return `<span  class="ArticleReference"><a href="#${children}References">${children}</a></span>`
      }
      return `<a href="${escapeHtml(node?.url)}" target="_blank">${children}</a>`
    default:
      return children
  }
}

export const getUserRole = (role: string | undefined) => {
  if(role === "Writer") {
    return "كاتب"
  }
  if(role === "Reader") {
    return "قارئ"
  }
  if(role === "Writer & Reviewer") {
    return "كاتب ومراجع"
  }
  if(role === "Writer & Editor") {
    return "كاتب ومحرر"
  }
  if(role === "Reviewer") {
    return "مراجع"
  }
  if(role === "Reviewer & Editor") {
    return "مراجع ومحرر"
  }
  if(role === "Editor") {
    return "محرر"
  }
  return ""
}

export const serializeToText = (node : any) => {
  if (Text?.isText(node)) {
    return escapeHtml(node.text)
  }
  
  const children = node?.children?.map((n : any)=> serializeToText(n)).join('');

  switch (node?.type) {
    default:
      return children
  }
}

export const deserialize = (el : any) : any => {  
  if (el.nodeType === 3) {
    
    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  }
 
  const children = Array.from(el.childNodes).map(deserialize);
  switch (el.nodeName) {
    case 'SPAN': 
    if(el.style.fontWeight === "bold" || el.style.textDecoration === "underline" || el.style.fontSize === "18px" || el.style.fontSize === "22px" || el.style.fontSize === "24px") {
      let bold  = false;
      let underline = false;
      let fontSize18 = false;
      let fontSize22 = false;
      let fontSize24 = false;
      if(el.style.fontSize === "18px" ) {
        fontSize18 = true
      }
      if(el.style.fontSize === "22px" ) {
        fontSize22 = true
      }
      if(el.style.fontSize === "24px" ) {
        fontSize24 = true
      }
      if(el.style.fontWeight === "bold") {
        bold = true
      }
      if(el.style.textDecoration === "underline") {
        underline = true
      }
      return jsx(
        'text',
        { text: el.textContent, bold, underline, fontSize18, fontSize22, fontSize24 }
      )
    }
    if(el.classList[0] === "ArticleReference" ) {
      return jsx(
        'element',
        { type: 'link', url: el.textContent },
        children[0]
      )
    }
    if(el.classList[0] === "ArticleReferenceHover") {
      return jsx(
        'text',
        { text: el.textContent },
        children
      )
    }
    return jsx(
      'text',
      { text: el.textContent }
    )
    case 'IFRAME':
      return jsx('element', { type: 'video', url: el.getAttribute('src')}, {text: ""}) 
    case 'IMG':
      return jsx('element', { type: 'image', url: el.getAttribute('src')}, {text: ""}) 
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children) 
    case 'LI' : 
      return jsx('element', { type: 'list-item' }, children)
    case 'OL':
      return jsx('element', { type: 'numbered-list' }, children)
    case 'BODY':
      return jsx('fragment', {}, children)
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'block-quote' }, children)
    case 'P':
        if(el.classList[1] === "text-align-right") {
          return jsx('element', { type: 'text-align-right' }, children)
        }
        if(el.classList[1] === "text-align-left") {
          return jsx('element', { type: 'text-align-left' }, children)
        }
        if(el.classList[1] === "text-align-center") {
          return jsx('element', { type: 'text-align-center' }, children)
        }
      return jsx('element', { type: 'paragraph' }, children)
    case 'DIV':
      return jsx('element', { type: 'block' }, children)
    case 'A':
      return jsx(
        'element',
        { type: 'link', url: el.getAttribute('href') },
        children
      )
    case 'MARK':
        return jsx(
          'element', children
        )
    default:
      return el.textContent
  }
}


export const getDate = (date: string | undefined) => {
  if(date){
    let normalDate = ""
    const month = date.slice(5, 7);
    if(month[0] === "0") {
      normalDate = Months[+month[1]-1];
    } else {
      normalDate = Months[+month-1];
    }
    
    return `${date.slice(8, 10)} ${normalDate} ${date.slice(0, 4)}` 
  }
  return ""
}
export function objToRange(rangeStr: any) {
  try {
  let range = document.createRange();
  //setSelectArray([...selectArray, rangeStr]);
    range.setStart(document?.getElementById(rangeStr.startKey)?.childNodes[rangeStr.startTextIndex] as Node, rangeStr.startOffset);
    range.setEnd(document?.getElementById(rangeStr.endKey)?.childNodes[rangeStr.endTextIndex] as Node, rangeStr.endOffset);
  
    

  return range;
  } catch (error) {
    //console.log(error);
    
  }
}

export function rangeToObj(range : any, spanId: any, parentId?: any) {
    return {
        highlightId: spanId,
        startKey: parentId ? parentId : range.startContainer.parentNode.id,
        startTextIndex: Array.prototype.indexOf.call(range.startContainer.parentNode.childNodes, range.startContainer),
        endKey: parentId ? parentId : range.endContainer.parentNode.id,
        endTextIndex: Array.prototype.indexOf.call(range.endContainer.parentNode.childNodes, range.endContainer),
        startOffset: range.startOffset,
        endOffset: range.endOffset
      }
}

export const getMinutesForReading = (articleLength : number) => {
  if(articleLength <= 500) {
    return 2 
  }
  if(articleLength > 500 && articleLength <= 600) {
    return 3
  }
  if(articleLength > 600 && articleLength <= 800) {
    return 4
  }
  if(articleLength > 800 && articleLength <= 1100) {
    return 5
  }
  if(articleLength > 1100 && articleLength <= 1300) {
    return 6
  }
  if(articleLength > 1300 && articleLength <= 1500) {
    return 7
  }
  if(articleLength > 1500 && articleLength <= 1700) {
    return 8
  }
  if(articleLength > 1700 && articleLength <= 1900) {
    return 9
  }
  if(articleLength > 1900 && articleLength <= 2100) {
    return 10
  }
  if(articleLength > 2100 && articleLength <= 2400) {
    return 11
  }
  if(articleLength > 2400 && articleLength <= 2700) {
    return 12
  }
  if(articleLength > 2700 && articleLength <= 2900) {
    return 13
  }
  if(articleLength > 2900) {
    return 14
  }
  return 0
}