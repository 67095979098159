import React, { FunctionComponent, useState } from 'react';
import { apiService } from '../../shared/services/api.service';
import { BtnColorless } from '../buttons/btn.component';
import { InputObligatory } from '../inputs/input.component';
import { View } from '../view/view.component';
import { AxiosError } from 'axios';

import "./reset-password.scss";

export const ResetPasswordModal:FunctionComponent = () => {


    const [value, setValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [text, setText] = useState("");

    const initReset = () => {
        if( value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)) {
            apiService.auth.initResetPassword(value)
            .then((r) => {                
                setText("إذا كان بريدك الإلكتروني مسجل لدينا، سيتم من خلاله إرسال رابط لإعادة تعيين كلمة السر.");
                setErrorMessage("");
            }).catch((err : AxiosError) => {
                setText("إذا كان بريدك الإلكتروني مسجل لدينا، سيتم من خلاله إرسال رابط لإعادة تعيين كلمة السر.");
                setErrorMessage("");                
            })
        } else {
            setText("إذا كان بريدك الإلكتروني مسجل لدينا، سيتم من خلاله إرسال رابط لإعادة تعيين كلمة السر.");
            setErrorMessage("");
        }
    }
    
    return (
        <View className="WriterWelcomeModal">
           <View className="Header">
           إعادة تعيين كلمة المرور
            </View> 
            <View className="ResetPasswordSubTitle" style={{padding: "35px"}}>
            الرجاء إدخال البريد الإلكتروني
            </View>
            <View className="ResetPasswordEmailInput">
                <InputObligatory value={value} onChange={(e) => setValue(e.value)} name="emailForget" type="email" label="البريد الإلكتروني" placeholder="الرجاء إدخال البريد الإلكتروني" required/>
                <View className="ResetPasswordErrorMessage">{errorMessage}</View>
            </View>
            <View className="ResetPasswordSubTitle" style={{padding: "35px"}}>
            {text}
            </View>
            <View className="Buttons">
                <BtnColorless title="إرسال" onClick={initReset}/>
            </View>
        </View>
    )
}