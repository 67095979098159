import { HomePage } from './../pages/homepage/homepage.page';
import { IRoute } from "../shared/interfaces";
import { CareersPage } from '../pages/static-pages/careers/careers.page';
import { LoginPage } from '../pages/login/login.page';
import { SignupPage } from '../pages/signup/signup.page';
import { SignupWriterEditorPage } from '../pages/signup-writer-editor/signup-writer-editor';
import { WritersSignupPage } from '../pages/signup/writers-signup/writers-signup.page';
import { ReviewersSignupPage } from '../pages/signup/reviewers-signup/reviewers-signup.page';
import { EditorSignupPage } from '../pages/signup/editor-signup/editor-signup.page';
import { WriterReviewerSignupPage } from '../pages/signup/signup-writer-reviewer/signup-writer-reviewer.page';
import { ReviewerEditorSignupPage } from '../pages/signup/reviewer-editor-signup/reviewer-editor-signup.page';
import { SearchPage } from '../pages/search/search.page';
import { InterestsTabsPage } from '../pages/interests/interests-tabs.page';
import { InterestsLandingPage } from '../pages/interests/interests-landing.page';
import { SubInterestsTabsPage } from '../pages/interests/subinterests-tabs.page';
import { UserSettingsPage } from '../pages/user-settings/user-setting.page';
import { UserProfilePage } from '../pages/user-profile/user-profile.page';
import { UserInvitesPage } from '../pages/user-invites/user-invites.page';
import { MainSignupPage } from '../pages/signup/main-signup/main-signup.page';
import { ArticlePage } from '../pages/article/article.page';
import { PrivacyPage } from '../pages/static-pages/privacy/privacy.page';
import { RulesPage } from '../pages/static-pages/rules/rules.page';
import { BriefPage } from '../pages/static-pages/brief/brief.page';
import { TermsPage } from '../pages/static-pages/terms/terms.page';
import { AboutPage } from '../pages/static-pages/about/about.page';
import { RequestContentPage } from '../pages/static-pages/content-request/request.page';
import { DashboardArticlePage } from '../pages/dashboard-article/dashboard-article.page';
import { DraftArticlePage } from '../pages/draft-article/draft-article.page';
import { ArticleReviewPage } from '../pages/article-review-page/article-review-page.page';
import { ExplorePage } from '../pages/explore/explore.page';
import { TwitterAuthPage } from '../pages/static-pages/twitter-auth/twitter-auth.page';
import { TwitterCallbackPage } from '../pages/static-pages/twitter-auth/twitter-callback.page';
import { UserProfileAuthPage } from '../pages/user-profile-auth-profile/user-profile-auth-profile.page';
import { ResetPasswordPage } from '../pages/reset-password/reset-password.page';
import { CertifiedPage } from '../pages/static-pages/certified/certified.page';
import { HelpPage } from '../pages/static-pages/help/help.page';
import { BlogPage } from '../pages/static-pages/blog/blog.page';
import { PartnersPage } from '../pages/static-pages/partners/partners.page';
import { SharePage } from '../pages/static-pages/share/share.page';
import { CommunityPage } from '../pages/static-pages/community/community.page';
import { ArticleEditorPage } from '../pages/article-editor/article-editor.page';
import { UserNotificationPage } from '../pages/notifications/notifications.page';
import { FaqPage } from '../pages/static-pages/faq/faq.page';
import { ThankYouPage } from '../pages/static-pages/thankyou/thankyou.page';

export const routes: IRoute[] = [
    {
      path: "/homepage",
      component: HomePage
    },
    {
        path: "/careers",
        component: CareersPage
    },
    {
        path: "/blog",
        component: BlogPage
    },
    {
        path: "/partners",
        component: PartnersPage
    },
    {
        path: "/community",
        component: CommunityPage
    },
    {
        path: "/share",
        component: SharePage
    },
    {
        path: "/help",
        component: HelpPage
    },
    {
        path: "/privacy",
        component: PrivacyPage
    },
    {
        path: "/rules",
        component: RulesPage
    },
    {
        path: "/about",
        component: AboutPage
    },
    {
        path: "/brief",
        component: BriefPage
    },
    {
        path: "/faq",
        component: FaqPage
    },
    {
        path: "/terms",
        component: TermsPage
    },
    {
        path: "/certified",
        component: CertifiedPage
    },
    {
        path: "/login",
        component: LoginPage
    },
    {
        path: "/main_signup",
        component: MainSignupPage
    },
    {
        path: "/signup_editors",
        component: EditorSignupPage
    },
    {
        path: "/signup",
        component: SignupPage
    },
    {
        path: "/signup_writer_editor",
        component: SignupWriterEditorPage
    },
    {
        path: "/signup_writers",
        component: WritersSignupPage
    },
    {
        path: "/signup_reviewers",
        component: ReviewersSignupPage
    },
    {
        path: "/signup_editors",
        component: EditorSignupPage
    },
    {
        path: "/signup_writer_reviewer",
        component: WriterReviewerSignupPage
    },
    {
        path: "/signup_reviewer_editor",
        component: ReviewerEditorSignupPage
    },
    {
        path: "/search",
        component: SearchPage
    },
    {
        path: "/interests_tabs/:id",
        component: InterestsTabsPage
    },
    {
        path: "/subinterests_tabs/:id",
        component: SubInterestsTabsPage
    },
    {
        path: "/interests_landing",
        component: InterestsLandingPage
    },
    {
        path: "/user_settings",
        component: UserSettingsPage,
        protected: true
    },
    {
        path: "/user_profile/:id",
        component: UserProfilePage
    },
    {
        path: "/user_profile_auth/:id",
        component: UserProfileAuthPage,
        protected: true
    },
    {
        path: "/user_invites",
        component: UserInvitesPage,
        protected: true
    },
    {
        path: "/notifications",
        component: UserNotificationPage,
        protected: true
    },
    {
        path: "/article/:id",
        component: ArticlePage
    },
    {
        path: "/dashboard_article",
        component: DashboardArticlePage,
        protected: true
    },
    {
        path: "/draft_article/:id",
        component: DraftArticlePage,
        protected: true
    },
    {
        path: "/article_review/:id",
        component: ArticleReviewPage,
        protected: true
    },
    {
        path: "/article_editor/:id/correction/:correction_id",
        component: ArticleEditorPage,
        protected: true
    },
    {
        path: "/explore",
        component: ExplorePage
    },
    {
        path: "/twitter_auth",
        component: TwitterAuthPage,
        protected: true
    },
    {
        path: "/twitter_callback",
        component: TwitterCallbackPage,
        protected: true
    },
    {
        path: "/reset_password",
        component: ResetPasswordPage
    },
    {
        path: "/content_request",
        component: RequestContentPage
    },
    {
        path: "/thank_you",
        component: ThankYouPage
    },
    
];