import React, { FunctionComponent} from 'react';
import { Btn, BtnColorless } from '../buttons/btn.component';
import { View } from '../view/view.component';
import { apiService } from '../../shared/services/api.service';
import "./logout.scss";

interface LogoutModalProps{
    hideModal: () => void;
}

export const LogoutModel:FunctionComponent<LogoutModalProps> = (props) => {

    const {hideModal} = props;    
    
    // const [setIsAuth] = useState(apiService.auth.isAuthenticated());
    return (
        <View className="LogoutModal">
           <View className="Header">
           تسجيل الخروج؟
            </View> 
            <View className="Buttons">
                <Btn title="خروج" 
                onClick={() => {
                        apiService.auth.signout();
                        // setIsAuth(false);
                        window.location.reload();
                    }}
                />
                <BtnColorless title="إغلاق" onClick={hideModal}/>
            </View>
        </View>
    )
}