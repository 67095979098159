import React, { FunctionComponent, useEffect, useState } from 'react';
import { Btn } from '../../../../components/buttons/btn.component';
import { Icon } from '../../../../components/icons/icon.component';
import { Loader } from '../../../../components/loader/loader.component';
import { SpacerVertical } from '../../../../components/spacer/spacer.component';
import { SuggestedArticlesItem } from '../../../../components/suggested-articles/nested/suggested-articles-item.component';
import { View } from '../../../../components/view/view.component';
import { useFetch } from '../../../../shared/hooks/fetch.hook';
import { ArtcileInfo, AuthorData, InterestsData } from '../../../../shared/interfaces';
import { apiService } from '../../../../shared/services/api.service';
import { activeClass, getUserId } from '../../../../shared/utils';
import { ContentNotFound } from '../../../search/nested/content-not-found.component';
import { InterestsSearchItem } from '../../../search/nested/interests-item.search-page';
import { WritersSearchItem } from '../../../search/nested/writers-item.search-page';
import { useHistory } from 'react-router';

import "./user-profile-auth-homepage.scss";

interface UserProfileAuthHomePageProps {
}

export const UserProfileAuthHomePage: FunctionComponent<UserProfileAuthHomePageProps> = (props) => {

   

    const {response: ArticlesByInterest, isLoading: ArticlesByInterestLoading} = useFetch(() => apiService.user.getArticlesByUserInterests(getUserId(),0 ,10));
    const {response: allInterests} = useFetch(() => apiService.interests.getAllInterests(0, 10, apiService.auth.isAuthenticated()));
    
    const [myInterests, setmyInterests] = useState<InterestsData[]>([]);
    const [mostMyFollowedWriters, setmostMyFollowedWriters] = useState<AuthorData[]>([]);
    const [isArticles, setIsArticles] = useState(true);
    const [isFollowers , setIsFollowers ] = useState(false);
    const [isInterests, setIsInterests] = useState(false);
    const [interests, setInterests] = useState<InterestsData[]>([]);
    const [artcilesData, setArticlesData] = useState<ArtcileInfo[]>([]);
    const [statusFilter, setStatusFilter] = useState('Newest');
    const history = useHistory();

    useEffect(() => {
        if(allInterests?.data?.interests) {
            setInterests(allInterests?.data?.interests)
        }
    }, [allInterests]);

    useEffect(() => {
        setArticlesData(ArticlesByInterest?.data?.articles)
    }, [ArticlesByInterest])

    useEffect(() => {
        const userId = JSON.parse(localStorage.getItem("mawsuah_user_data") as string)?.uuid
        apiService.interests.getUserInterests(userId)
            .then(r => setmyInterests(r?.data));
        apiService.user.getMyMostFollowedWriters(0, 10, userId)
        .then(r => setmostMyFollowedWriters(r?.data?.authors));
    }, []);


    const getMoreItems = () => {
        if(isArticles) {
            apiService.user.getArticlesByUserInterests(getUserId(), artcilesData.length, 10)
                .then((r) => {
                    if(r.data.articles !== undefined && r.data.articles !== null) {
                        setArticlesData([...artcilesData, ...r.data.articles])
                    }
                })
        }
        if(isInterests) {
            apiService.interests.getAllInterests(interests.length, 10, apiService.auth.isAuthenticated())
                .then((r) => {
                    if(r.data.interests !== undefined && r.data.interests !== null) {
                        setInterests([...interests, ...r.data.interests]);
                    }
                })
        }
        if(isFollowers) {
            apiService.user.getMyMostFollowedWriters(mostMyFollowedWriters.length, 10, getUserId())
                .then(r => {
                    if(r.data.authors !== undefined && r.data.authors !== null) {
                        setmostMyFollowedWriters([...mostMyFollowedWriters, ...r.data.authors]);
                    }
                });
        }
    }

    const handleFilter = (status : string) => {
        if(status === "Newest") {
            setStatusFilter("Newest");
        }
        if(status === "MostRead") {
            setStatusFilter("MostRead");
        }
        if(status === "MostEngaged") {
            setStatusFilter("MostEngaged");
        }
    }

    return (
            <View className="UserProfileAuthHomePage">
            <View className="searchPageMenu">
                <View className={activeClass("searchPageMenuItem", isArticles)}
                    onClick={() => {
                        setIsArticles(true);
                        setIsFollowers(false);
                        setIsInterests(false);
                    }}>
                مقالات  
                </View>
                <View className={activeClass("searchPageMenuItem", isFollowers)}
                        onClick={() => {
                            setIsArticles(false);
                            setIsFollowers(true);
                            setIsInterests(false);
                        }}>
                كتّاب
                </View>
                <View className={activeClass("searchPageMenuItem", isInterests)} 
                    onClick={() => {
                        setIsArticles(false);
                        setIsFollowers(false);
                        setIsInterests(true);
                    }}>
                اهتمامات
                </View>
            </View>
            <SpacerVertical height={"2em"} />
            <View className="searchPageFilter">
                <View className={statusFilter === "Newest" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("Newest")}>
                الأحدث  
                </View>
                <View className={statusFilter === "MostRead" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostRead")} >
                الأكثر تصفحاً
                </View>
                <View className={statusFilter === "MostEngaged" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostEngaged")}>
                {!isArticles ? "الأكثر متابعة" : "الأكثر تفاعلاً"}
                </View>
            </View>
            <SpacerVertical height={"3.143em"} />
            <View className="searchPageContent">
            {!ArticlesByInterestLoading ?  
            <>
            {isArticles && 
                <>
                    {artcilesData?.length !== 0 ? artcilesData?.map((item : ArtcileInfo) => {
                        return (
                            <SuggestedArticlesItem 
                                toSave
                                key={item.id}
                                title={item.title}
                                header={item.header}
                                image_url={item.image_url}
                                interest={item.interest}
                                author={item.author}
                                published_at={item.published_at}
                                created_at={item.created_at}
                                minutes_for_reading={item.minutes_for_reading}
                                articleId={item.id}
                                status={item?.status?.name}
                                isSaved={item.is_saved}
                                updated_at={item.updated_at}
                            />
                        )
                    }) : <ContentNotFound/>}
                </>}
                {isFollowers && 
                    <>
                    {mostMyFollowedWriters?.map((item) => {
                        return (
                            <WritersSearchItem key={item.id} writerInfo={item}/>
                        )
                    })}
                    </>}
                {isInterests && 
                    <>
                    {interests.map((item) => {
                        return(
                            <View key={item.id}>
                                <View className="searchPageContentInterests" >
                                    <View className="bg" onClick={(e) => { e.stopPropagation(); history.push(`/interests_tabs/${item.id}`); }} >
                                        <Icon src={item.image_url} name="bg1" alt={item.title}/>
                                    </View>
                                    <InterestsSearchItem myInterests={myInterests} item={item}/>
                                </View>
                                <SpacerVertical height={"2.286em"} />
                            </View>    
                        )
                    })}
                </>}
                <Btn title="استكشف أكثر" className="searchPageContentBtn" onClick={getMoreItems}/>
        </> : <Loader/>}
            </View>
            </View>
    )
}