import React, { FunctionComponent, useEffect, useState } from 'react';
import {  Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ArtcileInfo } from '../../shared/interfaces';
import { getDate } from '../../shared/utils';
import { Icon } from '../icons/icon.component';
import { SpacerVertical } from '../spacer/spacer.component';
import {  Header3 } from '../typo/header.component';
import { View } from '../view/view.component';

import "./chosen-topics.scss"

interface ChosenTopicsProps {
    randomArticles: ArtcileInfo[];
}


export const ChosenTopics: FunctionComponent<ChosenTopicsProps> = (props) => {

    const {randomArticles} = props;

    const [mainArticle, setMainArticle] = useState<ArtcileInfo>();
    const [subArticles, setSubArticles] = useState<ArtcileInfo[]>();

    const history = useHistory();
    
    useEffect(() => {
        if(randomArticles) {
            setMainArticle(randomArticles[0]);
            setSubArticles(randomArticles.slice(1,4));
        }
    }, [randomArticles])


    return ( 
        <Container>
            <Header3 className="writersHeader" title="مختارات موسوعة"/>
            <div className="smallLine"></div>   
            <Row>
                <Col lg={6} className="chosenTopics" onClick={() => {history.push(`/article/${mainArticle?.id}`)}}>
                    <Icon src={mainArticle?.image_url} className="chosenTopicsBigIcon" name="iconChosenTopicsBigIcon" alt={mainArticle?.title}/>
                    <SpacerVertical height={"1.143em"} />
                    <h1>{mainArticle?.title}</h1>
                    <h2>{mainArticle?.header}</h2>    
                    <View className="chosenTopicsPhoto" onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/user_profile/${mainArticle?.author?.id}`)
                    }}>
                        <Icon className="IconAuthorPhoto" src={mainArticle?.author.image_url} name="iconProfilePlaceholder" alt="author_profile_photo"/>
                        <p><span>{mainArticle?.author.first_name} {mainArticle?.author.last_name}</span><Icon name="iconVerifiedLarge"/></p>    
                    </View>
                    <View className="chosenTopicsInfo">
                        <p>
                            <span onClick={(e) => {
                                e.stopPropagation();
                                history.push(`/interests_tabs/${mainArticle?.interest.id}`);
                            }}>{mainArticle?.interest.title}</span>
                            <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                            {mainArticle && getDate(mainArticle.created_at)}
                            <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                            {mainArticle?.minutes_for_reading} دقائق قراءة 
                        </p>
                    </View>
                </Col>
                <Col lg={6} className="chosenTopics">
                    {subArticles?.map((item) => {
                        return (
                        <View key={item.id} className="chosenTopicsItem" onClick={() => {history.push(`/article/${item.id}`)}}>
                            <View style={{width: "349px"}}>
                                <h1>{item.title}</h1>
                                <SpacerVertical height={16} />
                                <View className="chosenTopicsPhoto" onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(`/user_profile/${item?.author?.id}`)
                                    }}>
                                    <Icon className="IconAuthorPhoto" src={item?.author.image_url} name="iconProfilePlaceholder" alt="author_profile_photo"/>
                                    <p><span>{item.author.first_name} {item.author.last_name}</span><Icon name="iconVerifiedLarge"/></p>    
                                </View>
                                <View className="chosenTopicsInfo">
                                    <p><span onClick={(e) => {
                                e.stopPropagation();
                                history.push(`/interests_tabs/${item?.interest.id}`);
                            }}>{item.interest.title}</span><Icon className="iconGrayCircle" name="iconGrayCircle"/>{getDate(item.created_at)}<Icon className="iconGrayCircle" name="iconGrayCircle"/>{item.minutes_for_reading} دقائق قراءة  </p>
                                </View>
                            </View> 
                            <View className="chosenTopicsIcons">
                                <Icon src={item.image_url} className="chosenTopicsIcons" name="iconChosenTopicsIcon" alt={item?.title}/>
                            </View>   
                        </View>
                        )
                    })}
                    <View className="moreChosenArticles" onClick={() => {history.push("/explore")}}>
                        <div>مختارات أكثر</div>
                        <Icon name="iconArrowLeft" />
                    </View>
                </Col>
            </Row>
        </Container>
    )
}