import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { Btn, BtnSmallOpacity } from '../../components/buttons/btn.component';
import { Icon } from '../../components/icons/icon.component';
import { Loader } from '../../components/loader/loader.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { SuggestedArticlesItem } from '../../components/suggested-articles/nested/suggested-articles-item.component';
import { SuggestedArticlesWriters } from '../../components/suggested-articles/nested/suggested-articles-writers.component';
import { View } from '../../components/view/view.component';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { ArtcileInfo, AuthorData, InterestsData, WriterInfo } from '../../shared/interfaces';
import { apiService } from '../../shared/services/api.service';
import { activeClass } from '../../shared/utils';
import { InterestsSearchItem } from '../search/nested/interests-item.search-page';
import { WritersSearchItem } from '../search/nested/writers-item.search-page';

export const ExplorePage: FunctionComponent = () => {

    const {response: ArticlesResponse, isLoading: ArticlesLoading} = useFetch(() => apiService.articles.getAllPublishedArticles(0, 10, apiService.auth.isAuthenticated()));
    const {response: randomWriters, isLoading: WritersLoading} = useFetch(() => apiService.user.getRandomWriters(5));
    const {response: allInterests} = useFetch(() => apiService.interests.getAllInterests(0, 10, apiService.auth.isAuthenticated()));
    const {response: authorsResponse} = useFetch(() => apiService.user.getAllAuthors(10, 0));

    const [isAuthenticated , setIsAuthenticated] = useState(false);
    const [myInterests, setmyInterests] = useState<InterestsData[]>([]);
    const [articlesData, setArticlesData] = useState<ArtcileInfo[]>([]);
    const [statusTabs, setStatusTabs] = useState("Articles");
    const [interests, setInterests] = useState<InterestsData[]>([]);
    const [statusFilter, setStatusFilter] = useState('');
    const [authorsData, setAuthorsData] = useState<AuthorData[]>([]);

    const history = useHistory();

    useEffect(() => {
        setAuthorsData(authorsResponse?.data?.authors);
    }, [authorsResponse]);

    useEffect(() => {
        setArticlesData(ArticlesResponse?.data?.articles);
    }, [ArticlesResponse]);

    useEffect(() => {
        if(allInterests?.data?.interests) {
            setInterests(allInterests?.data?.interests)
        }
    }, [allInterests]);

    useEffect(() => {
        setIsAuthenticated(apiService.auth.isAuthenticated());
        if(localStorage.getItem("mawsuah_user_data")) {
            JSON.parse(localStorage.getItem("mawsuah_user_data") as string);
        } 
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        if(localStorage.getItem("mawsuah_user_data")) {
            const userId = JSON.parse(localStorage.getItem("mawsuah_user_data") as string).uuid
            apiService.interests.getUserInterests(userId)
                .then(r => setmyInterests(r?.data));
        }
    }, []);

    useEffect(() => {
        if(!ArticlesResponse?.data) return
        if(statusFilter === "Newest") {
            if(statusTabs === "Interests") {
                apiService.interests.getAllInterests(0, 10, apiService.auth.isAuthenticated())
                    .then((r) => {
                        setInterests(r?.data?.interests)
                    })
            }
            if(statusTabs === "Articles") {
                setArticlesData((prev) => {
                    const articlesDataSorted = prev?.sort((a, b) => Date.parse(b?.updated_at) - Date.parse(a?.updated_at));
                    return articlesDataSorted && [...articlesDataSorted]
                });
            }
            if(statusTabs === "Authors") {
                apiService.user.getLatestAuthors(10, 0)
                    .then((r) => {
                        setAuthorsData(r?.data?.authors);
                    })
            }
        }
        if(statusFilter === "MostRead") {
            if(statusTabs === "Interests") {
                apiService.user.getMostViewedInterests(10, 0)
                    .then((r) => {
                        setInterests(r?.data);
                    })
            }
            if (statusTabs === "Authors") {
                apiService.user.getMostViewedAuthors(10,0)
                    .then((r) => {
                        setAuthorsData(r?.data?.authors);
                    })
            }
            setArticlesData((prev) => {
                const articlesDataSorted = prev?.sort((a, b) => b.views_count - a.views_count);
                return articlesDataSorted && [...articlesDataSorted]
            });
        }
        if(statusFilter === "MostEngaged") {
            if(statusTabs === "Articles") {
                apiService.articles.getMostEngagedArticles(0, 10)
                .then((r) => {
                    setArticlesData(r.data.articles);
                })
            }
            if(statusTabs === "Interests") {
                apiService.user.getMostFollowedInterests(10, 0)
                    .then((r) => {
                        setInterests(r?.data);
                    })
            }
            if (statusTabs === "Authors") {
                apiService.user.getMostViewedAuthors(10,0)
                    .then((r) => {
                        setAuthorsData(r?.data?.authors);
                    })
            }
            
        }
    }, [statusFilter, ArticlesResponse, statusTabs])

    const getMore = () => {
        if(statusTabs === "Articles") {
                if(statusFilter !== "MostEngaged") {
                    apiService.articles.getAllPublishedArticles(articlesData?.length, 10, apiService.auth.isAuthenticated())
                    .then((r) => {
                        if(r.data.articles !== undefined && r.data.articles !== null) {
                            setArticlesData([...articlesData, ...r.data.articles]);
                        }
                    })
                } else {
                    apiService.articles.getMostEngagedArticles(articlesData?.length, 10)
                    .then((r) => {
                        setArticlesData([...articlesData, ...r.data.articles]);
                    })
                }
        }
        if(statusTabs === "Authors") {
            if(statusFilter === "MostEngaged") {
                apiService.user.getMostViewedAuthors(10, authorsData.length)
                .then((r) => {
                    if(r?.data?.authors !== null && r?.data?.authors !== undefined) {
                        setAuthorsData([...authorsData, ...r?.data?.authors]);
                    }
                })
            }
            if(statusFilter === "MostRead") {
                apiService.user.getMostViewedAuthors(10,authorsData.length)
                .then((r) => {
                    if(r?.data?.authors !== null && r?.data?.authors !== undefined) {
                        setAuthorsData([...authorsData, ...r?.data?.authors]);
                    }
                })
            }
            if(statusFilter === "Newest") {
                apiService.user.getLatestAuthors(10, authorsData.length)
                    .then((r) => {
                        if(r?.data?.authors !== null && r?.data?.authors !== undefined) {
                            setAuthorsData([...authorsData, ...r?.data?.authors]);
                        }
                    })
            }
        }
        if (statusTabs === "Interests") {
            if(statusFilter === "MostEngaged") {
                apiService.user.getMostFollowedInterests(10, interests.length)
                    .then((r) => {
                        if(r.data.interests !== undefined && r.data.interests !== null) {
                            setInterests([...interests, ...r.data]);
                        }
                    })
            }
            if(statusFilter === "MostRead") {
                apiService.user.getMostViewedInterests(10, interests.length)
                    .then((r) => {
                        if(r.data.interests !== undefined && r.data.interests !== null) {
                            setInterests([...interests, ...r.data]);
                        }
                    })
            }
            if(statusFilter === "Newest") {
                apiService.interests.getAllInterests(interests.length, 10, apiService.auth.isAuthenticated())
                .then((r) => {
                    if(r.data.interests !== undefined && r.data.interests !== null) {
                        setInterests([...interests, ...r.data.interests]);
                    }
                })
            }
        }
    }

    const handleFilter = (status : string) => {
        if(status === "Newest") {
            setStatusFilter("Newest");
        }
        if(status === "MostRead") {
            setStatusFilter("MostRead");
        }
        if(status === "MostEngaged") {
            setStatusFilter("MostEngaged");
        }
    }
    
    return (
        <View className="App">
            <View className="searchPage">
            <AppHeader /> 
            <Container>
                <Row>
                    <Col lg={4}>
                        {!isAuthenticated && 
                            <View className="whyMawsuah">
                            <View className="whyMawsuahTitle">لماذا موسوعة؟</View>
                            <SpacerVertical height={"1em"}/>
                            <View className="whyMawsuahText">
                            موسوعة نظام تحفيزي مبني على تقنية البلوكتشين، وهي المنصة العربية المفضلة لمنتجي المحتوى المختّص في كتابة مواضيع موثوقة لتصلهم بملايين الباحثين والقرّاء.
                            </View>
                            <SpacerVertical height={"1.714em"}/>
                            <View className="whyMawsuahBtn">
                                <Btn className="registerBtn" title="أنشئ حساباً" onClick={() => {history.push("/main_signup")}}/>
                            </View>
                        </View>}
                           <View className="SuggestedArticlesListMostReadInterests"> 
                                    <BtnSmallOpacity className="MostReadInterestsTitle" title="الكتّاب الأكثر متابعة"  disabled={true}/>
                                    <SpacerVertical height={"2.286em"}/>
                                    <View className="MostFollowedWritersLists">
                                        {WritersLoading ? 
                                        <Loader/> :
                                        randomWriters?.data?.authors?.map((item : WriterInfo) => {
                                            return (
                                                <View key={item.id}>
                                                    <SuggestedArticlesWriters userInfo={item}/>
                                                    <SpacerVertical height={"1.714em"}/>
                                                </View>
                                            )
                                        })}
                                   </View>
                            </View>
                    </Col>
                    <Col lg={8}>
                    <SpacerVertical height={"3.643em"} />
                    <View className="searchPageMenu">
                        <View className={activeClass("searchPageMenuItem", statusTabs === "Articles")}
                            onClick={() => {
                                setStatusTabs("Articles");
                            }}>
                        مقالات  
                        </View>
                        <View className={activeClass("searchPageMenuItem", statusTabs === "Authors")}
                                onClick={() => {
                                    setStatusTabs("Authors");
                                }}>
                        كتّاب
                        </View>
                        <View className={activeClass("searchPageMenuItem", statusTabs === "Interests")} 
                            onClick={() => {
                                setStatusTabs("Interests");
                            }}>
                        اهتمامات
                        </View>
                    </View>
                    <SpacerVertical height={"2em"} />
                    <View className="searchPageFilter">
                        <View className={statusFilter === "Newest" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("Newest")}>
                        الأحدث  
                        </View>
                        <View className={statusFilter === "MostRead" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostRead")} >
                        الأكثر تصفحاً
                        </View>
                        <View className={statusFilter === "MostEngaged" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostEngaged")}>
                        {statusTabs !== "Articles" ? "الأكثر متابعة" : "الأكثر تفاعلاً"}
                        </View>
                    </View>
                    <SpacerVertical height={"3.143em"} />
                    {ArticlesLoading ? 
                        <Loader/> :
                        <>
                            {statusTabs === "Articles" &&  
                                articlesData?.map((item : ArtcileInfo) => {
                                    return (
                                        <SuggestedArticlesItem 
                                            key={item.id}
                                            title={item.title}
                                            header={item.header}
                                            image_url={item.image_url}
                                            interest={item.interest}
                                            author={item.author}
                                            created_at={item.created_at}
                                            published_at={item.published_at}
                                            minutes_for_reading={item.minutes_for_reading}
                                            articleId={item.id}
                                            status={item.status.name}
                                            updated_at={item.updated_at}
                                        />
                                    )
                                })}
                            {statusTabs === "Authors" && 
                                <>
                                {authorsData?.map((item) => {
                                    return (
                                        <WritersSearchItem key={item.id} writerInfo={item}/>
                                    )
                                })}
                            </>}
                            {statusTabs === "Interests" && 
                                <>
                                {interests.map((item) => {
                                    return(
                                        <View key={item.id}>
                                            <View className="searchPageContentInterests" >
                                                <View className="bg"  onClick={(e) => { e.stopPropagation(); history.push(`/interests_tabs/${item.id}`); }}>
                                                    <Icon src={item.image_url} name="bg1" alt={item.title}/>
                                                </View>
                                                <InterestsSearchItem myInterests={myInterests} item={item}/>
                                            </View>
                                            <SpacerVertical height={"2.286em"} />
                                        </View>    
                                    )
                                })}
                            </>}
                    </>}
                    <Btn title="استكشف أكثر" className="searchPageContentBtn" onClick={getMore}/>
                    </Col>
                </Row>
            </Container>
            <SpacerVertical height={"4.571em"} />
            <AppFooter />
            </View>
        </View>
    )
}