import React, { FunctionComponent } from 'react';
import { Carousel, Container } from 'react-bootstrap';
import { Icon } from '../icons/icon.component';
import { Header1, Header3 } from '../typo/header.component';



import "./testimonials.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { View } from '../view/view.component';

export const Testimonials: FunctionComponent = () => {
    
    return (
        <>
        <Container className="Testimonials">
        <Header3 className="writersHeader" title="موسوعة بعيون قرائها"/>
        <div className="smallLine"></div> 
            <View className="Flex">
                <View className="slider">
                <Carousel fade={true} controls={false} interval={10000}>
                <Carousel.Item >
                    <View className="carouselItemContent">
                        <Icon name="iconPhoto100" alt="author_profile_photo"/>
                        <p>محمد علي</p>
                    </View>
                </Carousel.Item>
                <Carousel.Item >
                    <View className="carouselItemContent">
                        <Icon name="iconSlider1" alt="author_profile_photo"/>
                        <p>ياسمين أحمد</p>
                    </View>
                </Carousel.Item>
                <Carousel.Item >
                    <View className="carouselItemContent">
                        <Icon name="iconSlider2" alt="author_profile_photo"/>
                        <p>مريم يعقوب</p>
                    </View>
                </Carousel.Item>
                </Carousel>
                </View>
                <Header1 className="testimonialsHeader" title='شكراً لمجتمع موسوعة الذي قدم لنا مثال مختلف في طرح المعلومات الفريدة لتطوير أفكارنا، لهم الدعم المستمر.'/>
            </View>
        </Container>
        </>
    )
}