import React, { FC, useState } from "react"
import { InputObligatory } from "../../../components/inputs/input.component"
import { SpacerVertical } from "../../../components/spacer/spacer.component";

interface DraftArticleTitleProps {
    status: string;
    articleTitle: string;
    setArticleTitle: (value: string) => void;
}

export const DraftArticleTitle:FC<DraftArticleTitleProps> = (props) => {

    const {status, articleTitle, setArticleTitle} = props;

    const [isActive, setIsActive] = useState(false);

    return (
        <>
            {isActive && status === "DRAFT" ? 
            <>
            <InputObligatory 
             value={articleTitle} 
             name="title" 
             placeholder="اضافة عنوان لمقالك" 
             onChange={(e) => setArticleTitle(e.value)}/>
             <SpacerVertical height={"2em"}/>
             </> :
             <>
             <h1 id="1000" onClick={() => setIsActive(true)}>{articleTitle}</h1>
             <SpacerVertical height={"2em"}/>
             </>
            }
        </>
    )
}