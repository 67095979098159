import React from 'react';

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { routes } from "./routes";
import { IRoute } from "../shared/interfaces";
import { apiService } from '../shared/services/api.service';

const HomePagePath = routes[0].path;

function App() {

  return (
      <Router>
        <Switch>
          {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
          ))}
          <Route path="/">
            <Redirect to={HomePagePath} />
          </Route>
        </Switch>
      </Router>
  );
}

export default App;


export function RouteWithSubRoutes(route: IRoute) {
  const isAuthenticated = apiService.auth.isAuthenticated();

  if (!isAuthenticated && route.protected)
    return (
      <Route  path={route.path}>
        <Redirect to={{ pathname: routes[0].path }} />
      </Route>
    );
    
  return (
    <Route
      path={route.path}
      render={props => {
       return <route.component {...props} routes={route.routes} />}}
    />
  );
}
