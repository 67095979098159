import React, { FunctionComponent } from "react"
import { Col, Row } from "react-bootstrap"
import { Btn } from "../../../../components/buttons/btn.component"
import { Icon } from "../../../../components/icons/icon.component";
import { InputObligatory } from "../../../../components/inputs/input.component";
import { SpacerVertical } from "../../../../components/spacer/spacer.component";
import { View } from "../../../../components/view/view.component"

import "./account-settings-form.scss";


export const ConnectionsSettingsForm: FunctionComponent = () => {


    return (
        <>
        <View className="AccountSettingsForm">
            <Row className="silverInput">
                <Col lg={12}>
                    <View className="Flex">
                        <View className="Images">
                            <Icon name="iconFacebook" />
                        </View>
                        <View>
                        البريد الإلكتروني
                        </View>
                    </View>
                    <SpacerVertical height={"0.714em"}/>
                    <InputObligatory name="" label="" placeholder="123"/>
                </Col>
                <Col lg={12}>
                    <View className="Flex">
                        <View className="Images">
                            <Icon name="iconLinkedIn"/>
                        </View>
                        <View>
                        البريد الإلكتروني
                        </View>
                    </View>
                    <SpacerVertical height={"0.714em"}/>
                    <InputObligatory name="" label="" placeholder="123" />
                </Col>
                <Col lg={12}>
                    <View className="Flex">
                        <View className="Images">
                            <Icon name="iconTwitter"/>
                        </View>
                        <View>
                        البريد الإلكتروني
                        </View>
                    </View>
                    <SpacerVertical height={"0.714em"}/>
                    <InputObligatory name="" label="" placeholder="123" />
                </Col>
            </Row>
        </View>
        <View className="AccountSettingsButton">
            <Btn title="حفظ التعديلات"/>
        </View>
        </>
    )
}