import React,  { FunctionComponent } from "react";
import { useHistory } from "react-router";
import { Btn } from "../../../../../components/buttons/btn.component";
import { Icon } from "../../../../../components/icons/icon.component";
import { SuggestedArticlesItem } from "../../../../../components/suggested-articles/nested/suggested-articles-item.component";
// import { Timer } from "../../../../../components/timer/timer.component";
import { View } from "../../../../../components/view/view.component";
import { ArtcileInfo } from "../../../../../shared/interfaces";

interface UserProfileAuthEditorItemProps {
    status: string
    articleInfo: ArtcileInfo;
    endTime?: string | undefined;
}

export const UserProfileAuthEditorItem: FunctionComponent<UserProfileAuthEditorItemProps> = (props) => {

    const history = useHistory();

    // const {status, articleInfo, endTime} = props;

    const {status, articleInfo} = props;

    return(
        <View className="UserProfileAuthEditorItem">
        {/* {status === "DRAFT" &&
        <View className="editing">
            <View className="editingTitle">
                <Icon name="iconEditorCornerBlack"/>
                المسودة
            </View>
        </View>} */}
        {status === "ON_REVIEW" && 
        <View className="editing">
            <View className="editingTitle">
                <Icon name="iconEditorCornerBlack"/>
                بانتظار تعديلاتك
            </View>
            {/* <View className="editingInfo">
            الوقت المتبقي للتعديلات
            <span> 16 تعديل</span>
            </View> */}
        </View>}
        {status === "ON_CORRECTING" && 
        <View className="reviewing">
            <View className="reviewingTitle">
                <Icon name="iconReviewerCornerOrange"/>
                بانتظار التحرير
                <Btn title="تحرير المقال" onClick={() => history.push(`/article_editor/${articleInfo.id}/correction/${articleInfo.correction?.id}`)}/>
            </View>
            {/* <View className="reviewingTime">
            الوقت المقدر للتحرير 

            <span> <Timer endDate={endTime ? endTime : ""}/> ساعة</span>
            </View> */}
        </View>}
        {status === "APPROVED" && 
        <View className="approved">
            <View className="approvedTitle">
        مقال موثق   
            <Icon name="iconVerifiedGreen"/>
            </View>
            {/* <View className="editingInfo">
            الوقت المتبقي للتعديلات
            <span> 16 تعديل</span>
            </View> */}
        </View>}
        <SuggestedArticlesItem 
            opacity={(status === "ON_EDITING") ? 0.4 : 1}
            title={articleInfo.title}
            header={articleInfo.header}
            image_url={articleInfo.image_url}
            interest={articleInfo.interest}
            author={articleInfo.author}
            created_at={articleInfo.created_at}
            minutes_for_reading={articleInfo.minutes_for_reading}
            articleId={articleInfo.id}
            status={articleInfo.status.name}
            correctionId={articleInfo.correction?.id}
            updated_at={articleInfo.updated_at}/>
        </View>
    )
}