import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { BtnColorless } from '../../../components/buttons/btn.component';
import { Icon } from '../../../components/icons/icon.component';
import { SpacerHorizontal, SpacerVertical } from '../../../components/spacer/spacer.component';
import { View } from '../../../components/view/view.component';

import "./main-signup.scss";


export const MainSignupPage: FunctionComponent = () => { 

    const history = useHistory();

    return (
        <View className="App">
            <View className="signup">
                <View className="signupContent">
                    <View className="signupContentLogos" onClick={() => {history.push("/homepage")}}>
                        {/* <View className="Logo">
                            <Icon name="iconLogoBig1" alt="logo_picture"/>
                        </View> */}
                        <Icon name="iconGroupBlack" alt="logo_picture"/>
                    </View>
                    <SpacerVertical height={"2.143em"}/>
                    <View className="signupContentText">
                        <View style={{fontSize: "2.143em", lineHeight: "1.5em"}} className="textTitle"><span>كُن جزءاً من مجتمع موسوعة</span></View>
                        <SpacerVertical height={"2.143em"}/>
                        <View className="textTitleSecondary"><span>هل لديك حساب؟<span style={{cursor: "pointer", color: "var(--blueColor)"}} onClick={() => {history.push("/login")}}> تسجيل دخول </span></span></View>
                        <SpacerVertical height={"1.429em"}/>
                        <View style={{color: "var(--secondaryHeader)", fontWeight: 400, lineHeight: "1.5em"}}>
                            <span>
                            انضمامك إلى مجتمع موسوعة، يعني إقرارك بالموافقة على سياسة الخصوصية وسياسة الاستخدام
                            </span>
                        </View>
                    </View>
                    <SpacerVertical height={"2.143em"}/>
                    <View className="mainSignupButtons">                                                                                                
                        <View className="RegisterToolTip">
                            <BtnColorless className="btnHover" title=" قارئ" onClick={() => {history.push("/signup")}}/>
                            <span  className="RegisterToolTipHover">الشخص الباحث عن المعلومة الموثوقة المختّصة</span>
                        </View>  
                        <View className="RegisterToolTip">
                            <BtnColorless className="btnHover" title="كاتب" onClick={() => {history.push("/signup_writers")}}/>
                            <span  className="RegisterToolTipHover">الشخص الذي يقوم بكتابة المقال المختّص استناداً على خبرته والمراجع</span>
                        </View>
                        <View className="RegisterToolTip">
                            <BtnColorless className="btnHover" title="مراجع" onClick={() => {history.push("/signup_reviewers")}}/>
                            <span  className="RegisterToolTipHover">الشخص الذي يقوم بمراجعة وتأكيد صحة محتوى المقال قبل النشر</span>
                        </View>
                        {/* <View className="RegisterToolTip">
                            <BtnColorless className="btnHover" title="محرر" onClick={() => {history.push("/signup_editors")}}/>
                            <span  className="RegisterToolTipHover">الشخص الباحث عن المعلومة الموثوقة المختّصة</span>
                        </View>                       */}
                    </View>
                </View>
                <View className="signupPicture" title="signup_picture">
                    <View className="signupPictureContent">
                        <View className="icon">
                            <Icon name="iconLogoBigLogin" alt="logo_picture"/>
                        </View>
                        <SpacerHorizontal width={"1.8em"}/>
                        <View className="text">
                            <span>
                            أهلا بك في "موسوعة"
                            <br/>
                            معاً نبني أكبر مجتمع عربي معرفي مختّص وموثوق
                            </span>		
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}