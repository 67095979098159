import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { AlertModal } from '../../components/alert-modal/alert-modal.component';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { Btn, BtnSmallOpacity } from '../../components/buttons/btn.component';
import { CommunityPicks } from '../../components/communitypicks/communitypicks.component';
import { Icon } from '../../components/icons/icon.component';
import { LatestRandomArticle } from '../../components/latest-random-article/latest-random-article.component';
import { Loader } from '../../components/loader/loader.component';
import { useModal } from '../../components/modal/modal.component';
import { ProfileMenu } from '../../components/profile-menu/profile-menu.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { SuggestedArticlesItem } from '../../components/suggested-articles/nested/suggested-articles-item.component';
import { SuggestedArticlesWriters } from '../../components/suggested-articles/nested/suggested-articles-writers.component';
import { TelescopeBanner } from '../../components/telescope-banner/telescope-banner.component';
import { TrendingMawsuah } from '../../components/trendingMawsuah/trending-mawsuah.component';
import { Header1, Header3 } from '../../components/typo/header.component';
import { View } from '../../components/view/view.component';
import { WritersWorthFollowing } from '../../components/writers-worth-following/writers-worth-following.component';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { ArtcileInfo, WriterInfo } from '../../shared/interfaces';
import { apiService } from '../../shared/services/api.service';
import { activeClass } from '../../shared/utils';
import { ContentNotFound } from '../search/nested/content-not-found.component';
import { WritersSearchItem } from '../search/nested/writers-item.search-page';

import "./interests.scss";


export const SubInterestsTabsPage: FunctionComponent = () => {

    const location : any = useLocation();

    const {response, isLoading} = useFetch(() => apiService.articles.getAllPublishedArticles(0, 9, apiService.auth.isAuthenticated(), 0, location.pathname.split('/')[2]));
    const {response: randomWriters} = useFetch(() => apiService.user.getRandomWriters(8));
    const {response: randomArticles, isLoading: randomArticlesLoading} = useFetch(() => apiService.articles.getRandomArticles(7, apiService.auth.isAuthenticated()));

    const {showModal: showModalFullData, RenderModal: RenderModalFullData, hideModal: hideModalFullData} = useModal();

    const [isAuthenticated] = useState(apiService.auth.isAuthenticated());
    const [isArticles, setIsArticles] = useState(true);
    const [isWriters, setIsWriters] = useState(false);
    const [dataItemsCheck, setDataItemsCheck] = useState(false);
    const [articlesByInterest, setArticlesByInterest] = useState<any[]>([]);
    const [statusFilter, setStatusFilter] = useState('Newest');

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    useEffect(() => {
        if(!isLoading && response?.data?.articles) {
            setArticlesByInterest(response?.data?.articles)
        }
    }, [response, isLoading]);   

    const getMoreArticles = () => {
        apiService.articles.getAllPublishedArticles(articlesByInterest.length, 6, apiService.auth.isAuthenticated(), 0, location.pathname.split('/')[2])
            .then((r) => {
                if(r.data.articles !== undefined && r.data.articles !== null){
                    setArticlesByInterest([...articlesByInterest , ...r.data.articles])
                }
            })
            .catch(() => {
                showModalFullData();
            })
    }

    const handleFilter = (status : string) => {
        if(status === "Newest") {
            setStatusFilter("Newest");
        }
        if(status === "MostRead") {
            setStatusFilter("MostRead");
        }
        if(status === "MostEngaged") {
            setStatusFilter("MostEngaged");
        }
        if(status === "forthFilter") {
            setStatusFilter("forthFilter");
        }
    }

    const history = useHistory();

    return (
        <View className="App">
            <View className="searchPage">
            <AppHeader /> 
            <Container>
                <Row>
                    <Col lg={4}>
                        {isAuthenticated ? 
                        <View className="interestsTabsMenu">
                            <ProfileMenu />
                        </View>
                        :
                        <>
                            <View className="whyMawsuah">
                                <View className="whyMawsuahTitle">لماذا موسوعة؟</View>
                                <SpacerVertical height={"1em"}/>
                                <View className="whyMawsuahText">
                                موسوعة نظام تحفيزي مبني على تقنية البلوكتشين، وهي المنصة العربية المفضلة لمنتجي المحتوى المختّص في كتابة مواضيع موثوقة لتصلهم بملايين الباحثين والقرّاء.

                                </View>
                                <SpacerVertical height={"1.714em"}/>
                                <View className="whyMawsuahBtn">
                                    <Btn className="registerBtn" title="أنشئ حساباً" onClick={() => {history.push("/main_signup")}}/>
                                </View>
                            </View>
                                <View className="SuggestedArticlesListMostReadInterests"> 
                                        <BtnSmallOpacity className="MostReadInterestsTitle" title="الكتّاب الأكثر متابعة"  disabled={true}/>
                                        <SpacerVertical height={"2.286em"}/>
                                        <View className="MostFollowedWritersLists">
                                            {randomWriters?.data.authors?.slice(0,5).map((item : WriterInfo) => {
                                                return (
                                                    <View key={item.id}>
                                                        <SuggestedArticlesWriters userInfo={item}/>
                                                        <SpacerVertical height={"1.714em"}/>
                                                    </View>
                                                )
                                            })}
                                    </View>
                                </View> 
                        </>}
                    </Col>
                    <Col lg={8}>
                    {!isLoading ?
                    <>
                    <SpacerVertical height={"4.571em"} />
                    {articlesByInterest?.length !== 0 ? 
                    <>
                    <View className="interestsTabsHeader">
                        <View className="Flex">
                            <Header1 title={response?.data?.articles[0]?.interest?.title}/>
                            <Icon style={{cursor: "pointer"}} name={dataItemsCheck ? "iconSuccess" : "iconAdd"} onClick={() => setDataItemsCheck(!dataItemsCheck)}/>
                        </View>
                        <View className="interestsTabsHeaderNumbers">                            
                            <View><span>{response?.data?.total_count}</span>مقال </View>
                            {/* <View className="border"></View> */}
                            {/* <View><span>5,764</span>كاتب </View> */}
                            {/* <View className="border"></View> */}
                            {/* <View><span>837,901</span>متابَع </View> */}
                        </View>
                    </View>
                    <SpacerVertical height={"2.286em"} />
                    <View className="searchPageMenu">
                        <View className={activeClass("searchPageMenuItem", isArticles)}
                            onClick={() => {
                                setIsArticles(true);
                                setIsWriters(false);
                            }}>
                        مقالات  
                        </View>
                        <View className={activeClass("searchPageMenuItem", isWriters)}
                                onClick={() => {
                                    setIsArticles(false);
                                    setIsWriters(true);
                                }}>
                        كتّاب
                        </View>
                    </View>
                    <SpacerVertical height={"2em"} />
                    <View className="searchPageFilter">
                        <View className={statusFilter === "Newest" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("Newest")}>
                        الأحدث 
                        </View>
                        <View className={statusFilter === "MostRead" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostRead")} >
                        الأكثر تصفحاً
                        </View>
                        <View className={statusFilter === "MostEngaged" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostEngaged")}> 
                        {!isArticles ? "الأكثر متابعة" : "الأكثر تفاعلاً"}
                        </View>
                        <View className={statusFilter === "forthFilter" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("forthFilter")}> 
                        {(isArticles && !apiService.auth.isAuthenticated())? "الأكثر تفعالاً" : ""}
                        </View>
                    </View>
                    <SpacerVertical height={"3.143em"} />
                    <View className="searchPageContent">
                        {isArticles && 
                        <>
                            {articlesByInterest?.map((item: any, indx: number) => {
                                if(indx === 3){
                                    return (
                                        <View key={item.id}>
                                        <SuggestedArticlesItem 
                                         published_at={item.published_at}
                                        status={item.status.name}
                                        title={item.title}
                                        header={item.header}
                                        image_url={item.image_url}
                                        interest={item.interest}
                                        author={item.author}
                                        created_at={item.created_at}
                                        minutes_for_reading={item.minutes_for_reading}
                                        articleId={item.id}
                                        updated_at={item.updated_at}
                                        />
                                        <LatestRandomArticle articleData={item} lgPicture={6} lgText={6}/>
                                        <SpacerVertical height={"2.286em"} />
                                        </View>
                                    )
                                }
                                return (
                                <SuggestedArticlesItem 
                                published_at={item.published_at}
                                status={item.status.name}
                                key={item.id}
                                title={item.title}
                                header={item.header}
                                image_url={item.image_url}
                                interest={item.interest}
                                author={item.author}
                                created_at={item.created_at}
                                minutes_for_reading={item.minutes_for_reading}
                                articleId={item.id}
                                updated_at={item.updated_at}
                                />)
                            })}
                        </>}
                        {isWriters && 
                            <>
                                {randomWriters?.data.authors?.map((item : WriterInfo) => {
                                    return (
                                        <View key={item.id}>
                                            <WritersSearchItem writerInfo={item}/>
                                            <SpacerVertical height={"1.714em"}/>
                                        </View>
                                    )
                                })}
                            </>}
                        <Btn title="استكشف أكثر" className="searchPageContentBtn" onClick={getMoreArticles}/>
                    </View>
                    </> : <ContentNotFound />}
                     </> : <Loader />}
                </Col> 
                </Row>
            </Container>
            <SpacerVertical height={"6.714em"} />
            {!isAuthenticated ? <TelescopeBanner /> : <TrendingMawsuah lgPicture={8} lgText={4} info={randomArticles?.data?.articles.slice(0,1)}/>}
            {isArticles ? 
            <>
            <SpacerVertical height={"6.571em"} />
            <Container>
            <Header3 className="writersHeader RecommendedInterestsHeader" title={"اختارها المحررون"} onClick={() => {history.push("/explore")}}/>
                <div className="smallLine"></div> 
                {!randomArticlesLoading ? 
                <Row>
                    {randomArticles?.data?.articles.slice(1,6).map((item :  ArtcileInfo) => {
                        return (
                            <Col lg={4}  key={item.id}>
                                <CommunityPicks 
                                    toSave
                                    title={item.title}
                                    header={item.header}
                                    image_url={item.image_url}
                                    interest={item.interest}
                                    author={item.author}
                                    created_at={item.created_at}
                                    published_at={item.published_at}
                                    minutes_for_reading={item.minutes_for_reading}
                                    articleId={item.id}
                                    status={item.status.name}
                                    isSaved={item.is_saved}/>
                            </Col>
                        )
                    })}
                </Row> : <Loader/>}
            </Container>
            </> : null}
            {isWriters ? 
            <>
            <SpacerVertical height={"4.500em"} />
            <WritersWorthFollowing title="كتّاب يستحقون المتابعة" randomWriters={randomWriters?.data.authors}/>
            <SpacerVertical height={"1.286em"} />
            </> : null}
            <RenderModalFullData>
                    <AlertModal alertText="Something went wrong..." alertHeader="" hideModal={hideModalFullData}/>
            </RenderModalFullData>
            <AppFooter />
            </View>
        </View>
    )
}