import React, { FunctionComponent } from 'react';
import { Icon } from '../../../../../components/icons/icon.component';
import { SpacerVertical } from '../../../../../components/spacer/spacer.component';
import { View } from '../../../../../components/view/view.component';


export const UserProfileAllNotificationsItem: FunctionComponent = () => {

   

    return(
        <>
        <View className="UserProfileNotificationsItem">
                <View className="Flex">
                    <View className="UserProfileNotificationsItemType">
                        <Icon name="iconLikeBlue"/>
                    </View>
                    <View className="UserProfileNotificationsItemContent">
                        <Icon className="iconPhoto40" name="iconPhoto40" alt="author_profile_photo"/>
                        <span className="Name">سارة رأفت </span>
                        <Icon className="iconGrayCircle" name="iconVerifiedSmall"/>
                    <span className="Something"> قام بحفظ مقالك </span>
                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                        20 تموز 
                        <SpacerVertical height={"0.571em"}/>
                        <View className="Text">
                        الوجه كيف يمكنك المحافظة على خصوصيتك من أنظمة التعرف على    
                        </View>
                    </View>
                </View>
                <View className="iconNotificationsPicture">
                    <Icon  name="iconNotificationsPicture" alt="notifications_picture"/>
                </View>
        </View>
        <SpacerVertical height={"2.286em"}/>
        <View className="UserProfileNotificationsItem">
                <View className="Flex">
                    <View className="UserProfileNotificationsItemType">
                        <Icon name="iconBookmarkRoundedGray"/>
                    </View>
                    <View className="UserProfileNotificationsItemContent">
                        <Icon className="iconPhoto40" name="iconPhoto40" alt="author_profile_photo"/>
                        <span className="Name">سارة رأفت </span>
                        <Icon className="iconGrayCircle" name="iconVerifiedSmall"/>
                    <span className="Something"> قام بحفظ مقالك </span>
                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                        20 تموز 
                        <SpacerVertical height={"0.571em"}/>
                        <View className="Text">
                        الوجه كيف يمكنك المحافظة على خصوصيتك من أنظمة التعرف على    
                        </View>
                    </View>
                </View>
                <View className="iconNotificationsPicture">
                    <Icon  name="iconNotificationsPicture" alt="notifications_picture"/>
                </View>
        </View>
        <SpacerVertical height={"2.286em"}/>
        <View className="UserProfileNotificationsItem">
                <View className="Flex">
                    <View className="UserProfileNotificationsItemType">
                        <Icon name="iconBookmarkRoundedBlue"/>
                    </View>
                    <View className="UserProfileNotificationsItemContent">
                        <Icon className="iconPhoto40" name="iconPhoto40" alt="author_profile_photo"/>
                        <span className="Name">سارة رأفت </span>
                        <Icon className="iconGrayCircle" name="iconVerifiedSmall"/>
                    <span className="Something"> قام بحفظ مقالك </span>
                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                        20 تموز 
                        <SpacerVertical height={"0.571em"}/>
                        <View className="Text">
                        الوجه كيف يمكنك المحافظة على خصوصيتك من أنظمة التعرف على    
                        </View>
                    </View>
                </View>
                <View className="iconNotificationsPicture">
                    <Icon  name="iconNotificationsPicture" alt="notifications_picture"/>
                </View>
        </View>
        <SpacerVertical height={"2.286em"}/>
        <View className="UserProfileNotificationsItem">
                <View className="Flex">
                    <View className="UserProfileNotificationsItemType">
                        <Icon name="iconLogoGray"/>
                    </View>
                    <View className="UserProfileNotificationsItemContent">
                        <span className="Name">هذا المقال لديه تفاعل عالي </span>
                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                        20 تموز 
                        <SpacerVertical height={"0.571em"}/>
                        <View className="Text">
                        الوجه كيف يمكنك المحافظة على خصوصيتك من أنظمة التعرف على    
                        </View>
                    </View>
                </View>
                <View className="iconNotificationsPicture">
                    <Icon  name="iconNotificationsPicture" alt="notifications_picture"/>
                </View>
        </View>
        <SpacerVertical height={"2.286em"}/>
        <View className="UserProfileNotificationsItem">
                <View className="Flex">
                    <View className="UserProfileNotificationsItemType">
                        <Icon name="iconAddUser"/>
                    </View>
                    <View style={{alignSelf: "center"}} className="UserProfileNotificationsItemContent">
                        <Icon className="iconPhoto40" name="iconPhoto40" alt="author_profile_photo"/>
                        <span className="Name">سارة رأفت </span>
                        <Icon className="iconGrayCircle" name="iconVerifiedSmall"/>
                    <span className="Something"> قام بحفظ مقالك </span>
                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                        20 تموز 
                        <SpacerVertical height={"0.571em"}/>
                    </View>
                </View>
        </View>
        <SpacerVertical height={"2.286em"}/>
        <View className="UserProfileNotificationsItem">
                <View className="Flex">
                    <View className="UserProfileNotificationsItemType">
                        <Icon name="iconHighlighter"/>
                    </View>
                    <View className="UserProfileNotificationsItemContent">
                        <Icon className="iconPhoto40" name="iconPhoto40" alt="author_profile_photo"/>
                        <span className="Name">سارة رأفت </span>
                        <Icon className="iconGrayCircle" name="iconVerifiedSmall"/>
                    <span className="Something"> قام بحفظ مقالك </span>
                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                        20 تموز 
                        <SpacerVertical height={"0.571em"}/>
                        <View className="TextNote">
                        لعب الواقع الافتراضي خلال الحجر المنزلي دورا مهما لعدد من المستخدمين الذين
                        </View>
                        <View className="Text">
                        الوجه كيف يمكنك المحافظة على خصوصيتك من أنظمة التعرف على    
                        </View>
                    </View>
                </View>
                <View className="iconNotificationsPicture">
                    <Icon  name="iconNotificationsPicture" alt="notifications_picture"/>
                </View>
        </View>
        </>
    )
}