import { AxiosError } from "axios"
import React, { FC, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import { AlertModal } from "../../../components/alert-modal/alert-modal.component"
import { HaveAccountModal } from "../../../components/have-account-modal/have-account.modal"
import { Icon } from "../../../components/icons/icon.component"
import { Loader } from "../../../components/loader/loader.component"
import { useModal } from "../../../components/modal/modal.component"
import { SpacerVertical } from "../../../components/spacer/spacer.component"
import { Header1 } from "../../../components/typo/header.component"
import { View } from "../../../components/view/view.component"
import { InterestsData, sub_interests } from "../../../shared/interfaces"
import { apiService } from "../../../shared/services/api.service"
import { getUserId } from "../../../shared/utils"
import { InterestsLandingItem } from "./interests-landing-page-item"

interface InterestsLandingItemWrapperProps {
    item: InterestsData | undefined;
    isAuth: boolean;
    userInterests: InterestsData[];
    userSubInterests: sub_interests | undefined;
}

export const InterestsLandingItemWrapper:FC<InterestsLandingItemWrapperProps> = (props) => {

    const {item, isAuth, userInterests, userSubInterests} = props;

    const {showModal: showModalFullData, RenderModal: RenderModalFullData, hideModal: hideModalFullData} = useModal();
    const {showModal: showModalLogIn, RenderModal: RenderModalLogIn} = useModal();

    const history = useHistory();

    const [dataItemsCheck, setDataItemsCheck] = useState(false);
    const [data] = useState(item);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        userInterests?.forEach((el) => {
            if(el.id === item?.id) {
                setDataItemsCheck(true);
            }
        })
    },[userInterests,item])

    const checkAllItems = () => {
        if(isAuth) {
            setIsLoading(true);
            if(!dataItemsCheck) {
                apiService.interests.subscribeOnInterest(getUserId(), data?.id)
                .then(r => {
                    console.log(r);
                    setIsLoading(false);
                    setDataItemsCheck((prev) => !prev);
                })
                .catch((err: AxiosError) => {
                    setIsLoading(false);
                    showModalFullData();
                })
            } else {
                apiService.interests.unSubscribeFromInterest(getUserId(), data?.id)
                .then(r => {
                    console.log(r);
                    setIsLoading(false);
                    setDataItemsCheck((prev) => !prev);
                })
                .catch((err: AxiosError) => {
                    setIsLoading(false);
                    showModalFullData();
                })
            }
        }else {
            showModalLogIn();
        }
        
    }

    return (
        <>
            <View className="InterestsLandingPageHeadersContainer">
                <Header1 className="HeaderContainer" title={data ? data.title : ""} onClick={() => history.push(`/interests_tabs/${item?.id}`)}/>
                <Icon name={dataItemsCheck ? "iconSuccess" : "iconAdd"} onClick={checkAllItems}/>
                {isLoading && <Loader/>}
            </View>
            <SpacerVertical height={"3.786em"}/>
            <Row>
                {data?.sub_interests?.map((item) => {
                    return(
                        <Col xs={12} sm={6} md={6} lg={4} key={item.id}>
                            <InterestsLandingItem userSubInterests={userSubInterests} sub_interests={item} status={dataItemsCheck} isAuth={isAuth}/>
                            <SpacerVertical height={"2.143em"}/>
                        </Col>
                    )
                })}
            </Row>
            <RenderModalFullData>
                    <AlertModal alertText="Something went wrong..." alertHeader="" hideModal={hideModalFullData}/>
            </RenderModalFullData>
            <RenderModalLogIn>
                    <HaveAccountModal />
            </RenderModalLogIn>
        </>
    )
}