import React, { FunctionComponent , useState} from 'react';
// import { useForm, ValidationError } from '@formspree/react';
import './form-spree.scss';
import { SpacerVertical } from '../spacer/spacer.component';
import { Btn } from '../buttons/btn.component';
import Select from 'react-select';

export const ContactForm: FunctionComponent = () => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [fullname, setFullName] = useState('');
    const [message, setMessage] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    
    
    
    
    
    

    
    
    
    
    const options = [
      { value: 'محتوى شركات -  مواقع إلكترونية ومدونات', label: 'محتوى شركات -  مواقع إلكترونية ومدونات' },
      { value: 'محتوى علمي مختّص - أكاديمي', label: 'محتوى علمي مختّص - أكاديمي' },
      { value: 'محتوى رقمي - وسائل التواصل الاجتماعي', label: 'محتوى رقمي - وسائل التواصل الاجتماعي' },
      { value: 'محتوى إعلاني - تسويق ومبيعات', label: 'محتوى إعلاني - تسويق ومبيعات' },
      { value: 'محتوى مشاريع وشركات ناشئة - العرض التقديمي', label: 'محتوى مشاريع وشركات ناشئة - العرض التقديمي' },
      { value: 'محتوى علاقات عامة - صحفي وإعلامي', label: 'محتوى علاقات عامة - صحفي وإعلامي' },
      { value: 'محتوى مرئي - سيناريو وأفلام وثائقية', label: 'محتوى مرئي - سيناريو وأفلام وثائقية' },
      { value: 'محتوى إخباري', label: 'محتوى إخباري' },
      { value: 'خبراء التنقيح والتدقيق اللغوي', label: 'خبراء التنقيح والتدقيق اللغوي' },
      { value: 'خبراء الترجمة الإبداعية', label: 'خبراء الترجمة الإبداعية' },
      { value: 'خبراء التفريغ الصوتي', label: 'خبراء التفريغ الصوتي' }
    ];
  
    const handleChange = (selected) => {
      setSelectedOptions(selected);
    };
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // const form = event.target as HTMLFormElement;
    
        fetch('https://formspree.io/f/xjvnakeq', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fullname,
            phone,
            selectedOptions,
            company,
            email,
            message,            
          }),
        })
          .then((response) => {
            if (response.ok) {
              // alert('Message sent successfully!');
              setEmail('');
              setMessage('');
              setCompany('');
              setFullName('');
              setPhone('');
              setSelectedOptions([]);
              window.location.href = '/thank_you';  // Redirect to the thank you page
            } else {
              alert('Failed to send message.');
            }
          })
          .catch((error) => {
            console.error('Error sending message:', error);
            alert('Error sending message.');
          });
      };
      return (
        <form className="request-form" onSubmit={handleSubmit}>
            <label>
            الاسم *
            <SpacerVertical height={"1.3em"}/>
            <input
              type="fullname"
              name="fullname"
              value={fullname}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </label>
          <SpacerVertical height={"2.3em"}/>
          <label>
            اسم الشركة *
            <SpacerVertical height={"1.3em"}/>
            <input
              type="company"
              name="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
            />
          </label>
          <SpacerVertical height={"2.3em"}/>
          <label>
            عنوان بريدك الالكتروني *
            <SpacerVertical height={"1.3em"}/>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <SpacerVertical height={"2.3em"}/>
          <label>
            رقم الهاتف*
            <SpacerVertical height={"1.3em"}/>
            <input
              type="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </label>
          <SpacerVertical height={"2.3em"}/>
          <div>
          <label>
          نوع الخدمة المطلوبة *
            <SpacerVertical height={"1.3em"}/>
      <Select
        isMulti
        value={selectedOptions}
        onChange={handleChange}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="الرجاء الاختيار..."
      />
      </label>
    </div>
          <SpacerVertical height={"2.3em"}/>
          <label>
            معلومات إضافية
            <SpacerVertical height={"1.3em"}/>
            <textarea
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </label>

          
          <SpacerVertical height={"2.3em"}/>
          {/* your other form fields go here */}
          <Btn title="أرسل" type="submit"/>
        </form>
      );
    // // const [state, handleSubmit] = useForm("xjvnakeq");
    // if (state.succeeded) {
    //     return <p>Thanks for joining!</p>;
    // }
    // return (
       
    //       <form onSubmit={handleSubmit}>
    //         <label htmlFor="email">
    //           Email Address
    //         </label>
    //         <input
    //           id="email"
    //           type="email" 
    //           name="email"
    //         />
    //         <ValidationError 
    //           prefix="Email" 
    //           field="email"
    //           errors={state.errors}
    //         />
    //         <textarea
    //           id="message"
    //           name="message"
    //         />
    //         <ValidationError 
    //           prefix="Message" 
    //           field="message"
    //           errors={state.errors}
    //         />
    //         <button type="submit" disabled={state.submitting}>
    //           Submit
    //         </button>
    //       </form>
        
    // )
}