import React, { FunctionComponent } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { ProfileMenu } from '../../components/profile-menu/profile-menu.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { View } from '../../components/view/view.component';
import { UserProfileAuthEditor } from '../user-profile-auth/nested/user-profile-auth-editor/user-profile-auth-editor.component';
import { UserProfileAuthHomePage } from '../user-profile-auth/nested/user-profile-auth-homepage/user-profile-auth-homepage.component';
import { UserProfileAuthProfile } from '../user-profile-auth/nested/user-profile-auth-profile/user-profile-auth-profile.component';
import { UserProfileAuthReadingList } from '../user-profile-auth/nested/user-profile-auth-readingList/user-profile-auth-readingList.component';
import { UserProfileAuthReviewer } from '../user-profile-auth/nested/user-profile-auth-reviewer/user-profile-auth-reviewer.component';
import { UserProfileAuthTrending } from '../user-profile-auth/nested/user-profile-auth-trending/user-profile-auth-trending.component';
import { UserProfileAuthWriter } from '../user-profile-auth/nested/user-profile-auth-writer/user-profile-auth-writer.component';

type paramsType = "profile" | "homepage" | "reading_list" | "trending" | "writer" | "editor" | "reviewer";

const routes : { [name: string]: JSX.Element } = {
    profile: <UserProfileAuthProfile/>,
    homepage:  <UserProfileAuthHomePage />,
    reading_list: <UserProfileAuthReadingList />,
    trending: <UserProfileAuthTrending />,
    writer: <UserProfileAuthWriter />,
    editor: <UserProfileAuthEditor />,
    reviewer: <UserProfileAuthReviewer />
};

export const UserProfileAuthPage: FunctionComponent = () => {

    const params : {id : paramsType} = useParams();

    return (
        <View className="App">
            <View className="searchPage">
            <AppHeader /> 
            <Container>
                <Row>
                    <Col lg={4}>
                        <ProfileMenu/>
                    </Col>
                    <Col lg={8}>   
                    <SpacerVertical height={"4.571em"} /> 
                       <View>
                       {routes[params.id]}
                        </View>
                    </Col>
                </Row>
            </Container>
            <SpacerVertical height={"4.571em"} />
            <AppFooter />
            </View>
        </View>
    )
}