import React, { FunctionComponent, useEffect, useState } from "react";
import { deserialize, serialize } from "../../shared/utils";
import { Btn } from "../buttons/btn.component";
import { Icon } from "../icons/icon.component";
import { RichTextEditor } from "../rich-text-editor/rich-text-editor.component";
// import { Timer } from "../timer/timer.component";
import { View } from "../view/view.component";

interface ChatModalEditorProps {
    hideModal: () => void;
    getNewEdtiorData: (data: any, blockId: number, textContent: string, highlightId: string, highlightsOfBlock: any[]) => void;
}

export const ChatModalEditor: FunctionComponent<ChatModalEditorProps> = (props : any) => {

    const [chatInfo, setChatInfo] = useState<any>();
    const [blockId, setBlockId] = useState(0);
    const [textEditorData, setTextEditorData] = useState<any[]>([]);
    
    useEffect(() => {
        let textEditorData
        if(props.newProp?.highlight.length && props.newProp?.highlight[0].newText) {
            const myDocument = new DOMParser().parseFromString(props.newProp?.highlight[0].newText ? props.newProp?.highlight[0].newText : props.newProp?.highlight[0].text, 'text/html')
        textEditorData = [{
            type: 'paragraph',
            children: deserialize(myDocument.body),
          }]
        } else if (props.newProp?.highlight.length){
            const el =  document.getElementById(props.newProp?.highlight[0].highlightId);
            if(el?.textContent) {
                if (el.children.length > 0) {
                    if ( el.children[el.children.length-1].id === "demo") {
                        el.removeChild(el.children[el.children.length-1]);
                    }
                }
                const myDocument = new DOMParser().parseFromString(el.textContent, 'text/html');
                textEditorData = [{
                type: 'paragraph',
                children: deserialize(myDocument.body)
            }]
            }
        }
        if (textEditorData){
            setTextEditorData(textEditorData);
        }
         
        if(props.newProp?.highlight.length) {
            setChatInfo(props.newProp?.highlight[0]);
            setBlockId(props.newProp?.highlight[0].blockId);
        }
    }, [props]);

    const handleReferenceData = (data: string, id: number, numberOfReferences: number) => {
       
      }

      const handleClick = () => {
        
        const doc = new DOMParser().parseFromString(serialize(textEditorData[0]), 'text/html');
        const span = document.getElementById(props.newProp?.highlight[0].highlightId);
        const parent = document.getElementById(blockId.toString());
        if(span && doc.body.children[0].innerHTML) {
          span.innerHTML = doc.body.children[0].innerHTML
          span.style.backgroundColor = "#EDEDED"
        }
        if(parent && span?.textContent) {
            const newDoc = deserialize(parent);
            let newDocMid
            if(newDoc.children) {
                newDocMid = newDoc.children.filter((item : any)=> item.type !== "block");
            } else {
                newDocMid = newDoc
            }
            let newDocFinal = {
              type: "paragraph",
              children: newDocMid
          }

          if(newDoc?.type === "numbered-list" || newDoc?.type === "bulleted-list" || newDoc?.type === "block-quote") {
              newDocFinal = {
                  type: newDoc?.type,
                  children: newDocMid
              }
          }
                let idEL = 4000;
                const div = document.createElement('div');
                const cloneParent = document.createElement('div');
                
                if(parent.innerHTML){
                    cloneParent.innerHTML = parent.innerHTML
                    cloneParent.id = parent.id
                    div.appendChild(cloneParent);
                }
                if(div.children[0]?.childNodes.length){
                    div.children[0]?.childNodes.forEach((item : any) => {
                      
                    if(item.childNodes.length > 1) {
                      item.childNodes.forEach((item : any) => {
                        if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
                            item.id = `${blockId}${idEL+1}`;
                            idEL = idEL+1
                        }
                      })
                    }
                    if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
                        item.id = `${blockId}${idEL+1}`;
                        idEL = idEL+1
                    }
                  })
                }
                const allMarks = div.querySelectorAll(".HighlitedText");
                const range : any = new Range();
                if(allMarks?.length) {
                    let newHighLights : any[] = []
                    let arr : any[] = []
                    allMarks.forEach((item, indx) => {
                      
                      if(!!item.firstChild?.textContent?.length && item.firstChild?.textContent.length) {
                        range.setStartBefore(item);
                        range.setEndAfter(item);
                      }
                      newHighLights.push(item.textContent);
                      
                        const startTextIndex = Array.prototype.indexOf.call(range.startContainer.childNodes, item)
                        let endTextIndex = Array.prototype.indexOf.call(range.endContainer.childNodes, item)
                  
                       let startOffset = item.previousSibling?.textContent?.length
                       let endOffset = item.textContent?.length

                       if(!startOffset) {
                        startOffset = 0;
                      }

                       if(item.childNodes.length > 1 && item?.parentElement?.id === blockId.toString()) {
                        endTextIndex = endTextIndex + item.childNodes.length - 1
                        endOffset = item.childNodes[item.childNodes.length - 1].textContent?.length
                        }
                    
                      
                        if(startOffset !== undefined && startOffset !== null && endOffset){
                          if(indx === 0 && startTextIndex === 1){
                            const obj = startTextIndex-1 === endTextIndex-1 ? {
                              highlightId : item.id,
                              startKey: range.startContainer.id,
                              startTextIndex: startTextIndex-1,
                              endKey: range.endContainer.id,
                              endTextIndex: endTextIndex - 1,
                              startOffset: startOffset,
                              endOffset: endOffset+startOffset
                             } : {
                              highlightId : item.id,
                              startKey: range.startContainer.id,
                              startTextIndex: startTextIndex-1,
                              endKey: range.endContainer.id,
                              endTextIndex: endTextIndex-1,
                              startOffset: startOffset,
                              endOffset: endOffset
                            }
                             arr.push(obj);
                          } else if (indx === 0 && startTextIndex === 0 && endTextIndex === 0) {
                            const obj2 =  {
                              highlightId : item.id,
                              startKey: range.startContainer.id,
                              startTextIndex: startTextIndex,
                              endKey: range.endContainer.id,
                              endTextIndex: endTextIndex,
                              startOffset: startOffset,
                              endOffset: endOffset+startOffset
                            }
                            arr.push(obj2);
                          } else if(range.startContainer.childNodes[0].nodeName !== "#text") {
                            const obj3 = startTextIndex === endTextIndex ? {
                              highlightId : item.id,
                              startKey: range.startContainer.id,
                              startTextIndex: startTextIndex,
                              endKey: range.endContainer.id,
                              endTextIndex: endTextIndex,
                              startOffset: startOffset,
                              endOffset: endOffset+startOffset
                            } : {
                              highlightId : item.id,
                              startKey: range.startContainer.id,
                              startTextIndex: startTextIndex,
                              endKey: range.endContainer.id,
                              endTextIndex: endTextIndex,
                              startOffset: startOffset,
                              endOffset: endOffset
                            }
                            arr.push(obj3);
                          } else  {
                            const obj1 = startTextIndex-1 === endTextIndex-1 ? {
                              highlightId : item.id,
                              startKey: range.startContainer.id,
                              startTextIndex: startTextIndex-1,
                              endKey: range.endContainer.id,
                              endTextIndex: endTextIndex-1,
                              startOffset: startOffset,
                              endOffset: endOffset+startOffset
                            } : {
                              highlightId : item.id,
                              startKey: range.startContainer.id,
                              startTextIndex: startTextIndex-1,
                              endKey: range.endContainer.id,
                              endTextIndex: endTextIndex-1,
                              startOffset: startOffset,
                              endOffset: endOffset
                            }
                            arr.push(obj1);
                          }
                        }
                    })
                    props.getNewEdtiorData(newDocFinal, blockId, span.textContent, props.newProp?.highlight[0].highlightId, arr);
                    props.hideModal();
                  }
        }
      }

      const handleTextEditor = (instance: any, id: number) => {
        setTextEditorData(instance);
      }

    return(
        <View className="ChatModal">
          <View className="ChatModalEditorColumn">
            <View className="ChatModalHeader">
                <View className="Comments">
                    <Icon name="iconCommentLinesBlack"/>
                    تعليق  {chatInfo?.numberOfComment}  
                </View>
                {/* <View className="RemainigTime">
                 الوقت المتبقي للتعديلات:
                 <span>  <Timer endDate={props.newProp?.endTime}/> ساعة </span>
                </View> */}
            </View>
            <View className="ChatModalSubHeaderTitle">
            النص الآصلي
            </View>
            <View className="ChatModalSubHeader">
            {chatInfo?.text}
            </View>
            <View style={{backgroundColor: "white", padding: "1.714em 1.143em 0 1.143em"}} className="DashboardArticle">
            {textEditorData.length !== 0 &&
            <RichTextEditor 
                id={1} 
                numberOfReferences={1}
                handleReferenceData={handleReferenceData}
                handleTextEditor={handleTextEditor}
                editorData={textEditorData}
                isParagraphProp={true}
                isListProp={true}
                iconsItemProp={"iconParagraph"}
                textAlignIconProp="iconTextAlignRight"
                isWithSettings={true}
            />}
            </View>
            <View className="CommentHandled">
            </View>
            <View className="ChatModalButtons">
                <Btn className="colored" title="تأكيد عملية التحرير" onClick={handleClick}/>
                {/* <BtnColorless className="colorless" title="إغلاق" onClick={() => props.hideModal()}/> */}
            </View>
            </View>
        </View>
    )
}