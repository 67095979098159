import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
// import { Icon } from '../../../components/icons/icon.component';
// import { BtnColorless } from '../../../components/buttons/btn.component';
import partnersBG from '../../../assets/images/StaticPages/RequestContentPage/requestContentBG.png';
import abdaliLogo from '../../../assets/images/StaticPages/RequestContentPage/abdali.png';
import hikmaLogo from '../../../assets/images/StaticPages/RequestContentPage/hikma.png';
import startmeshreqLogo from '../../../assets/images/StaticPages/RequestContentPage/startmeshreq.png';
import thegroupLogo from '../../../assets/images/StaticPages/RequestContentPage/thegroup.png';
import { ContactForm } from '../../../components/form-spree/form-spree';
import "./request.scss";

export const RequestContentPage: FunctionComponent = () => { 


    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    

    return (
        <View className="App">
        <AppHeader isWhite={true}/>
        <View className="CareersPage">
            <View className="CareersPageHeader" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(${partnersBG})`}}>
                <Container style={{marginTop: "96px"}}>
                    <View className="CareersPageHeaderContent">
                        <Header1 title="كاتب محتوى موثوق" className="header1"/>
                    </View>
                    <View className="CareersPageHeaderContent">
                        {/* <Icon name="iconMawsuahLogo"/> */}
                        {/* <span className="bigText">موسوعة</span> */}
                        {/* <Icon name="iconVerifiedSmallWhiteBG"/> */}
                        {/* <span className="smallText">196 ألف متابع</span> */}
                        {/* <BtnColorless title="تابِع"/> */}
                    </View>
                </Container>
            </View>
            <SpacerVertical height={"4.5em"}/>
            <View className="CareersPageContent">
                <Container>
                    <Row>
                        <Col lg={3} className="CareersPageContentSecondColumn">
                            {/* <View className="CareersPageContentSecondColumnMenu" style={{borderBottom: "0px"}}>
                                <View className="Item">سياسية الخصوصية</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View>
                            <View className="CareersPageContentSecondColumnDisabled">
                                <View className="Item">الشركاء</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المساعدة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المجتمعات</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">معتمدون لدى </View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المدونة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الوظائف</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">سياسية الخصوصية</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">شروط الاستحدام</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">التواصل</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View> */}
                        </Col>
                        <Col className="CareersPageContentFirstColumn" lg={9}>
                            <Header3 className="writersHeader" title="خبراء الكتابة بانتظارك"/>
                            <div className="smallLine"></div>
                            <View className="CareersPageContentFirstColumnContent">
                            <View className="smallHeader">
                                أصبح الآن بإمكانك اختيار خبراء مستقلين مختصين بالكتابة الإبداعية في لحظات. مئات المحترفين العرب على استعداد لمساعدتكم للوصول للعالمية. تواصل معنا الآن لضمان جودة المحتوى وتنفيذ المهمة عن طريق طلب أمهر الكفاءات في مجتمع موسوعة.                            </View>
                                <SpacerVertical height={"2.3em"}/>                           
                            {/* <View style={{borderBottom: "1px solid var(--grayColorMain)"}}> */}
                            <Header3 className="writersHeader" title="شركات تثق بموسوعة"/>
                            <div className="smallLine"></div>
                            <SpacerVertical height={"2.3em"}/>
                            <Row>                
                                <Col xs={6} sm={3}>
                                    <View>                        
                                        <View className="RequestPartnerBlock">                                    
                                            <View className="RequestPartnerBlockLogo">
                                                <img src={hikmaLogo} alt="zenobiaLogo" />
                                            </View>
                                        </View>
                                    </View>
                                </Col>
                                <Col xs={6} sm={3}>
                                    <View>                        
                                        <View className="RequestPartnerBlock">                                    
                                            <View className="RequestPartnerBlockLogo">
                                                <img src={startmeshreqLogo} alt="zenobiaLogo" />
                                            </View>
                                        </View>
                                    </View>
                                </Col>
                                <Col xs={6} sm={3}>
                                    <View>                        
                                        <View className="RequestPartnerBlock">                                    
                                            <View className="RequestPartnerBlockLogo">
                                                <img src={thegroupLogo} alt="zenobiaLogo" />
                                            </View>
                                        </View>
                                    </View>
                                </Col>
                                <Col xs={6} sm={3}>
                                    <View>                        
                                        <View className="RequestPartnerBlock">                                    
                                            <View className="RequestPartnerBlockLogo">
                                                <img src={abdaliLogo} alt="zenobiaLogo" />
                                            </View>
                                        </View>
                                    </View>
                                </Col>
                            </Row>
                            

                            <View>
                                {/* <div data-paperform-id="tau6fvgi"></div> */}
                                <ContactForm />
                                
                    
                            </View>
                            {/* <View className="moreCareers">
                                <div>استكشاف المزيد</div>
                                <Icon name="iconArrowLeft" />
                            </View> */}
                            
                            </View>                           
                        </Col>
                    </Row>
                    
                </Container>
                <SpacerVertical height={"3.3em"}/>
            </View>
            <AppFooter />
        </View>
    </View>
    )
}