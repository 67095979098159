import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { BtnSmallColorless } from "../../../../components/buttons/btn.component";
import { Icon } from "../../../../components/icons/icon.component";
import { SpacerVertical } from "../../../../components/spacer/spacer.component"
import { View } from "../../../../components/view/view.component"
import { AuthorData } from "../../../../shared/interfaces";
import { getUserRole } from "../../../../shared/utils";

import "./user-profile-auth-info.scss";

interface UserProfileAuthInfoProps {
    authorData: AuthorData | null;
}

export const UserProfileAuthInfo: FunctionComponent<UserProfileAuthInfoProps> = (props) => {

    const {authorData} = props;

    const history = useHistory();

    return (
        <>
        <View className="UserProfileGoBack" onClick={() => {history.goBack()}}>
            <Icon name="iconArrowRight" alt="iconArrowRight"/>
            رجوع إلى الخلف
        </View>
        <View className="UserProfileAuthInfo">
        <View className="Badges">
            {authorData?.badges?.map((item) => {
                return <Icon key={item.id} src={item.image_url} name="iconBadge1" alt={item.name}/>
            })}
        </View>
        <View className="UserProfileAuthInfoPhoto">
                <Icon src={authorData?.image_url} name="iconProfilePlaceholder" alt="author_profile_photo"/>
        </View>
        <View className="UserProfileAuthInfoContent">
                <View className="UserProfileAuthInfoContentTitle">
                    <span style={authorData?.role === "Reader" ? {marginLeft: "0.534em"} : {marginLeft: "0"}}>{authorData?.first_name} {authorData?.last_name}</span>  
                    {authorData?.role !== "Reader" && <Icon name="iconVerifiedLarge"/>}
                    <BtnSmallColorless title="تعديل الحساب" onClick={() => {history.push("/user_settings")}}/>
                </View>
                <SpacerVertical height={"0.571em"} />
                <View className="WritersSearchNumbers">   
                    <View className="Flex">
                        {getUserRole(authorData?.role)} 
                        <span className="spanBold" style={{cursor: "pointer"}} onClick={() => {
                            if(authorData?.specializations !== undefined) {
                                history.push(`/interests_tabs/${authorData?.specializations[0].id}`)
                            }
                           }}>{authorData?.specializations !== undefined ? authorData?.specializations[0].title : ""}</span>
                        <Icon className="iconGrayCircle displayNone" name="iconGrayCircle"/>
                        <span className="displayNone"> عضو منذ {authorData?.registration_year} </span>   
                        <Icon className="iconGrayCircle displayNone" name="iconGrayCircle"/>
                        <span className="displayNone">{authorData?.country} </span>
                    </View>
                </View>
                <SpacerVertical height={"1.143em"} />
                <View className="UserProfileAuthInfoContentText">
                    {authorData?.bio}
                </View>
                <SpacerVertical height={"1.143em"} />
               {authorData?.role !== "Reader" && <View className="WritersSearchNumbers">   
                    <View className="Flex">
                        <Icon name="iconVideo"/>
                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                        {authorData?.articles_count ? authorData?.articles_count : 0} <span>مقال</span>
                        {authorData?.role?.indexOf("Writer") !== -1 &&
                        <> <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                        {authorData?.followers_count ? authorData?.followers_count : 0} <span>متابَع </span></>}
                    </View>
                </View>}
        </View>
    </View>
    </>
    )
}