import React, { FunctionComponent, useState } from "react";
import { Btn} from "../../../../components/buttons/btn.component";
import { SpacerVertical } from "../../../../components/spacer/spacer.component";
import { View } from "../../../../components/view/view.component";
import { activeClass } from "../../../../shared/utils";
import { UserProfileAllNotificationsItem } from "./nested/user-profile-auth-all-notifications-item.component";
import { UserProfileWriterNotificationsItem } from "./nested/user-profile-auth-writer-notifications-item.component";

import "./user-profile-auth-notifications.scss";

interface IUserProfileAuthProfileProps {
}


export const UserProfileAuthNotifications : FunctionComponent<IUserProfileAuthProfileProps> = (props) => {


    
    const [isAll, setIsAll] = useState(true);
    const [isEditor , setIsEditor ] = useState(false);
    const [isReviewer, setIsReviewer] = useState(false);
    const [isWriter, setIsWriter] = useState(false);

    return (
        <>
            <View className="searchPageMenu">
                <View className={activeClass("searchPageMenuItem", isAll)}
                    onClick={() => {
                        setIsAll(true);
                        setIsEditor(false);
                        setIsReviewer(false);
                        setIsWriter(false);
                    }}>
                 كل الاشعارات   <span style={{color: "var(--alert)"}}>(9)</span>
                </View>
                <View className={activeClass("searchPageMenuItem", isEditor)} 
                    onClick={() => {
                        setIsAll(false);
                        setIsEditor(true);
                        setIsReviewer(false);
                        setIsWriter(false);
                    }}>
                   ركن المحرر   <span style={{color: "var(--alert)"}}>(5)</span>
                </View>
                <View className={activeClass("searchPageMenuItem", isReviewer)}
                        onClick={() => {
                            setIsAll(false);
                            setIsEditor(false);
                            setIsReviewer(true);
                            setIsWriter(false);
                        }}>
                    ركن المُراجع  <span style={{color: "var(--alert)"}}>(3)</span>
                </View>
                <View className={activeClass("searchPageMenuItem", isWriter)} 
                    onClick={() => {
                        setIsAll(false);
                        setIsEditor(false);
                        setIsReviewer(false);
                        setIsWriter(true);
                    }}>
                 ركن الكاتب <span style={{color: "var(--alert)"}}>(1)</span>
                </View>
            </View>
            <SpacerVertical height={"2.286em"} />
            <View className="searchPageContent">
                {isAll && 
                <>
                    <UserProfileAllNotificationsItem />
                </>}
                {isEditor && 
                    <>
                        <UserProfileWriterNotificationsItem 
                        btnTitle="راجع المقال"
                        Published={false} 
                        Edit={true} 
                        RemainingTime={true} 
                        text="لتعليم البرمجة للأطفال تعزز إبداعهم وتوفر فرص عمل.. 5 مواقع وتطبيقات سهلة" 
                        title="مقالك بإنتظار تعديلاتك" Noticed={true}/>
                        <UserProfileWriterNotificationsItem 
                        text="لتعليم البرمجة للأطفال تعزز إبداعهم وتوفر فرص عمل.. 5 مواقع وتطبيقات سهلة" 
                        title="مقالك بإنتظار تعديلاتك" />
                        <UserProfileWriterNotificationsItem 
                        text="لتعليم البرمجة للأطفال تعزز إبداعهم وتوفر فرص عمل.. 5 مواقع وتطبيقات سهلة" 
                        title="مقالك بإنتظار تعديلاتك" />
                    </>}
                {isReviewer && 
                    <>
                        <UserProfileWriterNotificationsItem 
                        btnTitle="راجع المقال"
                        Published={false} 
                        Edit={true} 
                        RemainingTime={true} 
                        text="لتعليم البرمجة للأطفال تعزز إبداعهم وتوفر فرص عمل.. 5 مواقع وتطبيقات سهلة" 
                        title="مقالك بإنتظار تعديلاتك" Noticed={false}/>
                        <UserProfileWriterNotificationsItem 
                        btnTitle="راجع المقال"
                        Published={false} 
                        Edit={true} 
                        RemainingTime={true} 
                        text="لتعليم البرمجة للأطفال تعزز إبداعهم وتوفر فرص عمل.. 5 مواقع وتطبيقات سهلة" 
                        title="مقالك بإنتظار تعديلاتك" Noticed={false}/>
                        <UserProfileWriterNotificationsItem 
                        btnTitle="اسباب الرفض"
                        Published={false} 
                        Edit={true} 
                        text="لتعليم البرمجة للأطفال تعزز إبداعهم وتوفر فرص عمل.. 5 مواقع وتطبيقات سهلة" 
                        title="مقالك بإنتظار تعديلاتك" Noticed={false}/>
                        <UserProfileWriterNotificationsItem 
                        text="لتعليم البرمجة للأطفال تعزز إبداعهم وتوفر فرص عمل.. 5 مواقع وتطبيقات سهلة" 
                        title="مقالك بإنتظار تعديلاتك" />
                    </>}
                    {isWriter && 
                    <>
                        <UserProfileWriterNotificationsItem 
                        btnTitle="انشر المقال"
                        Published={true} 
                        text="المستخدمين قبل مشاركة الأخبار القديمة اعترفت بمسؤوليتها عن نشر المعلومات المضللة.. فيسبوك تحذر" 
                        title="المقال تم تحريره وجاهز للنشر"/>
                        <UserProfileWriterNotificationsItem 
                        Published={false} 
                        Edit={false} 
                        RemainingTime={true} 
                        text="لهواتف آيفون أبرزها النقر على الجهاز لاستدعاء خدمة.. ميّزات جديدة قادمة" 
                        title="مقالك قيد المراجعة" />
                        <UserProfileWriterNotificationsItem 
                        text="لتخدمك في أثناء الركود الاقتصادي؟ من الهاتف الذكي للحاسوب.. كيف تطيل عمر أجهزتك الإلكترونية" 
                        title="تم الموافقة على المقال من قبل مجتمع المراجعين"/>
                        <UserProfileWriterNotificationsItem 
                        btnTitle="اسباب الرفض"
                        Published={false} 
                        Edit={true} 
                        RemainingTime={false} 
                        text="المستخدمين قبل مشاركة الأخبار القديمة اعترفت بمسؤوليتها عن نشر المعلومات المضللة.. فيسبوك تحذر" 
                        title="المقال تم تحريره وجاهز للنشر" Noticed={true}/>
                        <UserProfileWriterNotificationsItem 
                        btnTitle="تعديل"
                        Published={false} 
                        Edit={true} 
                        RemainingTime={true} 
                        text="لتعليم البرمجة للأطفال تعزز إبداعهم وتوفر فرص عمل.. 5 مواقع وتطبيقات سهلة" 
                        title="مقالك بإنتظار تعديلاتك" Noticed={false}/>
                    </>
                    }
                    <SpacerVertical height={"2.286em"}/>
                <Btn title="استكشف أكثر" className="searchPageContentBtn"/>
            </View>
        </>    
    )
}