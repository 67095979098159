import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { Loader } from '../../components/loader/loader.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { Header1, Header2 } from '../../components/typo/header.component';
import { View } from '../../components/view/view.component';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { InterestsData, sub_interests } from '../../shared/interfaces';
import { apiService } from '../../shared/services/api.service';
import { getUserId } from '../../shared/utils';

import "./interests-landing.scss";
import { InterestsLandingItemWrapper } from './nested/interests-landing-page-items-wrapper';

export const InterestsLandingPage: FunctionComponent = () => {
    
    const {response, isLoading} = useFetch(() => apiService.interests.getAllInterests(0, 40, apiService.auth.isAuthenticated()));

    const [interests, setInterests] = useState<InterestsData[]>([]);
    const [isAuth] = useState(apiService.auth.isAuthenticated());
    const [userInterestsResponse, setUserInterestsResponse] = useState<InterestsData[]>([]);
    const [userSubInterestsResponse, setUserSubInterestsResponse] = useState<sub_interests>();

    useEffect(() => {
        if(isAuth){
        apiService.interests.getUserInterests(getUserId())
            .then((r) => {
                setUserInterestsResponse(r?.data);
            });
        apiService.interests.getUserSubInterests(getUserId())
            .then((r) => {
                setUserSubInterestsResponse(r?.data);
            });
        }
    }, [isAuth]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    

   useEffect(() => {
    if(!isLoading) {
        let arr : InterestsData[] = [];
        response?.data?.interests?.forEach((item : any) => {
            if(item?.sub_interests?.length !== 0 ) {
                arr.push(item)
            }
        })
        setInterests(arr);
    }
}, [response, isLoading]);

    return (
        <View className="App">
            <View className="InterestsLandingPage">
            <AppHeader/> 
                <Container>
                    <SpacerVertical height={"4.571em"}/>
                    <View className="InterestsLandingPageHeaders">
                        <Header1 className="Header" title="اهتمامات"/>
                        <Header2 className="SubHeader" title="عالم من المواضيع المتنوعة بين يديك، اختر الاهتمامات المفضلة لديك لمتابعتها."/>
                    </View>
                    <SpacerVertical height={"4.571em"}/>
                    {isLoading ?
                    <>
                    <Loader/>
                    <SpacerVertical height={"50em"}/> 
                    </> :
                    interests?.map((item) => {                    
                    return(
                        <View key={item.id}>
                            <InterestsLandingItemWrapper userSubInterests={userSubInterestsResponse} userInterests={userInterestsResponse} item={item} isAuth={isAuth}/>
                        </View>
                    )})
                    }
                    <SpacerVertical height={"2.428em"}/>
                </Container>
            <AppFooter />
            </View>
        </View>
    )
}