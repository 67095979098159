import React, { FunctionComponent, useState } from "react"
import { Icon } from "../../../components/icons/icon.component";
import { SpacerVertical } from "../../../components/spacer/spacer.component"
import { View } from "../../../components/view/view.component"
import { AccountSettingsForm } from "./forms/account-settings-form.component";
import { ConnectionsSettingsForm } from "./forms/connections-settings-form.component";
import { ContentPreferencesSettingsForm } from "./forms/contentPreferences-settings-form.component";
import { CVSettingsForm } from "./forms/CV-settings-form.component";
import { DeleteAccount } from "./forms/delete-settings-form.component";
import { PasswordSettingsForm } from "./forms/password-settings-form.component";
import { SessionLogSettingsForm } from "./forms/sessionsLog-settings-form.component";


interface UserSettingsItemProps {
    iconName: string;
    title: string;
    subTitle: string;
}


export const UserSettingsItem: FunctionComponent<UserSettingsItemProps> = (props) => {

    const {iconName, title, subTitle} = props;

    const [status, setStatus] = useState(false);

    return (
        <>
        <SpacerVertical height={"2.143em"}/>
        <View className="UserSettingsItem">
            <View className="Flex">
                <View className="Flex1">
                    <View className="FlexIcon">
                        <Icon name={iconName}/>
                    </View>
                    <View className="FlexText">
                        <View className="Title">{title}</View>
                        <View className="SubTitle">{subTitle}</View>
                    </View>
                </View>
                <View className="FlexEdit" onClick={() => setStatus(!status)}>
                تعديل   
                </View>
            </View>
        </View>
        
        {(status && iconName === "iconContentPreferences" )&& <ContentPreferencesSettingsForm />}
        {(status && iconName === "iconDeleteAccount" )&& <DeleteAccount text="حذف الحساب"/>}
        {(status && iconName === "iconDeactivate" )&& <DeleteAccount text=" تعطيل الحساب"/>}
        {(status && iconName === "iconSessions" ) && <SessionLogSettingsForm />}
        {(status && iconName === "iconConnections" ) && <ConnectionsSettingsForm />}
        {(status && iconName === "iconCV" ) && <CVSettingsForm />}
        {(status && iconName === "iconPassword" ) && <PasswordSettingsForm />}
        {(status && iconName === "iconAccountSettings" ) && <AccountSettingsForm />}
        </>
    )
}