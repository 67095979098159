import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
import { Icon } from '../../../components/icons/icon.component';
import { BtnColorless, BtnUltraSmall } from '../../../components/buttons/btn.component';
import helpBG from '../../../assets/images/StaticPages/HelpPage/helpBG.png';

export const HelpPage: FunctionComponent = () => { 

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
        <AppHeader isWhite={true}/>
        <View className="CareersPage">
            <View className="CareersPageHeader" title="careers_photo" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(${helpBG})`}}>
                <Container style={{marginTop: "96px"}}>
                    <View className="CareersPageHeaderContent">
                        <Header1 title="دائماَ هنا للمساعدة" className="header1"/>
                    </View>
                    <View className="CareersPageHeaderContent">
                        <Icon name="iconMawsuahLogo"/>
                        <span className="bigText">موسوعة</span>
                        <Icon name="iconVerifiedSmallWhiteBG"/>
                        <span className="smallText">196 ألف متابع</span>
                        <BtnColorless title="تابِع"/>
                    </View>
                </Container>
            </View>
            <SpacerVertical height={"4.5em"}/>
            <View className="CareersPageContent">
                <Container>
                    <Row>
                        <Col lg={3} className="CareersPageContentSecondColumn">
                            {/* <View className="CareersPageContentSecondColumnMenu" style={{borderBottom: "0px"}}>
                                <View className="Item">سياسية الخصوصية</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View>
                            <View className="CareersPageContentSecondColumnDisabled">
                                <View className="Item">المساعدة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المجتمعات</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">معتمدون لدى </View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الشركاء</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المدونة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الوظائف</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">شروط الاستحدام</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">التواصل</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View> */}
                        </Col>
                        <Col className="CareersPageContentFirstColumn" lg={9}>
                            <Header3 className="writersHeader" title="بصمتكم في موسوعة"/>
                            <div className="smallLine"></div>
                            <View className="CareersPageContentFirstColumnContent">
                            <View className="smallHeader">لأولئك الذين يُشكّل وجودهم فرقاً بحماسهم وإمكانياتهم وإرادتهم، لأولئك الذين يؤمنون بقدراتهم في إحداث تأثير إيجابي وبصمة تميّز ونجاح في ميدان العمل، نحن نبحث عنكم موفّرين لكم وظائف تليق بكم وبطموحاتكم.
                            </View>
                            <SpacerVertical height={"2.286em"}/>
                            <View className="moreCareers">
                                <div>استكشاف المزيد</div>
                                <Icon name="iconArrowLeft" />
                            </View>
                            <SpacerVertical height={"2.3em"}/>
                            <Header3 className="writersHeader" title="شواغر فريق التصميم"/>
                            <div className="smallLine"></div>
                            <View style={{borderBottom: "1px solid var(--grayColorMain)"}}>
                                <View className="content">
                                    <View className="contentBlock">
                                        <SpacerVertical height={"1.8em"}/>
                                        <View className="contentBlockHeader">ةيرادا ةدناسم لوؤسم</View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <View className="contentBlockText">زيكرتلا نع ئراقلا يهليس ام ةحفصل ءورقملا ىوتحملا نأ يهو ليوط نمز ذنم ةتبثم ةقيقح 
                                                                            .اهأرقي يتلا ةحفصلا يف تارقفلا عضوت لكش وأ صنلل يجراخلا لكشلا ىلع
                                        </View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <BtnUltraSmall className="contentBlockBtn" title="ليصافت"/>
                                    </View>
                                </View>
                                <SpacerVertical height={"2.3em"}/>
                                <View className="content">
                                    <View className="contentBlock">
                                        <SpacerVertical height={"1.8em"}/>
                                        <View className="contentBlockHeader">يعامتجالا لصاوتلا لوؤسم</View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <View className="contentBlockText">زيكرتلا نع ئراقلا يهليس ام ةحفصل ءورقملا ىوتحملا نأ يهو ليوط نمز ذنم ةتبثم ةقيقح 
                                                                            .اهأرقي يتلا ةحفصلا يف تارقفلا عضوت لكش وأ صنلل يجراخلا لكشلا ىلع
                                        </View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <BtnUltraSmall className="contentBlockBtn" title="ليصافت"/>
                                    </View>
                                </View>
                                <SpacerVertical height={"2.286em"}/>
                            </View>
                            <View className="moreCareers">
                                <div>استكشاف المزيد</div>
                                <Icon name="iconArrowLeft" />
                            </View>
                            <Header3 className="writersHeader" title="شواغر فريق التصميم"/>
                            <div className="smallLine"></div>
                            <View style={{borderBottom: "1px solid var(--grayColorMain)"}}>
                                <View className="content">
                                    <View className="contentBlock">
                                        <SpacerVertical height={"1.8em"}/>
                                        <View className="contentBlockHeader">ةيرادا ةدناسم لوؤسم</View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <View className="contentBlockText">زيكرتلا نع ئراقلا يهليس ام ةحفصل ءورقملا ىوتحملا نأ يهو ليوط نمز ذنم ةتبثم ةقيقح 
                                                                            .اهأرقي يتلا ةحفصلا يف تارقفلا عضوت لكش وأ صنلل يجراخلا لكشلا ىلع
                                        </View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <BtnUltraSmall className="contentBlockBtn" title="ليصافت"/>
                                    </View>
                                </View>
                                <SpacerVertical height={"2.3em"}/>
                                <View className="content">
                                    <View className="contentBlock">
                                        <SpacerVertical height={"1.8em"}/>
                                        <View className="contentBlockHeader">يعامتجالا لصاوتلا لوؤسم</View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <View className="contentBlockText">زيكرتلا نع ئراقلا يهليس ام ةحفصل ءورقملا ىوتحملا نأ يهو ليوط نمز ذنم ةتبثم ةقيقح 
                                                                            .اهأرقي يتلا ةحفصلا يف تارقفلا عضوت لكش وأ صنلل يجراخلا لكشلا ىلع
                                        </View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <BtnUltraSmall className="contentBlockBtn" title="ليصافت"/>
                                    </View>
                                </View>
                                <SpacerVertical height={"2.286em"}/>
                            </View>
                            <View className="moreCareers">
                                <div>استكشاف المزيد</div>
                                <Icon name="iconArrowLeft" />
                            </View>
                            <Header3 className="writersHeader" title="شواغر فريق التصميم"/>
                            <div className="smallLine"></div>
                            <View style={{borderBottom: "1px solid var(--grayColorMain)"}}>
                                <View className="content">
                                    <View className="contentBlock">
                                        <SpacerVertical height={"1.8em"}/>
                                        <View className="contentBlockHeader">ةيرادا ةدناسم لوؤسم</View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <View className="contentBlockText">زيكرتلا نع ئراقلا يهليس ام ةحفصل ءورقملا ىوتحملا نأ يهو ليوط نمز ذنم ةتبثم ةقيقح 
                                                                            .اهأرقي يتلا ةحفصلا يف تارقفلا عضوت لكش وأ صنلل يجراخلا لكشلا ىلع
                                        </View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <BtnUltraSmall className="contentBlockBtn" title="ليصافت"/>
                                    </View>
                                </View>
                                <SpacerVertical height={"2.3em"}/>
                                <View className="content">
                                    <View className="contentBlock">
                                        <SpacerVertical height={"1.8em"}/>
                                        <View className="contentBlockHeader">يعامتجالا لصاوتلا لوؤسم</View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <View className="contentBlockText">زيكرتلا نع ئراقلا يهليس ام ةحفصل ءورقملا ىوتحملا نأ يهو ليوط نمز ذنم ةتبثم ةقيقح 
                                                                            .اهأرقي يتلا ةحفصلا يف تارقفلا عضوت لكش وأ صنلل يجراخلا لكشلا ىلع
                                        </View>
                                        <SpacerVertical height={"0.6em"}/>
                                        <BtnUltraSmall className="contentBlockBtn" title="ليصافت"/>
                                    </View>
                                </View>
                                <SpacerVertical height={"2.286em"}/>
                            </View>
                            <View className="moreCareers">
                                <div>استكشاف المزيد</div>
                                <Icon name="iconArrowLeft" />
                            </View>
                            </View>
                        </Col>
                    </Row>
                </Container>
                <SpacerVertical height={"3.3em"}/>
            </View>
            <AppFooter />
        </View>
    </View>
    )
}