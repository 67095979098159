import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Btn, BtnColorless } from '../../buttons/btn.component';
// import { Timer } from '../../timer/timer.component';
import { View } from '../../view/view.component';

import './before-publishing.scss';

interface BeforePublishingModalProps {
    initReview: () => void;
    hideBeforePublishing: () => void;
    endTime: string;
}

export const BeforePublishingModal:FunctionComponent<BeforePublishingModalProps> = (props) => {

    // const {initReview, hideBeforePublishing, endTime} = props;

    const {initReview, hideBeforePublishing} = props;

    const history = useHistory();

    const [isThx, setIsThx] = useState(false);

    const handleCLose = () => {
        if(!isThx) {
            hideBeforePublishing();
        } else {
            history.push("/user_profile_auth/writer")
        }
    }

    return (
        <View className="BeforePublishing">
            <View className="Header">
            سيتم إرسال المقال للمراجعة 
            </View>
            {isThx && <View className="Content">
                 {isThx ? <h1>شكرا لك</h1> : ''}
                 {isThx ? <View className="Text">
                 لقد تم إرسال المقال للمراجعة 
                 </View>
                 : <View className="Text"> </View>}
                 {/* {isThx && <View className="Time">
                      الوقت المقدّر للمراجعة:
                      <span>
                      <Timer endDate={endTime}/> ساعة
                      </span>
                     </View>} */}
            </View>}
            <View className="Buttons">
                {!isThx && <Btn className="buttonPublish" title="ارسل" onClick={() => {
                    setIsThx(true);
                    initReview();
                    }}/>}
                <BtnColorless className="buttonClose" title="إغلاق" onClick={handleCLose}/>
            </View>
        </View>
    )
}