import React, { FunctionComponent, useEffect, useState } from "react";
import { BtnColorless } from "../buttons/btn.component";
import { Icon } from "../icons/icon.component";
import { View } from "../view/view.component";

interface ChatModalWriterEditorProps {
    hideModal: () => void;
}

export const ChatModalWriterEditor: FunctionComponent<ChatModalWriterEditorProps> = (props : any) => {

    const [chatInfo, setChatInfo] = useState<any>();

    useEffect(() => {
        if(props.newProp?.highlight.length) {
            setChatInfo(props.newProp?.highlight[0]);
        }
    }, [props]);


    return(
        <View className="ChatModal" style={{backgroundColor: "white"}}>
            <View className="ChatModalHeader">
                <View className="Comments">
                    <Icon name="iconCommentLinesBlack"/>
                    تعليق  {chatInfo?.numberOfComment}  
                </View>
            </View>
            <View className="ChatModalSubHeader">
            {chatInfo?.text}
            </View>
            <View className="ChatModalTitle">
            تم تحريره إلى 
            </View>
            <View className="ChatModalFixed">
                {chatInfo?.newText || ""}
            </View>
            <View className="ChatModalButtons" style={{borderTop: "1px solid #E8E8E8"}}>
                <BtnColorless className="colorless" title="إغلاق" onClick={() => props.hideModal()}/>
            </View>
        </View>
    )
}