import { baseURL } from './../config';
import axios from "axios";
import { getCookie } from './cookie.service';

const TOKEN = getCookie('mawsuah_access_token');

const axiosServiceAuth = axios.create({
  baseURL: `${baseURL}/auth/`,
  headers: { 'Content-Type': 'application/json' },
  timeout: 0,
  maxRedirects: 0
});

const axiosServiceVersion1 = axios.create({
  baseURL: `${baseURL}/v1.0/`,
  headers: {'Content-Type': 'application/json'},
  timeout: 0,
  maxRedirects: 0
});

const axiosServiceVersion1Token = axios.create({
  baseURL: `${baseURL}/v1.0/`,
  headers: { 'Authorization': 'Bearer ' + TOKEN, 'Content-Type': 'application/json'},
  timeout: 0,
  maxRedirects: 0
});

const axiosServiceFileVersion1Token = axios.create({
  baseURL: `${baseURL}/v1.0/`,
  headers: { 'Authorization': 'Bearer ' + TOKEN, 'Content-Type': 'multipart/form-data'},
  timeout: 0,
  maxRedirects: 0
});

axiosServiceVersion1Token.interceptors.request.use(
  config => {
      const token = getCookie('mawsuah_access_token');
      if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
      }
      // config.headers['Content-Type'] = 'application/json';
      return config;
  },
  error => {
      Promise.reject(error)
  });

  axiosServiceFileVersion1Token.interceptors.request.use(
    config => {
        const token = getCookie('mawsuah_access_token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });

export {axiosServiceAuth, axiosServiceVersion1, axiosServiceVersion1Token, axiosServiceFileVersion1Token}