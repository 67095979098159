import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router';
import { apiService } from '../../shared/services/api.service';
import { Btn, BtnColorless } from '../buttons/btn.component';
import { InputRadioBox } from '../inputs/input.component';
import { View } from '../view/view.component';

import "./report-content.scss";

interface ReportContentModalProps {
    reviewId: number
}


export const ReportContentModal:FunctionComponent<ReportContentModalProps> = (props) => {

    const {reviewId} = props;

    const history = useHistory();

    const [id, setId] = useState("1");
    const [comment, setComment] = useState("");

    const handleReject = () => {
        if(id && comment) {
            apiService.articles.UpdateReviewStatus(reviewId, "REJECTED")
            .then(r => {
              apiService.articles.addRejectedReasonToReview(r?.data?.id, id, comment);
              history.push("/user_profile_auth/reviewer");
            });
        }
    }

    return (
        <View className="ReportContentModal">
           <View className="Header">
           رفض المقال 
            </View> 
            <View className="SubHeader">
            أسباب رفض المقال
            </View>
            <View className="Text">
                <span>
                الرجاء اختيار السبب الرئيسي لرفض المقال وإضافة شرح عن السبب في الصندوق الأسفل ليتم إعلام الكاتب بتفاصيل السبب.
                </span>
            </View>
            <View className="RadioButtons">
                <InputRadioBox name="radio1"  label="محتوى غير أصلي" id="1" handlecheck={(e) => setId(e.target.id)}/>
                <InputRadioBox name="radio1"  label="لا يوجد مراجع" id="2" handlecheck={(e) => setId(e.target.id)}/>
                {/* <InputRadioBox name="radio1"  label="محتوى غير أصلي" id="3" handlecheck={(e) => setId(e.target.id)}/> */}
                <InputRadioBox name="radio1"  label="حقائق غير صحيحة" id="4" handlecheck={(e) => setId(e.target.id)}/>
                <InputRadioBox name="radio1"  label="مقال ضعيف" id="5" handlecheck={(e) => setId(e.target.id)}/>
                <InputRadioBox name="radio1"  label="محتوى مسيء" id="6" handlecheck={(e) => setId(e.target.id)}/>
            </View>
            <View className="ReportContentModalInput">
                <textarea value={comment} onChange={(e) => setComment(e.target.value)}/>
            </View>
            <View className="Buttons">
                <Btn className="ButtonReject" title="ارسل التبليغ" onClick={handleReject}/>
                <BtnColorless title="إغلاق"/>
            </View>
        </View>
    )
}