import React from "react"
import { View } from "../view/view.component"
import loader from "../../assets/images/Loader/loader-img.gif"



export const Loader = () => {
    return <View style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src={loader} alt="Loading..."></img>
        </View>
}