import React, { FunctionComponent, useEffect, useState } from 'react';
import { Btn } from '../../../components/buttons/btn.component';
import { Icon } from '../../../components/icons/icon.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { View } from '../../../components/view/view.component';
import { InterestsData } from '../../../shared/interfaces';
import { apiService } from '../../../shared/services/api.service';
import { getUserId } from '../../../shared/utils';
import { useHistory } from 'react-router';

interface InterestsSearchItemProps {
    item : InterestsData;
    myInterests: InterestsData[];
}



export const InterestsSearchItem: FunctionComponent<InterestsSearchItemProps> = (props) => {

    const {item, myInterests} = props;

    const [isFollowed, setIsFollowed] = useState(false);

    useEffect(() => {
        myInterests.forEach((el) => {
            if(el.id === item.id) {
                setIsFollowed(true);
            }
        })
    }, [item, myInterests])

    const handleClick = () => {
        if(!isFollowed) {
            apiService.interests.subscribeOnInterest(getUserId(), item.id)
                .then(r => setIsFollowed(true))
        } else {
            apiService.interests.unSubscribeFromInterest(getUserId(), item.id)
                .then(r => setIsFollowed(false))
        }
    }


    const history = useHistory();
    
    return(
        <>
        <SpacerVertical height={"1.143em"}/>
        <View className="InterestsSearchItem">
            <View className="InterestsSearchItemTitle" onClick={(e) => { e.stopPropagation(); history.push(`/interests_tabs/${item.id}`); }}>{item.title}</View>
            <View className="InterestsSearchItemNumbers">
                <span >{item.articles_count}</span> مقال
                <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                <span>{item.authors_count}</span> كاتب
                <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                <span>{item.followers_count}</span> متابَع 
                <Btn className="WritersSearchBtn" 
                    title={isFollowed ? "متابَع" : "تابِع"} 
                    style={isFollowed ? {backgroundColor: "var(--blueColor)", color:"var(--white)", border: "0px"} : {}} 
                    onClick={handleClick}
                />
            </View>
        </View>
        {/* <SpacerVertical height={"1.143em"}/>
        <View className="InterestsSearchItemText">
        {item.description}
        </View> */}
        <SpacerVertical height={"2.071em"}/>
        </>
    )
}