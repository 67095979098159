import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Btn } from '../../../components/buttons/btn.component';
import { DropDown } from '../../../components/dropdown/dropdown.component';
import { Icon } from '../../../components/icons/icon.component';
import { InputRadioBox } from '../../../components/inputs/input.component';
import { InterestsModal } from '../../../components/interests-modal/interests-modal';
import { useModal } from '../../../components/modal/modal.component';
import { SpacerHorizontal, SpacerVertical } from '../../../components/spacer/spacer.component';
import { View } from '../../../components/view/view.component';
import { IFormValues, sub_interests } from '../../../shared/interfaces';
import  writer_reviewer_no  from '../../../assets/images/signup-login/writer-reviewer-no.jpg'; 
import  writer_reviewer_yes  from '../../../assets/images/signup-login/writer-reviewer-yes.jpg'; 
import { MoreExp } from './nested/writer-reviewer-more-exp.component';
import { useFetch } from '../../../shared/hooks/fetch.hook';
import { apiService } from '../../../shared/services/api.service';
import { setCookie } from '../../../shared/services/cookie.service';
import { getUserId } from '../../../shared/utils';

export const WriterReviewerSignupPage: FunctionComponent = () => { 

    const { showModal, RenderModal } = useModal();
    
    const { register, handleSubmit, errors } = useForm<IFormValues>();

    const {response, isLoading} = useFetch(() =>  apiService.interests.getAllInterests(0, 30, apiService.auth.isAuthenticated()));

    const [isChecked, setIsChecked] = useState(true);
    const [expectedArticleLength,setExpectedArticleLength] = useState('');
    const [hoursSpentPerDay,setHoursSpentPerDay] = useState('');
    const [bestTimeForWorking,setBestTimeForWorking] = useState('');
    const [lengthContainer, setLengthContainer] = useState(0);
    const [counter, setCounter] = useState([1]);
    const [ subSpecOpt, setSubSpecOpt] = useState<sub_interests>();
    const [extraStepData, setExtraStepData] = useState<any[]>([{
        id : 1,
        institution: "",
        years_of_experience : "",
        sub_specialisations : [
            {
                id : 1,
                title : "gdsgsdg"
            }
        ]
    }]);

    const ref = useRef<any>(null);

    useEffect(() => {
        if(!isLoading) {
            const arr = response?.data.interests.map((item : any) => {
                return item.sub_interests
            });
            let items : any[] = []
            arr?.forEach((item : any) => {
                items = items.concat(item);
            });
           setSubSpecOpt(!!items && items as sub_interests)
        }
    }, [response, isLoading]);
    

    useEffect(() => {
        setLengthContainer(ref?.current?.offsetHeight);
    }, [ref?.current?.offsetHeight, isChecked, counter, errors]);

    const addForm = () => {
        let a = counter[counter.length-1] + 1;
        if(a !== 5) {
            setCounter([...counter, a]);
            const data = {
                id : a,
                institution: "",
                years_of_experience : "",
                sub_specialisations : [
                    {
                        id : 1,
                        title : "gdsgsdg"
                    }
                ]
            }
            setExtraStepData([...extraStepData, data]);
        }
    }

    const handleData = (data: any) => {
        const arr = extraStepData.map((item) => {
                if(item.id === data.id) {
                    return data
                }
            return item
        });
        setExtraStepData(arr);
    }

    const onSubmit = () => {
        const arr = extraStepData.map((item) => item);
        arr.forEach((item) => {
            delete item["id"]
        })

        let finalData

        if(isChecked) {
            finalData = {
                has_experience : isChecked,
                expected_article_length : expectedArticleLength,
                hours_spent_per_day : hoursSpentPerDay,
                best_time_for_working : bestTimeForWorking,
                extra_experiences : arr
            }
        } else {
            finalData = {
                has_experience : isChecked,
                expected_article_length : expectedArticleLength,
                hours_spent_per_day : hoursSpentPerDay,
                best_time_for_working : bestTimeForWorking
            }
        }
            apiService.auth.signupWriterExtraStep(getUserId(), finalData)
            .then(r => {
                setCookie('mawsuah_access_token',r.data.access_token, 1);
                setCookie('mawsuah_refresh_token',r.data.refresh_token, 7);
                showModal();
            })
    };

    return (
        <View className="App">
            <View className="writersSignup">
                <View className="writersSignupContent">
                    <View className="writersSignupContentLogos">
                        <View className="Logo">
                            <Icon name="iconLogoBig1" alt="logo_picture"/>
                        </View>
                        <Icon name="iconGroupBlack" alt="logo_picture"/>
                    </View>
                    <View className="writersSignupContentText">
                        <View className="subText"><span>معاً نبني أكبر مجتمع معرفيّ موثوق عربياً</span></View>
                        <SpacerVertical height={"2.222em"}/>
                        <View className="textTitle"><span>إضافة دور مراجع</span></View>
                        <SpacerVertical height={"2.357em"}/>
                        <View className="textTitleSub" >
                            <div className="StepActive">1</div> 
                            <span>خبرات عملية
                                </span>
                        </View>
                    </View>
                    <SpacerVertical height={"1.857em"}/> 
                    <View className="writersSignupContentInputs">
                    <form onSubmit={handleSubmit(onSubmit)}>  
                            <div className="form1" ref={ref}>
                            <div className="line" style={{height: lengthContainer, top: "-0.75em"}}></div>
                            <View className="text"><span>هل لديك خبرة في مراجعة المقالات؟</span></View>
                            <SpacerVertical height={"1.286em"}/> 
                            <View className="Flex">
                                <InputRadioBox name="radio1"  label="نعم"  handlecheck={() => {setIsChecked(true)}} style={{marginLeft: "3.5em"}} checked={isChecked}/>
                                <InputRadioBox name="radio1"  label="لا"  handlecheck={() => {setIsChecked(false)}}/>
                        </View>
                            {isChecked && 
                                <View className="signupWriterEditorContentInputs">
                                    <SpacerVertical height={"2em"}/>
                                    {counter.map((item) => {
                                        return(
                                        <View key={item}>
                                            <MoreExp register={register} errors={errors} handleData={handleData} id={counter[counter.length-1]} subSpecOpt={subSpecOpt}/>
                                        </View>)
                                    })}
                                    <Icon className="iconPlus" name="iconPlus" onClick={addForm}/>
                                </View>}
                                {!isChecked && 
                                <View className="noCheckedText">
                                    <SpacerVertical height={"2.286em"}/>
                                    <span>سيتم حفظ طلبك لإضافة دور مراجع، وسيقوم الفريق بالتواصل معك بعد
النظر في طلبك.  </span>
                                <SpacerVertical height={"2.143em"}/>
                                </View>}
                            <SpacerVertical height={"1.571em"}/>
                            </div>
                        <View className="nextStepText" style={{color: "var(--black)"}}>
                            <div className="StepActive">2</div> 
                            <span>تفضيلات العمل
                                </span>
                        </View>
                        <SpacerVertical height={"1.714em"}>
                        </SpacerVertical>
                            <DropDown 
                                value={expectedArticleLength} 
                                options={["150-500 كلمة",
                                "500- 1600 كلمة",
                                "1600 - 3000 كلمة"]} 
                                label={"ما هو طول المقالات المفضل لديك لعملية المراجعة؟"} 
                                onChange={setExpectedArticleLength} 
                                placeholder="الرجاء تحديد طول المقال المناسب" 
                                name="expected_article_length" 
                                register={register} 
                                errors={errors} 
                                errorMessage="الرجاء تحديد طول المقال المناسب" 
                                required/>
                            <SpacerVertical height={"2.500em"}/>
                            <DropDown 
                                value={hoursSpentPerDay} 
                                options={["أقل من ساعة",
                                "من 1 إلى 3 ساعات",
                                "أكثر من 3 ساعات"]} 
                                isLastWordBold 
                                label="كم ساعة يمكنك منح عملية مراجعة المقالات يومًّيا ؟" 
                                onChange={setHoursSpentPerDay} 
                                placeholder="الرجاء تحديد عدد الساعات" 
                                name="hours_spent_per_day" 
                                register={register} 
                                errors={errors} 
                                errorMessage="الرجاء تحديد عدد الساعات" 
                                required/>
                            <SpacerVertical height={"2.500em"}/>
                            <DropDown 
                                value={bestTimeForWorking} 
                                options={["8 صباحاً- 6 مساءً",
                                "6 مساءً- 12 مساءً",
                                "12 صباحاً- 8 صباحاً"]}
                                 label="ما هو التوقيت المفّضل للقيام بالمراجعة؟" 
                                 onChange={setBestTimeForWorking} 
                                 placeholder="الرجاء تحديد التوقيت المناسب" 
                                 name="best_time_for_working" 
                                 register={register} 
                                 errors={errors} 
                                 errorMessage="الرجاء تحديد التوقيت المناسب" 
                                 required/>
                            <SpacerVertical height={"2em"}/>
                            <Btn className="writers-signupBtn" title="انتهاء"/>
                            <SpacerVertical height={"21.8em"}/>
                            </form>
                    </View>
                </View>
                <View title="signup_picture"  className="writersSignupPicture" style={isChecked ? {backgroundImage: `url(${writer_reviewer_no})`, objectFit: "cover"} : {backgroundImage: `url(${writer_reviewer_yes})`, objectFit: "cover"}}>
                        <View className="writersSignupPictureContent">
                            <View className="icon">
                                <Icon name="iconLogoBigLogin" alt="logo_picture"/>
                            </View>
                            <SpacerHorizontal width={"1.8em"}/>
                            <View className="text">
                            <span>"لتكن موسوعة طريقك إلى نشر مقالاتك العلمية وجسر يصلك بالقرّاء! قرّاء كثر ينتظرون أن يقرؤوا ما تكتب
"</span>						
                            </View>
                        </View>
                </View>
            </View>
            <RenderModal>
                <InterestsModal/>
            </RenderModal>
        </View>
    )
}