import React, { FunctionComponent } from 'react';
import { Btn, BtnColorless } from '../../../../../components/buttons/btn.component';
import { Icon } from '../../../../../components/icons/icon.component';
import { SpacerVertical } from '../../../../../components/spacer/spacer.component';
import { View } from '../../../../../components/view/view.component';

interface UserProfileWriterNotificationsItemProps {
    Published?: boolean;
    Edit?: boolean;
    RemainingTime?: boolean;
    title: string;
    text: string;
    Noticed?: boolean;
    btnTitle?: string;
}

export const UserProfileWriterNotificationsItem: FunctionComponent<UserProfileWriterNotificationsItemProps> = (props) => {

    // const {Published, Edit, RemainingTime, title, text, Noticed, btnTitle} = props;
    const {Published, Edit, title, text, Noticed, btnTitle} = props;

    return(
        <>
        
        <SpacerVertical height={"2.286em"}/>
        <View className="UserProfileNotificationsItem">
                <View className="Flex">
                    <View className="UserProfileNotificationsItemType">
                        <Icon name={Noticed ? "iconLogo":"iconLogoGray"}/>
                    </View>
                    <View className="UserProfileNotificationsItemContent">
                        <span className="Name">{title} </span>
                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                        20 تموز 
                        <SpacerVertical height={"0.571em"}/>
                        <View className="Text">
                        {text}
                        </View>
                        {!Published && 
                        <>
                            <SpacerVertical height={"0.571em"}/>
                            <View className="Flex">
                                {Edit && <BtnColorless title={btnTitle}/>}
                                {/* {RemainingTime && <View className="RemainingTime">
                                الوقت المقدر للمراجعة: <span style={{color: "var(--alert)"}}> ٤٢ ساعة وخمسون دقيقة </span>
                                </View>} */}
                            </View>
                        </>
                        }
                        {Published &&
                        <>
                            <SpacerVertical height={"1.429em"}/>
                            <Btn title={btnTitle}/>
                        </>
                        }
                        
                    </View>
                </View>
                <View className="iconNotificationsPicture">
                    <Icon  name="iconNotificationsPicture" alt="notifications_picture"/>
                </View>
        </View>
        </>
    )
}