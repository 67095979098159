import React, { FunctionComponent } from "react"
import { Btn } from "../../../../components/buttons/btn.component"
import { InputCheckBox} from "../../../../components/inputs/input.component";
import { SpacerVertical } from "../../../../components/spacer/spacer.component";
import { View } from "../../../../components/view/view.component"

import "./account-settings-form.scss";


export const ContentPreferencesSettingsForm: FunctionComponent = () => {


    return (
        <>
        <View className="AccountSettingsForm">
            <View className="ContentPreferences">
            إعدادات البحث
            <SpacerVertical height={"1.714em"}/>
            <InputCheckBox name="" label="إخفاء المحتوى الحساس" />
            <SpacerVertical height={"1.714em"}/>
            <InputCheckBox name="" label="حذف الحسابات المحظورة والمكتومة"/>
            </View>
            <View className="ContentPreferences" style={{border: "none"}}>
            <SpacerVertical height={"1.714em"}/>
            إعدادات استكشف
            <SpacerVertical height={"1.714em"}/>
            <InputCheckBox name="" label="إظهار المحتوى في هذا الموقع الجغرافي"/>
            <SpacerVertical height={"1.714em"}/>
            <InputCheckBox name="" label="لأكثر تداولًا"/>
            </View>
        </View>
        <View className="AccountSettingsButton">
            <Btn title="حفظ التعديلات"/>
        </View>
        </>
    )
}