import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
import { Icon } from '../../../components/icons/icon.component';
import { BtnColorless } from '../../../components/buttons/btn.component';
import termsBG from '../../../assets/images/StaticPages/TermsPage/termsBG.png';

export const TermsPage: FunctionComponent = () => { 

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
        <AppHeader isWhite={true}/>
        <View className="CareersPage">
            <View className="CareersPageHeader" title="careers_photo" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(${termsBG})`}}>
                <Container style={{marginTop: "96px"}}>
                    <View className="CareersPageHeaderContent">
                        <Header1 title="دليلك بين يديك" className="header1"/>
                    </View>
                    <View className="CareersPageHeaderContent">
                        <Icon name="iconMawsuahLogo"/>
                        <span className="bigText">موسوعة</span>
                        <Icon name="iconVerifiedSmallWhiteBG"/>
                    </View>
                </Container>
            </View>
            <SpacerVertical height={"4.5em"}/>
            <View className="CareersPageContent">
                <Container>
                    <Row>
                        <Col lg={3} className="CareersPageContentSecondColumn">
                            {/* <View className="CareersPageContentSecondColumnMenu" style={{borderBottom: "0px"}}>
                                <View className="Item">سياسية الخصوصية</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View>
                            <View className="CareersPageContentSecondColumnDisabled">
                                <View className="Item">المساعدة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المجتمعات</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">معتمدون لدى </View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الشركاء</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المدونة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الوظائف</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">شروط الاستحدام</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">التواصل</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View> */}
                        </Col>
                        <Col className="CareersPageContentFirstColumn" lg={9}>
                            <Header3 className="writersHeader" title="شروط الاستخدام"/>
                            <div className="smallLine"></div>
                            <View className="CareersPageContentFirstColumnContent">
                            <View className="smallHeader">
                            <p>شروط وأحكام استخدام "الملف الشخصي للأعمال" في موقع "موسوعة" www.mawsuah.com </p>
                            <p>أهلاً بك في "الملف الشخصي للأعمال" في موقع "موسوعة" www.mawsuah.com يتم تشغيل وإدارة هذا الموقع من قِبل الشركة المالكة له (شركة التشعبية الرقمية للأفكار التقنية ذ.م.م " موسوعة"و/أو الشركات التابعة له و/أو الشركات المملوكة له). </p>                            
                            </View>
                            <View className="CareersAuthor">
                                <View className="CareersAuthorPhoto">
                                    <div style={{cursor: "pointer"}}>
                                      <Icon name="iconProfilePlaceholder" alt="author_profile_photo" className="AuthorDataImage"/>
                                      <span className="AuthorPhotoSpan">موسوعة</span>
                                      <Icon style={{marginRight: "0.179em"}} name="iconVerifiedSmall"/>
                                    </div>
                                     <BtnColorless title="تابِع"/>
                                </View> 
                                <View className="CareersAuthorText">
                                    <span className="spanBold">تكنولوجيا</span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>20 تموز </span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>4 دقائق قراءة</span>
                                </View>
                            </View>
                            <SpacerVertical height={"2.3em"}/>
                            <View className="PrivacyContent">
                                <p>
                                    <b>يرجى قراءة هذه الشروط والأحكام بعناية لأنها تحتوي على معلومات مهمة تتضمن حقوقك والتزاماتك القانونية، من خلال الوصول إلى أو استخدام "الموقع" و/أو " منصة موسوعة"، فإنك توافق على الامتثال للشروط والسياسات والأحكام هذه والالتزام بها.  </b>
                                </p>
                                <p>
                                تشكل هذه الشروط اتفاقية ملزمة قانوناً "اتفاقية" بينك وبين "موسوعة" كما هو موضح أدناه، تحكم وصولك إلى منصة “Mawsuah” واستخدامها، بما في ذلك أي نطاقات فرعية لها، وأي موقع ويب آخر يمكن من خلاله أو يتيحها موقع Mawsuah يشار إليها إجمالاً بــ"الموقع" والموقع الإلكتروني. 
                                </p>
                                <p>
                                    <b>
                                    يعتبر استخدامك لهذا الموقع والخدمات المقدمة فيه موافقة صريحة منك على الالتزام بأحكام وشروط الاستخدام وجميع القوانين والأنظمة المعمول بها، وتوافق على أن تكون مسؤولاً عن الامتثال للقوانين المحلية والدولية المعمول بها. نحن نحتفظ بحق تحديث و/أو تغيير أحكام وشروط الاستخدام في أي وقت كان من دون إنذار مسبق بحيث تكون هذه التعديلات نافذة المفعول من تاريخ نشرها. لذلك، ندعوك لمراجعة شروط وأحكام الاستخدام كلما زرت الموقع.

                                    </b>
                                </p>
                                <p><b><u>
                                تنويه: في حال كنت لا توافق على هذه الأحكام، يرجى منك عدم استخدام الموقع.
                                    </u></b></p>
                                    <p>
                                    كما نحتفظ بحقنا بتحديث و/أو تعديل الموقع و/أو الخدمات و/أو إيقافها وبأي وقت. أنت تقر بأننا غير مسؤولين بمواجهتك أو بمواجهة أي طرف ثالث عن أي تحديث و/أو تعديل على الموقع والخدمات أو أي إيقاف لها.

                                    </p>
                                    <p>لا يجوز استعمال هذا الموقع أو الخدمات المقدمة فيه إذا كان عمرك أقل من (18) سنة. 
</p>
<p>
أنت تقر بموجب هذا وتوافق على أن عمرك لا يقل عن السن المحدد بهذا البند. في حالة تسجيل حساب شخصي لشخص معنوي (شركة أو مؤسسة أو جمعية أو غيرها) أنت تقر بأنك مفوّض قانوني من قِبل ذلك الشخص المعنوي وتملك كامل الصلاحية لإنشاء الحساب واستخدام الموقع وتحميل ونشر المحتوى باسمه وبالنيابة عنه.

</p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                مقدمة
                                </h4>
                                <h4>
                                1.	إنشاء الحساب الشخصي:
                                </h4>
                                <ul>
                                    <li>يستلزم التسجيل وإنشاء حساب شخصي لاستعمال الموقع والدخول إليه واستعمال الخدمات. عبر التسجيل، أنت توافق على تزويد معلومات صحيحة ودقيقة وحديثة وكاملة عن نفسك أو الشخص المعنوي.</li>
                                    <li>أنت توافق على استعمال اسمك أو اسم الشخص المعنوي الحقيقي.</li>
                                    <li>أنت توافق على تزويد "سيرة شخصية Biography" صحيحة ودقيقة وحديثة عنك أو عن الشخص المعنوي.</li>
                                    <li>لا يجوز لك استخدام عنوان بريد إلكتروني خاطئ أو معلومات تعريف أخرى غير صحيحة ولا يجوز لك انتحال أي شخصية أو كيان أو غير ذلك.</li>
                                    <li>أنت مسؤول عن أي معلومات تقدمها متعلقة بحسابك الشخصي وأي تحديث قد يطرأ عليها. يجب أن تحافظ على أمن حسابك الشخصي وأن تشعرنا فوراً بأي دخول غير مصرّح للحساب. نوصي بأن يتم استعمال كلمة سر قوية تستعمل فقط للموقع.</li>
                                    <li>لا يحق لك بيع أو تحويل أو ترخيص استعمال حسابك الشخصي لأي طرف ثالث دون الحصول على موافقة الموقع الخطية المسبقة.</li>
                                </ul>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                2.	كيفية استعمال الموقع والمحتوى:
                                </h4>
                                <ul>
                                    <li>يمنحك الموقع رخصة غير قابلة للتحويل وغير حصرية ومحدودة لاستعمال الموقع والخدمات والدخول للموقع فقط بالحد المسموح به بموجب هذه الأحكام. نحتفظ بكامل الحقوق الأخرى التي لم تمنح لك بشكل صريح بموجب هذه الأحكام.</li>
                                    <li>أنت توافق على أن يكون المحتوى المنشور أو المحمّل من قِبلك على الموقع أصلي ولم يتم تحميله و/أو نشره على أي موقع إلكتروني آخر و/أو منصات و/أو صفحات إلكترونية أخرى من قبل.</li>
                                    <li>أنت توافق على أن يكون المحتوى المنشور أو المحمّل من قِبلك على الموقع صحيح ودقيق وحديث ومن النوع الاكتشافي "Discovery" وأن يعكس المعرفة والخبرة في قطاع الصناعة المتعلق بالأعمال "Businesses" الخاصة بك أو بالشخص المعنوي.</li>
                                    <li>يمكن أن يكون المحتوى المنشور أو المحمّل من قِبلك على الموقع متعلق بالأعمال "Businesses" الخاصة بك أو بالشخص المعنوي سواء بشكل مباشر أو غير مباشر إلا أنه لا يجوز أن يعرض المحتوى عروض وخدمات أعمالك أو أعمال الشخص المعنوي (Business Offerings) بشكل مباشر.</li>
                                    <li>يوصى بأن يسد المحتوى الفجوة بين عروض وخدمات أعمالك أو أعمال الشخص المعنوي (Business Offerings) وبين احتياجات المستخدم (User Needs).</li>
                                    <li>يجب بأن تتكون كل مادة كتابية (Written Content) منشورة أو محمّلة من قِبلك من 250 كلمة على الأقل.</li>
                                    <li>يوصى بأن يتم نشر المحتوى باستمرار (يوميًا أو أسبوعيًا أو كل أسبوعين).</li>
                                    <li>يوصى بأن يتم نشر من 2 - 4 مقالات على الأقل شهرياً.</li>
                                    <li>يجب أن يكون المحتوى المنشور أو المحمل خالي من الأخطاء الإملائية والتحريرية والأخطاء المعلوماتية. ولذلك ننصحك بمراجعة كل محتوى على الأقل لمرة واحدة قبل نشره أو تحميله.</li>
                                    <li> يجب أن يكون المحتوى أصليًا ولا يجوز نسخه من أي مصدر آخر إلا أنه إذا كانت المصادر الأخرى تستخدم لتحسين المعلومات، فيجب عليك الإشارة إلى مصدر الموقع الذي يجب أن يكون موثوق به.</li>
                                    <li>يجب ألا تحتوي المقالات والمواد على أي محتوى سياسي أو عنصري سواء بشكل مباشر أو غير مباشر، وفي حال احتواء المقالات والمواد على أي من هذين النوعين من المحتوى و/أو أي محتوى يسيء للموقع فإن الموقع غير مسؤولاً قانونياً عنه، ولا يتحمل أي تبعات قانونية عن المؤلف، كما أنه ليس مسؤولاً عن الأضرار و/أو الخسائر التي قد تلحق بالغير و/أو أي تعويض، وأن المؤلف مسؤولاً باسمه الشخصي وليس تبعاً لإسم الموقع ويتحمل كافة التبعات القانونية التي تنشأ نتيجة نشره بأي محتوى على الموقع.</li>
                                    <li>أنت توافق على عدم استعمال الموقع أو الخدمات لغايات نشر أي تعليق أو محتوى سياسي أو غير قانوني أو يمس بالدولة أو الأخلاق أو الآداب أو الأمن العام أو مؤذي أو مهدد أو استغلالي أو تحرشي أو تعذيبي أو افترائي أو مبتذل أو فاحش أو تشهيري أو بغيض أو يقلل أو يحط من الآخرين من حيث الجنس أو العرق أو المستوى أو الأصول أو الديانة أو العجز أو من أي ناحية أخرى أو الاعتراض على ذلك بأي طريقة علماً بأن الموقع يخلي مسؤوليته بحالة نشر أي تعليق و/أو نشر أي من تلك المحتويات المذكورة أعلاه قانونياً، ولا يتحمل أي نتائج و/أو تبعات قانونية عن المؤلف، كما أن الموقع ليس مسؤولاً عن أية أضرار أو خسائر قد تلحق بالغير أو أي تعويض للغير وأن المؤلف مسؤولاً بإسمه الشخصي وليس تبعاً لإسم الموقع.</li>
                                    <li>أنت توافق على عدم استعمال الموقع أو الخدمات لغايات وضع أي محتوى أو مواد غير مرغوب فيها أو غير مسموحة سواء دعائية أو مواد إعلانية أو بريد غير مرغوب به أو بريد مزعج أو رسائل متسلسلة أو أي مواد أخرى غير مرغوب فيها.</li>
                                    <li> أنت توافق على عدم استعمال الموقع أو الخدمات لغايات نشر أي محتوى يخترق حقوق الملكية الفكرية أو العلامات التجارية أو الأسرار التجارية لأي طرف ثالث أو مشاركة معلومات خاصة بأطراف ثالثة (سواء كانت محمية أو لا) واختراق سريتها.</li>
                                    <li>   أنت توافق على عدم استعمال الموقع أو الخدمات لغايات وضع أي محتوى يخرق حقوق الآخرين بالخصوصية، بما في ذلك على سبيل المثال وليس الحصر إفصاح معلومات خاصة بالآخرين دون موافقتهم.</li>
                                    <li>أنت توافق على عدم نشر أو تحميل أي محتوى يحتوي فيروسات أو أي مواد أو كودات أو برامج تخريبية أو مدمرة أو تكنولوجيا مشابهة أو وضع أي محتوى يتضمن برمجيات تحتوي فيروسات أو كودات كومبيوتر أخرى أو ملفات أو برامج أو تكنولوجيا مشابهة صممت لقطع، تدمير أو الحد من عمل الموقع أو برمجيات الكمبيوتر أو معداته أو معدات الاتصال.</li>
                                    <li> أنت توافق على عدم التلاعب بأدوات التعريف لغايات إخفاء أو تضليل مصدر المحتوى المنشور أو المحمل على الموقع أو مصدر أي تعليق صادر من قِبلك على الموقع.</li>
                                    <li>أنت توافق على عدم نشر أي مواد من مواد الموقع في أي وسيلة إعلامية أخرى وبيعها و/أو ترخيصها من الباطن و/أو تسويق أي مواد من مواد الموقع؛ وأداء أو عرض أي مواد على موقع علني، واستخدام الموقع بأي طريقة تضر الموقع أو تؤثر وصول المستخدم إلى هذا الموقع، والمشاركة في أي استخراج للبيانات أو جمع البيانات و/أو أي نشاط آخر مماثل فيما يتعلق بهذا الموقع، أو استخدام هذا الموقع للمشاركة في أي إعلان أو تسويق.</li>                                   
                                </ul>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                3.	  الإقرار بالمسؤولية عن المحتوى:
                                </h4>
                                <p>
                                بمجرد الموافقة على الدخول والتسجيل بالموقع فإنني أقر بمسؤوليتي القانونية الكاملة والمنفردة عن دقة أي مواد و/أو مقالات و/أو معلومات و/أو بيانات و/أو صور و/أو فيديوهات و/أو تسجيلات صوتية تقوم بتحميلها و/أو نشرها على الموقع كما أنني أقر بأن تلك المقالات و/أو المواد و/أو المعلومات و/أو البيانات و/أو الصور و/أو الفيديوهات و/أو التسجيلات الصوتية أصلية ولا تخل أو تنتهك حقوق ملكية أطراف ثالثة وكذلك أنني أقر بعدم مسؤولية الموقع عما أقوم بتحميله أو نشره أو أن المقالات و/أو المواد و/أو المعلومات و/أو البيانات و/أو الصور و/أو الفيديوهات و/أو التسجيلات الصوتية منقولة أو منسوخة عن أي شخص ثالث كما أنني أتحمل كامل المسؤولية اتجاهنا واتجاه أي أطراف ثالثة نتيجة عدم التزامك بهذا البند. 
                                </p>
                                <ul>
                                    <li>يمنحك الموقع رخصة غير قابلة للتحويل وغير حصرية ومحدودة لاستعمال الموقع والخدمات والدخول للموقع فقط بالحد المسموح به بموجب هذه الأحكام. نحتفظ بكامل الحقوق الأخرى التي لم تمنح لك بشكل صريح بموجب هذه الأحكام.</li>
                                    <li>أنت توافق على أن يكون المحتوى المنشور أو المحمّل من قِبلك على الموقع أصلي ولم يتم تحميله و/أو نشره على أي موقع إلكتروني آخر و/أو منصات و/أو صفحات إلكترونية أخرى من قبل.</li>
                                    <li>أنت توافق على أن يكون المحتوى المنشور أو المحمّل من قِبلك على الموقع صحيح ودقيق وحديث ومن النوع الاكتشافي "Discovery" وأن يعكس المعرفة والخبرة في قطاع الصناعة المتعلق بالأعمال "Businesses" الخاصة بك أو بالشخص المعنوي.</li>
                                    <li>يمكن أن يكون المحتوى المنشور أو المحمّل من قِبلك على الموقع متعلق بالأعمال "Businesses" الخاصة بك أو بالشخص المعنوي سواء بشكل مباشر أو غير مباشر إلا أنه لا يجوز أن يعرض المحتوى عروض وخدمات أعمالك أو أعمال الشخص المعنوي (Business Offerings) بشكل مباشر.</li>
                                    <li>يوصى بأن يسد المحتوى الفجوة بين عروض وخدمات أعمالك أو أعمال الشخص المعنوي (Business Offerings) وبين احتياجات المستخدم (User Needs).</li>
                                    <li>يجب بأن تتكون كل مادة كتابية (Written Content) منشورة أو محمّلة من قِبلك من 250 كلمة على الأقل.</li>
                                    <li>يوصى بأن يتم نشر المحتوى باستمرار (يوميًا أو أسبوعيًا أو كل أسبوعين).</li>
                                    <li>يوصى بأن يتم نشر من 2 - 4 مقالات على الأقل شهرياً.</li>
                                    <li>يجب أن يكون المحتوى المنشور أو المحمل خالي من الأخطاء الإملائية والتحريرية والأخطاء المعلوماتية. ولذلك ننصحك بمراجعة كل محتوى على الأقل لمرة واحدة قبل نشره أو تحميله.</li>
                                    <li> يجب أن يكون المحتوى أصليًا ولا يجوز نسخه من أي مصدر آخر إلا أنه إذا كانت المصادر الأخرى تستخدم لتحسين المعلومات، فيجب عليك الإشارة إلى مصدر الموقع الذي يجب أن يكون موثوق به.</li>
                                    <li>يجب ألا تحتوي المقالات والمواد على أي محتوى سياسي أو عنصري سواء بشكل مباشر أو غير مباشر، وفي حال احتواء المقالات والمواد على أي من هذين النوعين من المحتوى و/أو أي محتوى يسيء للموقع فإن الموقع غير مسؤولاً قانونياً عنه، ولا يتحمل أي تبعات قانونية عن المؤلف، كما أنه ليس مسؤولاً عن الأضرار و/أو الخسائر التي قد تلحق بالغير و/أو أي تعويض، وأن المؤلف مسؤولاً باسمه الشخصي وليس تبعاً لإسم الموقع ويتحمل كافة التبعات القانونية التي تنشأ نتيجة نشره بأي محتوى على الموقع.</li>
                                    <li>أنت توافق على عدم استعمال الموقع أو الخدمات لغايات نشر أي تعليق أو محتوى سياسي أو غير قانوني أو يمس بالدولة أو الأخلاق أو الآداب أو الأمن العام أو مؤذي أو مهدد أو استغلالي أو تحرشي أو تعذيبي أو افترائي أو مبتذل أو فاحش أو تشهيري أو بغيض أو يقلل أو يحط من الآخرين من حيث الجنس أو العرق أو المستوى أو الأصول أو الديانة أو العجز أو من أي ناحية أخرى أو الاعتراض على ذلك بأي طريقة علماً بأن الموقع يخلي مسؤوليته بحالة نشر أي تعليق و/أو نشر أي من تلك المحتويات المذكورة أعلاه قانونياً، ولا يتحمل أي نتائج و/أو تبعات قانونية عن المؤلف، كما أن الموقع ليس مسؤولاً عن أية أضرار أو خسائر قد تلحق بالغير أو أي تعويض للغير وأن المؤلف مسؤولاً بإسمه الشخصي وليس تبعاً لإسم الموقع.</li>
                                    <li>أنت توافق على عدم استعمال الموقع أو الخدمات لغايات وضع أي محتوى أو مواد غير مرغوب فيها أو غير مسموحة سواء دعائية أو مواد إعلانية أو بريد غير مرغوب به أو بريد مزعج أو رسائل متسلسلة أو أي مواد أخرى غير مرغوب فيها.</li>
                                    <li> أنت توافق على عدم استعمال الموقع أو الخدمات لغايات نشر أي محتوى يخترق حقوق الملكية الفكرية أو العلامات التجارية أو الأسرار التجارية لأي طرف ثالث أو مشاركة معلومات خاصة بأطراف ثالثة (سواء كانت محمية أو لا) واختراق سريتها.</li>
                                    <li>   أنت توافق على عدم استعمال الموقع أو الخدمات لغايات وضع أي محتوى يخرق حقوق الآخرين بالخصوصية، بما في ذلك على سبيل المثال وليس الحصر إفصاح معلومات خاصة بالآخرين دون موافقتهم.</li>
                                    <li>أنت توافق على عدم نشر أو تحميل أي محتوى يحتوي فيروسات أو أي مواد أو كودات أو برامج تخريبية أو مدمرة أو تكنولوجيا مشابهة أو وضع أي محتوى يتضمن برمجيات تحتوي فيروسات أو كودات كومبيوتر أخرى أو ملفات أو برامج أو تكنولوجيا مشابهة صممت لقطع، تدمير أو الحد من عمل الموقع أو برمجيات الكمبيوتر أو معداته أو معدات الاتصال.</li>
                                    <li> أنت توافق على عدم التلاعب بأدوات التعريف لغايات إخفاء أو تضليل مصدر المحتوى المنشور أو المحمل على الموقع أو مصدر أي تعليق صادر من قِبلك على الموقع.</li>
                                    <li>أنت توافق على عدم نشر أي مواد من مواد الموقع في أي وسيلة إعلامية أخرى وبيعها و/أو ترخيصها من الباطن و/أو تسويق أي مواد من مواد الموقع؛ وأداء أو عرض أي مواد على موقع علني، واستخدام الموقع بأي طريقة تضر الموقع أو تؤثر وصول المستخدم إلى هذا الموقع، والمشاركة في أي استخراج للبيانات أو جمع البيانات و/أو أي نشاط آخر مماثل فيما يتعلق بهذا الموقع، أو استخدام هذا الموقع للمشاركة في أي إعلان أو تسويق.</li>
                                   
                                </ul>
                            </View>

                            <View className="PrivacyContent">
                                <h4>
                                4.	   استعمال المحتوى المقدم منك من قِبل الموقع والغير: 
                                </h4>
                                <ul>
                                    <li>أنت تمنح الموقع ترخيصاً محلياً ودولياً دائماً لا رجوع عنه وغير قابل للإنهاء وغير حصري لاستخدام وتخزين ونسخ وتوزيع وعرض بطريقة عامة وتغيير وترجمة وإنشاء أعمال مشتقة وإعادة ترخيص المحتوى المقدّم من قِبلك أو أي جزء منه في أي وسيلة إعلامية معروفة ومتوافرة في الوقت الحالي أو قد تطور مستقبلاً وإخفائه وإزالته من على الموقع.</li>
                                    <li>أنت توافق بأن نستعمل ونحيل كافة المعلومات المتعلقة باستخدام الموقع من قِبلك وكافة المعلومات التي تزودنا بها بأي طريقة تتلاءم مع سياستنا.</li>
                                    <li>أنت توافق بأن نستعمل كامل بيناتك والمعلومات المتعلقة بالإجراءات التي تتخذها على الموقع لأي سبب يراه الموقع مناسباً بما في ذلك تزويدها لأطراف ثالثة.</li>
                                    <li>كون أن الموقع لن يتمكن من السيطرة والرقابة على أفعال الغير من زائري ومستخدمي الموقع والأطراف الثالثة، أنت توافق على إعادة نشر و/أو نسخ و/أو التعليق و/أو مشاركة المحتوى المقدم منك من قِبل الغير على أي مواقع إلكترونية و/أو منصات و/أو وسائل تواصل اجتماعي أخرى.</li>
                                    <li> أنت تصرّح بموجب هذه الأحكام وتضمن وتتعهد أن أي محتوى تقدمه لا يتضمن أي عنصر على سبيل المثال لا الحصر (أي نصوص أو صور أو موسيقى أو فيديو) لا تتمتع بالحق الكامل عليه لترخصه بهذا الشكل.</li>
                                    <li>للموقع الأحقية في أن يتم تقييد مناطق معينة من هذا الموقع من الوصول إليها من قبلك وقد يقوم موسوعة بتقييد وصولك إلى أي مناطق من هذا الموقع، في أي وقت، وفقا لتقديرها، وأن أي معرف مستخدم وكلمة مرور قد تكون لديك لهذا الموقع هي سرية ويجب عليك الحفاظ على السرية كذلك.</li>
                                    <li>أنت توافق على منح المحتوى الخاص بك سواء كان صوت أو نص فيديو أو صورة أي مواد أخرى تختار عرضها لموقع موسوعة بأن يكون ترخيصاً غير حصري وغير قابل للإلغاء في جميع أنحاء العالم وقابل للترخيص من الباطن لاستخدامه وإعادة إنتاجه وتكييفه ونشره وترجمته وتوزيعه في أي وجميع الوسائط.</li>
                                    <li>أنت توافق أن يكون المحتوى الخاص بك ملكاً لك ويجب ألا ينتهك حقوق أي طرف ثالث. ويحتفظ موقع موسوعة بالحق في إزالة أي من المحتوى الخاص بك من هذا الموقع في أي وقت دون سابق إنذار.</li>                                 
                                </ul>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                5.	   إنهاء الدخول إلى الموقع والخدمات:
                                </h4>
                                <p>
                                للموقع بناء على رأيه المنفرد في أي وقت ودون إشعار مسبق حذف و/أو تعليق و/أو إخفاء المحتوى المنشور أو المحمل من قِبلك فورا و/أو إنهاء دخولك إلى الموقع واستعمالك له أو وقف أو إلغاء حسابك أو تعليق حسابك لأي سبب بما في ذلك على سبيل المثال لا الحصر إذا كان استعمالك للموقع بشكل يخترق شروط الاستخدام هذه أو المحتوى المقدم من قِبلك غير ملائم ولا يتوافق مع الشروط والبنود المنصوص عليها هنا أو كان استعمالك للموقع والخدمات والمحتوى المقدم من قِبلك لا يتوافق مع سياسات الموقع الداخلية. عند إنهاء الدخول أو وقف أو إلغاء حسابك، يكون للموقع الحق في منعك من الدخول مجدداً بالمستقبل كما يكون له الحق بحذف أو إخفاء أية معلومات متعلقة بك أو أي محتوى مقدم منك.

                                </p>
                            </View>

                            <View className="PrivacyContent">
                                <h4>
                                6.	      إخلاء مسؤولية الموقع:
                                </h4>
                                <ul>
                                    <li>إن الموقع والخدمات مقدمة لغايات مشاركة المعلومات فقط. لا يعتبر أي محتوى على الموقع بأي حال من الأحوال على أنه نصيحة مهنية سواء قانونية أو مالية أو طبية أو غير ذلك. لا يكون الموقع مسؤولاً عن أية عواقب تتعلق بشكل مباشر أو غير مباشر لأي تصرف تقوم به أو لا تقوم به بالاستناد إلى المحتوى المتوفر على الموقع أو أي مواد أخرى على الموقع.</li>
                                    <li>اعتمادك على دقة المحتوى وشموليته وفائدته هو من مسؤوليتك وحدك، لن يكون الموقع مسؤول بأي شكل عن المحتوى بما في ذلك أي خطأ به أو حذف أو خطأ بالتعبير ولن يكون مسؤول عن أي خسارة أو ضرر ناتج عن استعمالك للمحتوى واعتمادك عليه.</li>
                                    <li> الموقع والخدمات تحتوي على روابط لمصادر ومواقع إلكترونية أخرى وحيث إن الموقع لا يملك أي سيطرة على هذه المواقع والمصادر فإنك تقر وتوافق على أن الموقع غير مسؤول عن توافر هذه المواقع الخارجية والمصادر وأنه غير مسؤول عن أي محتوى، إعلانات، منتجات أو مواد أخرى متوافرة على مثل هذه المصادر والمواقع. أنت تقر بأن هذه المواقع الخارجية لديها شروط استخدام خاصة بها وأنها ستنظم علاقتك وحقوقك والتزامك بما يتعلق بهذه المصادر الخارجية وأن الموقع لا يملك أية سيطرة عليها.</li>
                                    <li>إن أي تعامل بينك وبين أطراف ثالثة من خلال الموقع بما في ذلك مشاركتك بحملات إعلانية أو شراء البضائع والخدمات وأي شروط وضمانات متعلقة بهذه الأعمال هي بينك وبين الطرف الثالث وحدكم. لا يقدم الموقع أي ضمانات فيما يتعلق بالبضائع والخدمات والمواقع الإلكترونية الأخرى التي تظهر بأي معلومات على الموقع ذات طبيعة إعلانية أو ترويجية كما توافق على أن الموقع لن يكون مسؤولاً عن أي ضرر أو خسارة من أي نوع تنتج عن هذه الأعمال أو عن تعاملك مع أي أطراف ثالثة عن طريق الموقع.</li>
                                    <li>إن استخدامك لهذا الموقع يكون على مسؤوليتك الخاصة. يتم تقديم هذا الموقع على أساس "كما هو" و"كما هو متوفر" ولا نُقدم أي ضمانات تجاه بقاء الموقع آمن أو محمي أو خالي من الأخطاء أو أنه سيعمل دون حالات تعطل أو تأخير أو حالات تشغيل غير مثالية. إلى الحد الذي يسمح به القانون، فإننا نخلي مسؤوليتنا أيضًا تجاه كل أنواع الضمانات، سواءً كانت صريحة أو ضمنية، بما في ذلك الضمانات الضمنية للرواج التجاري، والملاءمة لغرض معين، وعدم انتهاك الحقوق والملكية. لا يضمن الموقع أنه سيكون متوفر من دون أي انقطاع أو أخطاء أو أن الموقع آمن أو أن الموقع أو موفر خدمة الموقع سيكونان خاليين من الفيروسات أو أن المعلومات على الموقع صحيحة ودقيقة ومناسبة ومفيدة وحديثة وموثوق بها وكاملة. في حال قمت بتحميل أي محتوى من الموقع، تقوم بذلك حسب تقديرك الخاص وعلى مسؤوليتك. أنت وحدك مسؤول عن أي ضرر يصيب نظام جهاز الحاسوب الخاص بك أو عن أي فقدان للبيانات الناتج عن تحميل مثل هذا المحتوى وعن أي ضرر وخسائر من أي نوع ناتجة عن ذلك. لا يجوز لأي نصائح أو معلومات تحصل عليها من الموقع أن تشكل لديك ضمانة من أي نوع كانت وتتحمل وحدك أي أضرار وخسائر تقع نتيجة ذلك.</li>
                                    <li>أي مواد يتم الحصول عليها عن طريق استعمالك للموقع أو الخدمات يكون الحصول عليها بموجب قرارك لوحدك وعلى مسؤوليتك وستكون مسؤول وحدك عن أي ضرر يلحق بنظام جهاز الحاسوب الخاص بك أو أي فقدان للمعلومات ناتج عن تحميلك لأي من البرمجيات أو المواد الخاصة بأطراف ثالثة أو استعمالك لها وعن أي ضرر وخسائر من أي نوع ناتجة عن ذلك.</li>                                  
                                </ul>
                                <p>إن استخدام موقع التطبيق على مسؤوليتك الخاصة وعليه يخلي موقع موسوعة مسؤوليته من أي نوع كانت ناشئة عن العقد أو المسؤولية التقصيرية (بما في ذلك الإهمال) أو غير ذلك فيما يتعلق بموقع التطبيق.
</p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                7.	    تحديد مسؤولية الموقع:
                                </h4>
                                <p>
                                أنت توافق بأن الموقع لن يكون في أي حال من الأحوال هو والشركة المالكة له وشركاته التابعة وشركاته الفرعية وموظفيه ومديريه ووكلائه وأي من ممثليه مسؤولين عن أي أضرار مباشرة أو غير مباشرة قد تلحق بك بما في ذلك على سبيل المثال لا الحصر الأضرار الناجمة عن فوات الربح وفقدان البيانات الناشئ عن استخدام أو عدم القدرة على استخدام الموقع أو المواد على الموقع بما في ذلك المحتوى الموجود عليه أو الدخول غير المصرّح على الموقع أو أي تغيير في نقل البيانات أو الاعتماد على أي محتوى موجود على الموقع أو القيام بوضع أي مواد على الموقع وأي أمور أخرى ذات علاقة بالموقع واستعماله. ستطبق هذه المحددات حتى لو تم إخطار الموقع عن احتمال وقوع هذه الأضرار.


                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                8.	     إخلاء طرف:
                                </h4>
                                <ul>
                                    <li>يُخلي المؤلف كل من الموقع والمساهمون المعنيون والمسؤولون والمدراء والموظفون والوكلاء والمرخصون مسؤوليتهم القانونية والأدبية عن المقال الذي يتضمن أي محتوى سياسي أو عنصري و/أو استعمال الموقع أو الخدمات لغايات نشر أي تعليق أو محتوى سياسي أو غير قانوني أو يمس بالدولة أو الأخلاق أو الآداب وما إلى ذلك، عن أي أضرار مباشرة أو غير مباشرة، خاصة أو تبعية، تأديبية أو عرضية، وأخلي مسؤولية الموقع عن التعويض المادي وغير المادي، وعن الأضرار التي قد تلحق بالمؤلف أو بالغير حتى وإن تم إخطار الموقع بإمكانية حدوث أي من هذه الأضرار أو كانوا قد توقعوا حدوثها فعلاً.</li>
                                    <li>يعتبر بند إخلاء طرف هو إثباتاً بإخلاء الطرف مسؤولية الموقع عن هذا المحتوى بشكل موثق.
</li>
                                    <li>على المؤلفات تعويض في حال تعرض الموقع للضرر أدبياً أو مادياً نتيجة مصنف المؤلف .
</li>
                                    <li>على المؤلف والغير تعويض الموقع من وضد أي و / أو جميع المسؤوليات والتكاليف والمطالب والدعاوى والأضرار والنفقات الناشئة بأي شكل من الأشكال المتعلقة بخرقك لأي من أحكام هذه الشروط.
</li>
                                </ul>
                                <p>إن استخدام موقع التطبيق على مسؤوليتك الخاصة وعليه يخلي موقع موسوعة مسؤوليته من أي نوع كانت ناشئة عن العقد أو المسؤولية التقصيرية (بما في ذلك الإهمال) أو غير ذلك فيما يتعلق بموقع التطبيق.
</p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                9.	      قيود استخدام حقوق ملكيتنا الفكرية:
                                </h4>
                                <p>إن موقع "موسوعة" www.mawsuah.com وغيرها من علامات تجارية مسجلة أو قيد التسجيل "موسوعة" هي مملوكة من قِبل شركة التشعبية الرقمية للأفكار التقنية ذ.م.م " موسوعة" و/أو الشركات التابعة له و/أو الشركات المملوكة له.
</p>
<p>
إذا كنت تستخدم عناصر محتوى محمية بموجب حقوق الملكية الفكرية التي نمتلكها ونوفرها عبر منتجاتنا (على سبيل المثال، الصور أو التصميمات أو مقاطع الفيديو أو المقاطع الصوتية التي نوفرها لك وتقوم أنت بإضافتها إلى محتوى تقوم بإنشائه ومشاركته على الموقع)، فإننا نحتفظ بجميع الحقوق اتجاه عناصر المحتوى هذه (ولكن لا نحتفظ بحقوق عناصر المحتوى الخاصة بك). ولا يمكنك إلا استخدام حقوق النشر أو حقوق العلامات التجارية (أو أية علامات مشابهة) الخاصة بنا بموجب إذن خطي مسبق من قِبلنا. ويجب عليك الحصول على إذن كتابيّ حتى تتمكن من تعديل رمز برمجي مصدر خاص بنا أو إنشاء أعمال مشتقة منه أو إلغاء تجميعه أو محاولة استخراجه بأي طريقة.

</p>
<p>كما ويمتلك موقع ("موسوعة") جميع حقوق الملكية الفكرية والمواد الواردة فيه ويتم منح ترخيصاً محدوداً فقط لأغراض عرض المواد الواردة في هذا الموقع.
</p>                            
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                10.	    حق المؤلف:
                                </h4>
                                <p>هناك حقوق أدبية للمؤلف لا يتم التنازل عنها و/أو التصرف فيها ولا تعد قابلة للتقادم، كأن ينسب إليه مصنفه ويذكر اسمه على كافة النسخ باستثناء ما يتم ذكره من هذه المصنفات أثناء تقديم إخباري للأحداث الجارية وله الحق في الموافقة على نشر المصنف وطريقة نشره وموعده والتعديل عليه بالتغيير أو التنقيح أو الحذف أو الإضافة وبدفع أي اعتداء ومنع أية تشويه و/أو تحريف و/أو تعديل و/أو أي مساس به من شأنه الإضرار بسمعته وشرفه، وفيما يخص أي حذف أو تغيير أو إضافة في ترجمة المصنف فليس للمؤلف الحق في المنع إلا أن تم المساس بسمعة المؤلف أو إخلال بمضمون المصنف، وإن وجدت أسباب مشروعة لسحب المصنف من التداول فعلى المؤلف تعويض ما آلت إليه حقوق الاستغلال المالي تعويضاً عادلاً.
</p>                     
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                11.	     حق النشر والمراجعة:
                                </h4>
                                <p>لموقع الحق في تنزيل المصنف أو الاطلاع أو الطباعة و/أو وضع روابط على موقعهم تؤشر على أماكن وجود المعلومات شرط أن يتم النشر بدون تعديل وبالشكل الأصلي له مع ضرورة الإشارة بشكل واضح إلى مصدر ومؤلف هذا المصنف.
</p>                     
<p>كما وتوافق على أن يقوم "المراجع المختص" بمراجعة محتواك وأن يقوم الموقع بواسطة مراجعيه أو موظفينه أو من يتم التعاقد معهم لغايات مراجعة المحتوى و/أو أي مقال يتم عرضه على الموقع من قبلك. 
 
 </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                12.	    التعويضات:
                                </h4>
                                <ul>
                                    <li>أنت توافق على الدفاع عن الموقع وتعويضه وحمايته من أي أذى وكل الإدعاءات والأضرار والخسائر والتكاليف والمصاريف بما في ذلك رسوم المحامين الناشئة عن أو المرتبطة باستخدام هذا الموقع و/أو مخالفتك لأي تصريح أو ضمانة أو نصوص والتزامات أخرى من أحكام وشروط الاستخدام.</li>
                                    <li>أنت تتعهد بتحمل المسؤولية الكاملة عن أي تعليق أو محتوى تقوم بوضعه على الموقع ومسؤولية لأي من الأضرار والخسائر التي قد تلحق بالموقع و/أو أي أطراف ثالثة وتكون ناتجة عن المحتوى المحمل أو المنشور من قِبلك وتعويضهم عنها.</li>
                                </ul>

                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                13.	   الكوكيز (cookies) وتحليلات غوغل (Google Analytics):

                                </h4>
                                <p>إن الموقع يستخدم الكوكيز الخاصة به وتقنيات مماثلة لحفظ بعض المعلومات وتعقب البيانات وعدد الزوار والمعلومات الخاصة بهم، بالإضافة إلى استخدام الكوكيز الخاصة بأطراف ثالثة كالمعلنين أو وكالات الإعلان الذين يضعون إعلانات على الموقع. كما يحتوي الموقع أيضًا على بعض المكونات المرسلة من Google Analytics، وهي خدمة تحليلية لمدى ازدحام الشبكة العنكبوتية مزودة من قِبَل شركة جوجل في هذه الحالة أيضاً، هي عبارة عن كوكيز الطرف الثالث يتم تجميعها وإدارتها بشكل مجهول الهوية من أجل مراقبة وتحسين أداء الموقع المضيف (الكوكيز الخاص بالأداء). يستخدم Google Analytics الـ "كوكيز" لتجميع وتحليل المعلومات حول طريقة استخدام الموقع. يتم تجميع تلك المعلومات من خلال Google Analytics، والتي تقوم بمعالجتها بهدف إرسال تقرير إلى الموقع.</p>                               
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                14.	     سياسة الخصوصية:

                                </h4>
                                <p>
                                لن يستخدم الموقع معلوماتك الشخصية إلا وفقاً لشروط سياسة الخصوصية وسياسة ملفات تعريف الارتباط الخاصة بنا. باستخدام موقع فإنك تقر وتوافق على أنك قد قرأت وقبلت شروط سياسة خصوصية الموقع وهذه الشروط.

                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                15.	    كامل الاتفاقية:
                                </h4>
                                <p>
                                تشكل أحكام وشروط استخدام الموقع هذه الاتفاقية الكاملة بينك وبين الموقع حول المواضيع المعالجة بموجبها وتحل هذه الاتفاقية محل كافة الاتفاقيات والخطابات والعروض والمناقشات والمستندات الأخرى السابقة المتعلقة بالأمور المذكورة في هذه الاتفاقية.


                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                16.	   التنازل:
                                </h4>
                                <p>
                                إن عدم قيام الموقع بالتصرّف في ما يتعلق بمخالفة أحكام وشروط الاستخدام هذه من قِبلك أو من قِبل آخرين لا يعتبر تنازلاً منه عن حقوقه للتصرّف في ما يتعلق بالمخالفات اللاحقة أو المماثلة. في حال إن كان أي محتوى لهذا الموقع أو أي استخدام لهذا الموقع مخالفاً للقوانين المعمول بها في المكان الذي تكون أنت فيه عندما تدخل إلى الموقع، يكون عندئذٍ هذا الموقع غير مصمم لك ونطلب منك عدم استخدام الموقع. أنت مسؤول عن الاطلاع على القوانين المطبقة على أحكام وشروط استخدام الموقع كما أنك مسؤول عن الالتزام به.



                                </p>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                17.	    حل النزاعات والقانون الواجب التطبيق:
                                </h4>
                                <p>في حال وجود أي خلاف أو ادعاءات أو نزاعات ناشئة عن أو مرتبطة بأي معاملة يتم تنفيذها على الموقع أو مخالفة أو تنفيذ أو تفسير أو صلاحية أحكام الاستخدام أو أي جزء منها ("النزاع")، يتعين على الطرف الذي يتلقى مثل هذا النزاع أن يحاول أولاً تسوية مثل هذا النزاع بحسن نية عبر تزويد الطرف الآخر بإشعار خطيّ (من خلال بريد الدرجة الأولى أو البريد المسجل) يصف فيه الوقائع والظروف (بما في ذلك أي مستندات ذات صلة)، المتعلقة بالنزاع ويعطي فيه للطرف الآخر مهلة (30) يوماً عليه أن يرد فيها على الأشعار، أو يعالج النزاع يجب إرسال الإشعار على العنوان التالي / ask@mawsuah.com مجمع الحسين للأعمال - عمارة رقم 7 – شارع الشعب – عمان - الأردن. في حال إن لم يتم تسوية النزاع خلال مدة 3 ثلاثة أشهر من تاريخ توجيه الإشعار للطرف الآخر تكون محاكم عمان (قصر العدل) هي المختصة بتسوية النزاع. يحكم نفاذ وتفسير وتنفيذ أحكام وشروط استخدام الموقع قوانين المملكة الأردنية الهاشمية.</p>                                
                            </View>
                            {/* <View className="PrivacyInterests">
                                <View>الذكاء الاصطناعي</View>
                                <View>شركات ناشئة</View>
                                <View>تكنولوجيا</View>
                                <View>ادارة اعمال</View>
                            </View> */}
                             {/*<View className="PrivacyLike">
                                    <Icon name="iconLikeBlue"/>
                                    <span>546,932</span>
                            </View>
                            <View className="PrivacyViewers">
                                <View>
                                    <Icon name="iconViewers"/>
                                    <span style={{marginRight: "8px"}}>897,538</span>
                                </View>
                                <View>
                                    <Icon name="iconBookmarkRounded" alt="bookmark_picture" />
                                    <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                                    <Icon name="iconShare"/>
                                    <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                                    <Icon name="iconMore"/>
                                </View>
                            </View>
                            <View className="PrivacySubAuthorData">
                                <View>
                                    <Icon name="iconProfilePlaceholder"/>
                                </View>
                                <View>
                                    <View className="PrivacySubAuthorDataInfo">
                                        <div style={{cursor: "pointer"}}>
                                        <span className="AuthorPhotoSpan">موسوعة</span>
                                        <Icon style={{marginRight: "0.179em"}} name="iconVerifiedSmall"/>
                                        </div>
                                        <BtnColorless title="تابِع"/>
                                    </View>
                                    <View>
                                        <span>323 مقال</span>
                                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                                        <span>116 ألف تابِع</span>
                                    </View>
                                </View>
                            </View>
                            */}
                            </View>
                        </Col>
                    </Row>
                </Container>
                <SpacerVertical height={"3.3em"}/>
            </View>
            <AppFooter />
        </View>
    </View>
    )
}