import { AxiosError } from 'axios';
import React, { FunctionComponent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from '../../../components/loader/loader.component';
import { View } from '../../../components/view/view.component';
import { apiService } from '../../../shared/services/api.service';
import { getCookie } from '../../../shared/services/cookie.service';
import { getUserId } from '../../../shared/utils';

export const TwitterCallbackPage: FunctionComponent = () => { 

    const location : any = useLocation();
    const history = useHistory();

    useEffect(() => {
        const test = new URLSearchParams(location.search).get('oauth_verifier');
        const denied = new URLSearchParams(location.search).get('denied');
        if(denied) {
            history.push('/homepage');
        } else if(test) {
            apiService.social.getAccessToken(getCookie("oauth_token"),  getCookie("oauth_token_secret"), test)
            .then((r) => {
                console.log(r);
                apiService.social.postSocialNetworkWithUser(getUserId(), {
                    "social_network": "TWITTER",
                    "access_token": r.data.token,
                    "access_secret": r.data.token_secret
                })
                .then((r) => {
                    console.log(r);
                    history.push('/twitter_auth');
                })
                .catch((e : AxiosError) => {
                    console.log(e);
                })
            })
            .catch((e: AxiosError) => {
                console.log(e);
            })
        }
        
    }, [location.search, history]);

    return (
        <View className="App">
            <Loader />
        </View>
    )
}