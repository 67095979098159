import React, { FunctionComponent, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { Btn } from "../../../../components/buttons/btn.component"
import { DropDownMultiple } from "../../../../components/dropdown/dropdown-multiple.component";
import { DropDown } from "../../../../components/dropdown/dropdown.component";
import { InputObligatory } from "../../../../components/inputs/input.component";
import { SpacerVertical } from "../../../../components/spacer/spacer.component";
import { View } from "../../../../components/view/view.component"

import "./account-settings-form.scss";


export const CVSettingsForm: FunctionComponent = () => {

    const [value, setValue] = useState(["العربية", "الانجليزية"]);

    const handleChange = (arr: string[]) => {
        setValue(arr);
    }

    return (
        <>
        <View className="AccountSettingsForm">
            <Row>
                <Col lg={6}>
                    <InputObligatory name="" label="مجال الكتابة الرئيسي" placeholder="التكنولوجيا"/>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <DropDownMultiple name=""  label="الجنس" placeholder="ذكر" value={value} options={["123", "234", "456"]} onChange={handleChange}/>
                </Col>
            </Row>
            <SpacerVertical height={"1.143em"}/>
            <Row>
                <Col lg={6}>
                <DropDown name=""  label="حقل الخبرة" placeholder="تكنولوجيا المعلومات" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
                <Col lg={6}>
                <DropDown name=""  label="سنوات الخبرة" placeholder="5 سنوات" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
            </Row>
            <SpacerVertical height={"2.500em"}/>
            <Row>
                <Col lg={6}>
                <DropDown name=""  label="مجال التعليم" placeholder="علوم حاسوب" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
                <Col lg={6}>
                <DropDown name=""  label="مستوى التعليم" placeholder="بكالوريوس" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
            </Row>
            <SpacerVertical height={"2.500em"}/>
            <Row>
                <Col lg={12}>
                    <DropDownMultiple name=""  label="الجنس" placeholder="ذكر" value={value} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
            </Row>
            <SpacerVertical height={"2.500em"}/>
            <Row>
                <Col lg={12}>
                    <DropDown name=""  label="شهادات وكورسات" placeholder="الرجاء إدخال شهادات وكورسات" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
            </Row>
            <SpacerVertical height={"2.500em"}/>
            <Row>
                <Col lg={12}>
                    <DropDown name=""  label="منشورات وأبحاث" placeholder="الرجاء إدخال منشورات وأبحاث" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
            </Row>
            <SpacerVertical height={"2.500em"}/>
            <Row>
                <Col lg={12}>
                    <DropDown name=""  label="الأدوات والبرامج" placeholder="الرجاء إدخال الأدوات والبرامج" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
            </Row>
            <SpacerVertical height={"2.500em"}/>
            <Row>
                <Col lg={12}>
                    <DropDown name=""  label="المهارات" placeholder="الرجاء إدخال المهارات" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
            </Row>
        </View>
        <View className="AccountSettingsButton">
            <Btn title="حفظ التعديلات"/>
        </View>
        </>
    )
}