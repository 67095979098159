import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Header1 } from '../typo/header.component';
import { View } from '../view/view.component';
import { SpacerVertical } from '../spacer/spacer.component';

import "./interests-modal.scss";
import { useHistory } from 'react-router-dom';
import { apiService } from '../../shared/services/api.service';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { sub_interests } from '../../shared/interfaces';
import { getUserId } from '../../shared/utils';
import { setCookie } from '../../shared/services/cookie.service';
import { Loader } from '../loader/loader.component';


type InterestsModalArr = {
    id: number;
    description: string;
    status?: boolean;
    title: string;
    url: string;
    image_url?: string;
    sub_interests : sub_interests;
  }

export const InterestsModal:FunctionComponent = () => {

    const {response, isLoading} =  useFetch(() => apiService.interests.getAllInterests(0,30, apiService.auth.isAuthenticated()));

    const [counter, setCounter] = useState(0);
    const [interests, setInterests] = useState<InterestsModalArr[]>([]);

    const history = useHistory();

    useEffect(() => {
        if(!isLoading) {
            const arr = response?.data?.interests?.map((item : InterestsModalArr) => {
                return {...item, status : false}
            });
            setInterests(arr);
        }
    }, [isLoading, response]);
    
    const handleClick = (id: number) => {
        if(counter === 2) {
            const arr = interests.map((item) => {
                if(item.id === id) {
                    if(item.status) {
                        setCounter(counter - 1)
                        return {...item, status: !item.status}
                    } 
                    
                }
                return item
                });
                setInterests(arr);
            
        } else {
            const arr = interests.map((item) => {
                if(item.id === id) {
                    item.status ? setCounter(counter - 1) : setCounter(counter + 1);
                    return {...item, status: !item.status}
                }
                return item
                });
                setInterests(arr);
        }
    }

    const makeApiCall = () => {
        if(counter === 2) {
            const arr = interests.filter((item) => item.status === true);
            const interestsArr = arr.map((item) =>{
                return {id: item.id, title: item.title}
            });
            apiService.interests.subscribeInterestsRegistration(getUserId(), {interests : interestsArr})
                .then(r => {
                    setCookie('mawsuah_access_token',r.data.access_token, 1);
                    setCookie('mawsuah_refresh_token',r.data.refresh_token, 7);
                    const myStorage = window.localStorage;
                    myStorage.setItem("mawsuah_user_data", JSON.stringify(r.data.user));
                    history.push("/homepage");
                });
        } 
        
    }
   
    return (
        <View className="modalInterests">
            <Header1 title="أهلا وسهلا بك في مجتمع موسوعة"/>
            <SpacerVertical height={"1.214em"}/>
            <div className="modalInterestsSubHeader"> اختر على الأقل <span>2</span>  اهتمامات لنساعدك في قراءة أهم المقالات الموثوقة. انطلق الآن</div>
            <SpacerVertical height={"2.500em"}/>
            <Container className="modalInterestsContent">
                {isLoading ? <Loader /> :
                    <div className="row">
                        {interests?.map((item) => {
                                return(
                                    <div className="col-3 "  key={item.id}>
                                        <View className="modalInterestsContentItem" onClick={() => handleClick(item.id)}>
                                            {!item.status ? 
                                            <div 
                                            className="modalIcon" 
                                            style={{backgroundImage: `url(${item.image_url})`, 
                                                    width: "100%", 
                                                    height: "100%", 
                                                    objectFit: "cover"}}>
                                                {item.title}
                                            </div> :
                                            <div className="modalIcon" style={{backgroundColor: "var(--blue)"}}>{item.title}</div>}
                                        </View>
                                    </div>
                                )
                        })}
                    </div> 
                }
            </Container>
            <SpacerVertical height={"1em"}/>
            <View onClick={makeApiCall} className="modalInterestsButton" style={counter === 2 ?
                 {backgroundColor: "var(--blue)", color: "var(--white)"} :
                 {}}> {counter !==2 ? `2 / ${counter}` : "انتهاء"} </View>
        </View>
    )
}