import React, { FunctionComponent, useEffect, useState } from "react";
import {unstable_batchedUpdates} from 'react-dom';
import { apiService } from "../../shared/services/api.service";
import { deserialize, getDate, getUserId, getUserRole, serialize } from "../../shared/utils";
import { Btn } from "../buttons/btn.component";
import { Icon } from "../icons/icon.component";
import { Input, InputCheckBox, InputValue } from "../inputs/input.component";
import { RichTextEditor } from "../rich-text-editor/rich-text-editor.component";
// import { Timer } from "../timer/timer.component";
import { View } from "../view/view.component";
import articleSvg from '../../assets/images/ArticleReview/commentAltLinesGray.png';

interface ChatModalWriterProps {
    hideModal: () => void;
    getNewEdtiorData: (data: any, blockId: number, textContent: string, highlightId: string, highlightsOfBlock: any[]) => void;
    image_url?: string;
}

export const ChatModalWriter: FunctionComponent<ChatModalWriterProps> = (props : any) => {

    const [chatInfo, setChatInfo] = useState<any>();
    const [chatData, setChatData] = useState<any[]>([]);
    // const [reviewerCorrection, setReviewerCorrection] = useState("");
    const [value, setValue] = useState("");
    const [userInfo] = useState(getUserId());
    const [blockId, setBlockId] = useState(0);
    const [commentId, setCommentId] = useState("");
    const [textEditorData, setTextEditorData] = useState<any[]>([]);
    const [date, setDate] = useState("");



    useEffect(() => {
        let textEditorData
        if(props.newProp?.highlight.length && props.newProp?.highlight[0].newText) {
            const myDocument = new DOMParser().parseFromString(props.newProp?.highlight[0].newText ? props.newProp?.highlight[0].newText : props.newProp?.highlight[0].text, 'text/html')
        textEditorData = [{
            type: 'paragraph',
            children: deserialize(myDocument.body),
          }]
        } else if (props.newProp?.highlight.length){
            const el =  document.getElementById(props.newProp?.highlight[0].highlightId);
            if(el?.textContent) {
                if (el.children.length > 0) {
                    if ( el.children[el.children.length-1].id === "demo") {
                        el.removeChild(el.children[el.children.length-1]);
                    }
                }
                const myDocument = new DOMParser().parseFromString(el.textContent, 'text/html');
                textEditorData = [{
                type: 'paragraph',
                children: deserialize(myDocument.body)
            }]
            }
        }
        if (textEditorData){
            setTextEditorData(textEditorData);
        }
         
        
        if(props.newProp.highlight_id && props.newProp?.highlight[0].numberOfComment) {
            apiService.comments.getCommentariesByHighlightId(props.newProp.highlight_id, 0, 50, props.newProp?.highlight[0].numberOfComment)
                .then((r) => {
                    const info = JSON.parse(r.data.commentaries[0].content);
                    unstable_batchedUpdates(() => {
                        setChatData(r.data.commentaries);
                        setBlockId(info.blockId);
                        setCommentId(info.info.commentId);                        
                        // setReviewerCorrection(r.data.commentaries[0].highlight.reviewerCorrection);
                    })
                });
        }
        if(props.newProp?.highlight.length) {
            setChatInfo(props.newProp?.highlight[0]);
           
        }
    }, [props]);

    const handleChange = (item: InputValue) => {
        setValue(item.value);
    }
    const handleChatData = () => {

        const newComment = {
            blockId: blockId,
            info : {
              comment: value,
              commentId: commentId
            }
          }
          
        apiService.comments.createCommentaryByReviewHighlight(chatData[0].highlight.id, chatData[0].highlight_number, JSON.stringify(newComment), false)
          .then((r) => {
            setChatData([...chatData, r.data]);
          })
        setValue("");
    }

    const getCommentText = (item: string) => {
        const info = JSON.parse(item);
        return info.info.comment.toString();
    }

    const handleReferenceData = (data: string, id: number, numberOfReferences: number) => {
       
      } 

      const handleClick = () => {
        
        const doc = new DOMParser().parseFromString(serialize(textEditorData[0]), 'text/html');
        const span = document.getElementById(props.newProp?.highlight[0].highlightId);
        const parent = document.getElementById(blockId.toString());
        if(parent?.children){
            for (let a of Object.keys(parent.children)) {
              let b = parent.children[+a];
              if(b.className === "CommentDiv" && b.id === props.newProp?.highlight[0].highlightId) {
                b.classList.add("CommentDivFixed");
                const img : any = b.childNodes[0];
                img.src = articleSvg;
              }
            }
          }
        
        if(span && doc.body.children[0].innerHTML) {
          span.innerHTML = doc.body.children[0].innerHTML
          span.style.backgroundColor = "#EDEDED"
        }
        if(parent && span?.textContent) {
            const newDoc = deserialize(parent);
            let newDocMid
            
            if(newDoc.children) {
                newDocMid = newDoc.children.filter((item : any)=> item.type !== "block");
            } else {
                newDocMid = newDoc
            }
            
            let newDocFinal = {
                type: "paragraph",
                children: newDocMid
            }

            if(newDoc?.type === "numbered-list" || newDoc?.type === "bulleted-list" || newDoc?.type === "block-quote") {
                newDocFinal = {
                    type: newDoc?.type,
                    children: newDocMid
                }
            }
                let idEL = 4000;
                const div = document.createElement('p');
                const cloneParent = document.createElement('p');
                
                if(parent.innerHTML){
                    cloneParent.innerHTML = parent.innerHTML
                    cloneParent.id = parent.id
                    div.appendChild(cloneParent);
                }
                if(div.children[0]?.childNodes.length){
                    div.children[0]?.childNodes.forEach((item : any) => {
                      
                    if(item.childNodes.length > 1) {
                      item.childNodes.forEach((item : any) => {
                        if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
                            item.id = `${blockId}${idEL+1}`;
                            idEL = idEL+1
                        }
                      })
                    }
                    if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
                        item.id = `${blockId}${idEL+1}`;
                        idEL = idEL+1
                    }
                  })
                }
                const allMarks = div.querySelectorAll(".HighlitedText");
                const range : any = new Range();
                if(allMarks?.length) {
                    let newHighLights : any[] = []
                    let arr : any[] = []
                    
                    allMarks.forEach((item, indx) => {
                      
                      if(!!item.firstChild?.textContent?.length && item.firstChild?.textContent.length) {
                        range.setStartBefore(item);
                        range.setEndAfter(item);
                      }
                      newHighLights.push(item.textContent);
                        const startTextIndex = Array.prototype.indexOf.call(range.startContainer.childNodes, item)
                        let endTextIndex = Array.prototype.indexOf.call(range.endContainer.childNodes, item)
                  
                       let startOffset = item.previousSibling?.textContent?.length
                       let endOffset = item.textContent?.length
                      if(!startOffset) {
                        startOffset = 0;
                      }

                       if(item.childNodes.length > 1 && item?.parentElement?.id === blockId.toString()) {
                        endTextIndex = endTextIndex + item.childNodes.length - 1
                        endOffset = item.childNodes[item.childNodes.length - 1].textContent?.length
                        }
                      
                      if(startOffset !== undefined && startOffset !== null && endOffset){
                        if(indx === 0 && startTextIndex === 1){
                          const obj = startTextIndex-1 === endTextIndex-1 ? {
                            highlightId : item.id,
                            startKey: range.startContainer.id,
                            startTextIndex: startTextIndex-1,
                            endKey: range.endContainer.id,
                            endTextIndex: endTextIndex - 1,
                            startOffset: startOffset,
                            endOffset: endOffset+startOffset
                           } : {
                            highlightId : item.id,
                            startKey: range.startContainer.id,
                            startTextIndex: startTextIndex-1,
                            endKey: range.endContainer.id,
                            endTextIndex: endTextIndex-1,
                            startOffset: startOffset,
                            endOffset: endOffset
                          }
                           arr.push(obj);
                        } else if (indx === 0 && startTextIndex === 0 && endTextIndex === 0) {
                          const obj2 =  {
                            highlightId : item.id,
                            startKey: range.startContainer.id,
                            startTextIndex: startTextIndex,
                            endKey: range.endContainer.id,
                            endTextIndex: endTextIndex,
                            startOffset: startOffset,
                            endOffset: endOffset+startOffset
                          }
                          arr.push(obj2);
                        } else if(range.startContainer.childNodes[0].nodeName !== "#text") {
                          const obj3 = startTextIndex === endTextIndex ? {
                            highlightId : item.id,
                            startKey: range.startContainer.id,
                            startTextIndex: startTextIndex,
                            endKey: range.endContainer.id,
                            endTextIndex: endTextIndex,
                            startOffset: startOffset,
                            endOffset: endOffset+startOffset
                          } : {
                            highlightId : item.id,
                            startKey: range.startContainer.id,
                            startTextIndex: startTextIndex,
                            endKey: range.endContainer.id,
                            endTextIndex: endTextIndex,
                            startOffset: startOffset,
                            endOffset: endOffset
                          }
                          arr.push(obj3);
                        } else  {
                          const obj1 = startTextIndex-1 === endTextIndex-1 ? {
                            highlightId : item.id,
                            startKey: range.startContainer.id,
                            startTextIndex: startTextIndex-1,
                            endKey: range.endContainer.id,
                            endTextIndex: endTextIndex-1,
                            startOffset: startOffset,
                            endOffset: endOffset+startOffset
                          } : {
                            highlightId : item.id,
                            startKey: range.startContainer.id,
                            startTextIndex: startTextIndex-1,
                            endKey: range.endContainer.id,
                            endTextIndex: endTextIndex-1,
                            startOffset: startOffset,
                            endOffset: endOffset
                          }
                          arr.push(obj1);
                        }
                      }
                    })
                    
                    apiService.comments.createCommentaryByReviewHighlight(
                      props.newProp.comment[0].commentaries[0].highlight.id,
                      props.newProp.comment[0].commentaries[0].highlight_number,
                      props.newProp.comment[0].commentaries[0].content,
                      true
                    )
                    
                    props.getNewEdtiorData(newDocFinal, blockId, span.textContent, props.newProp?.highlight[0].highlightId, arr);
                    props.hideModal();
                  }
        }
      }

      useEffect(() => {
        const date = new Date(props.newProp.comment[0]?.changedAt);
        setDate(`${date.getHours()+2}:${date.getMinutes()}`);
      }, [props.newProp.comment])

      const handleTextEditor = (instance: any, id: number) => {
        setTextEditorData(instance);
      }

    return(
        <View className="ChatModal">
          <View className="ChatModalRightColumn">
              <View className="ChatModalHeader">
                  <View className="Comments">
                      <Icon name="iconCommentLinesBlack"/>
                      تعليق {chatInfo?.numberOfComment}  
                  </View>
                  {/* <View className="RemainigTime">
                  الوقت المتبقي للتعديلات:
                  <span>  <Timer endDate={props.newProp?.endTime}/> ساعة </span>
                  </View> */}
              </View>
              <View className="ChatModalSubHeaderTitle">
              {/* <Icon name="iconNoteMark"/> */}
              النص الآصلي
              </View>
              <View className="ChatModalSubHeader">
              {chatInfo?.text}
              </View>
              <View className="ChatModalSubHeaderTitle">
              {/* <Icon name="iconNoteMark"/> */}
              تعديل المراجع
              </View>
              <View className="ChatModalSubHeader ChatModalSubHeaderChanges">
              {chatInfo?.newText}
              </View>
              {/* <View className="ChatModalSubHeaderTitle">
              <Icon name="iconNoteMark"/>
              لتعديل النص بناءً على تعليق الكاتب، يرجى التعديل داخل الصندوق من ثم الضغط على زر تعديل.
              </View> */}
              <View style={{backgroundColor: "white", padding: "1.5em 1.5em 0.688em" , marginTop: "0", borderLeft: "1px solid #e8e8e8"}} className="DashboardArticle">
              {textEditorData.length !== 0 &&
              <RichTextEditor 
                  id={1} 
                  numberOfReferences={1}
                  handleReferenceData={handleReferenceData}
                  handleTextEditor={handleTextEditor}
                  editorData={textEditorData}
                  isParagraphProp={true}
                  isListProp={true}
                  iconsItemProp={"iconParagraph"}
                  textAlignIconProp="iconTextAlignRight"
                  isWithSettings={true}
              />}
              </View>
              <View className="ChatModalButtons">
                  <Btn className="colored" title="تأكيد التعديل على المقال" onClick={handleClick}/>                
              </View>
            </View>
            <View className="ChatModalLeftColumn">
              <View className="ChatModalHeader">
                <View className="Comments">
                      <Icon name="iconReviewerHeaderChat"/>
                      للتحدّث مع المراجع 
                </View>
              </View>
              {/* <View className="ChatModalSubHeaderTitle">
              <Icon name="iconNoteMark"/>
              للحديث مع المراجع حول التعليق المُرسل، يرجى الكتابة داخل الصندوق ثم الضغط على زر إرسال.
              </View> */}

              <View className="ChatWrapper">
                  <View className="Chat">
                      {chatData.map((item) => {
                          if(getCommentText(item.content) !== "") {
                          if (userInfo === item.author.id) {
                              return (
                                  <View key={item.id} className="MessageWriter">
                                      <View className="Author">
                                          <View>
                                              <Icon src={item.author.image_url} name="iconProfilePlaceholder"/>
                                              <span>{item.author.last_name} {item.author.first_name}</span>
                                          </View>
                                          <span>{getUserRole(item.author.role)}</span>
                                      </View>
                                      <View className="comment">
                                          {getCommentText(item.content)}
                                      </View>
                                  </View>
                              )
                          } else {
                              return (
                                  <View key={item.id} className="MessageEditor">
                                      <View className="Editor">
                                          <span>{getUserRole(item.author.role)}</span>
                                          <View>
                                              {item.author.last_name} {item.author.first_name}
                                              <Icon src={item.author.image_url} name="iconProfilePlaceholder"/>
                                          </View>
                                      </View>
                                      <View className="comment">
                                        {getCommentText(item.content)}
                                      </View>
                                  </View>
                              )
                          }
                      }
                      return undefined})}
                  </View>
              </View>
              <View className="MessageInput">
                  <Icon src={props.image_url ? props.image_url : ""} name="iconProfilePlaceholder"/>
                  <Input name="msg" placeholder="اكتب تعليقاً واضغط إرسال" value={value} onChange={handleChange} onKeyPress={handleChatData} style={{width:"100%"}}/>
                  <button onClick={handleChatData}>
                      <Icon name="iconSendMessage"/>
                  </button>
              </View>
              <View className="CommentHandled">
              {props.newProp.comment[0]?.changedAt &&
                  <>
                  <InputCheckBox className="CommentHandledChecked" name="CommentHandled" readOnly checked/>
                  <span>تم تعديل التعليق في {getDate(props.newProp.comment[0]?.changedAt)} الساعة {date} مساءا</span>
                  </>}
              </View>
            </View>
            {/* <View className="ChatModalButtons">
                <Btn className="colored" title="تعديل" onClick={handleClick}/>
                <BtnColorless className="colorless" title="إغلاق" onClick={() => props.hideModal()}/>
            </View> */}
        </View>
    )
}