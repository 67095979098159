import React, { FunctionComponent } from 'react';
import {  Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ArtcileInfo, InterestsData, WriterInfo } from '../../shared/interfaces';
import { Btn, BtnSmallOpacity } from '../buttons/btn.component';
import { Icon } from '../icons/icon.component';
import { SpacerVertical } from '../spacer/spacer.component';
import {  Header3 } from '../typo/header.component';
import { View } from '../view/view.component';
import { SuggestedArticlesItem } from './nested/suggested-articles-item.component';
import { SuggestedArticlesWriters } from './nested/suggested-articles-writers.component';

import "./suggested-articles.scss"

interface SuggestedArticlesProps {
    dataArticles: ArtcileInfo[];
    randomWriters: WriterInfo[];
    isAuthenticated: boolean;
    randomInterests: InterestsData[];
}

export const SuggestedArticles: FunctionComponent<SuggestedArticlesProps> = (props) => {

    const history = useHistory();

    const {dataArticles, randomWriters, isAuthenticated, randomInterests} = props;

    return(
        <View className="SuggestedArticles">
            <Container>
                <Header3 className="writersHeader" title="مقالات مقترحة"/>
                <div className="smallLine"></div> 
                <Row>
                    <Col lg={8}>
                        {dataArticles?.map((item) => {
                            return (
                                <SuggestedArticlesItem 
                                    toSave
                                    key={item.id}
                                    title={item.title}
                                    header={item.header}
                                    image_url={item.image_url}
                                    interest={item.interest}
                                    author={item.author}
                                    created_at={item.created_at}
                                    published_at={item.published_at}
                                    minutes_for_reading={item.minutes_for_reading}
                                    articleId={item.id}
                                    status={item.status.name}
                                    isSaved={item.is_saved}
                                    updated_at={item.updated_at}
                                />
                            )
                        })}
                        <View className="moreSuggestedArticles" onClick={() => {history.push('/explore')}}>
                            <div>استكشاف المزيد</div>
                            <Icon name="iconArrowLeft" />
                        </View>
                    </Col>
                    <Col lg={4}>
                        <View style={{marginRight: "95px"}} className="SuggestedArticlesList">
                            {!isAuthenticated && <>
                                <View className="whyMawsuah">
                                    <View className="whyMawsuahTitle">لماذا موسوعة؟</View>
                                    <SpacerVertical height={"1em"}/>
                                    <View className="whyMawsuahText">
                                    موسوعة نظام تحفيزي مبني على تقنية البلوكتشين، وهي المنصة العربية المفضلة لمنتجي المحتوى المختّص في كتابة مواضيع موثوقة لتصلهم بملايين الباحثين والقرّاء.
                                    </View>
                                    <SpacerVertical height={"1.714em"}/>
                                    <View className="whyMawsuahBtn">
                                        <Btn className="registerBtn" title="أنشئ حساباً" onClick={() => {history.push('/main_signup')}}/>
                                    </View>
                                </View>
                                <SpacerVertical height={"3.5em"}/>
                            </>}
                            <View className="SuggestedArticlesListMostReadInterests"> 
                                    <BtnSmallOpacity className="MostReadInterestsTitle" title="الاهتمامات الأكثر قراءةً" disabled={true}/>
                                    <SpacerVertical height={"2.286em"}/>
                                    <View className="MostReadInterestsLists">
                                        {randomInterests?.slice(0,5)?.map((item) => {
                                            return (
                                                <View key={item.id} onClick={() => {history.push(`/interests_tabs/${item.id}`)}}>
                                                    <span>{item.title}</span>
                                                    <SpacerVertical height={"1em"}/>
                                                </View>
                                            )
                                        })}
                                    </View>
                            </View>
                            <SpacerVertical height={"1.5em"}/>
                            <View className="SuggestedArticlesListMostReadInterests"> 
                                    <BtnSmallOpacity className="MostReadInterestsTitle" title="الكتّاب الأكثر متابعة" disabled={true}/>
                                    <SpacerVertical height={"2.286em"}/>
                                    <View className="MostFollowedWritersLists">
                                        {randomWriters?.slice(0,5).map((item) => {
                                            return (
                                                <View key={item.id}>
                                                    <SuggestedArticlesWriters userInfo={item}/>
                                                    <SpacerVertical height={"1.714em"}/>
                                                </View>
                                            )
                                        })}
                                   </View>
                            </View>
                        </View>
                    </Col>
                </Row>
            </Container>
        </View>
    )
}