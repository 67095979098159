import React, { FunctionComponent } from 'react';
import { BtnColorless } from '../../../components/buttons/btn.component';
import { Icon } from '../../../components/icons/icon.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
import { useHistory } from 'react-router-dom';

export const ContentNotFound: FunctionComponent = () => {
    const history = useHistory();
    return(
        <>
            <View className="ContentNotFound">
                <View className="ContentNotFoundTitles">
                    <Header1 title="المحتوى غير متوفر "/>
                    <SpacerVertical height={"1.143em"}/>
                    <View className="ContentNotFoundTitlesText">
                    اطلب مقالات عن هذا الموضوع وساهم في إثراء المحتوى المختّص الموثوق عبر موسوعة
                    </View>
                    <SpacerVertical height={"2.286em"}/>
                    <BtnColorless title="ارسل طلبك" onClick={() => history.push("/share")} />
                </View>
                <View>
                    <Icon name="iconContentNotFound" alt="content_not_found_picture"/>
                </View>
            </View>
        </>
    )
}