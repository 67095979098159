import React, { FunctionComponent, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
// import { Btn } from '../../../../components/buttons/btn.component';
import { Loader } from '../../../../components/loader/loader.component';
import { SpacerVertical } from '../../../../components/spacer/spacer.component';
import { View } from '../../../../components/view/view.component';
import { useFetch } from '../../../../shared/hooks/fetch.hook';
import { ArtcileInfo } from '../../../../shared/interfaces';
import { apiService } from '../../../../shared/services/api.service';
import { UserProfileAuthNoContent } from '../user-profile-auth-no-content/user-profile-auth-no-content.component';
import { UserProfileAuthWriterItem } from '../user-profile-auth-writer/nested/user-profile-auth-writer-item.component';
import { UserProfileAuthReviewerItem } from './nested/User-profile-auth-reviewer-item.component';

export const UserProfileAuthReviewer: FunctionComponent = () => {


    const {response: articlesToReview, isLoading: articlesToReviewLoading} = useFetch(() => apiService.articles.getArticlesToReview());
    const [role, setRole] = useState("");
    const [articlesToReviewData, setArticlesToReviewData] = useState<ArtcileInfo[]>([]);
    
    useEffect(() => {
        setRole(JSON.parse(localStorage.getItem("mawsuah_user_data") as string).role.name);
    }, [])

    useEffect(() => {
        // setArticlesToReviewData(articlesToReview?.data?.articles.slice(0,10));
        setArticlesToReviewData(articlesToReview?.data?.articles);
    }, [articlesToReview]);

    // const getMore = () => {
    //     if(articlesToReview?.data?.articles !== undefined && articlesToReview?.data?.articles !== null) {
    //         setArticlesToReviewData([...articlesToReviewData , ...articlesToReview.data.articles.slice(articlesToReviewData.length,10+articlesToReviewData.length)]);
    //     }
    // }
    
    return(
        <View className="UserProfileAuthWriter">
            {articlesToReviewLoading ? 
                <Loader/> :
                articlesToReviewData?.length ? 
                <View>
            <Tabs defaultActiveKey="all" transition={false} id="noanim-tab-example">
                <Tab eventKey="all" title="كل المقالات">
                    <>
                    <SpacerVertical height={"2.357em"}/>
                    {articlesToReviewData?.map((item: ArtcileInfo) => {
                        return (
                            <UserProfileAuthReviewerItem 
                            key={item.id} 
                            status={item.status.name} 
                            articleInfo={item}
                            rejectedComment={item.status.reject_commentary} 
                            rejectedReason={item.status.reject_reason} 
                            endTime={item.review?.end_review_at ? item.review?.end_review_at : item.correction?.end_correction_at}
                            />
                        )
                    })}
                    </>
                </Tab>
                <Tab eventKey="editing" title="اتخذ إجراءً">
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {articlesToReviewData?.map((item: ArtcileInfo) => {
                        if(item.status.name === "ON_REVIEW") {
                            if(role === "REVIEWER" || role === "WRITER_REVIEWER") {
                                return (
                                    <UserProfileAuthReviewerItem 
                                    key={item.id} 
                                    status={item.status.name} 
                                    articleInfo={item} 
                                    endTime={item.review?.end_review_at}/>
                                )
                            }
                            return (
                                <UserProfileAuthWriterItem 
                                key={item.id} 
                                status={item.status.name} 
                                articleInfo={item} 
                                endTime={item.review?.end_review_at ? item.review?.end_review_at : item.correction?.end_correction_at}/>
                            )
                        }
                        return undefined
                    })}
                    
                    </>
                </Tab>
                <Tab eventKey="review" title="قيد الإجراء">
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {articlesToReviewData?.map((item: ArtcileInfo) => {
                        if(item.status.name === "ON_EDITING" || item.status.name === "ON_CORRECTING") {
                            return (
                                <UserProfileAuthWriterItem 
                                key={item.id} 
                                status={item.status.name} 
                                articleInfo={item}/>
                            )
                        }
                        return undefined
                    })}
                    </>
                </Tab>
                <Tab eventKey="approved" title="المقالات الموثوقة">
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {articlesToReviewData?.map((item: ArtcileInfo) => {
                        if(item.status.name === "PUBLISHED") {
                            return (
                                <UserProfileAuthWriterItem 
                                key={item.id} 
                                status={item.status.name} 
                                articleInfo={item}/>
                            )
                        }
                        return undefined
                    })}
                    </>
                </Tab>
                <Tab eventKey="rejected" title="المقالات المرفوضة" >
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {articlesToReviewData?.map((item: ArtcileInfo) => {
                        if(item.status.name === "REJECTED") {
                            return (
                                <UserProfileAuthWriterItem 
                                key={item.id} 
                                rejectedComment={item.status.reject_commentary} 
                                rejectedReason={item.status.reject_reason} 
                                status={item.status.name} 
                                articleInfo={item}
                                endTime={item.review?.end_review_at ? item.review?.end_review_at : item.correction?.end_correction_at}/>
                            )
                        }
                        return undefined
                    })}
                    </>
                </Tab>
                </Tabs>
                {/* <Btn title="استكشف أكثر" className="searchPageContentBtn" onClick={getMore}/> */}
            </View> :
            <UserProfileAuthNoContent />
            }
        </View>
    )
}