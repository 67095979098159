import React, { FC, useEffect, useState } from "react";
import { DropDownMultiple } from "../../../../components/dropdown/dropdown-multiple.component";
import { DropDown } from "../../../../components/dropdown/dropdown.component";
import { InputObligatory, InputValue } from "../../../../components/inputs/input.component";
import { SpacerHorizontal, SpacerVertical } from "../../../../components/spacer/spacer.component";
import { View } from "../../../../components/view/view.component";
import { EXPERIENCE } from "../../../../shared/constants";
import { InterestsData, sub_interests } from "../../../../shared/interfaces";
import { getSubSpecOpt } from "../../../../shared/utils";
import { yearsData } from "../../reviewers-signup/reviewers-signup.page";

interface IEditorMoreExp {
    register : any;
    errors : any;
    handleData : (data : any) => void;
    optionSpecialisation : string[];
    interests : InterestsData[] | undefined;
    id : number;
}

export const EditorMoreExp:FC<IEditorMoreExp> = (props) => {

    const {register, errors, handleData, id, optionSpecialisation, interests} = props;

    const [WritingSpecialization, setWritingSpecialization] = useState("");
    const [optionSubSpecialisation, setOptionSubSpecialisation] = useState<sub_interests>();
    const [SubWritingSpecialization, setSubWritingSpecialization] = useState<string[]>([]);
    const [YearsofExperience, setYearsofExperience] = useState('');
    const [ExperienceField, setExperienceField] = useState('');
    const [institution, setInstitution] = useState("");
    const [idItem] = useState(id);

    const handleInstitution = (value : InputValue) => {
        setInstitution(value.value);
    }

    const handleSpecialisation = (value : string) => {
        setWritingSpecialization(value);
        const elemArr = interests?.find((item: InterestsData) => item.title === value);
        setOptionSubSpecialisation(elemArr?.sub_interests);
    }

    useEffect(() => {
        let arr = optionSubSpecialisation?.filter((obj) => { return SubWritingSpecialization.indexOf(obj.title) >= 0; });
        arr?.forEach((item) => {
            delete item["description"]
        });

        const specialisationVal = interests?.find((item: InterestsData) => item.title === WritingSpecialization);

        const experiences = {
            id: idItem,
            experience: ExperienceField,
            years_of_experience: YearsofExperience,
            institution: institution,
            specialisation: {
                id: specialisationVal?.id
            },
            sub_specialisations: arr
        }
        handleData(experiences);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [institution, YearsofExperience, SubWritingSpecialization])

    return(
        <>
            <DropDown placeholder="الرجاء اختيار المجال الرئيسي" value={WritingSpecialization} options={optionSpecialisation} label={"مجال التحرير الرئيسي"} onChange={handleSpecialisation} name="specialisation" register={register} errors={errors} errorMessage="الرجاء اختيار المجال الرئيسي" required/>
            <SpacerVertical height={"2.286em"}/>
            <DropDownMultiple placeholder="الرجاء اختيار المجالات الفرعية" value={SubWritingSpecialization} options={getSubSpecOpt(optionSubSpecialisation)} label={"مجال التحرير الفرعي"} onChange={setSubWritingSpecialization} name="sub_specialisations" register={register} errors={errors} errorMessage="الرجاء اختيار المجالات الفرعية" required/>
            <SpacerVertical height={"2.286em"}/>
            <View className="inlineFlex">
                <DropDown className="inlineFlexDropdown" value={ExperienceField} options={EXPERIENCE} label={"مجال الخبرة العملية"} onChange={setExperienceField} placeholder="الرجاء إدخال مجال الخبرة العملية" name="experience" register={register} errors={errors} errorMessage="الرجاء إدخال مجال الخبرة العملية" required/>
                <SpacerHorizontal width={"2.145em"}/>
                <DropDown className="inlineFlexDropdown" value={YearsofExperience} options={yearsData} label="سنوات الخبرة العملية" onChange={setYearsofExperience} placeholder="الرجاء تحديد سنوات الخبرة العملية" name="years_of_experience" register={register} errors={errors} errorMessage="الرجاء تحديد سنوات الخبرة العملية" required/>
            </View>
            <SpacerVertical height={"2.286em"}/>
            <InputObligatory 
            name="institution" 
            label="اسم جهة الخبرة" 
            placeholder="الرجاء إدخال اسم جهة الخبرة"
            register={register} 
            errors={errors} 
            errorMessage="الرجاء إدخال اسم جهة الخبرة" 
            required
            errorMessagePattern="إدخال غير صحيح. يرجى استخدام أحرف"
            onChange={handleInstitution}
            />
        </>
    )
}