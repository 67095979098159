import React, { FunctionComponent } from "react"
import { Icon } from "../../../components/icons/icon.component"
import { View } from "../../../components/view/view.component"

interface IEngagementsItemProps {
    isLiked?: boolean;
    isShared?: boolean;
}


export const EngagementsItem: FunctionComponent<IEngagementsItemProps> = (props) => {
    const { isLiked , isShared } = props;
    return(
        <>
            <View className="UserProfileEngagements">
                {isLiked &&
                    <View>
                        <Icon name="iconLike" alt="like_picture"/>
                        مقال اعجبني
                    </View>
                }
                {isShared &&
                    <View className="shared">
                        <Icon name="iconShare" alt="shared_picture"/>
                        مقال شاركته
                    </View>
                }
            </View>
            {/* <SuggestedArticlesItem /> */}
        </>
    )
}