import React, { FunctionComponent } from "react"
import { View } from "../../../../components/view/view.component"

import "./account-settings-form.scss";


export const SessionLogSettingsForm: FunctionComponent = () => {


    return (
        <>
       
            <View className="SessionLog">
                <View>
                    <View className="title">
                    8.1 Windows اليوم في ال 8:36 ص
                    </View>
                    <View className="text" style={{color: "var(--success)"}}>
                    الجلسة الحالية  
                    </View>
                </View>
                <View className="edit">

                </View>
            </View>
            <View className="SessionLog">
                <View>
                    <View className="title">
                    Android
                    </View>
                    <View className="text">
                    15  ويلوي ، م 7:45 
                    </View>
                </View>
                <View className="edit">
                خروج
                </View>
            </View>
            <View className="SessionLog">
                <View>
                    <View className="title">
                    iMac
                    </View>
                    <View className="text">
                    12 ويلوي ، م 5:20  
                    </View>
                </View>
                <View className="edit">
                خروج
                </View>
            </View>
       
        <View className="AccountSettingsButton SessionLogBtn">
            تسجيل الخروج من جميع الجلسات    
        </View>
        </>
    )
}