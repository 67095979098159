import React, { FunctionComponent, useEffect, useState } from 'react';
// import { Accredited } from '../../components/accredited/accredited.component';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { ChosenTopics } from '../../components/chosen-topics/chosen-topics.component';
import { Interests } from '../../components/interests/interest.component';
import { LatestRandomArticle } from '../../components/latest-random-article/latest-random-article.component';
import { Loader } from '../../components/loader/loader.component';
import { RecommendedInterests } from '../../components/recommended-interests/recommended-interests.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { SuggestedArticles } from '../../components/suggested-articles/suggested-articles.component';
import { TelescopeBanner } from '../../components/telescope-banner/telescope-banner.component';
import { Testimonials } from '../../components/testimonials/testimonials.component';
import { View } from '../../components/view/view.component';
import { WritersWorthFollowing } from '../../components/writers-worth-following/writers-worth-following.component';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { apiService } from '../../shared/services/api.service';

import "./homepage.scss";

export const HomePage: FunctionComponent = () => {

    const {response: randomArticles, isLoading: ArticlesLoading} = useFetch(() => apiService.articles.getRandomArticles(10, apiService.auth.isAuthenticated()));
    const {response: trendingAuthors, isLoading: WritersLoading} = useFetch(() => apiService.articles.getTrendingAuthors(0,8));
    const {response: randomInterests, isLoading: InterestsLoading} = useFetch(() => apiService.interests.getRandomInterests()); 
    
    const [isAuthenticated , setIsAuthenticated] = useState(false);
  
    useEffect(() => {
        setIsAuthenticated(apiService.auth.isAuthenticated());
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
            <View className="homePage">
            <AppHeader /> 
            <SpacerVertical height={"4.571em"} />
            <Interests isAuthenticated={isAuthenticated}/>
            <SpacerVertical height={"7.786em"} />
            {!ArticlesLoading ? <ChosenTopics randomArticles={randomArticles?.data.articles.slice(0,4)}/> : <Loader />}
            <SpacerVertical height={"6.857em"} />
            {!isAuthenticated ? 
                <TelescopeBanner/> :
                <LatestRandomArticle lgPicture={8} lgText={4} articleData={randomArticles?.data.articles.slice(4,5)[0]}/>
            }
            <SpacerVertical height={"6.857em"} />
            {(ArticlesLoading || WritersLoading || InterestsLoading) ? 
                <Loader /> : 
                <SuggestedArticles 
                    isAuthenticated={isAuthenticated} 
                    dataArticles={randomArticles?.data.articles.slice(6,10)} 
                    randomWriters={trendingAuthors?.data.authors}
                    randomInterests={randomInterests?.data}
                    />
            }
            <SpacerVertical height={"9.143em"} />
            <RecommendedInterests title="اهتمامات مقترحة" randomInterests={randomInterests?.data}/>
            <SpacerVertical height={"9.143em"} />
            {WritersLoading ? 
                <Loader/> : 
                <WritersWorthFollowing randomWriters={trendingAuthors?.data.authors}/>
            }
            <SpacerVertical height={"5.286em"} />
            <Testimonials/>
            <SpacerVertical height={"9.143em"} />
             {/*{<Accredited /> */}
            <SpacerVertical height={"4.571em"} />
            <AppFooter />
            </View>
        </View>
    )
}