import { useEffect, useState } from 'react';

interface UseFetchResult<T> {
  response: T | null;
  error: string;
  isLoading: boolean;
}

export const useFetch = <T>(method: () => Promise<T>, initial?: T): UseFetchResult<T> => {
  const [response, setResponse] = useState(initial || null as null | T);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        setResponse(await method());
        setIsLoading(false);
        // console.log(response)
      } catch (error) {
        setError(error?.message);
        setIsLoading(false);
      }
    }
    fetchData()
    // .then(() => { console.log(response) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { response, error, isLoading };
};