import React, { FunctionComponent } from "react";
import { Icon } from "../icons/icon.component";
import './input.scss';


export interface InputValue {
  value: string;
  name: string;
}
interface InputProps {
  className?: string
  password?: string;
  errorMessagePattern?: string;
  checked?: boolean;
  autocomplete?: string;
  idFormData?: number;
  id?: string;
  style?: any;
  errorMessage?: string;
  errors?: any;
  register?: any;
  inputPrompt?: string;
  icon?: string;
  name: string;
  label?: string;
  value?: string;
  type?: string;
  title?: string;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  required?: boolean;
  pattern?: any;
  placeholder?: string;
  checkBoxId?: string;
  onChange?: (value: InputValue, id?: number) => void;
  handlecheck?: (e?: any) => void;
  onChangeString?: (value: string) => void;
  onKeyPress?: () => void;
  readOnly ?: boolean;
}

export const InputCheckBox: FunctionComponent<InputProps> = (props) => {
  const { label,  handlecheck, name, checked, checkBoxId, readOnly, disabled, className } = props;


  return (
    <div className="CheckBox">
      <label>
        <input id={checkBoxId} className={"checkmark " + className} name={name} type="checkbox"  placeholder={' '} onChange={handlecheck} checked={checked} readOnly={readOnly} disabled={disabled}/>
        {label}
      </label>
    </div>  
  )
}
export const InputRadioBox: FunctionComponent<InputProps> = (props) => {
  const { label,  handlecheck, id, name, style, checked } = props;
  return (
    <div className="RadioBox">
        <input className="checkmark" name={name} type="radio" id={id} placeholder={' '} onChange={handlecheck} checked={checked}/>
        <label htmlFor={id} style={style}>
        {label}
      </label>
    </div>  
  )
}

export const Input: FunctionComponent<InputProps> = (props) => {
  const { pattern, type, value, name, onChange, disabled, required, placeholder, title, style, idFormData, onKeyPress } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    !!onChange && onChange({value: event?.target.value || '', name }, idFormData);
  }

 

  return (
    <div className="Input" style={{width:"100%"}}>
      <input name={name} 
             placeholder={placeholder || '  '} 
             value={value} 
             onChange={handleChange} 
             disabled={disabled} 
             type={type} 
             title={title}
             required={required} 
             pattern={pattern}
             style={style}
             onKeyPress={(e) => {
               if(!!onKeyPress && e.key === "Enter") {
                onKeyPress();
               }
             }}
      />
      {/* <label>{label}</label> */}
    </div>
  )
}
export const InputObligatory: FunctionComponent<InputProps> = (props) => {
  const { className, pattern, type, value, name, onChange, disabled, required, placeholder, title, label, icon, inputPrompt, register, errors, errorMessage, autocomplete, maxLength, minLength, errorMessagePattern, password } = props;


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    !!onChange && onChange({value: event?.target.value || '', name });
  }
  
  return (
    <div className={`Input ${className}`}>
      <label>
        <span>{label}</span>
        {required ? <Icon className="iconRedStar" name="iconRedStar"/> : ""}
        {icon ? 
        <div className="exclamation">
          <Icon name={icon} className="exclamation"/>
          <span className="exclamationtext">يستمر معك طيلة الرحلة
تأكد من استخدام اسم واضح سهل القراءة</span>
        </div> 
        : ''}
        
      </label>
      <input name={name} 
             autoComplete={autocomplete}
             placeholder={placeholder || '  '} 
             value={value} 
             onChange={handleChange} 
             disabled={disabled} 
             type={type} 
             title={title}
             //pattern={pattern}
             ref={register ? register({required: 'hello',
             maxLength: maxLength,
              pattern: pattern,
              minLength: minLength,
              validate: (value: any) => 
                {
                  if(name === "confirm_password" && value !== password){
                  return  "123"
                  } else 
                  return null
                  
                }
            }) : null}
      />
       {register ? 
       errors[`${name}`]?.type === "required" && <div className="ErrorText">{errorMessage}</div> : null }
       {register ? (errors[`${name}`]?.type === "pattern" || errors[`${name}`]?.type === "maxLength" || errors[`${name}`]?.type === "minLength" || errors[`${name}`]?.type === "validate") && <div className="ErrorText"><span>{errorMessagePattern}</span></div> : null
       }
      {register ? !errors[`${name}`] && <div className="inputPrompt"><span>{inputPrompt}</span></div> : null}
    </div>
  )
}

