import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
import { Icon } from '../../../components/icons/icon.component';
import certifiedBG from '../../../assets/images/StaticPages/CertifiedPage/certifiedBG.png';
import './certified.scss';
import { useLocation } from 'react-router';

export const CertifiedPage: FunctionComponent = () => { 

    const location : any = useLocation();

    useEffect(() => {
        if(location.state?.sponsor === "harvard") {
            window.scrollTo(0,900);
        }
        if(location.state?.sponsor === "aub") {
            window.scrollTo(0,1450);
        }
        if(location.state?.sponsor === "sharja") {
            window.scrollTo(0,2000);
        }
        if(location.state?.sponsor === "sharja1") {
            window.scrollTo(0,2450);
        }
    }, [location]);

    return (
        <View className="App">
            <AppHeader isWhite={true}/>
            <View className="CareersPage">
                <View className="CareersPageHeader" title="careers_photo" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(${certifiedBG})`}}>
                    <Container style={{marginTop: "96px"}}>
                        <View className="CareersPageHeaderContent">
                            <Header1 title="الثقة تعني المصدر" className="header1"/>
                        </View>
                        <View className="CareersPageHeaderContent">
                            <Icon name="iconMawsuahLogo"/>
                            <span className="bigText">موسوعة</span>
                            <Icon name="iconVerifiedSmallWhiteBG"/>                                                      
                        </View>
                    </Container>
            </View>
                <SpacerVertical height={"4.5em"}/>
                <View className="CareersPageContent">
                    <Container>
                        <Row>
                            <Col lg={3} className="CareersPageContentSecondColumn">
                                {/* <View className="CareersPageContentSecondColumnMenu" style={{borderBottom: "0px"}}>
                                    <View className="Item">معتمدون لدى </View>
                                    <SpacerVertical height={"0.55em"}/>
                                </View>
                                <View className="CareersPageContentSecondColumnDisabled">
                                    <View className="Item">المساعدة</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">المجتمعات</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">الشركاء</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">المدونة</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">الوظائف</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">سياسة الخصوصية</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">شروط الاستخدام</View>
                                    <SpacerVertical height={"0.55em"}/>
                                    <View className="Item">التواصل</View>
                                    <SpacerVertical height={"0.55em"}/>
                                </View> */}
                            </Col>
                            <Col className="CareersPageContentFirstColumn" lg={9}>
                                <Header3 className="writersHeader" title="تم الاعتماد بنجاح"/>
                                <div className="smallLine"></div>
                                <View className="CareersPageContentFirstColumnContent">
                                    <View className="smallHeader">انضم إلينا
                                    </View>
                                    <SpacerVertical height={"4.571em"}/>
                                    <View className="PartnersContent">
                                        <View className="PartnersBlock">
                                            <Icon name="iconPartnersBlock1"/>
                                            <View className="Flex">
                                                <View className="PartnersBlockPicture">
                                                    <View className="PartnersBlockPictureContent">
                                                        <Icon name="iconPartnerLogo" />
                                                    </View>
                                                </View>
                                                <View>
                                                    <View className="PartnersBlockTitle">
                                                        <View>
                                                        اسم الجهة
                                                        </View>
                                                        <View className="BlockLink">
                                                            <a href="https://mawsuah.com/">Your Website Link</a>
                                                        </View>
                                                    </View>
                                                    <View className="PartnersBlockText">
                                                    يسعدنا أن يتم اعتماد "موسوعة" من قِبلكم على أن نكون مصدر المعلومات المختصّة الأول لكم ولمجتمعاتكم، دعمكم يعني لنا النجاح. 
نفخر بثقتكم ويشرفنا أن تكونوا من المؤثرين الأوائل في مسيرتنا!
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <SpacerVertical height={"2.286em"}/>
                                    <View className="PartnersContent">
                                        <View className="PartnersBlock">
                                            <Icon name="iconPartnersBlock1"/>
                                            <View className="Flex">
                                                <View className="PartnersBlockPicture">
                                                    <View className="PartnersBlockPictureContent">
                                                        <Icon name="iconPartnerLogo" />
                                                    </View>
                                                </View>
                                                <View>
                                                    <View className="PartnersBlockTitle">
                                                        <View>
                                                        اسم الجهة
                                                        </View>
                                                        <View className="BlockLink">
                                                            <a href="https://mawsuah.com/">Your Website Link</a>
                                                        </View>
                                                    </View>
                                                    <View className="PartnersBlockText">
                                                    يسعدنا أن يتم اعتماد "موسوعة" من قِبلكم على أن نكون مصدر المعلومات المختصّة الأول لكم ولمجتمعاتكم، دعمكم يعني لنا النجاح. 
نفخر بثقتكم ويشرفنا أن تكونوا من المؤثرين الأوائل في مسيرتنا!
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <SpacerVertical height={"2.286em"}/>
                                    <View className="PartnersContent">
                                        <View className="PartnersBlock">
                                            <Icon name="iconPartnersBlock1"/>
                                            <View className="Flex">
                                                <View className="PartnersBlockPicture">
                                                    <View className="PartnersBlockPictureContent">
                                                        <Icon name="iconPartnerLogo" />
                                                    </View>
                                                </View>
                                                <View>
                                                    <View className="PartnersBlockTitle">
                                                        <View>
                                                        اسم الجهة
                                                        </View>
                                                        <View className="BlockLink">
                                                            <a href="https://mawsuah.com/">Your Website Link</a>
                                                        </View>
                                                    </View>
                                                    <View className="PartnersBlockText">
                                                    يسعدنا أن يتم اعتماد "موسوعة" من قِبلكم على أن نكون مصدر المعلومات المختصّة الأول لكم ولمجتمعاتكم، دعمكم يعني لنا النجاح. 
نفخر بثقتكم ويشرفنا أن تكونوا من المؤثرين الأوائل في مسيرتنا!
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <SpacerVertical height={"2.286em"}/>
                                    
                                </View>
                            </Col>
                        </Row>
                    </Container>
                    <SpacerVertical height={"3.3em"}/>
                </View>
                <AppFooter />
            </View>
        </View>
    )
}