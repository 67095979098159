import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Btn } from '../../../../components/buttons/btn.component';
import { Icon } from '../../../../components/icons/icon.component';
import { SpacerVertical } from '../../../../components/spacer/spacer.component';
import { SuggestedArticlesItem } from '../../../../components/suggested-articles/nested/suggested-articles-item.component';
import { View } from '../../../../components/view/view.component';
import { useFetch } from '../../../../shared/hooks/fetch.hook';
import { ArtcileInfo, AuthorData, InterestsData } from '../../../../shared/interfaces';
import { apiService } from '../../../../shared/services/api.service';
import { activeClass, getUserId } from '../../../../shared/utils';
import { InterestsSearchItem } from '../../../search/nested/interests-item.search-page';
import { WritersSearchItem } from '../../../search/nested/writers-item.search-page';
import { useHistory } from 'react-router';

interface LocationState {
      tabName: string;
  }

export const UserProfileAuthTrending: FunctionComponent = () => {

    const location = useLocation<LocationState>();

    const {response: newestArticles} = useFetch(() => apiService.articles.getTrendingArticles(0, 10));
    const {response: randomWriters} = useFetch(() => apiService.articles.getTrendingAuthors(0, 10));
    const {response: allInterests} = useFetch(() => apiService.interests.getAllInterests(0, 10, apiService.auth.isAuthenticated()));
    const {response: myInterests} = useFetch(() =>  apiService.interests.getUserInterests(getUserId()));

    const [isArticles, setIsArticles] = useState(true);
    const [isWriters , setIsWriters ] = useState(false);
    const [isInterests, setIsInterests] = useState(false);
    const [statusFilter, setStatusFilter] = useState('Newest');

    useEffect(() => {
        if(location.state?.tabName === "authors") {
            setIsWriters(true);
            setIsArticles(false);
            setIsInterests(false);
        } 
    }, [location]);

    const handleFilter = (status : string) => {
        if(status === "Newest") {
            setStatusFilter("Newest");
        }
        if(status === "MostRead") {
            setStatusFilter("MostRead");
        }
        if(status === "MostEngaged") {
            setStatusFilter("MostEngaged");
        }
    }

    const history = useHistory();

    return (
            <View className="UserProfileAuthHomePage">
            <View className="searchPageMenu">
                <View className={activeClass("searchPageMenuItem", isArticles)}
                    onClick={() => {
                        setIsArticles(true);
                        setIsWriters(false);
                        setIsInterests(false);
                    }}>
                مقالات 
                </View>
                <View className={activeClass("searchPageMenuItem", isWriters)}
                        onClick={() => {
                            setIsArticles(false);
                            setIsWriters(true);
                            setIsInterests(false);
                        }}>
                كتّاب
                </View>
                <View className={activeClass("searchPageMenuItem", isInterests)} 
                    onClick={() => {
                        setIsArticles(false);
                        setIsWriters(false);
                        setIsInterests(true);
                    }}>
                اهتمامات
                </View>
            </View>
            <SpacerVertical height={"2em"} />
            <View className="searchPageFilter">
                <View className={statusFilter === "Newest" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("Newest")}>
                الأحدث  
                </View>
                <View className={statusFilter === "MostRead" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostRead")} >
                الأكثر تصفحاً  
                </View>
                <View className={statusFilter === "MostEngaged" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostEngaged")}>
                { isArticles ? "الأكثر تفاعلاً" : "الأكثر متابعة" }
                </View>
            </View>
            <SpacerVertical height={"3.143em"} />
            <View className="searchPageContent">
                {isArticles && 
                <>
                    {newestArticles?.data?.articles?.map((item : ArtcileInfo) => {
                        return (
                        <SuggestedArticlesItem 
                            published_at={item.published_at}
                            toSave
                            key={item.id}
                            title={item.title}
                            header={item.header}
                            image_url={item.image_url}
                            interest={item.interest}
                            author={item.author}
                            created_at={item.created_at}
                            minutes_for_reading={item.minutes_for_reading}
                            articleId={item.id}
                            status={item.status.name}
                            isSaved={item.is_saved}
                            updated_at={item.updated_at}
                        />)
                    })}
                </>}
                {isWriters && 
                    <>
                    {randomWriters?.data?.authors?.map((item : AuthorData) => {
                        return (
                            <WritersSearchItem writerInfo={item} key={item.id}/>
                        )
                    })}
                    </>}
                {isInterests &&
                    <>
                   {allInterests?.data?.interests.map((item : InterestsData) => {
                    return(
                        <View key={item.id}>
                            <View className="searchPageContentInterests" onClick={(e) => { e.stopPropagation(); history.push(`/interests_tabs/${item.id}`); }} >
                                <View className="bg"  >
                                    <Icon src={item.image_url} name="bg1" alt={item.title}/>
                                </View>
                                <InterestsSearchItem myInterests={myInterests?.data} item={item}/>
                            </View>
                            <SpacerVertical height={"2.286em"} />
                        </View>    
                    )
                })}
                </>}
                <Btn title="استكشف أكثر" className="searchPageContentBtn"/>
            </View>
            </View>
    )
}