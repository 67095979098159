import React, { FunctionComponent } from "react"
// import { Btn } from "../../../components/buttons/btn.component"
import { SpacerVertical } from "../../../components/spacer/spacer.component"
import { View } from "../../../components/view/view.component"
import { AuthorData } from "../../../shared/interfaces"

interface UserProfileCVProps {
    authorData: AuthorData | null | undefined;
}



export const UserProfileCV: FunctionComponent<UserProfileCVProps> = (props) => {

    const {authorData} = props;


    console.log(authorData);
    

    return(
        <View className="UserProfileCV">
        <SpacerVertical height={"2.143em"}/>
        <View className="UserProfileCVItem">
            <View className="UserProfileCVItemFlex">
                <View className="UserProfileCVItemFlexTitle">مجال الكتابة الرئيسي</View>
                <View className="UserProfileCVItemFlexText">{authorData?.specializations !== undefined ? authorData?.specializations[0].title : "no data"}</View>
            </View>
            <View className="UserProfileCVItemFlex" style={{width: "65%"}}>
                <View className="UserProfileCVItemFlexTitle">مجالات الكتابة الفرعية</View>
                <View className="UserProfileCVItemFlexText">{authorData?.sub_specializations?.map((item) => {
                    return <span key={item.id}>{item.title+", "}</span>
                })}</View>
            </View>
        </View>
        <SpacerVertical height={"2.143em"}/>
        <View className="UserProfileCVItem">
        
            <View className="UserProfileCVItemFlex">
                <View className="UserProfileCVItemFlexTitle">الخبرة</View>
                <View className="UserProfileCVItemFlexText">{authorData?.experiences !== undefined ? authorData?.experiences[0]?.experience : "no data"}</View>
            </View>
            <View className="UserProfileCVItemFlex">
                <View className="UserProfileCVItemFlexTitle">سنوات الخبرة</View>
                <View className="UserProfileCVItemFlexText">{authorData?.experiences !== undefined ? `${authorData?.experiences[0]?.years_of_experience} سنوات` : "no data"}</View>
            </View>
        </View>
        <SpacerVertical height={"2.143em"}/>
        <View className="UserProfileCVItem">
            <View className="UserProfileCVItemFlex">
                <View className="UserProfileCVItemFlexTitle">التعليم</View>
                <View className="UserProfileCVItemFlexText">{authorData?.education}</View>
            </View>
            <View className="UserProfileCVItemFlex">
                <View className="UserProfileCVItemFlexTitle">مستوى التعليم</View>
                <View className="UserProfileCVItemFlexText">{authorData?.level_of_education}</View>
            </View>
        </View>
        <SpacerVertical height={"2.143em"}/>
        <View className="UserProfileCVItem">
            <View className="UserProfileCVItemFlex">
                <View className="UserProfileCVItemFlexTitle">اللغات</View>
                <View className="UserProfileCVItemFlexText">{authorData?.languages}</View>
            </View>
        </View>
        <SpacerVertical height={"2.143em"}/>
        {/* <Btn title="تعديل"/> */}
    </View>
    )
}