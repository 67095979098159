import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { apiService } from '../../shared/services/api.service';
import { Btn } from '../buttons/btn.component';
import { Icon } from '../icons/icon.component';
import { Loader } from '../loader/loader.component';
import { SpacerHorizontal, SpacerVertical } from '../spacer/spacer.component';
import { Header1, Header3 } from '../typo/header.component';
import { View } from '../view/view.component';

import "./interests.scss"
interface InterestsProps {
    isAuthenticated: boolean
}

export const Interests: FunctionComponent<InterestsProps> = (props) => {

    const {isAuthenticated} = props;
    
    const history = useHistory();    

    const {response, isLoading} = useFetch(() => apiService.interests.getRandomInterests());

    const [interests,setInterests] = useState<any[]>([]);

    useEffect(() => {
        if(!isLoading) {
            setInterests(response?.data);
        }
    }, [isLoading, response]);
    

    return (
        <View className="interests">
            <Container>
                <Header1 title="حلق في فضاء البحث في مجتمع معرفي موثوق."/>
                <Header3 title="اكتشف المواضيع، الأفكار، والخبرات الجديدة لكتاب محترفين في قطاعات عديدة تهمك."/>
                <SpacerVertical height={"2.857em"}/>
                {isLoading ? 
                    <Loader /> :
                    <div className="row">
                    {/* {interests?.map((item : any) => {
                            return(
                                <div 
                                className="interestsContent" 
                                onClick={() => {history.push(`/interests_tabs/${item.id}`)}}
                                key={item.id}
                                style={item.image_url ? { backgroundImage: `url(${item.image_url})`} : { backgroundImage: `url(${item.url})`}}>
                                    <View className="interestsText">
                                        <div>{item.title}</div>
                                    </View>
                                    <View className="blackBgOpcitity"></View>
                                </div>
                            )
                       })}*/}

                       {interests?.map((item) => {
                            return (                                
                                item?.sub_interests?.map((subitem: any) => {                            
                                    return (
                                        <div
                            className="interestsContent"
                            onClick={() => { history.push(`/subinterests_tabs/${subitem.id}`) }}
                            key={subitem.id}
                            style={subitem.image_url ? { backgroundImage: `url(${subitem.image_url})` } : { backgroundImage: `url(${subitem.url})` }}>
                            <View className="interestsText"  onClick={() => { history.push(`/subinterests_tabs/${subitem.id}`) }}>
                                <div>{subitem.title}</div>
                            </View>
                            <View className="blackBgOpcitity"></View>
                        </div>
                                     )
                                })
                            )
                        })}
                                    
   
                      
                </div> 
                }
                <SpacerVertical height={"1.143em"}/>
                {isAuthenticated ? 
                        <View className="moreInterests" onClick={() => {history.push("/interests_landing")}}>
                            <div >استكشاف المزيد</div>
                            <Icon name="iconArrowLeft" />
                        </View>:
                        <>
                        <SpacerVertical height={"0.714em"}/>
                         <View className="interestsRegistration">
                            <Btn title="أنشئ حساباً" onClick={() => {history.push("/main_signup")}}/>
                            <SpacerHorizontal width={"1.974em"}/>
                            <p>لديك حساب؟ <span style={{cursor: "pointer"}} onClick={() => {history.push("/login")}}>اذهب إلى تسجيل الدخول</span></p>
                        </View>
                        </>}
            </Container>
        </View>
    );
  }