import React from 'react';
import { useHistory } from 'react-router';
import { BtnColorless, Btn} from '../buttons/btn.component';
import { View } from '../view/view.component';



export const HaveAccountModal = () => {

    const history = useHistory();
    
    return (
        <View className="WriterWelcomeModal">
           <View className="Header">
           
            </View> 
            <View className="ResetPasswordSubTitle" style={{padding: "35px"}}>
            يرجى تسجيل الدخول أو إنشاء حساب جديد
            </View>
            <View className="Buttons">
                <BtnColorless style={{width:"13.929em !important"}} title="تسجيل الدخول" onClick={() => history.push("/login")}/>
                <Btn title="أنشىء حساباً" onClick={() => history.push("/main_signup")}/>
            </View>
        </View>
    )
}