import React, { FunctionComponent, useState } from "react";
import { View } from "../view/view.component";
import { Header1 } from "../typo/header.component";

import "./user-invites.modal.scss";
import { Col, Row } from "react-bootstrap";
import { Input, InputValue } from "../inputs/input.component";
import { DropDown } from "../dropdown/dropdown.component";
import { Btn, BtnColorless, BtnColorlessMiddleIcons } from "../buttons/btn.component";
import { SpacerVertical } from "../spacer/spacer.component";
import { Icon } from "../icons/icon.component";
import { IFormData } from "../../shared/interfaces";



interface UserInvitesModalProps {
    hadleData: (dataInvites: IFormData[]) => void;
    hideModal: () => void;
}

export const UserInvitesModal: FunctionComponent<UserInvitesModalProps> = (props) => {

    const {hadleData, hideModal} = props;

    const [isDone , setIsDone] = useState(false);
    const [isThx , setIsThx] = useState(false);

    const [formData, setFormData] = useState<IFormData[]>([{
        id: 1,
        name: "",
        email: "",
        interest: "",
        role: ""
    }]);

    const addField = () => {
        setFormData([...formData, {
        id: formData.length+1,
        name: "",
        email: "",
        interest: "",
        role: ""
    }]);
    }
    const onChange = (value: InputValue, idFormData?: number) => {
        const name1 = value.name;
        const value1 = value.value;
        let data = formData.filter((item) => 
            item.id === idFormData
        )
        
        let a = data[0];
        if("name" === name1){
            a["name"] = value1;
        }
        if("email" === name1){
            a["email"] = value1;
        }
        setFormData([...formData]);
    }

    const handleData = () => {
        hadleData(formData);
        hideModal();
    }

    const handleClick = () => {
        if(isDone) {
            setIsThx(true);
            
        }else {
            setIsDone(true);
        }
        console.log(isThx);
        
    }

    const handleInterest = (value: string, idFormData?: number) => {
        let data = formData.filter((item) => 
            item.id === idFormData
        )
        let a = data[0];
        a["interest"] = value;
        setFormData([...formData]);
        
    }
    const handleRole = (value: string, idFormData?: number) => {
        let data = formData.filter((item) => 
            item.id === idFormData
        )
        let a = data[0];
        a["role"] = value;
        setFormData([...formData]);
    }
    
    return ( 
        <View className="UserInvitesModal">
            <View> 
                <Header1 title={isThx ? "شكرا لك" : "دعوة أعضاء فريق جدد"}/>
            </View>
            <View className="UserInvitesModalContent">
                <View className="UserInvitesModalContentText">
                للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. هناك مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي
                </View>
                {(!isDone && !isThx)&& formData.map((item) => {
                    return (
                        <Row className="UserInvitesModalContentForm" key={item.id}>
                            <Col>
                                <Input name="name" placeholder="الاسم" onChange={onChange} idFormData={item.id}/>
                            </Col>
                            <Col>
                                <Input name="email" placeholder="البريد الاكتروني" onChange={onChange} idFormData={item.id}/>
                            </Col>
                            <Col>
                                <DropDown label="" value={item.interest} placeholder="الاهتمام" options={["123", "123", "123"]} onChange={handleInterest} idFormData={item.id}/>
                            </Col>
                            <Col>
                                <DropDown label="" value={item.role} placeholder="الدور" options={["123", "123", "123"]} onChange={handleRole} idFormData={item.id}/>   
                            </Col>
                        </Row>
                    )
                })}
                {(isDone && !isThx )&& formData.map((item) => {
                    return (
                        <Row className="UserInvitesModalContentForm" key={item.id}>
                            <Col>
                                <View>{item.name}</View>
                            </Col>
                            <Col>
                            <View>{item.email}</View>
                            </Col>
                            <Col>
                            <View>{item.interest}</View>
                            </Col>
                            <Col>
                            <View>
                                {item.role}
                                <Icon name="iconGrayCircle"/>
                                {item.role}
                                <Icon name="iconGrayCircle"/>
                                {item.role}
                            </View>
                            </Col>
                        </Row>
                    )
                })}
                {isThx && 
                    <View className="UserInvitesModalContentText">
                    للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. هناك مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي
                    </View>
                }
                {!isDone && <BtnColorlessMiddleIcons title="اضف المزيد" onClick={addField}/>}
                <SpacerVertical height={"2.286em"}/>
            </View>
            <View className="buttons">
                {!isThx && <Btn title="ارسل الدعوات" onClick={handleClick}/>}
                <BtnColorless title="إلغاء" onClick={handleData}/>
            </View>
        </View>
    )
}