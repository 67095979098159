import React, { FunctionComponent, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { AppFooter } from "../../components/app-footer/app-footer.component";
import { AppHeader } from "../../components/app-header/app-header.component";
import { BtnMiddleIcons } from "../../components/buttons/btn.component";
import { useModal } from "../../components/modal/modal.component";
import { SpacerVertical } from "../../components/spacer/spacer.component";
import { Header1, Header2 } from "../../components/typo/header.component";
import { UserInvitesModal } from "../../components/user-invites-modal/user-invites.modal";
import { View } from "../../components/view/view.component";
import { IFormData } from "../../shared/interfaces";
import { UserInvitesTable } from "./nested/user-invites-table.component";

import "./user-invites.scss";

const dataTable = [
    {
        id: 42585248,
        name: "رامي بدرية",
        email: "nathan.roberts@example.com",
        interest: "تكنولوجيا",
        role: ["كاتب","محرر"],
        date: "2020/9/5",
        status: "قيد الانتظار",
        isButton: true
    },
    {
        id: 42512452148,
        name: "رامي بدرية",
        email: "nathan.roberts@example.com",
        interest: "تكنولوجيا",
        role: ["كاتب","محرر"],
        date: "2020/9/5",
        status: "قيد الانتظار",
        isButton: true
    },
    {
        id: 42125125248,
        name: "عبداللطيف بن عبدالله",
        email: "nathan.roberts@example.com",
        interest: "تكنولوجيا",
        role: ["كاتب","محرر", "محرر"],
        date: "2020/9/5",
        status: "قيد الانتظار",
        isButton: true
    },
    {
        id: 425836346,
        name: "رامي بدرية",
        email: "nathan.roberts@example.com",
        interest: "تكنولوجيا",
        role: ["كاتب","محرر"],
        date: "2020/9/5",
        status: "قيد الانتظار",
        isButton: true
    },
    {
        id: 42532468,
        name: "رامي بدرية",
        email: "nathan.roberts@example.com",
        interest: "تكنولوجيا",
        role: ["كاتب","محرر"],
        date: "2020/9/5",
        status: "رفضت",
        isButton: false
    },
    {
        id: 425327243748,
        name: "رامي بدرية",
        email: "nathan.roberts@example.com",
        interest: "تكنولوجيا",
        role: ["كاتب","محرر"],
        date: "2020/9/5",
        status: "تم القبول",
        isButton: false
    },
]

const tableCellsWidth = ["15%","20%","10%","13%","10%","10%","15%"];

export const UserInvitesPage: FunctionComponent = () => {

    const {hideModal, showModal, RenderModal} = useModal();

    const [data, setData] = useState(dataTable);

    useEffect(() => {
        setData(dataTable);
    }, []);

    const handleChange = () => {

    }

    const hadleData = (dataInvites: IFormData[]) => {
       
        const _data = dataInvites.map((item) => {
            return {
                ...item,
                role: [item.role],
                status: "قيد الانتظار",
                date: "2020/11/12",
                isButton: false
            }
        })
        setData([...data, ..._data]);
    }


    return (
        <View className="App">
            <AppHeader />
            <Container>
                <View className="UsersInvites">
                    <SpacerVertical height={"4.571em"}/>
                    <View className="UsersInvitesHeaders">
                        <Header1 title="دعوة أعضاء فريق جدد"/>
                        <Header2 title="مقالات علمية. صوات غير مكتشفة. المواضيع التي تحبها."/>
                    </View>
                    <SpacerVertical height={"2.286em"}/>
                    <View className="UsersInvitesContent">
                        <BtnMiddleIcons title="اضف المزيد من الاعضاء" onClick={showModal}/>
                        <SpacerVertical height={"3.143em"}/>
                        <View className="UsersInvitesContentTable">
                            <View style={{width: tableCellsWidth[0]}}>
                            الاسم
                            </View>
                            <View style={{width: tableCellsWidth[1]}}>
                            البريد الاكتروني
                            </View>
                            <View style={{width: tableCellsWidth[2]}}>
                            الاهتمام
                            </View>
                            <View style={{width: tableCellsWidth[3]}}>
                            الدور
                            </View>
                            <View style={{width: tableCellsWidth[4]}}>
                            التاريخ
                            </View>
                            <View style={{width: tableCellsWidth[5]}}>
                            الحالة
                            </View>
                            <View style={{width: tableCellsWidth[6]}}>
                                
                            </View>
                        </View>
                        <UserInvitesTable dataTable={data} tableCellsWidth={tableCellsWidth} handleChange={handleChange}/>
                    </View>
                </View>
                <SpacerVertical height={"10.286em"}/>
            </Container>
            <AppFooter />
            <RenderModal style={{padding: 0}}> 
                <UserInvitesModal hadleData={hadleData} hideModal={hideModal}/>
            </RenderModal>
        </View>
    )   
}