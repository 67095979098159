import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import { Widget } from '@typeform/embed-react'
// import JotformEmbed from 'react-jotform-embed';
// import {JotFormReact} from 'jotform-react';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
// import { Btn } from '../../../components/buttons/btn.component';
import { Icon } from '../../../components/icons/icon.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
import shareBG from '../../../assets/images/StaticPages/SharePage/shareBG.png';
// import { DropDown } from '../../../components/dropdown/dropdown.component';
// import shareLocation from "../../../assets/images/StaticPages/SharePage/shareLocation.jpg";

import "./share.scss";
import { SpacerVertical } from '../../../components/spacer/spacer.component';

export const SharePage: FunctionComponent = () => { 

    // const [isActive, setIsActive] = useState(false);
    // const [value, setValue] = useState("اختروا السبب");
    
    const [fb, setFB] = useState(false);
    const [yb, setYB] = useState(false);
    const [inst, setInst] = useState(false);
    const [li, setLI] = useState(false);
    const [tw, setTW] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    
    // useEffect(() => {
    //     const shareForm = document.getElementById('ShareForm');
    //     const script = document.createElement("script");
    //     script.src = "https://form.jotform.com/jsform/222004271078446";
    //     shareForm.appendChild(script);
    // }, []);

    return (
        <View className="App">
            <AppHeader isWhite={true}/>
            <View className="CareersPage">
                <View className="CareersPageHeader" title="careers_photo" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(${shareBG})`}}>
                    <Container style={{marginTop: "96px"}}>
                        <View className="CareersPageHeaderContent">
                            <Header1 title="معاً نتواصل" className="header1"/>
                        </View>
                        <View className="CareersPageHeaderContent">
                            <Icon name="iconMawsuahLogo"/>
                            <span className="bigText">موسوعة</span>
                            <Icon name="iconVerifiedSmallWhiteBG"/>
                        </View>
                        {/* <View className="SharePageDropDown">
                            <DropDown label="" value={value} options={["محتوى غير متوفر","الإبلاغ عن خطأ", "مشاكل في الحساب", "استفسار عام", "تعاون وشراكات"]} onChange={(v) => {
                                // setIsActive(true);
                                setValue(v);
                            }}/>
                            <Btn title="قدم"/>
                        </View> */}
                    </Container>
                </View>
                <SpacerVertical height={"4.5em"}/>
                <View className="CareersPageContent">
                    <Container>
                        <Row>
                            <Col lg={3} className="CareersPageContentSecondColumn">
                            </Col>
                            <Col className="CareersPageContentFirstColumn" lg={9}>
                                <View id="ShareForm"></View>
                               {/* {isActive && <Widget id="uGqnuEJX" style={{ height: '700px' }} className="my-form" />} */}
                               {/* <script type="text/javascript" src="https://form.jotform.com/jsform/222004271078446"></script> */}
                                                              
                               {/* <View className="ShareForm">
                                    <InputObligatory name="input1" title="" placeholder="الاسم بالكامل"/>
                                    <InputObligatory name="input2" title="" placeholder="الرجاء إدخال البريد الإلكتروني"/>
                                    <InputObligatory name="input3" title="" placeholder="رقم الاتصال"/>
                                    <textarea placeholder="ابدأ في الكتابة"/>
                                    <View className="ShareFormButton">
                                        <span>سنأخذ رسالتك بأعلي درجات الاهتمام</span>
                                        <Btn title="إرسال"/>
                                    </View>
                                </View> */}
                                <Header3 className="writersHeader" title="نسعد بتواصلكم دومًا"/>
                                <div className="smallLine"></div>
                                <View className="CareersPageContentFirstColumnContent">
                                <View className="smallHeader">
                                لأن تواجدكم هدفنا وتفاعلكم غايتنا، ستجدوننا معكم عبر منصات مختلفة لتكونوا أقرب إلينا في كل زمان ومكان.
تواصل معنا بما يهمّك، فبالتواصل نستمر معاً إلى أبعد مدى.
                                </View>
                                <div data-paperform-id="lerml4jj"></div>
                                <SpacerVertical height={"3.571em"}/>
                                </View>
                                <View className="ShareSocials">
                                    <View className="ShareSocialsItem" onClick={() => {window.open("https://www.youtube.com/channel/UC2WLwrNgOft-zgVeJsDG2eQ")}} 
                                    onMouseEnter={() => {
                                        setYB(true)
                                      }} 
                                      onMouseLeave={() => {
                                        setYB(false)
                                      }}>
                                        <Icon name={ yb ? "iconYoutubeHoverShare"  : "iconYoutubeShare"}/>
                                    </View>
                                    <View className="ShareSocialsItem" onClick={() => {window.open("https://www.instagram.com/mawsuah")}}
                                    onMouseEnter={() => {
                                        setInst(true)
                                      }} 
                                      onMouseLeave={() => {
                                        setInst(false)
                                      }}>
                                        <Icon name={inst ? "iconInstgramHoverShare"  : "iconInstgramShare"}/>
                                    </View>
                                    <View className="ShareSocialsItem" onClick={() => {window.open("https://www.facebook.com/mawsuah")}}
                                    onMouseEnter={() => {
                                        setFB(true)
                                      }} 
                                      onMouseLeave={() => {
                                        setFB(false)
                                      }}>
                                        <Icon name={fb ? "iconFacebookHoverShare"  : "iconFacebookShare"}/>
                                    </View>
                                    <View className="ShareSocialsItem" onClick={() => {window.open("https://www.linkedin.com/company/mawsuah")}}
                                    onMouseEnter={() => {
                                        setLI(true)
                                      }} 
                                      onMouseLeave={() => {
                                        setLI(false)
                                      }}>
                                        <Icon name={li ? "iconLinkedinHoverShare"  : "iconLinkedinShare"}/>
                                    </View>
                                    <View className="ShareSocialsItem" onClick={() => {window.open("https://twitter.com/mawsuah")}}
                                     onMouseEnter={() => {
                                        setTW(true)
                                      }} 
                                      onMouseLeave={() => {
                                        setTW(false)
                                      }}>
                                        <Icon name={tw ? "iconTwitterHoverShare"  : "iconTwitterShare"}/>
                                    </View>
                                </View>
                                <SpacerVertical height={"3.571em"}/>
                                <Header3 className="writersHeader" title="موقع مكتبنا"/>
                                <div className="smallLine"></div>
                                <View className="ShareLocation">
                                    {/* <img src={shareLocation} alt="location"/> */}
                                    <iframe title={"Location"} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6769.227111156781!2d35.83262841267393!3d31.971379036189422!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151ca17ed1965e8d%3A0xd93a3913affa5945!2sKing%20Hussein%20Business%20Park!5e0!3m2!1sen!2sjo!4v1657993887082!5m2!1sen!2sjo" width={"825"} height={"450"} style={{border:0}} loading={"lazy"}></iframe>
                                </View>
                                <SpacerVertical height={"2.357em"}/>
                                <View className="ShareLocationText"><span>الأردن، عمان، مجمع الملك حسين للأعمال</span></View>
                                <SpacerVertical height={"5.214em"}/>
                            </Col>
                        </Row>
                    </Container>
                    <SpacerVertical height={"3.3em"}/>
                </View>
            </View>
            <AppFooter />
        </View>
    )
}