import React, { FunctionComponent } from "react";
import { BtnColorless } from "../../../components/buttons/btn.component";
import { Icon } from "../../../components/icons/icon.component";
import { View } from "../../../components/view/view.component";

interface UserInvitesTableProps {
    dataTable: {
        name: string,
        email: string,
        interest: string,
        role: string[],
        date: string,
        status: string,
        isButton: boolean
    }[];
    tableCellsWidth: string[];
    handleChange?: () => void;
}

export const UserInvitesTable: FunctionComponent<UserInvitesTableProps> = (props) => {

    const { dataTable, tableCellsWidth } = props;

    return (<>
        { dataTable.map((item, i) => {
            return (
                <View style={i%2 === 0 ? {background: "rgb(237,237,237, 0.25)"} : {}} className="UsersInvitesContentTableCells" key={Math.random() * 100000}>
                    <View className="name" style={{width: tableCellsWidth[0],  paddingRight: "1.143em"}}>
                    {item.name}
                    </View>
                    <View className="name" style={{width: tableCellsWidth[1]}}>
                    {item.email}
                    </View>
                    <View style={{width: tableCellsWidth[2]}}>
                    {item.interest}
                    </View>
                    <View className="icons" style={{width: tableCellsWidth[3]}}>
                    {item.role.map((item) => {
                        return(
                            <View className="icons" key={Math.random() * 100000}>
                            {item}
                            <Icon name="iconGrayCircle"/>
                            </View>
                        )
                    })}
                    </View>
                    <View style={{width: tableCellsWidth[4]}}>
                    {item.date}
                    </View>
                    <View className="name" style={item.status === "تم القبول" ? 
                    {color: "var(--success)" ,width: tableCellsWidth[5]} :
                    item.status === "رفضت" ? 
                    {color: "var(--alert)" ,width: tableCellsWidth[5]} : {width: tableCellsWidth[5]}
                    }>
                    {item.status}
                    </View>
                    
                    <View style={{width: tableCellsWidth[6]}}>
                    {item.status === "قيد الانتظار" &&
                        <BtnColorless title="إلغاء الدعوى"/>
                    }
                    </View>
                  
            </View>
            )
        })}
        </>
        
    )
}