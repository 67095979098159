import React, { FunctionComponent, useState } from "react";
import ReactDOM from "react-dom";

import { Icon } from "../icons/icon.component";

import "./modal.scss";

interface ModalProps {
  closeModal: () => void;
  style?: any;
}

export const Modal: FunctionComponent<ModalProps> = (props) => {
  const { closeModal, children, style } = props;

  return ReactDOM.createPortal(
    <div className="Modal">
      <div className="ModalBg">
        <div className="ModalBody" style={style}>
          <div className="ModalClose" onClick={closeModal}>
            <Icon name="iconClose" />
          </div>
          {children}
        </div>
      </div>
    </div>,
    document.querySelector("#modal-root") as Element
  );
};

export const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [withProps, setWithProps] = useState(false);
  const [data, setData] = useState<any>();

  const showModal = (data ?: any) =>{
      if(data) {
        setWithProps(true);
        setData(data);
        setIsVisible(true);
      } 
      setIsVisible(true);
    };
  const hideModal = () => setIsVisible(false);
  
  const RenderModal = ({ children, style }: { children: React.ReactChild , style?: any} ) => {
    if(withProps) {
      return (
        <React.Fragment>
      {isVisible && <Modal closeModal={hideModal} style={style}>{
      React.Children.map(children,
        child => {
          return React.cloneElement(child as any,
          {newProp: data}, null);
      })}
      </Modal>}
    </React.Fragment>
      )
    }
    return (
      <React.Fragment>
      {isVisible && <Modal closeModal={hideModal} style={style}>{children}</Modal>}
    </React.Fragment>
    )
  }

  return { showModal, hideModal, RenderModal}
}