import React, { FunctionComponent, } from "react";
import { Container } from "react-bootstrap";
import { AppFooter } from "../../components/app-footer/app-footer.component";
import { AppHeader } from "../../components/app-header/app-header.component";
import { SpacerVertical } from "../../components/spacer/spacer.component";
import { View } from "../../components/view/view.component";
import { UserProfileAuthNotifications } from "../user-profile-auth/nested/user-profile-auth-notifications/user-profile-auth-notifications.component";

import "./notifications.scss";


export const UserNotificationPage: FunctionComponent = () => {


    return (
        <View className="App">
            <AppHeader />
            <Container>
                <View className="Notifications">
                    <SpacerVertical height={"4.571em"}/>
                    <UserProfileAuthNotifications/>
                </View>
                <SpacerVertical height={"10.286em"}/>
            </Container>
            <AppFooter />
        </View>
    )   
}