import React, { FunctionComponent, useEffect, useState } from "react";
import { Btn} from "../../../../components/buttons/btn.component";
import { Loader } from "../../../../components/loader/loader.component";
import { SpacerVertical } from "../../../../components/spacer/spacer.component";
import { SuggestedArticlesItem } from "../../../../components/suggested-articles/nested/suggested-articles-item.component";
import { View } from "../../../../components/view/view.component";
import { useFetch } from "../../../../shared/hooks/fetch.hook";
import { ArtcileInfo, AuthorData } from "../../../../shared/interfaces";
import { apiService } from "../../../../shared/services/api.service";
import { activeClass, getUserId } from "../../../../shared/utils";
import { UserProfileCV } from "../../../user-profile/nested/user-profile-CV.component";
import { UserProfileAuthInfo } from "../user-profile-auth-info/user-profile-auth-info.component";



export const UserProfileAuthProfile : FunctionComponent = () => {

    const {response: authorDataResponse, isLoading: authorDataLoading} = useFetch(() => apiService.user.getCurrentUserProfile(getUserId()));
    const {response: articlesResponse, isLoading: articlesLoading} = useFetch(() => apiService.articles.getNewestArticlesByAuthorId(getUserId(), 0, 10));
    

    const [isArticles, setIsArticles] = useState(false);
    const [isCV, setIsCV] = useState(true);
    const [statusFilter, setStatusFilter] = useState('Newest');
    const [authorData, setAuthorData] = useState<AuthorData | null>(null);
    const [articlesData, setArticlesData] = useState<ArtcileInfo[]>([]);  

    useEffect(() => {
        if (!authorDataLoading) {
            setAuthorData(authorDataResponse?.data)
        }
    }, [authorDataResponse, authorDataLoading]);

    useEffect(() => {
        if(!articlesLoading) {
            setArticlesData(articlesResponse?.data?.articles.slice(0,10))
        }
    }, [articlesLoading, articlesResponse])

    const handleFilter = (status : string) => {
        if(status === "Newest") {
            setStatusFilter("Newest");
        }
        if(status === "MostRead") {
            setStatusFilter("MostRead");
        }
        if(status === "MostEngaged") {
            setStatusFilter("MostEngaged");
        }
    }

    const getMore = () => {
        apiService.articles.getNewestArticlesByAuthorId(getUserId(), articlesData.length, 10)
            .then(r => {
                if(r.data.articles !== undefined && r.data.articles !== null) {
                    setArticlesData([...articlesData, ...r.data.articles]);
                }
            })
    }

    return (
        <>
        {!authorDataLoading ? <UserProfileAuthInfo authorData={authorData}/> : <Loader/>}
            <SpacerVertical height={"2.286em"} />
            <View className="searchPageMenu">
                {authorData?.role !== "Reader" &&
                <View className={activeClass("searchPageMenuItem", isCV)} 
                    onClick={() => {
                        setIsArticles(false);
                        setIsCV(true);
                    }}>
                سيرتي الذاتية
                </View>}
                {authorData?.role?.indexOf("Writer") !== -1 && <View className={activeClass("searchPageMenuItem", isArticles)}
                    onClick={() => {
                        setIsArticles(true);
                        setIsCV(false);
                    }}>
                مقالات  
                </View>}               
            </View>
            {authorData?.role !== "Reader" && <><SpacerVertical height={"2em"} />
            {isArticles &&
             <View className="searchPageFilter">
                <View className={statusFilter === "Newest" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("Newest")}>
                الأحدث 
                </View>
                <View className={statusFilter === "MostRead" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostRead")} >
                الأكثر تصفحاً
                </View>
                <View className={statusFilter === "MostEngaged" ? "searchPageFilterItem Active" : "searchPageFilterItem"} onClick={() => handleFilter("MostEngaged")}>
                الأكثر تفاعلاً
                </View>
            </View>}
            <SpacerVertical height={"3.143em"} />
            <View className="searchPageContent">
                {isArticles && 
                <>
                    {!articlesLoading ? 
                        articlesData?.map((item) => {
                        return (<SuggestedArticlesItem 
                                    created_at={item.created_at}
                                    toSave
                                    key={item.id}
                                    title={item.title}
                                    header={item.header}
                                    image_url={item.image_url}
                                    interest={item.interest}
                                    author={item.author}
                                    published_at={item.published_at}
                                    minutes_for_reading={item.minutes_for_reading}
                                    articleId={item.id}
                                    status={item?.status?.name}
                                    isSaved={item.is_saved}
                                    updated_at={item.updated_at}
                                />)
                    }) : <Loader/>}
                   
                </>}
                    {isCV &&
                        <UserProfileCV  authorData={authorData}/>
                    }
                {!isCV && <Btn title="استكشف أكثر" className="searchPageContentBtn" onClick={getMore}/>}
            </View></>}
        </>    
    )
}