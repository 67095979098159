import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthorData, InterestsData } from '../../../shared/interfaces';
import { apiService } from '../../../shared/services/api.service';
import { getDate, getUserId } from '../../../shared/utils';
import { Icon } from '../../icons/icon.component';
import { SpacerVertical } from '../../spacer/spacer.component';
import { View } from '../../view/view.component';

import "../suggested-articles.scss"

interface SuggestedArticlesItemProps {
    opacity?: number;
    toSave?: boolean;
    title?: string;
    header?: string;
    image_url?: string;
    interest?: InterestsData;
    author?: AuthorData;
    created_at?: string;
    published_at?: string;
    minutes_for_reading?: number;
    articleId?: number;
    status: string;
    isSaved?: boolean;
    correctionId ?: number;
    updated_at?: string | undefined;
}

export const SuggestedArticlesItem: FunctionComponent<SuggestedArticlesItemProps> = (props) => {

    const {published_at, status, opacity, toSave, author, title, header, interest, image_url, created_at, minutes_for_reading, articleId, isSaved, correctionId, updated_at} = props;

    const [saved, setSaved] = useState(isSaved);
    const [isAuth, setIsAuth] = useState(false);
    const [userRole, setUserRole] = useState("");
    const [userID] = useState(getUserId());
    // const [isFollowed, setIsFollowed] = useState(false);

    useEffect(() => {
        if(localStorage.getItem("mawsuah_user_data")) {
            const user = JSON.parse(localStorage.getItem("mawsuah_user_data") as string);
            setUserRole(user.role.name);
        } 
        setIsAuth(apiService.auth.isAuthenticated());
    }, [])
    

    const handleSave = () => {
        if(articleId) {
            if(!saved) {
                apiService.articles.addArticleToSave(articleId)
                .then(r => {
                    setSaved(!saved);
                });
            } else {
                apiService.articles.removeArticleFromSaved(articleId)
                .then(r => {
                    setSaved(!saved);
                });
            }
            
        }
    }

    // const handleFollowInterest = () => {
    //     if(!isFollowed) {
    //         apiService.interests.subscribeOnInterest(getUserId(), interest?.id)
    //             .then(r => setIsFollowed(true))
    //     } else {
    //         apiService.interests.unSubscribeFromInterest(getUserId(), interest?.id)
    //             .then(r => setIsFollowed(false))
    //     }
    // }

    const history = useHistory();

    return(
        <View className="SuggestedArticlesItem" style={{opacity: opacity}}>
            <View className="SuggestedArticlesItemContent">
                <View className="SuggestedArticlesItemTitle" onClick={() => {
                    if(status !== "PUBLISHED" && status !== "ON_EDITING" && status !== "ON_REVIEW" && userRole !== "REVIEWER" && status !== "REJECTED" && status !== "ON_CORRECTING" && userRole !== "EDITOR" && author?.id === userID) {
                        history.push(`/draft_article/${articleId}`)
                    } 
                    if(status === "ON_CORRECTING" && (userRole === "EDITOR" || userRole === "WRITER_EDITOR" || userRole === "REVIEWER_EDITOR") && author?.id !== userID) {
                        history.push(`/article_editor/${articleId}/correction/${correctionId}`)
                    }
                    if((userRole === "REVIEWER" && status === "ON_REVIEW") || status === "APPROVED"){
                       // history.push(`/article_review/${articleId}`)
                    }else if(status === "PUBLISHED"){
                        history.push(`/article/${articleId}`)
                    }
                    }}>{title} </View>
                <SpacerVertical height={"0.571em"} />
                <View className="SuggestedArticlesItemText" onClick={() => {
                    if(status !== "PUBLISHED" && status !== "ON_REVIEW" && userRole !== "REVIEWER" && status !== "REJECTED" && status !== "ON_CORRECTING" && userRole !== "EDITOR" && author?.id === userID) {
                        history.push(`/draft_article/${articleId}`)
                    } 
                    if(status === "ON_CORRECTING" && (userRole === "EDITOR" || userRole === "WRITER_EDITOR" || userRole === "REVIEWER_EDITOR") && author?.id !== userID) {
                        history.push(`/article_editor/${articleId}/correction/${correctionId}`)
                    }
                    if((userRole === "REVIEWER" && status === "ON_REVIEW") || status === "APPROVED"){
                        //history.push(`/article_review/${articleId}`)
                    } else if(status === "PUBLISHED"){
                        history.push(`/article/${articleId}`)
                    }
                }}>{header || "here should be header"}
                </View>
                <SpacerVertical height={"0.571em"} />
                <View className="SuggestedArticlesPhoto">
                    <View className={"authorPhoto " +  
                    (((userRole === "REVIEWER" || userRole === "WRITER_REVIEWER") &&  status === "ON_REVIEW") ? 'blurText' : 'nothing')
                } onClick={() => history.push(`/user_profile/${author?.id}`)} >
                        <Icon src={author?.image_url} name="iconProfilePlaceholder" alt="author_profile_photo" className="suggestedArticlePhoto32"/>
                        <span>{author?.first_name} {author?.last_name}</span>
                        <Icon style={{marginRight: "0.179em"}} name="iconVerifiedSmall"/>
                        {/* <View className="authorPhotoPopup">
                            <SpacerVertical height={"1.429em"}/>
                            <View className="Flex">
                                <View style={{margin: "0px"}}>
                                    <Icon className="authorPhotoPopupAuthorPhoto" src={author?.image_url} name="iconProfilePlaceholder"  alt="author_profile_photo"/>
                                </View>
                                <View className="authorPhotoPopupContent">
                                    <View className="authorPhotoPopupContentTitle">
                                        <span>{author?.first_name} {author?.last_name} </span> 
                                        <Icon name="iconVerifiedLarge"/>
                                    </View>
                                    <SpacerVertical height={"0.321em"}/>
                                    <View className="authorPhotoPopupContentSubTitle">
                                     كاتب و قائد في تكنولوجيا    
                                    </View>
                                    <SpacerVertical height={"0.607em"}/>
                                    <View className="authorPhotoPopupContentSubTitle">
                                        {author?.followers_count || 0} <span>مقال </span>
                                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                                        {author?.articles_count || 0} <span>متابَع</span>
                                    </View>
                                    <SpacerVertical height={"0.571em"}/>
                                    <BtnColorless title="تابِع"/>
                                </View>
                            </View>
                        </View> */}
                    </View>    
                    <View className="article">
                        <span style={{cursor: "pointer"}} onClick={() => {history.push(`/interests_tabs/${interest?.id}`)}}>{interest?.title}</span>
                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                        <span style={{fontWeight: "normal"}}>{published_at ? getDate(published_at as string) : updated_at ? getDate(updated_at as string) : getDate(created_at as string)}</span>
                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                        {minutes_for_reading} دقائق قراءة
                        {(toSave && isAuth) && <Icon style={{cursor: "pointer"}} alt="bookmark_picture" name={saved ? "iconBookmarkRoundedBlack" : "iconBookmarkRounded"} onClick={handleSave} />}
                        {/* <View className="articlePopup">
                            <SpacerVertical height={"1.286em"}/>
                            <View className="Flex">
                                <View style={{margin: "0px"}}>
                                    <Icon src={interest?.image_url} className="iconPhoto72" alt="author_profile_photo" name="iconTrendingMawsuah"/>
                                </View>
                                <View className="articlePopupContent">
                                    <View className="articlePopupContentTitle" style={{cursor: "pointer"}} onClick={() => {history.push(`/interests_tabs/${interest?.id}`)}}>
                                    {interest?.title}    
                                    </View>
                                    <SpacerVertical height={"0.607em"}/>
                                    <View className="articlePopupContentSubTitle">
                                        {interest?.followers_count || 0} <span>مقال </span>
                                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                                        {interest?.authors_count || 0} <span>كاتب </span>
                                        <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                                        {interest?.articles_count || 0} <span>متابَع</span>
                                    </View>
                                    <SpacerVertical height={"0.571em"}/>
                                    <BtnColorless
                                     title={isFollowed ? "متابَع" : "تابع"} 
                                     style={isFollowed ? {backgroundColor: "var(--blueColor)", color:"var(--white)", border: "0px"} : {}}
                                     onClick={handleFollowInterest}/>
                                </View>
                            </View>
                        </View> */}
                    </View>
                </View>
            </View> 
            <View onClick={() => {
                    if(status !== "PUBLISHED"  && status !== "ON_EDITING" && status !== "ON_REVIEW" && userRole !== "REVIEWER" && status !== "REJECTED" && status !== "ON_CORRECTING" && userRole !== "EDITOR" && author?.id === userID) {
                        history.push(`/draft_article/${articleId}`)
                    } 
                    if(status === "ON_CORRECTING" && (userRole === "EDITOR" || userRole === "WRITER_EDITOR" || userRole === "REVIEWER_EDITOR") && author?.id !== userID) {
                        history.push(`/article_editor/${articleId}/correction/${correctionId}`)
                    }
                    if((userRole === "REVIEWER" && status === "ON_REVIEW") || status === "APPROVED"){
                        //history.push(`/article_review/${articleId}`)
                    } else if(status === "PUBLISHED"){
                        history.push(`/article/${articleId}`)
                    }
                }}>
                <Icon className="SuggestedArticlesIcons" src={image_url} name="iconChosenTopicsIcon" alt={title}/>
            </View>    
        </View>
    )
}