import React, { FunctionComponent,  PropsWithChildren,  useCallback,  useEffect,  useRef,  useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { Icon } from '../../components/icons/icon.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { Header1, Header2, Header3 } from '../../components/typo/header.component';
import { View } from '../../components/view/view.component';
import { SuggestedArticlesItem } from '../../components/suggested-articles/nested/suggested-articles-item.component';
import { useHistory, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { cx, css } from '@emotion/css';
import { apiService } from '../../shared/services/api.service';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { ArtcileInfo, AuthorData, References } from '../../shared/interfaces';
import { Loader } from '../../components/loader/loader.component';
import highlighter from "../../assets/images/UserProfileAuth/Notifications/highlighter.svg"
import { nanoid } from 'nanoid';
import { getDate, getUserId, getUserRole, objToRange, rangeToObj, serialize } from '../../shared/utils';
import { BtnColorless } from '../../components/buttons/btn.component';
import { useOnClickOutside } from '../../shared/hooks/click-outside.hook';
import { useModal } from '../../components/modal/modal.component';
import { AlertModal } from '../../components/alert-modal/alert-modal.component';

import "./article.scss";
import { HaveAccountModal } from '../../components/have-account-modal/have-account.modal';
import { Seo } from "../../components/seo/seo.component";



export const ArticlePage: FunctionComponent = () => {
    
    const location: any = useLocation();

    // const refPopUpMore = useRef(null);
    const refPopUpShare = useRef(null);
    // const refSubPopUpMore = useRef(null);
    const refSubPopUpShare = useRef(null);

    // const {showModal:showModalOldestVersion, RenderModal: RenderModalOldestVersion, hideModal: hideModalOldestVersion} = useModal();
    const {RenderModal: RenderModalArticleSuccess, hideModal: hideModalArticleSuccess} = useModal();
    const {RenderModal: RenderModalAlreadyShared, hideModal: hideModalAlreadyShared} = useModal();
    const {RenderModal: RenderModalError, hideModal: hideModalError} = useModal();
    const {showModal: showHaveAccountModal, RenderModal: RenderHaveAccountModal} = useModal();
    
    useEffect(() => {
      setCurrentArticleLoading(true);
      window.scrollTo(0,0);
      apiService.articles.getPublishedArticleById(location.pathname.split('/')[2], apiService.auth.isAuthenticated())
        .then((r) => {
          setResponseArticle(r);
          setCurrentArticleLoading(false);
        })
        if(apiService.auth.isAuthenticated()) {
          apiService.comments.getHighlightsByArticleId(location.pathname.split('/')[2])
        .then((r)=> setResponseHighlights(r))
        }
    }, [location.pathname])

    // useOnClickOutside(refPopUpMore, () => setIsPopUpMore(false));
    useOnClickOutside(refPopUpShare, () => setIsPopUpShare(false));
    // useOnClickOutside(refSubPopUpMore, () => setIsSubPopUpMore(false));
    useOnClickOutside(refSubPopUpShare, () => setIsSubPopUpShare(false));

    const {response: responseArticleInfo} = useFetch(() => apiService.articles.getArticleInfo(+location.pathname.split('/')[2]));
    const [CurrentArticleLoading, setCurrentArticleLoading] = useState(true);
    const [responseArticle, setResponseArticle] = useState<any>();
    const [responseHighlights, setResponseHighlights] = useState<any>();
    const [reviewerName, setReviewerName] = useState("");
    const [reviewerId, setReviewerId] = useState("");
    const [reviewerImageUrl, setReviewerImageUrl] = useState("");
    
    useEffect(() => {
      setReviewerId(responseArticleInfo?.data?.reviews[0]?.reviewer.id);
      setReviewerName(responseArticleInfo?.data?.reviews[0]?.reviewer.first_name + " " +
      responseArticleInfo?.data?.reviews[0]?.reviewer.last_name );
      setReviewerImageUrl(responseArticleInfo?.data?.reviews[0]?.reviewer.image_url);
    }, [responseArticleInfo])

    const [articleData, setArticleData] = useState<any[]>([]);
    const [articleTitle, setArticleTitle] = useState("");
    const [articleImage, setArticleImage] = useState("");
    const [header, setHeader] = useState("");
    const [created, setCreated] = useState("");
    const [minutesForReading, setMinutesForReading] = useState(0);
    const [authorInterest, setAuthorInterest] = useState("");
    const [likesCount, setLikesCount] = useState(0);
    const [viewsCount, setViewsCount] = useState(0);
    const [authorData, setAuthorData] = useState<AuthorData>();
    const [authorFollowed, setAuthorFollowed] = useState(false)
    const [references, setReferences] = useState<References[]>([]);
    const [subInterests, setSubInterests] = useState<any[]>([]);
    const [isSaved, setIsSaved] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [randomArticles, setRandomArticles] = useState<ArtcileInfo[]>([]);

    const [isPopUpShare, setIsPopUpShare] = useState(false);
    // const [setIsPopUpMore] = useState(false);
    const [isSubPopUpShare, setIsSubPopUpShare] = useState(false);
    // const [setIsSubPopUpMore] = useState(false);
    const [, setData] = useState([""]);
    const [highlight, setHighlight] = useState<any[]>([]);
   
    const [selectArray, setSelectArray] = useState<any[]>([]);
    //oldest version state
    const [isOldestVersion, setIsOldestVersion] = useState(false);
    // const [oldestVersionData, setOldestVersionData] = useState("");

    const cmmentId = useRef(0);

    const history = useHistory();

    useEffect(() => {
        document?.getElementById('ArticleContent')?.addEventListener("mouseup", function(e){
            if(e && getSelectionText() !== "") {
                e.preventDefault();
                }
            });
    }, []);

    const newSpan = document.createElement("span");
    newSpan.id = "demo";
    newSpan.style.display = "block";
    newSpan.classList.add("HighlitedTextArticleReviewPopUp");
    newSpan.innerHTML = `<img src=${highlighter} alt="gergergerg"></img>`
    newSpan.style.position = "absolute";
    newSpan.style.top = `-${40}px`;
    newSpan.style.left = `40%`;  //${item.scrollWidth / 2 - 50}px
    newSpan.style.width = "50px";
    newSpan.style.height = "50px";

    const listenerClick = (e: any) => {
      const mark = e.target.parentNode.parentNode;
      if(mark?.textContent && mark?.parentNode) {
        const str = mark.parentNode.innerHTML
        const findStr = `<span id="${mark.id}" class="HighlitedText">`;
        const findStr1 = `<span id="demo" class="HighlitedTextArticleReviewPopUp" style="display: block; position: absolute; top: -40px; left: 40%; width: 50px; height: 50px;"><img src="/static/media/highlighter.2b84f90d.svg" alt="gergergerg"></span>`
        
        
        const c = str?.indexOf(findStr);
        const f = str?.slice(0,c)
        const v = str?.slice(c+findStr.length);
        const newStr = f+v;
        
        const b = newStr.indexOf(findStr1);
        const g = newStr.slice(0,b)
        const t = newStr.slice(b+findStr1.length+7);
        const lastStr = g+t
        mark.parentNode.innerHTML = lastStr;
        
        func3();
       }
    }

    const listenerEnter = useCallback((e : any) => {
      newSpan.firstChild?.addEventListener("click", listenerClick)
      e.target?.appendChild(newSpan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const removeListeners = useCallback(() => {
      newSpan.firstChild?.removeEventListener("click", listenerClick);
      newSpan.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if(authorData?.id) {
        apiService.articles.getNewestArticlesByAuthorId(authorData?.id, 0, 3)
        .then((r) => {
          setRandomArticles(r?.data?.articles);
        })
      }
    }, [authorData])

    useEffect(() => {
      
      if(!CurrentArticleLoading && responseArticle?.data) {
        try {
          setArticleData(JSON?.parse(responseArticle?.data?.content));
        }catch {
        }
        setArticleTitle(responseArticle?.data?.title);
        setArticleImage(responseArticle?.data?.image_url);
        setHeader(responseArticle?.data?.header);
        setCreated(responseArticle?.data?.published_at ? getDate(responseArticle?.data?.published_at) : getDate(responseArticle?.data?.created_at));
        setMinutesForReading(responseArticle?.data?.minutes_for_reading);
        setAuthorInterest(responseArticle?.data?.interest?.title);
        setLikesCount(responseArticle?.data?.likes_count);
        setViewsCount(responseArticle?.data?.views_count);
        setAuthorData(responseArticle?.data?.author);
        setIsSaved(responseArticle?.data?.is_saved);
        setAuthorFollowed(responseArticle?.data?.author?.is_followed);
        try{
          setReferences(JSON.parse(responseArticle?.data?.references));
        }catch {
        }
        setSubInterests(responseArticle?.data?.sub_interests);
        setIsLiked(responseArticle?.data?.is_liked);
        
      }
      
    }, [responseArticle, CurrentArticleLoading])

    useEffect(() => {

        if(selectArray.length) {
          selectArray.forEach((el) => {
            const mark : any = document.getElementById(el.highlightId);
            mark?.removeEventListener("mouseenter", listenerEnter);
            mark?.removeEventListener("mouseleave", removeListeners);
            mark?.addEventListener("mouseenter", listenerEnter);
            mark?.addEventListener("mouseleave", removeListeners);
          })
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectArray])
    
    const getHighlighsFromRanges = (selStr : string) => {
            let selArr = JSON.parse(selStr as string);
          
            let sel = getSelection();
            selArr?.forEach(function(each: any) {
            sel?.removeAllRanges();
            const newRange = objToRange(each);
            if(newRange) {
              sel?.addRange(newRange);
              const range = sel?.getRangeAt(0);

            const span = document.createElement("span");
            if(sel?.toString()) {
                span.textContent = sel?.toString() ;
                span.id = each.highlightId;
                span.classList.add("HighlitedText");
               
            }
            if(range) {
                range.surroundContents(span);
            }
            }
            sel?.removeAllRanges();

            
          })
    }

        function getSelectionText() {
            let text ;
            if (document.getSelection()) {
                text = document?.getSelection()?.toString();
                setData([""]);
            } 
            
            return text;
        }
        
        function replaceSelectedText(replacementText : HTMLSpanElement) {
            let sel, range : any;
            if (document.getSelection) {
                sel = document.getSelection();
                if (sel?.rangeCount) {
                    range = sel?.getRangeAt(0);
                    try {
                        range?.surroundContents(replacementText);
                       
                    } catch (error) {
                    }
                    sel?.removeAllRanges();
                }
            } 
        }

        const func3 = () => {
            const range : any = new Range();
            const allMarks = document?.querySelectorAll(".HighlitedText");

          if(allMarks?.length) {
            let newHighLights : any[] = []
            let arr : any[] = []
            allMarks.forEach((item, indx) => {
              
              if(!!item.firstChild?.textContent?.length && item.firstChild?.textContent.length) {
                range.setStartBefore(item);
                range.setEndAfter(item);
              }
              newHighLights.push(item.textContent);
              
      
             const startTextIndex = Array.prototype.indexOf.call(range.startContainer.childNodes, item)
             const endTextIndex = Array.prototype.indexOf.call(range.endContainer.childNodes, item)
          
               let startOffset = item.previousSibling?.textContent?.length
               let endOffset = item.textContent?.length
              
              if(startOffset && endOffset){
                if(indx === 0 && startTextIndex === 1){
                  const obj = {
                    highlightId : item.id,
                    startKey: range.startContainer.id,
                    startTextIndex: 0,
                    endKey: range.endContainer.id,
                    endTextIndex: 0,
                    startOffset: startOffset,
                    endOffset: endOffset+startOffset
                   }
                   arr.push(obj);
                } else {
                  const obj1 = {
                    highlightId : item.id,
                    startKey: range.startContainer.id,
                    startTextIndex: startTextIndex-1,
                    endKey: range.endContainer.id,
                    endTextIndex: endTextIndex-1,
                    startOffset: startOffset,
                    endOffset: endOffset+startOffset
                  }
                  arr.push(obj1);
                }
              }
            })
            setHighlight(newHighLights);
            setSelectArray(arr);
            const finalStr = JSON.stringify(arr);
            const finalHighlights = JSON.stringify(newHighLights);
            apiService.comments.saveHighlights(location.pathname.split('/')[2], finalStr, finalHighlights)
              .then((r) => {
              })
          } else {
            apiService.comments.deleteHighlights(cmmentId.current);
          }
        }
          
        // useEffect(() => {
        //   let idEL = 4000;
        //   const el = document.getElementById('textToSelect');
        //   articleData.forEach((item) => {
        //       item?.data?.forEach((subitem: any) => {
        //       const span = document.createElement('span');
        //       span.innerHTML = serialize(subitem, item.blockId);
        //       if(span.children[0].childNodes.length){
        //         span.children[0].childNodes.forEach((item : any) => {
        //           if(item.childNodes.length > 1) {
        //             item.childNodes.forEach((item : any) => {
        //               item.id = idEL+1;
        //               idEL = idEL+1
        //             })
        //           }
        //           item.id = idEL+1;
        //           idEL = idEL+1
        //         })
        //       }
        //       el?.insertAdjacentHTML('beforeend', span.innerHTML);            
        //     })
        // })
          
        // }, [articleData, isOldestVersion]);

        useEffect(() => {
          let idEL = 4000;
          const el = document.getElementById('textToSelect');
          articleData.forEach((item) => {
            const span = document.createElement('span');
            span.innerHTML = serialize(item.data, item.blockId);
            if(span.children[0].childNodes.length){
              span.children[0].childNodes.forEach((item : any) => {
                if(item.childNodes.length > 1) {
                  item.childNodes.forEach((item : any) => {
                    item.id = idEL+1;
                    idEL = idEL+1
                  })
                }
                item.id = idEL+1;
                idEL = idEL+1
              })
            }
            el?.insertAdjacentHTML('beforeend', span.innerHTML);            
          })
          
        }, [articleData, isOldestVersion]);

        useEffect(() => {
            if(responseHighlights?.data?.content && articleData) {
              try {
              const oldHighlightsArray = JSON.parse(responseHighlights?.data?.content);
              setSelectArray(oldHighlightsArray);
              setHighlight(JSON.parse(responseHighlights?.data?.entries));
              cmmentId.current = responseHighlights?.data?.id;
              getHighlighsFromRanges(responseHighlights?.data?.content);
              } catch (error) {
                
              }
              
            }
          
        }, [responseHighlights, articleData, isOldestVersion]);

      const handleSave = () => {
        if(apiService.auth.isAuthenticated()) {
          if(!isSaved){
            apiService.articles.addArticleToSave(+location.pathname.split('/')[2])
                  .then(r => {
                    setIsSaved(true);
                  });
          }else {
            apiService.articles.removeArticleFromSaved(+location.pathname.split('/')[2])
            .then(r => {
              setIsSaved(false);
            });
          }
        } else {
          showHaveAccountModal();
        }
      }

      const handleFollowWriter = () => {
        if(!authorFollowed){
          if(authorData) {
            apiService.user.followAuthor(getUserId(), authorData.id)
              .then(() => setAuthorFollowed(true));
          }
      } else {
        if(authorData) {
          apiService.user.unFollowAuthor(getUserId(), authorData.id)
              .then(() => setAuthorFollowed(false));
        }
      }
      }
  

      const handleLike = () => {
        if(apiService.auth.isAuthenticated()) {
          if(isLiked){
            apiService.articles.removeLikeFromArticle(location.pathname.split('/')[2])
              .then(() => {
                setLikesCount((prev) => prev-1);
                setIsLiked(false);
              });
          } else {
            apiService.articles.likeArticle(location.pathname.split('/')[2])
              .then(() => {
                setLikesCount((prev) => prev+1);
                setIsLiked(true);
              });
          }
        } else {
          showHaveAccountModal();
        }
      }

      // const getPreviousArtcileVersion = () => {
      //   apiService.articles.getAllArchivedVersionsOfArticle(location.pathname.split('/')[2])
      //     .then((r) => {
      //       if(r.data.articles[0]) {
      //         apiService.articles.getSpecifiedArchivedVersionOfArticle(location.pathname.split('/')[2], r.data.articles[0].id)
      //         .then((r) => {
      //           setIsOldestVersion(true);
      //           setArticleTitle(r?.data?.title);
      //           setHeader(r?.data?.header);
      //           const arr = JSON?.parse(r?.data?.content);
      //           const finalHTML = arr?.map((item : any) => {
      //             return serialize(item.data);
      //           })
      //           setOldestVersionData(finalHTML?.join(""));  
      //         })
      //       } else {
      //         showModalOldestVersion();
      //       }
      //     })
      // }

      // const editMyPublishArticle = () => {
      //   history.push(`/draft_article/${location.pathname.split('/')[2]}`);
      // }

      // useEffect(() => {
      //   document.title = "موسوعة | " + articleTitle
      // }, [articleTitle]);

    return (
            <View className="Article">
            <AppHeader/>          
            
            <Seo
             title={articleTitle}
             description={header}
             image={articleImage}
             keywords={header}
             author={authorData?.last_name + " " +authorData?.first_name}
             url={window.location.href}
            />
            {isOldestVersion && 
            <View className="OldestVersion">
              <Container>
              <Row>
                <Col lg={4}>
                </Col>
                <Col lg={8}>
                  <View className="OldestVersionTitle">
                    <View>
                      <span>نسخه سابقة</span>
                    </View>
                    <View>
                      <BtnColorless title="إغلاق" onClick={() => {
                        setArticleTitle(responseArticle?.data?.title);
                        setHeader(responseArticle?.data?.header);
                        setIsOldestVersion(false)}}/>
                    </View>
                  </View>
                </Col>
                </Row>
              </Container>
            </View>}
            <Container>
                <Row>
                    <Col lg={4}>
                      {!isOldestVersion && 
                      <View className="ArticleMenu">
                            {/* <View className="VerifiedArticle">
                                مقال موثق
                                <Icon name="iconVerifiedGreen"/>
                            </View> */}
                            {/* <SpacerVertical height={"1.714em"}/> */}
                            <View onClick={handleLike}>
                                <Icon name={isLiked ? "iconLikeBlue" : "iconLike"}/>
                                <View style={isLiked ? {color: "var(--blueColor)"} : {}}>{likesCount}</View>
                            </View>
                            <SpacerVertical height={"1.714em"}/>
                            <View style={{cursor: "text"}}>
                                <View><Icon name="iconViewers"/></View>
                                <View><span>{viewsCount}</span></View>
                            </View>
                            <SpacerVertical height={"1.714em"}/>
                            <View>
                                <Icon name={isSaved ? "iconBookmarkRoundedBlack" : "iconBookmarkRounded"} alt="bookmark_picture" onClick={handleSave}/>
                            </View>
                            <SpacerVertical height={"1.714em"}/>
                            <View className="iconShare">
                               <div ref={refPopUpShare}><Icon  name="iconShare" onClick={() => {setIsPopUpShare(!isPopUpShare)}}/>
                                <View className="PopupShare" style={isPopUpShare ? {display: "block"} : {}}>                                     
                                     <View onClick={()=> window.open(`http://twitter.com/share?text=${articleTitle}&url=`+window.location.href, "_blank")}>
                                        <Icon name="iconTwitterGray"/>
                                        شارك على تويتر
                                    </View>
                                    <View onClick={()=> window.open("https://www.facebook.com/sharer/sharer.php?u="+window.location.href, "_blank")}>
                                        <Icon name="iconFacebookGray"/>
                                        شارك على فيسبوك
                                    </View>
                                    <View onClick={()=> window.open("https://www.linkedin.com/sharing/share-offsite/?url="+window.location.href, "_blank")}>
                                        <Icon name="iconLinkedinGray"/>
                                        شارك على لينكد ان
                                    </View>
                                </View>
                                </div>
                            </View>
                            <SpacerVertical height={"1.714em"}/>
                            {/* <View className="iconMore">
                            <div ref={refPopUpMore}><Icon name="iconMore" onClick={() => {setIsPopUpMore(!isPopUpMore)}}/>
                                <View className="PopUpMore" style={isPopUpMore ? {display: "block"} : {}}>
                                    
                                    <View onClick={() => {
                                      setIsPopUpMore(false);
                                      getPreviousArtcileVersion();
                                      }}>
                                        <Icon name="iconFiles"/>
                                        نسخه سابقة
                                    </View>
                                    <SpacerVertical height={"0.571em"}/>
                                    {authorData?.id === getUserId() && 
                                    <View onClick={editMyPublishArticle}>
                                        <Icon name="iconEdit"/>
                                        تحرير مقالاتي
                                    </View>}
                                     <View>
                                        <Icon name="iconFlag"/>
                                        ابلغ عن محتوى
                                    </View> 
                                </View>
                                </div>
                            </View> */}
                        </View>}
                    </Col>
                    <Col lg={8}>
                        <View itemScope itemType="http://schema.org/Article" id="ArticleContent" className="ArticleContent">
                          <HoveringToolbar 
                              location={location.pathname.split('/')[2]}
                              highlight={highlight} 
                              setHighlight={setHighlight} 
                              selectArray={selectArray} 
                              replaceSelectedText={replaceSelectedText} 
                              setSelectArray={setSelectArray} 
                              rangeToObj={rangeToObj}/>
                            {/* <View className="ArticleContentHeader" onClick={() => {history.goBack()}}>
                                <Icon name="iconArrowRight"/>
                                رجوع إلى الخلف
                            </View> */}
                            <View className="VerifiedArticle">
                                مقال موثق
                                <Icon name="iconVerifiedGreen"/>
                            </View>
                            {!CurrentArticleLoading ?
                            <>
                            <Header1 title={articleTitle}/>
                            <View className="ArticleContentText">{header}</View>
                            <View className="AuthorData">
                                <View className="authorPhoto">
                                    <div style={{cursor: "pointer"}} onClick={() => {history.push(`/user_profile/${authorData?.id}`)}}>
                                      <Icon src={authorData?.image_url} name="iconProfilePlaceholder" alt="author_profile_photo" className="ArticlePhotoImage"/>
                                      <span itemProp="author" itemScope itemType="http://schema.org/Person">
                                        <span itemProp="name" className="authorPhotoSpan">{authorData?.first_name} {authorData?.last_name}</span>
                                      </span>
                                      <Icon style={{marginRight: "0.179em"}} name="iconVerifiedSmall"/>
                                    </div>
                                    {(authorData?.id !== getUserId() && apiService.auth.isAuthenticated()) && 
                                     <BtnColorless 
                                     title={ authorFollowed ? "متابَع" : "تابِع"} 
                                     style={authorFollowed ? {backgroundColor: "var(--blueColor)", color:"var(--white)", border: "0px"} : {}}
                                     onClick={handleFollowWriter}/>}
                                </View> 
                                <View>
                                    <span className="spanBold" style={{cursor: "pointer"}}  onClick={() => {history.push(`/interests_tabs/${responseArticle?.data?.interest?.id}`)}}>
                                      {authorInterest}
                                    </span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>{created}</span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>{minutesForReading} دقائق قراءة</span>
                                </View>
                            </View>
                            <SpacerVertical height={"2em"}/>
                            <View id="textToSelect" className="ArticleContentText textToSelect"></View>
                                {/* {!isOldestVersion ? 
                                <View id="textToSelect" className="ArticleContentText textToSelect">
                                </View> : 
                                <div dangerouslySetInnerHTML={{ __html: oldestVersionData}}>
                                
                                </div>} */}
                                <SpacerVertical height={"2.286em"}/>
                                <Header2 title="المراجع"/>
                            <View className="NumberedList" style={{fontSize: "1em"}}>
                                <ol>
                                  {references?.map((item) => {
                                    var matches = item.title.match(/\bhttps?:\/\/\S+/gi); 
                                    return (
                                      matches && matches.length > 0 ? 
                                         <li key={item.numberOfReferences}><a href={matches[0]} id={'['+item.numberOfReferences + ']References'} target="_blank" rel="noreferrer" >{item.title}</a></li> 
                                         :
                                         <li key={item.numberOfReferences}><a href={item.title} id={'['+item.numberOfReferences + ']References'} target="_blank" rel="noreferrer" >{item.title}</a></li> 

                                      )
                                     
                                    
                                  })}
                                </ol>
                            </View>
                            <SpacerVertical height={" 2.571em"}/>
                            <View itemProp="articleSection" className="ArticleContentInterests">

                                <View style={{cursor: "pointer"}}  onClick={() => {history.push(`/interests_tabs/${responseArticle?.data?.interest?.id}`)}}>
                                  {authorInterest}
                                </View>
                                {subInterests?.map((item) => {
                                  return <View style={{cursor: "pointer"}} key={item.id} onClick={() => {history.push(`/subinterests_tabs/${item?.id}`)}}>
                                    {item.title}
                                  </View>
                                })}
                            </View>
                            <SpacerVertical height={" 2.571em"}/>
                            <View className="AuthorData" style={{display:'block'}}>
                                <View className="ReviewerTitle">تمت مراجعة المقال من قِبل:</View>
                                <SpacerVertical height={" 1em"}/>
                                <View className="authorPhoto">
                                    <div style={{cursor: "pointer"}} onClick={() => {history.push(`/user_profile/${reviewerId}`)}}>
                                      <Icon src={reviewerImageUrl} name="iconProfilePlaceholder" alt="author_profile_photo" className="ArticlePhotoImage"/>
                                      <span>
                                        <span className="authorPhotoSpan">{reviewerName}</span>
                                      </span>
                                      <Icon style={{marginRight: "0.179em"}} name="iconVerifiedSmall"/>
                                    </div>
                                    {/* {(reviewerId !== getUserId() && apiService.auth.isAuthenticated()) && 
                                     <BtnColorless 
                                     title={ authorFollowed ? "متابَع" : "تابِع"} 
                                     style={authorFollowed ? {backgroundColor: "var(--blueColor)", color:"var(--white)", border: "0px"} : {}}
                                     onClick={handleFollowWriter}/>} */}
                                </View>
                            </View>
                            <View className="ArticleContentLikes" onClick={handleLike}>
                                <Icon name={isLiked ? "iconLikeBlue" : "iconLike"}/>
                                <View style={isLiked ? {color: "var(--blueColor)"} : {}}>{likesCount}</View>
                            </View>
                            <SpacerVertical height={"1.143em"}/>
                            <View className="ArticleContentViewers">                               
                               <View className="Flex">
                                  <View><Icon name="iconViewers"/><span>  {viewsCount}</span></View>
                                  <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                                  <View>
                                    <Icon style={{cursor: 'pointer'}} name={isSaved ? "iconBookmarkRoundedBlack" : "iconBookmarkRounded"} alt="bookmark_picture" onClick={handleSave}/>
                                  </View>
                                  <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                                  <View className="iconShare">
                               <div ref={refSubPopUpShare}><Icon  name="iconShare" onClick={() => {setIsSubPopUpShare(!isSubPopUpShare)}}/>
                                <View className="PopupShare" style={isSubPopUpShare ? {display: "block"} : {}}>
                                    <View onClick={()=> window.open(`http://twitter.com/share?text=${articleTitle}&url=`+window.location.href, "_blank")}>
                                        <Icon name="iconTwitterGray"/>
                                        شارك على تويتر
                                    </View>
                                    <View onClick={()=> window.open("https://www.facebook.com/sharer/sharer.php?u="+window.location.href, "_blank")}>
                                        <Icon name="iconFacebookGray"/>
                                        شارك على فيسبوك
                                    </View>
                                    <View onClick={()=> window.open("https://www.linkedin.com/sharing/share-offsite/?url="+window.location.href, "_blank")}>
                                        <Icon name="iconLinkedinGray"/>
                                        شارك على لينكد ان
                                    </View>
                                    <SpacerVertical height={"0.571em"}/>
                                </View>
                                </div>
                            </View>
                                  {/* <Icon className="iconGrayCircle" name="iconGrayCircle"/> */}
                                  {/* <View className="iconMore">
                                    <div ref={refSubPopUpMore}><Icon name="iconMore" onClick={() => {setIsSubPopUpMore(!isSubPopUpMore)}}/>
                                        <View className="PopUpMore" style={isSubPopUpMore ? {display: "block"} : {}}>
                                            
                                            <View onClick={() => {
                                              setIsSubPopUpMore(false);
                                              getPreviousArtcileVersion();
                                              }}>
                                                <Icon name="iconFiles"/>
                                                نسخه سابقة
                                            </View>
                                            <SpacerVertical height={"0.571em"}/>
                                            {authorData?.id === getUserId() && 
                                            <View onClick={editMyPublishArticle}>
                                                <Icon name="iconEdit"/>
                                                تحرير مقالاتي
                                            </View>}
                                            <View>
                                                <Icon name="iconFlag"/>
                                                ابلغ عن محتوى
                                            </View>
                                        </View>
                                        </div>
                                    </View> */}
                               </View>
                            </View>
                            <SpacerVertical height={"2.286em"}/>
                            <View className="ArticleContentUserProfile">
                                <View className="iconPhoto100" onClick={() => {history.push(`/user_profile/${authorData?.id}`)}}>
                                        <Icon src={authorData?.image_url} name="iconProfilePlaceholder" alt="author_profile_photo"/>
                                </View>
                                <View className="UserProfileContent">
                                        <View className="UserProfileContentTitle">
                                            <div style={{cursor: "pointer"}} onClick={() => {history.push(`/user_profile/${authorData?.id}`)}}>
                                                <span style={{cursor: "pointer"}}>{authorData?.first_name} {authorData?.last_name}</span>   
                                                <Icon name="iconVerifiedLarge" style={{cursor: "pointer"}}/>
                                            </div>
                                            {(authorData?.id !== getUserId() && apiService.auth.isAuthenticated()) && 
                                              <BtnColorless 
                                              title={ authorFollowed ? "متابَع" : "تابِع"} 
                                              style={authorFollowed ? {backgroundColor: "var(--blueColor)", color:"var(--white)", border: "0px"} : {}}
                                              onClick={handleFollowWriter}/>}
                                        </View>
                                        <SpacerVertical height={"0.571em"} />
                                        <View className="Numbers">   
                                                <View className="text">
                                                    {getUserRole(authorData?.role)}
                                                    <span className="spanBold" style={{cursor: "pointer"}}  onClick={() => {history.push(`/interests_tabs/${responseArticle?.data?.interest?.id}`)}}>  {authorInterest}</span>
                                                </View>
                                                <View className="spans">
                                                <span className="displayNone">{authorData?.articles_count} </span> 
                                                مقال  
                                                <Icon className="iconGrayCircle displayNone" name="iconGrayCircle"/>
                                                <span className="displayNone">{authorData?.followers_count} </span>
                                                متابَع
                                                </View>
                                        </View>
                                </View>
                              </View>
                              </> : <Loader/>}
                            <View className="DifferentArticles">
                                <SpacerVertical height={"2.286em"}/>
                                <Header3 className="writersHeader" title="مقالات إضافية للكاتب"/>
                                <div className="smallLine"></div>
                                {randomArticles?.map((item) => {
                                    return (
                                        <SuggestedArticlesItem 
                                            toSave
                                            isSaved={item.is_saved}
                                            key={item.id}
                                            title={item.title}
                                            header={item.header}
                                            image_url={item.image_url}
                                            interest={item.interest}
                                            author={item.author}
                                            published_at={item.published_at}
                                            created_at={item.created_at}
                                            minutes_for_reading={item.minutes_for_reading}
                                            articleId={item.id}
                                            status={item.status.name}
                                            updated_at={item.updated_at}
                                        />
                                    )
                                })}
                            </View>
                        </View>
                    </Col>
                </Row>
            </Container>
            {/* <RenderModalOldestVersion>
                <AlertModal alertText="Oldest version do not exist..." alertHeader="" hideModal={hideModalOldestVersion}/>
            </RenderModalOldestVersion> */}
            <RenderModalArticleSuccess>
                <AlertModal alertText="Article shared successfully..." alertHeader="" hideModal={hideModalArticleSuccess}/>
            </RenderModalArticleSuccess>
            <RenderModalAlreadyShared>
                <AlertModal alertText="You have already shared this article" alertHeader="" hideModal={hideModalAlreadyShared}/>
            </RenderModalAlreadyShared>
            <RenderModalError>
                <AlertModal alertText="Somethig went wrong..." alertHeader="" hideModal={hideModalError}/>
            </RenderModalError>
            <RenderHaveAccountModal>
                 <HaveAccountModal  />             
            </RenderHaveAccountModal>
            <AppFooter />
            </View>
    )
}

interface HoveringToolbarProps {
    replaceSelectedText: (replacementText : HTMLSpanElement) => void;
    setSelectArray: any;
    rangeToObj: any;
    selectArray: any;
    setHighlight: any;
    highlight: string[];
    location: any;
}

const HoveringToolbar:FunctionComponent<HoveringToolbarProps> = (props) => {

    const {replaceSelectedText, setSelectArray, rangeToObj, selectArray, setHighlight, highlight, location} = props;

    const ref = useRef<any>();
    let range = document.createRange();
    
    useEffect(() => {
      const el = ref.current;

      const domSelection = window.getSelection();
      

  
      if (!el) {
        return
      }

      if (
        !domSelection?.anchorNode ||
        domSelection.type === "Caret" ||
         !range.collapsed || !apiService.auth.isAuthenticated()
      ) {
        el.removeAttribute('style')
        return
      }
  
      const domRange = domSelection?.getRangeAt(0);

      const startNode = domRange?.startContainer?.parentNode;
      const endNode = domRange?.endContainer?.parentNode;
      if(endNode) {
        if (!startNode?.isSameNode(endNode)) {
          el.removeAttribute('style')
          return
        }
      }
      
      const rect = domRange?.getBoundingClientRect();
      
      if(rect) {
        el.style.display = 'block'
        el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`
        el.style.left = `${rect.left +
          window.pageXOffset -
          el.offsetWidth / 2 +
          rect.width / 2}px`
      }
    })
  
    return (
      <Portal>
        <Menu
          ref={ref}
          className={css`
            padding: 8px 7px 6px;
            position: absolute;
            z-index: 1;
            margin-top: -15px;
            display: none;
            background-color: #1A1A1A;
            border-radius: 4px;
            transition: opacity 0.75s;
          `}
        >
            <View className="PortalHighlight" style={{padding: "0.778em"}}>
                <View className="text">الاكثر تمييزا</View>
                <View className="highlightBorder"></View>
                {/* <Icon name="iconTwitter" style={{marginLeft: "1.143em", cursor: "pointer"}}/> */}
                <Icon name="iconHighlighterWhite" onClick={() => {
                     const id = nanoid(4);
                     let range = document?.getSelection()?.getRangeAt(0);
                     
                     const finalStr = JSON.stringify([...selectArray, rangeToObj(range, id)]);
                     const finalHighlights = JSON.stringify([...highlight, document?.getSelection()?.toString()]);
                     setHighlight([...highlight, document?.getSelection()?.toString()])
                    setSelectArray([...selectArray, rangeToObj(range, id)]);
                    const b = document.createElement("span");
                    b.id = id
                    b.textContent = document?.getSelection()?.toString() as string;
                    b.classList.add("HighlitedText");
                    replaceSelectedText(b);
                    apiService.comments.saveHighlights(location, finalStr, finalHighlights)
                     .then(r => {});
                }} style={{marginLeft: "0", cursor: "pointer"}}/>
            </View>
        </Menu>
      </Portal>
    )
  }
  
export const Portal = ({ children } : any) => {
    return ReactDOM.createPortal(children, document.body)
  }
  
export interface BaseProps {
    className: string
    [key: string]: unknown
  }
  
export const Menu = React.forwardRef(
    (
      { className, ...props }: PropsWithChildren<BaseProps>,
      ref: any
    ) => (
      <div
        {...props}
        ref={ref}
        className={cx(
          className,
          css`
            & > * {
              display: flex;
            }
            & > * + * {
              margin-left: 15px;
            }
          `
        )}
      />
    )
  )


