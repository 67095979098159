import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
import { Icon } from '../../../components/icons/icon.component';
// import { BtnColorless } from '../../../components/buttons/btn.component';
import privacyBG from '../../../assets/images/StaticPages/BriefPage/brirefBG.png';

import "./brief.scss";

export const BriefPage: FunctionComponent = () => { 

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
        <AppHeader isWhite={true}/>
        <View className="CareersPage">
            <View className="CareersPageHeader" title="careers_photo" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(${privacyBG})`}}>
                <Container style={{marginTop: "96px"}}>
                    <View className="CareersPageHeaderContent">
                        <Header1 title="عن موسوعة" className="header1"/>
                    </View>
                    <View className="CareersPageHeaderContent">
                        <Icon name="iconMawsuahLogo"/>
                        <span className="bigText">موسوعة</span>
                        <Icon name="iconVerifiedSmallWhiteBG"/>
                    </View>
                </Container>
            </View>
            <SpacerVertical height={"4.5em"}/>
            <View className="CareersPageContent">
                <Container>
                    <Row>
                        <Col lg={3} className="CareersPageContentSecondColumn">
                            {/* <View className="CareersPageContentSecondColumnMenu" style={{borderBottom: "0px"}}>
                                <View className="Item">سياسية الخصوصية</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View>
                            <View className="CareersPageContentSecondColumnDisabled">
                                <View className="Item">المساعدة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المجتمعات</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">معتمدون لدى </View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الشركاء</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المدونة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الوظائف</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">شروط الاستحدام</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">التواصل</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View> */}
                        </Col>
                        <Col className="CareersPageContentFirstColumn" lg={9}>
                            <Header3 className="writersHeader" title="من نحن"/>
                            <div className="smallLine"></div>
                            <View className="CareersPageContentFirstColumnContent">
                            <View className="smallHeader">
                           
                            </View>
                            <View className="CareersAuthor">
                                <View className="CareersAuthorPhoto">
                                    <div style={{cursor: "pointer"}}>
                                      <Icon name="iconProfilePlaceholder" alt="author_profile_photo" className="AuthorDataImage"/>
                                      <span className="AuthorPhotoSpan">موسوعة</span>
                                      <Icon style={{marginRight: "0.179em"}} name="iconVerifiedSmall"/>
                                    </div>
                                     {/* <BtnColorless title="تابِع"/> */}
                                </View> 
                                <View className="CareersAuthorText">
                                    <span className="spanBold">تكنولوجيا</span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>٢٩ يناير </span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>4 دقائق قراءة</span>
                                </View>
                            </View>
                            <SpacerVertical height={"2.3em"}/>
                            <View className="PrivacyContent">
                                <h4>
                                مقدمة
                                </h4>
                                <p>
                                أظهرت أحدث الدراسات العالمية أن هناك أكثر من 500 مليون متحدث باللغة العربية حول العالم، إلا أن المحتوى العربي على الإنترنت لا يتجاوز الـ 3% دون ضمان موثوقيته، ويستخدم هذا المحتوى الضئيل أكثر من 270 مليون مستخدم عربي، مع توقع زيادته بنسبة 40% بحلول عام 2025. وبناءً عليه جاءت موسوعة بفكرتها لخلق حلول؛ لزيادة نسبة المحتوى العربي المختص والموثوق.
                                </p>
                            </View>
                            <View className="PrivacyContent">                              
                                <p>موسوعة مشروع عربي ناشئ mawsuah.com، وهي منصة لا مركزية مجانية، استندت إلى فكرة "الويب 03 Web" التي تضمن حماية أفضل لخصوصية المستخدمين من خلال اعتمادها على الأنظمة اللامركزية مثل تقنية البلوكشين Blockchain، حيث تتيح لمنتجي المحتوى العربي المختّصين مساحة خاصة لإنشاء حساباتهم الشخصية، وبناء مجتمعاتهم المعرفية التفاعلية، وإنتاج محتوى معرفي موثوق؛ ليصبح المجتمع هو المتحكم بإصدار المعلومة والمسؤول عنها، وليمثل مجتمع موسوعة المختّص بدوره المرجع الموثوق لكل الباحثين عن المعلومة باللغة العربية.</p>                             
                            </View>
                            <View className="PrivacyContent">
                                <p>
                                والهدف من إنشاء موسوعة سد الفجوة بين الحاجة للمعلومة العربية ومدى توفرها على الإنترنت، عن طريق تطوير نظام مكافآت معنوي ومادي موثوق تقديراً لمنتجي المحتوى. وزيادة الثقة في المعلومات العربية المتوفرة على الإنترنت من خلال نظام مراجعة مجتمعي غير متحيّزـ مبني على آلية عمل النظير للنظير (peer to peer) - ضمن ذات الاهتمام، للتأكد من صحة المعلومة ودقتها.
                                </p>
                            </View>
                            

                            <View className="PrivacyContent">
                                <h4>
                                ما الذي تقدمه منصة موسوعة لمنتجي المحتوى المختصين بالمقارنة مع منصات التواصل الاجتماعي؟
                                </h4>
                                <p>
                                عملية المراجعة قبل النشر هي عملية سرية وشفافة ولن يتم إظهار اسم الكاتب أو المراجع أو المحرر.
</p>
<p>
</p>
                     <ul>
<li>إنشاء حساب شخصي تفاعلي موثوق، يساعده في بناء قاعدة جماهيرية مستهدفة، ضمن مجتمعات معرفية مختّصة.</li>
<li>نظام مكافآت تحفيزي موثوق بما في ذلك نقاط ضمن محفظة إلكترونية، بالإضافة إلى نظام الأوسمة.</li>
<li>ضمان حماية المعلومات من الضياع أو الحذف، من خلال الاعتماد على الأنظمة اللامركزية المبنية على تقنية البلوكشين Blockchain.</li>
<li>مقالات موثقة ومعتمدة، استناداً على نظام المراجعة المبني على آلية عمل النظير للنظير.</li>
<li>توفير قائمة بالمواضيع الأكثر بحثاً؛ لتحقيق انتشار أسرع وتحسين ظهور المقال ومنتجه على محركات البحث ضمن أعلى المعايير العالمية.</li>
</ul>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                إنجازات موسوعة:
                                </h4>
                                <p>
                                اختيرت موسوعة ضمن "مسرّعة Google للأعمال الناشئة" في عام 2021، كما تم اختيارها من بين (العشرة الأوائل - Top 10) من بين أكثر من 2300 شركة ناشئة مشاركة في مسابقة "MIT Saudi Arabia" عام 2022.                                </p>
                                <p>وتطمح موسوعة في المرحلة الحالية للحصول على الدعم المعنوي والمادي؛ للاستمرار نحو تحقيق رؤيتها وأهدافها، وبالدرجة الأولى العمل على بناء أكبر منصة قائمة على نظام مكافآت عربي موثوق لمنتجي المحتوى.</p>
                              
                            </View>  
                            <View className="PrivacyContent">
                                <h4>
                                كيف ندعم موسوعة؟
                                </h4>
                                <p style={{fontWeight : "bold"}} >على الصعيد المعنوي:</p>                                
                                <p>تزويد منصة موسوعة بأسماء مقترحة لمختّصين في مجالات عدة، والتشبيك مع مؤسسات خاصة وعامة؛ لتوسيع دائرة عملها والمساهمة في بناء مجتمعها المختص الموثوق.</p>
                                <p style={{fontWeight : "bold"}}>على الصعيد المادي:</p>
                                <p>تحتاج موسوعة للدعم في هذه المرحلة؛ لتطوير التكنولوجيا المستخدمة في آلية عملها وزيادة مزاياها، وتمكينها في بناء نظام مكافآت تحفيزي موثوق يتم تقديمه لمنتجي المحتوى المختصين تقديراً لكتابتهم.</p>                                
                                
                            </View>                                                   
                            </View>
                        </Col>
                    </Row>
                </Container>
                <SpacerVertical height={"3.3em"}/>
            </View>
            <AppFooter />
        </View>
    </View>
    )
}