import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { Btn } from "../buttons/btn.component";
import { useModal } from "../modal/modal.component";
import { SpacerVertical } from "../spacer/spacer.component";
import { View } from "../view/view.component";
import { WriterWelcomeModal } from "../writer-welcome-modal/writer-welcome.modal";
import { ProfileMenuItem } from "./nested/profile-menu-item";

export const ProfileMenuData = [    
    {
        title: "صفحتي الشخصية" ,
        iconName: "iconProfile",
        url: "profile",
        status: true
    },
    {
        title: "ركن الكتابة",
        iconName: "iconEditArticle",
        url: "writer",
        status: false
    },
    {
        title : "ركن التحرير",
        iconName: "iconEditorCorner",
        url: "editor",
        status: false
    },
    {
        title: "ركن المراجعة",
        iconName: "iconReviewerCorner",
        url: "reviewer",
        status: false
    },
    {
        title: "المساحة الرئيسية",
        iconName: "iconHomePage",
        url: "homepage",
        status:  true
    },
    {
        title: "قائمة قراءاتي",
        iconName: "iconReadingList",
        url: "reading_list",
        status: false
    },
    {
        title: "شائع في موسوعة",
        iconName: "iconTrendingNow",
        url: "trending",
        status: false
    }
]

export const ProfileMenu: FunctionComponent = () => {

    const location : any = useLocation();

    const [role, setRole] = useState("");
    const [activeData, setActiveData] = useState(ProfileMenuData);

    useEffect(() => {
        if(localStorage.getItem("mawsuah_user_data")) {
            setRole(JSON.parse(localStorage.getItem("mawsuah_user_data") as string).role.name);
        } 
    }, []);

    useEffect(() => {
        const arr = ProfileMenuData.map((item) => {
            if(item.url === location.pathname.split("/")[2]) {
                item.status = true;
            } else {
                item.status = false;
            }
            return item
        });
        setActiveData(arr);
    }, [location.pathname])

    const {showModal, RenderModal, hideModal} = useModal();


    return (
        <View className="interestsTabsMenu" style={{marginTop: "64px"}}>
            <View className="interestsTabsMenuContent">
                {activeData.map((item) => {
                    if(item.iconName === "iconEditArticle" && role !== "WRITER" && role !== "WRITER_EDITOR" && role !== "WRITER_REVIEWER"){
                        return undefined
                    }
                    if(item.iconName === "iconReviewerCorner" && role !== "REVIEWER" && role !== "REVIEWER_EDITOR" && role !== "WRITER_REVIEWER" ){
                        return undefined
                    }
                    if(item.iconName === "iconEditorCorner" && role !== "EDITOR" && role !== "WRITER_EDITOR" && role !== "REVIEWER_EDITOR") {
                        return undefined
                    }
                    return(
                        <View style={{display: "block"}} key={item.iconName}>
                            <ProfileMenuItem url={item.url} iconName={item.iconName} title={item.title} status={item.status}/>
                        </View>
                    )
                })}
                <SpacerVertical height={"1.857em"}/>
            </View>
            <SpacerVertical height={"1.857em"}/>
            {(role !== "REVIEWER" && role !== "EDITOR" && role !== "READER" && role !== "REVIEWER_EDITOR") && <Btn title="اكتب مقال" onClick={() => {showModal()}}/>} 
            <RenderModal style={{padding: "0px", width: "33.429em", top: "300px"}}>
                <WriterWelcomeModal hideModal={hideModal}/>
            </RenderModal>
        </View>
    )
}