import React, { FunctionComponent, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
// import { Btn } from '../../../../components/buttons/btn.component';
import { Loader } from '../../../../components/loader/loader.component';
import { SpacerVertical } from '../../../../components/spacer/spacer.component';
import { View } from '../../../../components/view/view.component';
import { useFetch } from '../../../../shared/hooks/fetch.hook';
import { ArtcileInfo } from '../../../../shared/interfaces';
import { apiService } from '../../../../shared/services/api.service';
import { UserProfileAuthNoContent } from '../user-profile-auth-no-content/user-profile-auth-no-content.component';
import { UserProfileAuthWriterItem } from './nested/user-profile-auth-writer-item.component';

import "./user-profile-auth-writer.scss";

export const UserProfileAuthWriter: FunctionComponent = () => {

    const {response: allArticlseResponse, isLoading: allArticlesLoading} = useFetch(() => apiService.articles.getAllMyArticles());

    const [allArticles, setAllArticles] = useState<ArtcileInfo[]>([]); 

    useEffect(() => {
        // setAllArticles(allArticlseResponse?.data?.articles.slice(0,10));
        setAllArticles(allArticlseResponse?.data?.articles);
    }, [allArticlseResponse]);

    // const getMore = () => {
    //     if(allArticlseResponse?.data?.articles !== undefined && allArticlseResponse?.data?.articles !== null) {
    //         setAllArticles([...allArticles , ...allArticlseResponse.data.articles.slice(allArticles.length,10+allArticles.length)]);
    //     }
    // }

    return(
        <View className="UserProfileAuthWriter">
            
            {allArticlesLoading ? 
            <Loader/> :
            allArticles?.length ?
            <View>
            <Tabs defaultActiveKey="all" transition={false} id="noanim-tab-example">
                <Tab eventKey="all" title="كل المقالات">
                    <>
                    <SpacerVertical height={"2.357em"}/>
                    {allArticles?.map((item: ArtcileInfo) => {
                        return (
                            <UserProfileAuthWriterItem 
                            key={item.id} 
                            status={item.status.name} 
                            rejectedComment={item.status.reject_commentary} 
                            rejectedReason={item.status.reject_reason} 
                            articleInfo={item}
                            endTime={item.review?.end_review_at ? item.review?.end_review_at : item.correction?.end_correction_at}/>
                        )
                    })}
                    </>
                </Tab>
                <Tab eventKey="correcting" title="اتخذ إجراءً">
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {allArticles?.map((item: ArtcileInfo) => {
                        if(item.status.name === "ON_EDITING" || item.status.name === "APPROVED") {
                            return (
                                <UserProfileAuthWriterItem 
                                key={item.id} 
                                status={item.status.name} 
                                articleInfo={item}
                                endTime={item.review?.end_review_at ? item.review?.end_review_at : item.correction?.end_correction_at}/>
                            )
                        }
                        return undefined
                    })}
                    </>
                </Tab>
                {/* <Tab eventKey="approved" title="معتمدة للنشر" >
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {allArticles?.map((item: ArtcileInfo) => {
                        if(item.status.name === "APPROVED") {
                            return (
                                <UserProfileAuthWriterItem 
                                key={item.id} 
                                status={item.status.name} 
                                articleInfo={item}
                                endTime={item.review?.end_review_at ? item.review?.end_review_at : item.correction?.end_correction_at}/>
                            )
                        }
                        return undefined
                    })}
                    </>
                </Tab> */}
                {/* <Tab eventKey="draft" title="مسودة" >
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {allArticles?.map((item: ArtcileInfo) => {
                        if(item.status.name === "DRAFT") {
                            return (
                                <UserProfileAuthWriterItem 
                                key={item.id} 
                                status={item.status.name} 
                                articleInfo={item}
                                endTime={item.review?.end_review_at ? item.review?.end_review_at : item.correction?.end_correction_at}/>
                            )
                        }
                        return undefined
                    })}
                    </>
                </Tab> */}
                {/* <Tab eventKey="reviewing" title="قيد المراجعة" >
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {allArticles?.map((item: ArtcileInfo) => {
                        if(item.status.name === "ON_REVIEW") {
                            return (
                                <UserProfileAuthWriterItem 
                                key={item.id} 
                                status={item.status.name} 
                                articleInfo={item}
                                endTime={item.review?.end_review_at ? item.review?.end_review_at : item.correction?.end_correction_at}/>
                            )
                        }
                        return undefined
                    })}
                    </>
                </Tab> */}
                <Tab eventKey="editing" title="قيد الإجراء"  >
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {allArticles?.map((item: ArtcileInfo) => {
                        if(item.status.name === "ON_CORRECTING" || item.status.name === "ON_REVIEW") {
                            return (
                                <UserProfileAuthWriterItem 
                                key={item.id} 
                                status={item.status.name} 
                                articleInfo={item}
                                endTime={item.review?.end_review_at ? item.review?.end_review_at : item.correction?.end_correction_at}/>
                            )
                        }
                        return undefined
                    })}
                    </>
                </Tab>
                <Tab eventKey="published" title="المقالات الموثوقة">
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {allArticles?.map((item: ArtcileInfo) => {
                        if(item.status.name === "PUBLISHED") {
                            return (
                                <UserProfileAuthWriterItem 
                                key={item.id} 
                                status={item.status.name} 
                                articleInfo={item}
                                endTime={item.review?.end_review_at ? item.review?.end_review_at : item.correction?.end_correction_at}/>
                            )
                        }
                        return undefined
                    })}
                    </>
                </Tab>
                <Tab eventKey="rejected" title="المقالات المرفوضة" >
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {allArticles?.map((item: ArtcileInfo) => {
                        if(item.status.name === "REJECTED") {
                            return (
                                <UserProfileAuthWriterItem 
                                key={item.id} 
                                rejectedComment={item.status.reject_commentary} 
                                rejectedReason={item.status.reject_reason} 
                                status={item.status.name} 
                                articleInfo={item}
                                endTime={item.review?.end_review_at ? item.review?.end_review_at : item.correction?.end_correction_at}/>
                            )
                        }
                        return undefined
                    })}
                    </>
                </Tab>
                </Tabs>
                {/* <Btn title="استكشف أكثر" className="searchPageContentBtn" onClick={getMore}/> */}
            </View> :
            <UserProfileAuthNoContent />}
        </View>
    )
}