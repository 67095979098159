import { AxiosError } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AlertModal } from '../../../components/alert-modal/alert-modal.component';
import { HaveAccountModal } from '../../../components/have-account-modal/have-account.modal';
import { Icon } from '../../../components/icons/icon.component';
import { Loader } from '../../../components/loader/loader.component';
import { useModal } from '../../../components/modal/modal.component';
import { View } from '../../../components/view/view.component';
import { sub_interests } from '../../../shared/interfaces';
import { apiService } from '../../../shared/services/api.service';
import { getUserId } from '../../../shared/utils';
            
interface InterestsLandingItemProps{
    status : boolean;
    sub_interests: any;
    isAuth: boolean;
    userSubInterests: sub_interests | undefined;
}
            
export const InterestsLandingItem: FunctionComponent<InterestsLandingItemProps> = (props) => {

    const {status, sub_interests, isAuth, userSubInterests} = props;

    const {showModal: showModalFullData, RenderModal: RenderModalFullData, hideModal: hideModalFullData} = useModal();
    const {showModal: showModalLogIn, RenderModal: RenderModalLogIn} = useModal();
    
    const [itemStatus, setItemStatus] = useState(status);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setItemStatus(status);
    }, [status])

    useEffect(() => {
        const interesStatus = userSubInterests?.find((item) => item.id === sub_interests.id);
        setItemStatus(!!interesStatus);
    }, [sub_interests.id, userSubInterests]);
    
    const handleClick = () => {
        if(isAuth) {
            setIsLoading(true);
            if(!itemStatus) {
                apiService.interests.subscribeOnSubInterest(getUserId(), sub_interests.id)
                .then(r => {
                    setIsLoading(false);
                    setItemStatus((prev) => !prev);
                })
                .catch((err: AxiosError) => {
                    setIsLoading(false);
                    showModalFullData();
                })
            } else {
                apiService.interests.unSubscribeFromSubInterest(getUserId(), sub_interests.id)
                .then(r => {
                    setIsLoading(false);
                    setItemStatus((prev) => !prev);
                })
                .catch((err: AxiosError) => {
                    setIsLoading(false);
                    showModalFullData();
                })
            }
            
        } else {
            showModalLogIn();
        }
    } 

    const history = useHistory();

    return (
        <View className="InterestsLandingPageItem">
            <View className="InterestsLandingPageItemImages">
                 {itemStatus ? <View className="itemIconPlus" style={{backgroundColor: "var(--blueColor)", border: "none"}} onClick={handleClick}>
                {isLoading ? <Loader/> : <Icon className="iconCheckmarkPure" name="iconCheckmarkPure" /> }
                </View> :
                <View className="itemIconPlus" onClick={handleClick}>
                    {isLoading ? <Loader/> : <Icon name="iconAddInterest"/>}
                </View>}
                <View className="itemImage" onClick={() => {history.push(`/subinterests_tabs/${sub_interests.id}`)}}>
                    <Icon src={sub_interests.image_url} name={sub_interests.image_url} alt={sub_interests.title}/>
                </View>
            </View>
            <View className="InterestsLandingPageItemTitle">
                <View>
                    {sub_interests.title}   
                </View>   
            </View>
            <RenderModalFullData>
                    <AlertModal alertText="Something went wrong..." alertHeader="" hideModal={hideModalFullData}/>
            </RenderModalFullData>
            <RenderModalLogIn>
                    <HaveAccountModal />
            </RenderModalLogIn>
        </View>
    )                
}
            