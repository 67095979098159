import React, { FunctionComponent } from "react";
import Candy from "../../assets/images/candy.svg";
import verifiedLarge from "../../assets/images/verifiedLarge.svg";
import verifiedSmall from "../../assets/images/verifiedSmall.svg";
import verifiedSmallWhiteBG from "../../assets/images/verifiedSmallWhiteBG.svg";
import verifiedSmallWhite from "../../assets/images/verifiedSmallWhite.svg";
import grayCircle from "../../assets/images/grayCircle.svg";
import photo72 from "../../assets/images/photo72.svg";
import photo32 from "../../assets/images/Photo32.svg";
import photo40 from "../../assets/images/photo40.svg";
import photo100 from "../../assets/images/photo100.svg";
import photo140 from "../../assets/images/photo140.svg";
import Telescope from "../../assets/images/Telescope.png";
import colorlessBook from "../../assets/images/colorlessBook.svg";
import smallBook from "../../assets/images/smallBook.svg";
import chosenTopicsIcon from "../../assets/images/chosenTopicsIcon.svg";
import chosenTopicsBigIcon from "../../assets/images/chosenTopicsBigIcon.png";
import arrowLeft from "../../assets/images/arrowLeft.svg";
import arrowLeftBlack from "../../assets/images/arrowLeftBlack.svg";
import arrowBottom from "../../assets/images/arrowBottom.svg";
import logo from "../../assets/images/logo.svg";
import logoBig from "../../assets/images/logoBig.svg";
import logoBig1 from "../../assets/images/logoBig1.svg";
import logoBigLogin from "../../assets/images/logoBigLogin.svg";
import logoSmall from "../../assets/images/logoSmall.svg";
import Group from "../../assets/images/Group.svg";
import GroupSmall from "../../assets/images/GroupSmall.svg";
import youTube from "../../assets/images/youTube.svg";
import twitter from "../../assets/images/twitter.svg";
import facebook from "../../assets/images/facebook.svg";
import linkedIn from "../../assets/images/linkedIn.svg";
import Clip from "../../assets/images/Clip.svg";
import search from "../../assets/images/search.svg";
import bell from "../../assets/images/bell.svg";
import bookmarkRounded from "../../assets/images/bookmarkRounded.svg";
import bookmarkRoundedBlack from "../../assets/images/bookmarkRoundedBlack.svg";
import planet from "../../assets/images/planet.svg";
import interests from "../../assets/images/interests.svg";
import logoAUB from "../../assets/images/sponsors/logoAUB.jpg";
import logoCFFS from "../../assets/images/sponsors/logoCFFS.jpg";
import logoHBR from "../../assets/images/sponsors/logoHBR.jpg";
import logoSharja from "../../assets/images/sponsors/logoSharja.png";
import Moon from "../../assets/images/Moon.jpg";
import loginPage from "../../assets/images/loginPage.jpg";
import GroupBlack from "../../assets/images/GroupBlack.svg";
import logoWithBg from "../../assets/images/logoWithBg.svg";
import redStar from "../../assets/images/redStar.svg";
import googleLogo from "../../assets/images/googleLogo.svg";
import logoBg from "../../assets/images/logoBg.svg";
import facebookLogoButton from "../../assets/images/facebookLogoButton.svg";
import bg1 from "../../assets/images/bg/bg1.jpg";
import bg2 from "../../assets/images/bg/bg2.jpg";
import bg3 from "../../assets/images/bg/bg3.jpg";
import bg4 from "../../assets/images/bg/bg4.jpg";
import bg5 from "../../assets/images/bg/bg5.jpg";
import bg6 from "../../assets/images/bg/bg6.jpg";
import Exclamation from "../../assets/images/Exclamation.svg";
import iconSuccess from "../../assets/images/iconSuccess.svg";
import close from "../../assets/images/close.svg";
import ContentNotFound from "../../assets/images/ContentNotFound.png";
import searchDropDown from "../../assets/images/searchDropDown.jpg";
import plus from "../../assets/images/plus.svg";
import Add from "../../assets/images/Add.svg";
import plusPure from "../../assets/images/plusPure.svg";
import checkmarkPure from "../../assets/images/checkmarkPure.svg";
import communityPicks from "../../assets/images/communityPicks.jpg";
import trendingMawsuah from "../../assets/images/trendingMawsuah.jpg";
import editArticle from "../../assets/images/menuTabs/editArticle.svg";
import EditorCorner from "../../assets/images/menuTabs/EditorCorner.svg";
import homePage from "../../assets/images/menuTabs/homePage.svg";
import Notifications from "../../assets/images/menuTabs/Notifications.svg";
import profile from "../../assets/images/menuTabs/profile.svg";
import readingList from "../../assets/images/menuTabs/readingList.svg";
import ReviewerCorner from "../../assets/images/menuTabs/ReviewerCorner.svg";
import trendingNow from "../../assets/images/menuTabs/trendingNow.svg";
import editProfile from "../../assets/images/UserProfileSettings/editProfile.svg";
import settingsProfile from "../../assets/images/UserProfileSettings/settingsProfile.svg";
import NotificationsBlue from "../../assets/images/UserProfileSettings/NotificationsBlue.svg";
import editProfileBlue from "../../assets/images/UserProfileSettings/editProfileBlue.svg";
import settingsProfileBlue from "../../assets/images/UserProfileSettings/settingsProfileBlue.svg";
import accountSettings from "../../assets/images/UserProfileSettings/Settings/accountSettings.svg";
import Connections from "../../assets/images/UserProfileSettings/Settings/Connections.svg";
import contentPreferences from "../../assets/images/UserProfileSettings/Settings/contentPreferences.svg";
import CV from "../../assets/images/UserProfileSettings/Settings/CV.svg";
import Deactivate from "../../assets/images/UserProfileSettings/Settings/Deactivate.svg";
import deleteAccount from "../../assets/images/UserProfileSettings/Settings/delete.svg";
import Password from "../../assets/images/UserProfileSettings/Settings/Password.svg";
import Sessions from "../../assets/images/UserProfileSettings/Settings/Sessions.svg";
import EditorCornerBlue from "../../assets/images/UserProfileSettings/Settings/blue/EditorCornerBlue.svg";
import homePageBlue from "../../assets/images/UserProfileSettings/Settings/blue/homePageBlue.svg";
import profileBlue from "../../assets/images/UserProfileSettings/Settings/blue/profileBlue.svg";
import readingListBlue from "../../assets/images/UserProfileSettings/Settings/blue/readingListBlue.svg";
import ReviewerCornerBlue from "../../assets/images/UserProfileSettings/Settings/blue/ReviewerCornerBlue.svg";
import trendingNowBlue from "../../assets/images/UserProfileSettings/Settings/blue/trendingNowBlue.svg";
import editArticleBlue from "../../assets/images/UserProfileSettings/Settings/blue/editArticleBlue.svg";
import arrowBottomBlue from "../../assets/images/UserProfileSettings/Notifications/arrowBottomBlue.svg";
import Video from "../../assets/images/UserProfile/Video.svg";
import Like from "../../assets/images/UserProfile/Like.svg";
import Share from "../../assets/images/UserProfile/Share.svg";
import Badge1 from "../../assets/images/UserProfileAuth/Badge1.svg";
import Badge2 from "../../assets/images/UserProfileAuth/Badge2.svg";
import Badge3 from "../../assets/images/UserProfileAuth/Badge3.svg";
import likeBlue from "../../assets/images/UserProfileAuth/Notifications/likeBlue.svg";
import notificationsPicture from "../../assets/images/UserProfileAuth/Notifications/notificationsPicture.jpg";
import bookmarkRoundedBlue from "../../assets/images/UserProfileAuth/Notifications/bookmarkRoundedBlue.svg"
import bookmarkRoundedGray from "../../assets/images/UserProfileAuth/Notifications/bookmarkRoundedGray.svg"
import logoGray from "../../assets/images/UserProfileAuth/Notifications/logoGray.svg"
import highlighter from "../../assets/images/UserProfileAuth/Notifications/highlighter.svg"
import highlighterWhite from "../../assets/images/Articles/highlighterWhite.svg"
import addUser from "../../assets/images/UserProfileAuth/Notifications/addUser.svg"
import rocket from "../../assets/images/UserProfileAuth/Writer/rocket.png";
import verifiedGreen from "../../assets/images/UserProfileAuth/Writer/verifiedGreen.svg";
import reviewerCornerOrange from "../../assets/images/UserProfileAuth/Writer/reviewerCornerOrange.svg";
import noteMark from "../../assets/images/UserProfileAuth/Writer/noteMark.svg";
import closeRejected from "../../assets/images/UserProfileAuth/Writer/closeRejected.svg";
import editorCornerBlack from "../../assets/images/UserProfileAuth/Writer/editorCornerBlack.svg";
import AddMember from "../../assets/images/UserInvites/AddMember.svg";
import AddMemberBlue from "../../assets/images/UserInvites/AddMemberBlue.svg";
import BellNotification from "../../assets/images/appHeader/Bell.svg";
import LogOut from "../../assets/images/appHeader/logOut.svg";
import LogOutBlack from "../../assets/images/appHeader/logOutBlack.svg";
import editHeader from "../../assets/images/appHeader/Edit.svg";
import editBlackHeader from "../../assets/images/appHeader/EditBlack.svg";
import writeHeader from "../../assets/images/appHeader/Write.svg";
import writeBlackHeader from "../../assets/images/appHeader/WriteBlack.svg";
import reviewHeader from "../../assets/images/appHeader/Review.svg";
import reviewBlackHeader from "../../assets/images/appHeader/ReviewBlack.svg";
import EditorNotification from "../../assets/images/appHeader/Editor.svg";
import ReviewerNotification from "../../assets/images/appHeader/Reviewer.svg";
import WriterNotification from "../../assets/images/appHeader/Writer.svg";
import searchWhite from "../../assets/images/appHeader/searchWhite.svg";
import arrowRight from "../../assets/images/arrowRight.svg";
import ArticleBG from "../../assets/images/Articles/ArticleBG.jpg";
import blackCircle from "../../assets/images/Articles/blackCircle.svg";
import viewers from "../../assets/images/Articles/viewers.svg";
import more from "../../assets/images/Articles/more.svg";
import backgroundAbout from "../../assets/images/backgroundAbout.png";
import aboutPageReward from "../../assets/images/AboutPage/aboutReward.png";
import aboutPageArticle from "../../assets/images/AboutPage/aboutPageArticle.png";
import PartnersBlock1 from "../../assets/images/PartnersPage/PartnersBlock1.jpg";
import PartnersBlock2 from "../../assets/images/PartnersPage/PartnersBlock2.png";
import PartnersBlock3 from "../../assets/images/PartnersPage/PartnersBlock3.png";
import Harvard from "../../assets/images/PartnersPage/harvard.jpg";
import PartnerLogo from "../../assets/images/PartnersPage/PartnerLogo.png";
import alexandria from "../../assets/images/PartnersPage/alexandria.jpg";
import aub from "../../assets/images/PartnersPage/aub.jpg";
import twitterGray from "../../assets/images/Articles/twitterGray.svg";
import linkedinGray from "../../assets/images/Articles/linkedinGray.svg";
import facebookGray from "../../assets/images/Articles/facebookGray.svg";
import noteArticle from "../../assets/images/Articles/articleNote.svg";
import Flag from "../../assets/images/Articles/Flag.svg";
import Edit from "../../assets/images/Articles/Edit.svg";
import Files from "../../assets/images/Articles/Files.svg";
import star from "../../assets/images/Modals/star.svg";
import commentLines from "../../assets/images/Modals/commentLines.svg";
import commentLinesBlack from "../../assets/images/Modals/commentLinesBlack.svg";
import reviewerHeaderChat from "../../assets/images/Modals/reviewerHeaderChat.svg";
import editArticleBlack from "../../assets/images/Dashboard-article/editArticleBlack.svg";
import infoOutline from "../../assets/images/Dashboard-article/infoOutline.svg";
import cog from "../../assets/images/Dashboard-article/cog.svg";
import redo from "../../assets/images/Dashboard-article/redo.svg";
import undo from "../../assets/images/Dashboard-article/undo.svg";
import bold from "../../assets/images/Dashboard-article/bold.svg";
import underline from "../../assets/images/Dashboard-article/underline.svg";
import link from "../../assets/images/Dashboard-article/link.svg";
import cogWhite from "../../assets/images/Dashboard-article/cogWhite.svg";
import redoWhite from "../../assets/images/Dashboard-article/redoWhite.svg";
import undoWhite from "../../assets/images/Dashboard-article/undoWhite.svg";
import boldWhite from "../../assets/images/Dashboard-article/boldWhite.svg";
import note from "../../assets/images/Dashboard-article/note.svg";
import noteWhite from "../../assets/images/Dashboard-article/noteWhite.svg";
import underlineWhite from "../../assets/images/Dashboard-article/underlineWhite.svg";
import linkWhite from "../../assets/images/Dashboard-article/linkWhite.svg";
import addTextEditor from "../../assets/images/Dashboard-article/addTextEditor.svg";
import copy from "../../assets/images/Dashboard-article/copy.svg";
import deleteTextEditor from "../../assets/images/Dashboard-article/deleteTextEditor.svg";
import fontSize from "../../assets/images/Dashboard-article/fontSize.svg";
import fontSizeWhite from "../../assets/images/Dashboard-article/fontSizeWhite.svg";
import textAlignLeft from "../../assets/images/Dashboard-article/textAlignLeft.svg";
import textAlignLeftWhite from "../../assets/images/Dashboard-article/textAlignLeftWhite.svg";
import SliderCheck from "../../assets/images/Dashboard-article/SliderCheck.svg";
import SliderCheckGray from "../../assets/images/Dashboard-article/SliderCheckGray.svg";
import textAlignRight from "../../assets/images/Dashboard-article/textAlignRight.svg";
import textAlignRightWhite from "../../assets/images/Dashboard-article/textAlignRightWhite.svg";
import textAlignCenter from "../../assets/images/Dashboard-article/textAlignCenter.svg";
import textAlignCenterWhite from "../../assets/images/Dashboard-article/textAlignCenterWhite.svg";
import youtube from "../../assets/images/Dashboard-article/youtube.svg";
import youtubeWhite from "../../assets/images/Dashboard-article/youtubeWhite.svg";
import speakerNotes from "../../assets/images/Dashboard-article/speakerNotes.svg";
import speakerNotesWhite from "../../assets/images/Dashboard-article/speakerNotesWhite.svg";
import quote from "../../assets/images/Dashboard-article/quote.svg";
import quoteWhite from "../../assets/images/Dashboard-article/quoteWhite.svg";
import insertPhoto from "../../assets/images/Dashboard-article/insertPhoto.svg";
import insertPhotoWhite from "../../assets/images/Dashboard-article/insertPhotoWhite.svg";
import menuDown from "../../assets/images/Dashboard-article/menuDown.svg";
import menuDownWhite from "../../assets/images/Dashboard-article/menuDownWhite.svg";
import numberedList from "../../assets/images/Dashboard-article/numberedList.svg";
import bulletedList from "../../assets/images/Dashboard-article/bulletedList.svg";
import paragraph from "../../assets/images/Dashboard-article/paragraph.svg";
import numberedListWhite from "../../assets/images/Dashboard-article/numberedListWhite.svg";
import bulletedListWhite from "../../assets/images/Dashboard-article/bulletedListWhite.svg";
import paragraphWhite from "../../assets/images/Dashboard-article/paragraphWhite.svg";
import profilePlaceholder from "../../assets/images/appHeader/profile_placeholder.png";
import addInterest from "../../assets/images/interests/addInterest.svg";
import ArticleReview from "../../assets/images/ArticleReview/ArticleReview.svg";
import bulletedListDot from "../../assets/images/Dashboard-article/bulletedListDot.svg";
import logoBlack from "../../assets/images/AboutPage/LOGOBLACK.svg";
import notificationBlack from "../../assets/images/AboutPage/notificationBlack.svg";
import rewards1 from "../../assets/images/AboutPage/Rewards1.png";
import rewards2 from "../../assets/images/AboutPage/Rewards2.png";
import rewards3 from "../../assets/images/AboutPage/Rewards3.png";
import rewards4 from "../../assets/images/AboutPage/Rewards4.png";
import rewards5 from "../../assets/images/AboutPage/Rewards5.png";
import rectangle174 from "../../assets/images/AboutPage/Rectangle174.png";
import rectangle200 from "../../assets/images/AboutPage/Rectangle200.png";
import rectangle204 from "../../assets/images/AboutPage/Rectangle204.png";
import rectangle173 from "../../assets/images/AboutPage/Rectangle173.png";
import rectangle203 from "../../assets/images/AboutPage/Rectangle203.png";
import rectangle172 from "../../assets/images/AboutPage/Rectangle172.png";
import rectangle205 from "../../assets/images/AboutPage/Rectangle205.png";
import rectangle176 from "../../assets/images/AboutPage/Rectangle176.png";
import rectangle177 from "../../assets/images/AboutPage/Rectangle177.png";
import frame1 from "../../assets/images/AboutPage/Frame1.svg";
import frame2 from "../../assets/images/AboutPage/Frame2.svg";
import slider1 from "../../assets/images/homepage/slider1.png";
import slider2 from "../../assets/images/homepage/slider2.png";
import slider3 from "../../assets/images/homepage/slider3.png";
import slider4 from "../../assets/images/homepage/slider4.png";
import mawsuahLogo from "../../assets/images/StaticPages/Careers/MawsuahLogo.svg";
import GroupLogo from "../../assets/images/AppFooter/GroupLogo.svg";
import InstagramGray from "../../assets/images/AppFooter/InstagramGray.svg";
import Instagram from "../../assets/images/AppFooter/Instagram.svg";
import linkedInWhite from "../../assets/images/AppFooter/linkedIn.svg";
import YoutubeGray from "../../assets/images/AppFooter/YoutubeGray.svg";
import SendMessage from "../../assets/images/Articles/SendMessage.svg";
import facebookShare from "../../assets/images/StaticPages/SharePage/facebook.svg";
import facebookHoverShare from "../../assets/images/StaticPages/SharePage/facebookHover.svg";
import instgramShare from "../../assets/images/StaticPages/SharePage/instgram.svg";
import instgramHoverShare from "../../assets/images/StaticPages/SharePage/instgramHover.svg";
import linkedinShare from "../../assets/images/StaticPages/SharePage/linkedin.svg";
import linkedinHoverShare from "../../assets/images/StaticPages/SharePage/linkedinHover.svg";
import twitterShare from "../../assets/images/StaticPages/SharePage/twitter.svg";
import twitterHoverShare from "../../assets/images/StaticPages/SharePage/twitterHover.svg";
import youtubeShare from "../../assets/images/StaticPages/SharePage/youtube.svg";
import youtubeHoverShare from "../../assets/images/StaticPages/SharePage/youtubeHover.svg";

const icons: { [name: string]: string } = {
    iconSendMessage: SendMessage,
    iconVerifiedSmallWhiteBG: verifiedSmallWhiteBG,
    iconLogOut: LogOut,
    iconLogOutBlack: LogOutBlack,
    iconGroupLogo : GroupLogo,
    iconInstagram : Instagram,
    iconLinkedInWhite : linkedInWhite,
    iconInstagramGray : InstagramGray,
    iconYoutubeGray : YoutubeGray,
    iconMawsuahLogo: mawsuahLogo,
    iconSliderCheck: SliderCheck,
    iconSliderCheckGray: SliderCheckGray,
    iconSlider1: slider1,
    iconSlider2: slider2,
    iconSlider3: slider3,
    iconSlider4: slider4,
    iconFrame1: frame1,
    iconFrame2: frame2,
    iconRectangle174 : rectangle174,
    iconRectangle173 : rectangle173,
    iconRectangle172 : rectangle172,
    iconRectangle200 : rectangle200,
    iconRectangle205 : rectangle205,
    iconRectangle204 : rectangle204,
    iconRectangle203 : rectangle203,
    iconRectangle176 : rectangle176,
    iconRectangle177 : rectangle177,
    iconRewards1: rewards1,
    iconRewards2: rewards2,
    iconRewards3: rewards3,
    iconRewards4: rewards4,
    iconRewards5: rewards5,
    iconLogoBlack : logoBlack,
    iconNotificationBlack : notificationBlack,
    iconBulletedListDot: bulletedListDot,
    iconArticleReview : ArticleReview,
    iconAddInterest: addInterest,
    iconInsertPhotoWhite : insertPhotoWhite,
    iconProfilePlaceholder : profilePlaceholder,
    iconHighlighterWhite : highlighterWhite,
    iconYoutube : youtube,
    iconYoutubeWhite : youtubeWhite,
    iconSpeakerNotes : speakerNotes,
    iconSpeakerNotesWhite : speakerNotesWhite,
    iconQuote : quote,
    iconQuoteWhite : quoteWhite,
    iconInsertPhoto : insertPhoto,
    iconMenuDown : menuDown,
    iconMenuDownWhite : menuDownWhite,
    iconNumberedList : numberedList,
    iconBulletedList : bulletedList,
    iconParagraph : paragraph,
    iconNumberedListWhite : numberedListWhite,
    iconBulletedListWhite : bulletedListWhite,
    iconParagraphWhite : paragraphWhite,
    iconFontSize : fontSize,
    iconFontSizeWhite : fontSizeWhite,
    iconTextAlignLeft : textAlignLeft,
    iconTextAlignRight : textAlignRight,
    iconTextAlignCenter : textAlignCenter,
    iconTextAlignLeftWhite : textAlignLeftWhite,
    iconTextAlignRightWhite : textAlignRightWhite,
    iconTextAlignCenterWhite : textAlignCenterWhite,
    iconDeleteTextEditor : deleteTextEditor,
    iconCopy : copy,
    iconAddTextEditor : addTextEditor,
    iconLink : link,
    iconBold : bold,
    iconUnderline : underline,
    iconRedo : redo,
    iconUndo : undo,
    iconCog : cog,
    iconLinkWhite : linkWhite,
    iconBoldWhite : boldWhite,
    iconUnderlineWhite : underlineWhite,
    iconRedoWhite : redoWhite,
    iconUndoWhite : undoWhite,
    iconCogWhite : cogWhite,
    iconCommentLinesBlack : commentLinesBlack,
    iconReviewerHeaderChat : reviewerHeaderChat,
    iconInfoOutline : infoOutline,
    iconEditArticleBlack : editArticleBlack,
    iconCommentLines : commentLines,
    iconStar : star,
    iconFiles : Files,
    iconFlag : Flag,
    iconEdit : Edit,
    iconTwitterGray : twitterGray,
    iconLinkedinGray : linkedinGray,
    iconFacebookGray : facebookGray,
    iconHarvard : Harvard,
    iconPartnerLogo : PartnerLogo,
    iconAlexandria : alexandria,
    iconAub : aub,
    iconPartnersBlock1 : PartnersBlock1,
    iconPartnersBlock2 : PartnersBlock2,
    iconPartnersBlock3 : PartnersBlock3,
    iconAboutPageArticle : aboutPageArticle,
    iconBackgroundAbout: backgroundAbout,
    iconMore : more,
    iconViewers : viewers,
    iconBlackCircle : blackCircle,
    iconArticleBG : ArticleBG,
    iconArrowRight : arrowRight,
    iconSearchWhite : searchWhite,
    iconEditHeader: editHeader,
    iconEditHeaderBlack: editBlackHeader,
    iconWriteHeader: writeHeader,
    iconWriteBlackHeader: writeBlackHeader,
    iconReviewHeader: reviewHeader,
    iconReviwBlackHeader: reviewBlackHeader,
    iconBellNotification: BellNotification,
    iconEditorNotification: EditorNotification,
    iconReviewerNotification: ReviewerNotification,
    iconWriterNotification: WriterNotification,
    iconAddMemberBlue: AddMemberBlue,
    iconAddMember: AddMember,
    iconEditorCornerBlack: editorCornerBlack,
    iconCloseRejected: closeRejected,
    iconReviewerCornerOrange: reviewerCornerOrange,
    iconNoteMark: noteMark,
    iconVerifiedGreen: verifiedGreen,
    iconRocket: rocket,
    iconHighlighter: highlighter,
    iconAddUser: addUser,
    iconLogoGray : logoGray,
    iconBookmarkRoundedGray : bookmarkRoundedGray,
    iconBookmarkRoundedBlue : bookmarkRoundedBlue,
    iconNotificationsPicture: notificationsPicture,
    iconLikeBlue : likeBlue,
    iconBadge1: Badge1,
    iconBadge2: Badge2,
    iconBadge3: Badge3,
    iconShare: Share,
    iconLike: Like,
    iconVideo: Video,
    iconEditorCornerBlue: EditorCornerBlue,
    iconEditArticleBlue: editArticleBlue,
    iconHomePageBlue: homePageBlue,
    iconProfileBlue: profileBlue,
    iconReadingListBlue: readingListBlue,
    iconReviewerCornerBlue: ReviewerCornerBlue,
    iconTrendingNowBlue: trendingNowBlue,
    iconAccountSettings: accountSettings,
    iconArrowBottomBlue: arrowBottomBlue,
    iconConnections: Connections,
    iconContentPreferences: contentPreferences,
    iconCV: CV,
    iconDeactivate: Deactivate,
    iconDeleteAccount: deleteAccount,
    iconPassword: Password,
    iconSessions: Sessions,
    iconNotificationsBlue: NotificationsBlue,
    iconSettingsProfileBlue: settingsProfileBlue,
    iconEditProfileBlue: editProfileBlue,
    iconSettingsProfile: settingsProfile,
    iconEditProfile: editProfile,
    iconEditArticle: editArticle,
    iconEditorCorner: EditorCorner,
    iconHomePage: homePage,
    iconNotifications: Notifications,
    iconProfile: profile,
    iconReadingList: readingList,
    iconReviewerCorner: ReviewerCorner,
    iconTrendingNow: trendingNow,
    iconVerifiedSmallWhite: verifiedSmallWhite,
    iconTrendingMawsuah: trendingMawsuah,
    iconCommunityPicks: communityPicks,
    iconCheckmarkPure: checkmarkPure,
    iconAdd: Add,
    iconPlus : plus,
    iconPlusPure : plusPure,
    iconSearchDropDown: searchDropDown,
    iconClose: close,
    iconSuccess: iconSuccess,
    iconCandy: Candy,
    iconLogoAUB: logoAUB,
    iconLogoCFFS: logoCFFS,
    iconLogoHBR: logoHBR,
    iconLogoSharja: logoSharja,
    iconVerifiedLarge: verifiedLarge,
    iconVerifiedSmall: verifiedSmall,
    iconGrayCircle: grayCircle,
    iconPhoto72: photo72,
    iconPhoto100: photo100,
    iconPhoto140: photo140,
    iconPhoto40: photo40,
    iconTelescope: Telescope,
    iconColorlessBook: colorlessBook,
    iconSmallBook: smallBook,
    iconPhoto32: photo32,
    iconChosenTopicsIcon: chosenTopicsIcon,
    iconChosenTopicsBigIcon: chosenTopicsBigIcon,
    iconArrowLeft: arrowLeft,
    iconArrowLeftBlack: arrowLeftBlack,
    iconLogo: logo,
    iconLogoBig: logoBig,
    iconContentNotFound : ContentNotFound,
    iconLogoBig1: logoBig1,
    iconLogoBigLogin: logoBigLogin,
    iconGroup: Group,
    iconYouTube: youTube,
    iconTwitter: twitter,
    iconFacebook: facebook,
    iconLinkedIn: linkedIn,
    iconGroupSmall: GroupSmall,
    iconLogoSmall: logoSmall,
    iconClip: Clip,
    iconSearch: search,
    iconBell: bell,
    iconPlanet: planet,
    iconInterests: interests,
    iconBookmarkRounded: bookmarkRounded,
    iconBookmarkRoundedBlack: bookmarkRoundedBlack,
    iconMoon: Moon,
    iconLoginPage: loginPage,
    iconGroupBlack: GroupBlack,
    iconLogoWithBg: logoWithBg,
    iconRedStar: redStar,
    iconGoogleLogo: googleLogo,
    iconFacebookLogoButton: facebookLogoButton,
    iconLogoBg: logoBg,
    iconArrowBottom: arrowBottom,
    bg1: bg1,
    bg2: bg2,
    bg3: bg3,
    bg4: bg4,
    bg5: bg5,
    bg6: bg6,
    iconExclamation: Exclamation,
    iconFacebookShare: facebookShare, 
    iconFacebookHoverShare: facebookHoverShare, 
    iconInstgramShare: instgramShare, 
    iconInstgramHoverShare: instgramHoverShare, 
    iconLinkedinShare: linkedinShare, 
    iconLinkedinHoverShare: linkedinHoverShare, 
    iconTwitterShare: twitterShare, 
    iconTwitterHoverShare: twitterHoverShare, 
    iconYoutubeShare: youtubeShare, 
    iconYoutubeHoverShare: youtubeHoverShare,
    iconNote: note,
    iconNotewhite: noteWhite,
    iconArticleNote: noteArticle,
    iconAboutPageReward : aboutPageReward
};

interface IconsProps {
    alt?: string;
    style?: any;
    name: string;
    className?: string;
    onClick?: () => void;
    src ?: string;
};
  
export const Icon: FunctionComponent<IconsProps> = (props) => {
    const { name, className, onClick, style, alt, src } = props;

    return (
        <img src={src ? src : icons[name]} style={style} className={className} alt={alt ? alt : name} onClick={onClick}/>
    )
}