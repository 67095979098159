import React, { FunctionComponent } from "react"
import { Col, Row } from "react-bootstrap"
import { Btn } from "../../../../components/buttons/btn.component"
import { DropDown } from "../../../../components/dropdown/dropdown.component";
import { InputObligatory } from "../../../../components/inputs/input.component";
import { SpacerVertical } from "../../../../components/spacer/spacer.component";
import { View } from "../../../../components/view/view.component"

import "./account-settings-form.scss";


export const AccountSettingsForm: FunctionComponent = () => {


    return (
        <>
        <View className="AccountSettingsForm">
            <Row>
                <Col lg={6}>
                    <InputObligatory name="" label="اسم المستخدم" placeholder="123"/>
                </Col>
            </Row>
            <Row className="silverInput">
                <Col lg={6}>
                    <InputObligatory name="" label="البريد الإلكتروني" placeholder="123" />
                </Col>
                <Col lg={6}>
                    <InputObligatory name="" label="الهاتف" placeholder="123" />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <DropDown name=""  label="الجنس" placeholder="ذكر" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
            </Row>
            <SpacerVertical height={"2.500em"}/>
            <View>تاريخ الميلاد</View>
            <Row>
                <Col lg={4}>
                    <DropDown name="" label="" placeholder="اليوم" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
                <Col lg={4}>
                    <DropDown name="" label="" placeholder="الشهر" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
                <Col lg={4}>
                    <DropDown name="" label=""  placeholder="السنة" value={""} options={["123", "234", "456"]} onChange={() => {}}/>
                </Col>
            </Row>
        </View>
        <View className="AccountSettingsButton">
            <Btn title="حفظ التعديلات"/>
        </View>
        </>
    )
}