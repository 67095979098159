import React, { FunctionComponent } from "react";

interface ViewProps {
  title: string;
  className?: string;
  onClick?: () => void;
  style?: any;
}

export const Header1: FunctionComponent<ViewProps> = (props) => {
  const { title, className, onClick } = props;
  return (
    <h1 className={className} onClick={onClick}>
      <span itemProp="name">{title}</span>
      {props.children}
    </h1>
  )
}

export const Header2: FunctionComponent<ViewProps> = (props) => {
  const { title, className, style } = props;
  return (
    <h2 className={className}>
      <span style={style}>{title}</span>
      {props.children}
    </h2>
  )
}

export const Header3: FunctionComponent<ViewProps> = (props) => {
  const { title, className, style } = props;
  return (
    <h3 className={className} {...props} style={style}>
      <span>{title}</span>
      {props.children}
    </h3>
  )
}