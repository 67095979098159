import React, { FunctionComponent } from 'react';
import { View } from '../../../components/view/view.component';

export const TwitterAuthPage: FunctionComponent = () => { 

    return (
        <View className="App">
            <span>Thank you for twitter authorization</span>
        </View>
    )
}