import React, { FunctionComponent } from "react"
import { Icon } from "../../../../components/icons/icon.component";
import { View } from "../../../../components/view/view.component"

import "./account-settings-form.scss";

interface DeleteAccountProps {
    text: string
}

export const DeleteAccount: FunctionComponent<DeleteAccountProps> = (props) => {

    const {text} = props;

    return (
        <View className="DeleteAccount">
            {text}
            <Icon name="iconArrowLeft"/>
        </View>
    )
}