import React, { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { Icon } from "../../icons/icon.component";
import { SpacerVertical } from "../../spacer/spacer.component";
import { View } from "../../view/view.component";

interface ProfileMenuItemProps {
    iconName: string;
    title: string;
    status: boolean;
    url: string;
}

export const ProfileMenuItem: FunctionComponent<ProfileMenuItemProps> = (props) => {

    const {iconName, title, status, url} = props;

    const history = useHistory();

    
    return (
        <>
            <View onClick={() => {
                history.push(`/user_profile_auth/${url}`);
                }} style={status ? {color: "var(--blueColor)"} : {}}>
                <Icon name={status ? `${iconName}Blue` : iconName}/>
                {title}
            </View>
            <SpacerVertical height={"0.571em"}/>
        </>
    )
}