import React, { FunctionComponent } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { InterestsData } from '../../shared/interfaces';
import { Icon } from '../icons/icon.component';
import { Header3 } from '../typo/header.component';
import { View } from '../view/view.component';
import { RecommendedInterestsItem } from './nested/recommended-interests-item.component';

import "./recommended-interests.scss"
interface RecommendedInterestsProps {
    title : string;
    randomInterests?: InterestsData[];
}

export const RecommendedInterests: FunctionComponent<RecommendedInterestsProps> = (props) => {

    const { title, randomInterests } = props;

    const history = useHistory();
    
    return (
        <View className="RecommendedInterests">
            <Container>
                <Header3 className="writersHeader RecommendedInterestsHeader" title={title} onClick={() => {history.push(`/interests_landing`)}}/>
                <div className="smallLine"></div> 
                <Row >
                    {randomInterests?.slice(0,3)?.map((item, indx) => {
                            return(
                                <Col className="RecommendedInterestsContent" key={item.id} onClick={() => {history.push(`/interests_tabs/${item.id}`)}}>
                                    <View className="bg">
                                        <Icon src={item.image_url} name={`bg${indx + 1}`} alt={item.title}/>
                                    </View>
                                    <RecommendedInterestsItem authors_count={item.authors_count} articles_count={item.articles_count} followers_count={item.followers_count} title={item.title}/>
                                </Col>
                            )
                       })}
                </Row>
                <View className="moreRecommendedInterests" onClick={() => {history.push(`/interests_landing`)}}>
                    <p>استكشاف المزيد</p>
                    <Icon name="iconArrowLeft" />
                </View>
                
            </Container>
        </View>
    );
  }