import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
import { Icon } from '../../../components/icons/icon.component';
// import { BtnColorless } from '../../../components/buttons/btn.component';
import privacyBG from '../../../assets/images/PrivacyPage/privacyBG.png';

import "./rules.scss";

export const RulesPage: FunctionComponent = () => { 

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
        <AppHeader isWhite={true}/>
        <View className="CareersPage">
            <View className="CareersPageHeader" title="careers_photo" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(${privacyBG})`}}>
                <Container style={{marginTop: "96px"}}>
                    <View className="CareersPageHeaderContent">
                        <Header1 title="قوانين موسوعة" className="header1"/>
                    </View>
                    <View className="CareersPageHeaderContent">
                        <Icon name="iconMawsuahLogo"/>
                        <span className="bigText">موسوعة</span>
                        <Icon name="iconVerifiedSmallWhiteBG"/>
                    </View>
                </Container>
            </View>
            <SpacerVertical height={"4.5em"}/>
            <View className="CareersPageContent">
                <Container>
                    <Row>
                        <Col lg={3} className="CareersPageContentSecondColumn">
                            {/* <View className="CareersPageContentSecondColumnMenu" style={{borderBottom: "0px"}}>
                                <View className="Item">سياسية الخصوصية</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View>
                            <View className="CareersPageContentSecondColumnDisabled">
                                <View className="Item">المساعدة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المجتمعات</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">معتمدون لدى </View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الشركاء</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المدونة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الوظائف</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">شروط الاستحدام</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">التواصل</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View> */}
                        </Col>
                        <Col className="CareersPageContentFirstColumn" lg={9}>
                            <Header3 className="writersHeader" title="نحافظ على كل ما يتعلق بك"/>
                            <div className="smallLine"></div>
                            <View className="CareersPageContentFirstColumnContent">
                            <View className="smallHeader">
                           
                            </View>
                            <View className="CareersAuthor">
                                <View className="CareersAuthorPhoto">
                                    <div style={{cursor: "pointer"}}>
                                      <Icon name="iconProfilePlaceholder" alt="author_profile_photo" className="AuthorDataImage"/>
                                      <span className="AuthorPhotoSpan">موسوعة</span>
                                      <Icon style={{marginRight: "0.179em"}} name="iconVerifiedSmall"/>
                                    </div>
                                     {/* <BtnColorless title="تابِع"/> */}
                                </View> 
                                <View className="CareersAuthorText">
                                    <span className="spanBold">تكنولوجيا</span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>20 تموز </span>
                                    <Icon name="iconGrayCircle"/>
                                    <span>4 دقائق قراءة</span>
                                </View>
                            </View>
                            <SpacerVertical height={"2.3em"}/>
                            <View className="PrivacyContent">
                                <h4>
                                قوانين الكتابة:
                                </h4>
                                <p>عملية الكتابة قبل النشر هي عملية سرية وشفافة، ولن يتم إظهار اسم الكاتب أو المراجع أو المحرر، حيث تتيح لك موسوعة إمكانية إنشاء مقال في جميع المجالات، طالما المحتوى أصلي، ويتضمن على مراجع موثوقة.</p>                               
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                القوانين الرئيسية:
                                </h4>
                                <ul>
                                    <li>محتوى أصلي غير منسوخ من مقالات أخرى. </li>
                                    <li>عدم كتابة محتوى حساس أو مسيء.</li>
                                    <li>المحتوى يجب أن يعبّر عن حقائق وليس آراء.</li>
                                    <li>يحتوي على مراجع (3 مراجع على الأقل).</li>
                                    <li>في حال إرفاق صور ، يجب أن تكون الصور ذات صلة بالمقالة التي تظهر فيها مع ذكر المصدر.</li>
                                    <li>في حال إرفاق فيديوهات، يجب أن يكون الفيديوهات ذات صلة بالمقالة التي يظهر فيها مع ذكر المصدر.</li>                                    
                                    <li>الاقتباس والإشارة إلى مؤلف المصدر في حال الحصول على بيانات أو معلومات.</li>
                                    <li>الموافقة على مراجعة وتحرير المقال من قِبل فريق مختّص بشكل سري وشفاف.</li>
                                    <li>احترام التعليقات المرسلة من قِبل فريق المراجعين، مع إمكانية النقاش معهم بطريقة مهنية واحترافية.</li>
                                    <li>لا تقم بهجمات شخصية في أي مكان في موسوعة؛ إذ يكون التعليق على المحتوى، وليس على المراجع أو المحرر، فالهجمات الشخصية تضر بمجتمع موسوعة.</li>
                                    <li>اتباع مؤشر قوة المقال الموجود داخل صفحة "كتابة المقال".</li>
                                    <li>تجنب كتابة مقال عنك.</li>
                                    <li>شارك خبرتك، ولكن لا تجادل من السلطة.</li>
                                    <li>احترام وقت المجتمع، والقيام بتعديل المقال بالوقت المطلوب.</li>
                                    <li>قُم بمراجعة المقال قبل إرساله، حيث لن تستطيع التعديل بعد نشره.</li>
                                    <li>تأكد من الاستمتاع والتعلّم أثناء عملية الكتابة.</li>
                                </ul>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                قوانين المراجعة:
                                </h4>
                                <p>
                                عملية المراجعة قبل النشر هي عملية سرية وشفافة ولن يتم إظهار اسم الكاتب أو المراجع أو المحرر
                                </p>
                                <ul>
                                    <li>عدم الرفض إذا لا تتفق شخصياً مع محتوى المقال، كن موضوعياً في المراجعة. </li>
                                    <li> لا تقم بهجمات شخصية في أي مكان في موسوعة؛ إذ يكون التعليق على المحتوى، وليس على المراجع أو المحرر، فالهجمات الشخصية تضر بمجتمع موسوعة.</li>
                                    <li>إضافة تعليقات بوضوح وإيجاز.</li>
                                    <li>شارك خبرتك، ولكن لا تجادل من السلطة.</li>
                                    <li>التركيز على مراجعة المعلومات والحقائق المذكورة وليس الاتفاق الشخصي مع المحتوى. </li>
                                    <li>التأكد من المصادر المذكورة.</li>
                                    <li>في حال اقتباس من جملة من شخص آخر، يجب عليك أن تذكر الكاتب بالإشارة للمصدر.</li>                                    
                                    <li>عدم إرسال تعليقات تعبّر عن آراء شخصية، التعليق يجب أن يكون على صحة المحتوى فقط.</li>
                                    <li>اكتب تعليقات ستكون راضيًا عنها في حال كنت كاتب. </li>
                                    <li>في حال الرفض، يجب ذكر السبب والشرح عنه. </li>
                                    <li>احترام وقت المجتمع، والقيام بمراجعة المقال بالوقت المطلوب.</li>
                                    <li>قُم بمراجعة المقال قبل إرساله، حيث لن تستطيع التعديل بعد نشره.</li>
                                    <li>تأكد من الاستمتاع والتعلم أثناء عملية المراجعة.</li>
                                </ul>
                            </View>
                            

                            <View className="PrivacyContent">
                                <h4>
                                قوانين الرفض (من قِبل المراجع):


                                </h4>
                                <p>
                                عملية المراجعة قبل النشر هي عملية سرية وشفافة ولن يتم إظهار اسم الكاتب أو المراجع أو المحرر.
</p>
<p>
الأسباب الرئيسية لرفض المقال في موسوعة؛ إذ يجب اختيار واحدة من الأسباب مع إضافة شرح عن السبب. 
                     </p>
                     <ul>
<li>محتوى غير أصلي.</li>
<li>لا يوجد مراجع.</li>
<li>حقائق غير صحيحة. </li>
<li>مقال ضعيف.</li>
<li>محتوى مسيء.</li>
</ul>
                            </View>
                            <View className="PrivacyContent">
                                <h4>
                                قوانين التحرير:
                                </h4>
                                <p>
                                عملية التحرير قبل النشر هي عملية سرية وشفافة ولن يتم إظهار اسم الكاتب أو المراجع أو المحرر.
                                </p>
                                <ul>
                                    <li>إعادة الصياغة أو تحرير النص لتحسين قواعد الكتابة فقط وليس لتغير المحتوى.</li>
                                    <li>تصحيح الأخطاء الإملائية.</li>
                                    <li>عدم حذف أي جزء من النص، فقد تمت الموافقة على المحتوى من فريق المراجعين.</li>
                                    <li>عدم التعديل أو التغيير على المحتوى نفسه، الهدف هو التدقيق اللغوي فقط، فقد تمت الموافقة على المحتوى من فريق المراجعين.</li>
                                    <li>عدم التعليق على المحتوى نفسه، فقد تمت الموافقة على المحتوى من فريق المراجعين.</li>
                                    <li>لا تقم بهجمات شخصية في أي مكان في موسوعة؛ إذ يكون التعليق على المحتوى، وليس على المراجع أو المحرر، فالهجمات الشخصية تضر بمجتمع موسوعة.</li>
                                    <li>احترام وقت المجتمع، والقيام بتحرير المقال بالوقت المطلوب.</li>
                                    <li>قُم بمراجعة المقال قبل إرساله، حيث لن تستطيع التعديل بعد نشره.</li>
                                    <li>تأكد من الاستمتاع والتعلم أثناء عملية التحرير.</li>
                                </ul>
                            </View>  
                            <View className="PrivacyContent">
                                <h4>
                                فيديوهات توضيحية:
                                </h4>
                                <p style={{fontWeight : "bold"}} >
                                إضافة أو تغيير صورة الحساب الشخصية
                                </p>
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/MXdBGBhqh5U" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <p style={{fontWeight : "bold"}}>
                                إضافة نبذة شخصية على الحساب الشخصي لمنتج المحتوى
                                </p>
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/ZfcwI-fWWY8" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <p style={{fontWeight : "bold"}}>
                                إضافة تعليق من قبل المراجع
                                </p>
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/kippmYXVHgQ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <p style={{fontWeight : "bold"}}>
                                للحديث أو للنقاش مع الكاتب حول نقطة معينة
                                </p>
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/wvZiF_vWlVs" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                          
                            </View>                                                   
                            </View>
                        </Col>
                    </Row>
                </Container>
                <SpacerVertical height={"3.3em"}/>
            </View>
            <AppFooter />
        </View>
    </View>
    )
}