import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Btn } from '../../../components/buttons/btn.component';
import { DropDown } from '../../../components/dropdown/dropdown.component';
import { Icon } from '../../../components/icons/icon.component';
import { InputObligatory, InputValue } from '../../../components/inputs/input.component';
import { InterestsModal } from '../../../components/interests-modal/interests-modal';
import { useModal } from '../../../components/modal/modal.component';
import { SpacerHorizontal, SpacerVertical } from '../../../components/spacer/spacer.component';
import { View } from '../../../components/view/view.component';
import { IFormValues, InterestsData, SecondStepData, WriterThirdStepData } from '../../../shared/interfaces';
import { country } from '../reviewer-editor-signup/reviewer-editor-signup.page';
import editor_signup_step1 from '../../../assets/images/signup-login/editor-signup-step1.jpg';
import editor_signup_step2 from '../../../assets/images/signup-login/editor-signup-step2.jpg';
import editor_signup_step3 from '../../../assets/images/signup-login/editor-signup-step3.jpg';
import { DropDownMultiple } from '../../../components/dropdown/dropdown-multiple.component';
import { EDUCATION, LEVEL_OF_EDUCATION } from '../../../shared/constants';
import { useFetch } from '../../../shared/hooks/fetch.hook';
import { apiService } from '../../../shared/services/api.service';
import { setCookie } from '../../../shared/services/cookie.service';
import { AxiosError } from 'axios';
import { AlertModal } from '../../../components/alert-modal/alert-modal.component';
import { EditorMoreExp } from './nested/editor-signup-more-exp.component';


export const EditorSignupPage: FunctionComponent = () => { 

    const {response, isLoading} = useFetch(() =>  apiService.interests.getAllInterests(0, 30, apiService.auth.isAuthenticated()));

    const { showModal, RenderModal } = useModal();
    const {showModal: showModalError, RenderModal: RenderModalError, hideModal: hideModalError} = useModal();

    const { register, handleSubmit, errors } = useForm<IFormValues>();
    
    const ref = useRef<any>(null);
    const ref1 = useRef<any>(null);

    const [interests, setInterests] = useState<InterestsData[]>([]);
    const [value,setValue] = useState('');
    const [value1,setValue1] = useState('');
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [isSecondStep, setIsSecondStep] = useState(false);
    const [isThirdStep, setIsThirdStep] = useState(false);
    const [Education, setEducation] = useState('');
    const [LevelofEducation, setLevelofEducation] = useState('');
    const [Languages, setLanguages] = useState<string[]>([]);
    const [hoursSpentPerDay, setHoursSpentPerDay] = useState('');
    const [bestTimeForWorking, setBestTimeForWorking] = useState('');
    const [password, setPassword] = useState('');
    const [errorText, setErrorText] = useState("Something went wrong...");
    const [optionSpecialisation, setOptionSpecialisation] = useState<string[]>([""]);
    const [lengthContainer, setLengthContainer] = useState(0);
    const [lengthContainer1, setLengthContainer1] = useState(0);
    const [counter, setCounter] = useState([1]);
    const [secondStepExperienceData, setSecondStepExperienceData] = useState<any[]>([{
        id: 1,
        experience: "",
        years_of_experience: 0,
        institution: "",
        specialisation: {
            id: 0
        },
        sub_specialisations: []
        }]);

    useEffect(() => {
        setLengthContainer(ref?.current?.offsetHeight);
        setLengthContainer1(ref1?.current?.offsetHeight);
    }, [ref?.current?.offsetHeight, isFirstStep, isSecondStep, isThirdStep, counter]);


    useEffect(() => {
        if(!isLoading) {
            let arr : string[] = []
            response?.data?.interests?.forEach((item : any) => {
                if(item?.sub_interests?.length !== 0 ) {
                    arr.push(item.title)
                }
            })
            setOptionSpecialisation(arr);
            setInterests(response?.data?.interests);
        }
    }, [response, isLoading]);


    const handlePassword = (value: InputValue) => {
        setPassword(value.value);
    }

    
    const handleChange = (str: string) => {
        setValue(str);
    }
    const handleChange1 = (str: string) => {
        setValue1(str);
    }
    
    const addForm = () => {
        let a = counter[counter.length-1] + 1;
        if(a !== 5) {
            setCounter([...counter, a]);
            const data = {
                id: a,
                experience: "",
                years_of_experience: 0,
                institution: "",
                specialisation: {
                    id: 0
                },
                sub_specialisations: []
                }
            setSecondStepExperienceData([...secondStepExperienceData, data]);
        }
    }

    

    

    const handleData = (data: any) => {
        const arr = secondStepExperienceData.map((item) => {
                if(item.id === data.id) {
                    return data
                }
            return item
        });
        setSecondStepExperienceData(arr);
    }

    const onSubmit = (data: any) => {
        if(data.gender === "أنثى") {
            data.gender = "FEMALE"
        } else {
            data.gender = "MALE"
        }
        data["role"] = "EDITOR";
        apiService.auth.signup(data)
            .then(r => {
                setCookie('mawsuah_access_token',r.data.access_token, 1);
                setCookie('mawsuah_refresh_token',r.data.refresh_token, 7);
                const myStorage = window.localStorage;
                myStorage.setItem("mawsuah_user_data", JSON.stringify(r.data.user));
                window.scrollTo(0, 0);
                setIsSecondStep(true);
                setIsFirstStep(false);
            })
            .catch((err : AxiosError) => {
                if(err?.response?.data?.message.indexOf(`User with '${data.email}' email already exists`) === 0) {
                    setErrorText("لقد تم التسجيل بهذا البريد الإلكتروني من قبل. الرجاء إدخال بريد إلكتروني مختلف");
                    showModalError();
                } else if (err?.response?.data?.message.indexOf(`User with '${data.username}' username already exists`) === 0) {
                    setErrorText("اسم مستخدم غير متاح. الرجاء إدخال اسم مستخدم آخر");
                    showModalError();
                } else {
                    setErrorText(err?.response?.data?.message);
                    showModalError();
                }
            })
    };
    const onSubmitSecondStep = (data: SecondStepData) => {

        const secondStepData = {
            education : data.education,
            level_of_education : data.level_of_education,
            languages : Languages.join(", "),
            experiences : secondStepExperienceData
        }
        
        if(localStorage.getItem("mawsuah_user_data")) {
            apiService.auth.signupWriterSecondStep(JSON.parse(localStorage.getItem("mawsuah_user_data") as string).uuid, secondStepData)
                .then((r) => {
                    setCookie('mawsuah_access_token',r.data.access_token, 1);
                    setCookie('mawsuah_refresh_token',r.data.refresh_token, 7);
                    window.scrollTo(0, 0);
                    setIsSecondStep(false);
                    setIsThirdStep(true);
                })
        }
    };
    const handleSubmitAllForm = (data: WriterThirdStepData) => {
        const apiData = {
            hours_spent_per_day: hoursSpentPerDay,
            best_time_for_working: data?.best_time_for_working
        }
        
        if(localStorage.getItem("mawsuah_user_data")) {
            apiService.auth.signupWriterThirdStep(JSON.parse(localStorage.getItem("mawsuah_user_data") as string).uuid, apiData)
                .then((r) => {
                    setCookie('mawsuah_access_token',r.data.access_token, 1);
                    setCookie('mawsuah_refresh_token',r.data.refresh_token, 7);
                    window.scrollTo(0, 0)
                    showModal();  
                    } 
                )
        }
    };
    return (
        <View className="App">
            <View className="writersSignup">
                <View className="writersSignupContent">
                    <View className="writersSignupContentLogos">
                        {/* <View className="Logo">
                            <Icon name="iconLogoBig1" alt="logo_picture"/>
                        </View> */}
                        <Icon name="iconGroupBlack" alt="logo_picture"/>
                    </View>
                    <View className="writersSignupContentText">
                        {/* <View className="subText"><span>معاً نبني أكبر مجتمع معرفيّ موثوق عربياً</span></View> */}
                        <SpacerVertical height={"2.222em"}/>
                        <View className="textTitleSub"><span>الرجاء تعبئة بياناتك الشخصية، وسيتم التواصل معك مباشرة من قِبل فريق موسوعة!
علماً أننا نطلق إليكم منصتنا بنسختها التجريبية لضمان أفضل جودة</span></View>
                        <SpacerVertical height={"2.222em"}/>
                        <View className="textTitle"><span>إنشاء حساب محرر</span></View>
                        <SpacerVertical height={"2.357em"}/>
                        <View className="textTitleSub" >
                            {isFirstStep ?
                             <div className="StepActive">1</div> 
                            : <Icon className="iconStepper" name="iconSuccess" />}
                            <span>
                                بياناتك في موسوعة
                            </span>
                        </View>
                    </View>
                    {isFirstStep ? <SpacerVertical height={"1.857em"}/> :
                        <SpacerVertical height={"2.857em"}>
                            <div className="line" style={{height: "1.714em", top: "0.714em"}}></div>
                        </SpacerVertical>
                    }                  
                    <View className="writersSignupContentInputs">
                    {isFirstStep && 
                            <div className="form1" ref={ref}>
                            <div className="line" style={{height: lengthContainer, top: "-0.857em"}}></div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                            <View className="inlineFlex">
                                <InputObligatory name="first_name"  
                                label="الاسم الأول" 
                                placeholder="الرجاء إدخال اسمك الأول"
                                maxLength={20} 
                                minLength={3}
                                pattern={/^[ \u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]*$/}  
                                register={register} 
                                errors={errors} 
                                required 
                                inputPrompt="الرجاء إدخال اسمك الأول. يجب أن يتكوّن الاسم من حروف عربية من دون فواصل أو رموز"
                                errorMessage="الاسم الأول غير صحيح، الرجاء إدخال الاسم الأول مع مراعاة الشروط.
                                يجب أن يتكوّن من حروف عربية من دون فواصل أو رموز"
                                errorMessagePattern="الاسم الأول غير صحيح، الرجاء إدخال الاسم الأول مع مراعاة الشروط.
                                يجب أن يتكوّن من حروف عربية من دون فواصل أو رموز" />
                                <InputObligatory name="last_name" 
                                label="الاسم الأخير" 
                                placeholder="الرجاء إدخال اسمك الأخير"
                                inputPrompt="الرجاء إدخال الاسم الأخير. يجب أن يتكوّن الاسم من حروف عربية من دون فواصل أو رموز"
                                minLength={3}
                                maxLength={20}  
                                pattern={/^[ \u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]*$/} 
                                register={register} 
                                errors={errors} 
                                errorMessage="الاسم الأخير غير صحيح، الرجاء إدخال الاسم الأخير مع مراعاة الشروط.
                                يجب أن يتكوّن من حروف عربية من دون فواصل أو رموز"
                                required
                                errorMessagePattern="الاسم الأخير غير صحيح، الرجاء إدخال الاسم الأخير مع مراعاة الشروط.
                                يجب أن يتكوّن من حروف عربية من دون فواصل أو رموز"/>
                            </View>
                            <InputObligatory 
                            name="username" 
                            autocomplete="off" 
                            label="اسم المستخدم" 
                            placeholder="الرجاء إدخال اسم المستخدم"
                            minLength={4}
                            icon="iconExclamation" 
                            errorMessage="اسم مستخدم غير صحيح، يجب أن يتكون اسم المستخدم من أحرف إنجليزية فقط من دون فواصل أو فراغات أو رموز وعلى الأقل 4 أحرف. تأكد من استخدام اسم واضح وسهل للقراءة يستمر معك طيلة الرحلة"
                            inputPrompt="يجب أن يتكوّن اسم المستخدم من أحرف إنجليزية فقط من دون فواصل أو فراغات أو رموز وعلى الأقل 4 أحرف. تأكد من استخدام اسم واضح وسهل للقراءة يستمر معك طيلة الرحلة"
                            register={register} 
                            errors={errors} 
                            required
                            pattern={/^[a-zA-Z]+$/}
                            errorMessagePattern="اسم مستخدم غير صحيح، يجب أن يتكون اسم المستخدم من أحرف إنجليزية فقط من دون فواصل أو فراغات أو رموز وعلى الأقل 4 أحرف. تأكد من استخدام اسم واضح وسهل للقراءة يستمر معك طيلة الرحلة"
                            />
                            <InputObligatory 
                            name="email" 
                            label="البريد الإلكتروني" 
                            placeholder="الرجاء إدخال البريد الإلكتروني" 
                            register={register} 
                            errors={errors} 
                            errorMessage="الرجاء التحقق من البريد الإلكتروني"
                            required
                            minLength={8}
                            pattern={/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/}
                            errorMessagePattern="الرجاء التحقق من البريد الإلكتروني"/>
                            <InputObligatory 
                            name="password"
                            type="password"
                            autocomplete="off" 
                            label="كلمة السر" 
                            placeholder="الرجاء إدخال كلمة السر" 
                            register={register} 
                            errors={errors} 
                            inputPrompt="يجب أن تتكوّن على الأقل من 8 أحرف إنجليزية، حرف كبير واحد،  رقم واحد، و رمز خاص"
                            errorMessage="كلمة سر غير مقبولة، يجب أن تتكوّن على الأقل من 8 أحرف إنجليزية، حرف كبير واحد و رقم واحد ورمز خاص"
                            required
                            pattern={/(?=.*[0-9])(?=.*[!@#$%^_\-.&\]\\*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^_\-.&\]\\*]{8,}/}
                            errorMessagePattern="كلمة سر غير مقبولة، يجب أن تتكوّن على الأقل من 8 أحرف إنجليزية، حرف كبير واحد و رقم واحد ورمز خاص"
                            onChange={handlePassword}/>
                            <InputObligatory 
                            name="confirm_password" 
                            autocomplete="off" 
                            type="password"
                            label="تأكييد كلمة السر" 
                            placeholder="الرجاء إعادة كتابة كلمة السر" 
                            register={register}  
                            errors={errors} 
                            errorMessage="كلمة سر غير مطابقة، الرجاء إعادة الكتابة"
                            required
                            password={password}
                            errorMessagePattern="كلمة سر غير مطابقة، الرجاء إعادة الكتابة"
                            />
                            <DropDown 
                            name="country" 
                            placeholder='الرجاء اختيار البلد' 
                            value={value} 
                            options={country} 
                            label={"البلد"} 
                            onChange={handleChange} 
                            register={register}
                            errors={errors} 
                            errorMessage="الرجاء اختيار البلد" 
                            required/>
                            <SpacerVertical height={"2em"}/>
                            <DropDown 
                            name="gender" 
                            placeholder='الرجاء اختيار الجنس' 
                            value={value1} 
                            options={['ذكر','أنثى']} 
                            label="الجنس" 
                            onChange={handleChange1} 
                            register={register} 
                            errors={errors} 
                            errorMessage="الرجاء اختيار الجنس" 
                            required/>
                            <SpacerVertical height={"2.286em"}/>
                                <Btn type="submit" className="writers-signupBtn" title="التالي" />
                            </form>
                            <SpacerVertical height={"1.571em"}/>
                            </div>
                        }
                        <View className="nextStepText">
                           {isFirstStep ? <div className="StepDisabled">2</div> : 
                            isSecondStep ?  <div className="StepActive">2</div> : <Icon className="iconStepper" name="iconSuccess"/>}
                            خبرات عملية
                        </View>
                        <SpacerVertical height={!isSecondStep ? "2.857em" : "1.714em"}>
                            {!isSecondStep ? <div className="line" style={{height: "1.714em", top: "0.714em"}}></div> 
                            : <div className="line" style={{height: "1.714em", top: "1.714em"}}></div>}
                        </SpacerVertical>
                        {isSecondStep && 
                            <>
                                <div className="form1" ref={ref1}>
                                <div className="line" style={{height: lengthContainer1, top: "-0.857em"}}></div>
                                <form onSubmit={handleSubmit(onSubmitSecondStep)}>
                                {counter.map((item) => {
                                    return (
                                        <View key={item}>
                                            <EditorMoreExp 
                                            register={register} 
                                            errors={errors} 
                                            handleData={handleData}  
                                            optionSpecialisation={optionSpecialisation} 
                                            interests={interests}  
                                            id={counter[counter.length-1]} 
                                            />
                                        </View>
                                    )
                                })
                                }
                                <Icon className="iconPlus" style={{marginRight: "100px", marginTop: "-30px"}} name="iconPlus" onClick={addForm}/>
                                <DropDown 
                                value={Education} 
                                options={EDUCATION} 
                                label={"تخصص التعليم"} 
                                onChange={setEducation} 
                                placeholder="الرجاء اختيار تخصص التعليم"
                                name="education" 
                                register={register} 
                                errors={errors} 
                                errorMessage="الرجاء اختيار تخصص التعليم"
                                required/>
                                <SpacerVertical height={"2.286em"}/>
                                <DropDown 
                                value={LevelofEducation} 
                                options={LEVEL_OF_EDUCATION} 
                                label="مستوى التعليم"
                                onChange={setLevelofEducation} 
                                placeholder="الرجاء اختيار مستوى التعليم"
                                name="level_of_education" 
                                register={register} 
                                errors={errors} 
                                errorMessage="الرجاء اختيار مستوى التعليم" 
                                required/>
                                <SpacerVertical height={"2.286em"}/>
                                <DropDownMultiple 
                                value={Languages} 
                                options={['العربية','الانجليزية','الفرنسية']} 
                                label="اللغات" 
                                onChange={setLanguages} 
                                placeholder="خلافاً عن اللغة العربية، ما اللغات الأخرى التي تفضل التحرير فيها" 
                                name="languages" 
                                register={register} 
                                errors={errors} 
                                errorMessage="الرجاء تحديد اللغات" 
                                required/>
                                <SpacerVertical height={"2.591em"}/>
                                <Btn className="writers-signupBtn" title="التالي" />
                                <SpacerVertical height={"2.286em"}/>
                                </form>
                                </div>
                            </>
                        }
                        <View className="nextStepText">
                            {!isThirdStep ? <div className="StepDisabled">3</div> : <div className="StepActive">3</div>}
                            <span>تفضيلات العمل
                                </span>
                        </View>
                        {isThirdStep && 
                            <>
                             <form onSubmit={handleSubmit(handleSubmitAllForm)}>
                            <SpacerVertical height={"2.071em"}/>
                            <DropDown 
                            value={hoursSpentPerDay} 
                            options={["أقل من ساعة",
                                    "من 1 إلى 3 ساعات",
                                    "أكثر من 3 ساعات"]}
                            isLastWordBold 
                            label={"كم ساعة يمكنك منح عملية تحرير المقالات يوميّا؟"} 
                            onChange={setHoursSpentPerDay}  
                            placeholder="الرجاء تحديد عدد الساعات" 
                            name="hours_spent_per_day" 
                            register={register} 
                            errors={errors} 
                            errorMessage="الرجاء تحديد عدد الساعات" 
                            required/>
                            <SpacerVertical height={"2.286em"}/>
                            <DropDown 
                            value={bestTimeForWorking} 
                            options={["8 صباحاً- 6 مساءً",
                                "6 مساءً- 12 مساءً",
                                "12 صباحاً- 8 صباحاً"]} 
                            label="ما هو التوقيت المفّضل لديك للقيام بعملية التحرير؟" 
                            onChange={setBestTimeForWorking}  
                            placeholder="ما هو التوقيت المفّضل لديك للقيام بعملية التحرير؟"  
                            name="best_time_for_working" 
                            register={register} 
                            errors={errors} 
                            errorMessage="الرجاء تحديد الوقت المناسب"
                            required/>
                            <SpacerVertical height={"2em"}/>
                            <Btn className="writers-signupBtn" title="انتهاء"/>
                            </form>
                            </>
                        }
                        <SpacerVertical height={"21.8em"}/>
                    </View>
                </View>
                <View title="signup_picture" className="writersSignupPicture"
                style={isFirstStep ? {backgroundImage: `url(${editor_signup_step1})`} 
                : isSecondStep ? {backgroundImage: `url(${editor_signup_step2})`} : {backgroundImage: `url(${editor_signup_step3})`}}>
                        <View className="writersSignupPictureContent">
                            <View className="icon">
                                <Icon name="iconLogoBigLogin" alt="logo_picture"/>
                            </View>
                            <SpacerHorizontal width={"1.8em"}/>
                            <View className="text">
                            <span>" لتكن موسوعة طريقك إلى توظيف خبراتك في التحرير في خدمة المحتوى العلمي الموثوق! كثير من الكتّاب يتطلعون إلى الاستفادة من خبرتك في تحرير المقالات، وكثير من القرّاء ينتظرون قراءة المقالات التي تعطيها لمستك الاحترافية
"</span>	
                            </View>
                        </View>
                </View>
            </View>
            <RenderModal>
                <InterestsModal/>
            </RenderModal>
            <RenderModalError>
                <AlertModal alertText={errorText} alertHeader="" hideModal={hideModalError}/>
            </RenderModalError>
        </View>
    )
}