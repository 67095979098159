import React, { FunctionComponent } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";


interface SeoProps {
  title: string;   
  description: string;
  image: string;
  keywords: string;
  author: string;
  url: string;
}

export const Seo : FunctionComponent<SeoProps> = (props) => {
  const { title, description, image, keywords, author, url} = props;
  return (
    <HelmetProvider>
        <Helmet>
          <title>
            {title}
          </title>
          <meta
            name="description"
            content={description}
          />

        <meta
            name="keywords"
            content={keywords}
          />

          {/* Twitter meta tags below */}
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:image"
            content={image}
          />
          <meta
            name="twitter:title"
            content={title}
          />
          <meta name="twitter:creator" content={author} />
          <meta name="twitter:site" content={url} />
          <meta
            name="twitter:description"
            content={description}
          />

          {/* Facebook meta tags below */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta
            property="og:title"
            content={title}
          />
          <meta
            property="og:description"
            content={description}
          />
          <meta
            property="og:image"
            content={image}
          />
        </Helmet>
    </HelmetProvider>
  );
};