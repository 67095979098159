import React, { FunctionComponent, useState } from 'react';
import { Btn } from '../../components/buttons/btn.component';
import { Icon } from '../../components/icons/icon.component';
import { InputObligatory } from '../../components/inputs/input.component';
import { SpacerHorizontal, SpacerVertical } from '../../components/spacer/spacer.component';
import { View } from '../../components/view/view.component';
import { useForm } from "react-hook-form";

import "./login.scss"
import { apiService } from '../../shared/services/api.service';
import { setCookie } from '../../shared/services/cookie.service';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useModal } from '../../components/modal/modal.component';
import { ResetPasswordModal } from '../../components/reset-password-modal/reset-password.modal';
import { AlertModal } from '../../components/alert-modal/alert-modal.component';

interface IFormValues {
    email: string;
    password: string;
}


export const LoginPage: FunctionComponent = () => { 

    const { register, handleSubmit, errors } = useForm<IFormValues>();
   
    const {showModal, RenderModal} = useModal();
    const {showModal: showModalError, RenderModal: RenderModalError, hideModal: hideModalError} = useModal();

    const [errorText, setErrorText] = useState("Something went wrong...");

    const history = useHistory();

    const onSubmit = (data: IFormValues) => {
        apiService.auth.login(data)
            .then((r) => {
                setCookie('mawsuah_access_token',r.data.access_token, 1);
                setCookie('mawsuah_refresh_token',r.data.refresh_token, 7);
                const myStorage = window.localStorage;
                myStorage.setItem("mawsuah_user_data", JSON.stringify(r.data.user));
                history.push("/homepage");
            })
            .catch((err : AxiosError) => {
                setErrorText("الحساب غير موجود. الرجاء التأكد من اسم المستخدم أو كلمة السر");
                showModalError();
            })
    };
  
    
  
    return (
        <View className="App">
            <View className="login">
                    <View className="loginContent">
                            <View className="loginContentLogos" onClick={() => {history.push("/homepage")}}>
                                {/* <View className="Logo">
                                    <Icon name="LOGOBLACK" />
                                </View> */}
                                <Icon name="iconGroupBlack" />
                            </View>
                            <View className="loginContentText">
                                <View className="subText">معاً نبني أكبر مجتمع معرفيّ موثوق عربياً</View>
                                <SpacerVertical height={"2.909em"}/>
                                <View className="textTitle">تسجيل الدخول</View>
                                <SpacerVertical height={"0.806em"}/>
                                <View className="textTitleSecondary">مستخدم جديد ؟<span onClick={() => history.push("/main_signup")}> افتح حساًبا في موسوعة </span></View>
                            </View>
                            <SpacerVertical height={"2.623em"}/>
                            <form onSubmit={handleSubmit(onSubmit)}>
                            <View className="loginContentInputs">
                                <InputObligatory name="email" label="البريد الإلكتروني" placeholder="الرجاء إدخال البريد الإلكتروني" register={register} errors={errors} required/>
                                <InputObligatory name="password" type="password" label="كلمة السر" placeholder="الرجاء إدخال كلمة السر" errors={errors} register={register} required/>
                            </View>
                            <Btn type="submit" className="loginContentBtn" title="تسجيل دخول"/>
                            </form>
                            <SpacerVertical height={"1.163em"}/>
                            {/* <View className="loginContentLine">
                                <hr />
                                <View>أو</View>
                                <hr/>
                            </View> */}
                            {/* <SpacerVertical height={"1.143em"}/>
                            <button className="googleButton" style={{opacity: "0.6"}}>
                                <View className="btnContent">
                                    <Icon name="iconGoogleLogo"/>
                                    التسجيل عبر جوجل
                                </View>
                            </button>
                            <SpacerVertical height={"1.143em"}/> */}
                            {/* <button className="facebookButton" style={{opacity: "0.6"}}>
                            <View className="btnContent">
                                <Icon name="iconFacebookLogoButton"/> 
                                التسجيل عبر فيبسوك
                            </View>
                            </button> */}
                            <SpacerVertical height={"2.643em"}/>
                            <View className="forgetPassword" onClick={() => showModal()}>
                                <div>هل نسيت كلمة السر ؟</div>
                            </View>
                            <SpacerVertical height={"21.786em"}/>
                        </View>
                    <View className="loginPicture">
                        <View className="loginPictureContent">
                            <View className="tableCell">
                                <View className="icon">
                                    <Icon name="iconLogoBigLogin"/>
                                </View>
                                <SpacerHorizontal width={"1.8em"}/>
                                <View className="text">
                                <span>
                            أهلا بك في "موسوعة"
                            <br/>
                            معاً نبني أكبر مجتمع عربي معرفي مختّص وموثوق
                            </span>	
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            <RenderModal style={{padding: "0px", width: "31.429em"}}>
                <ResetPasswordModal />
            </RenderModal>
            <RenderModalError>
                <AlertModal alertText={errorText} alertHeader="" hideModal={hideModalError}/>
            </RenderModalError>
        </View>
    )
}