import React, { FC, useEffect, useState } from "react";
import { Node } from "slate";
import { RichTextEditor } from "../../../components/rich-text-editor/rich-text-editor.component";
import { SpacerVertical } from "../../../components/spacer/spacer.component";
import { ITextEditorData } from "../../../shared/interfaces";
import articleSvg from '../../../assets/images/ArticleReview/commentAltLines.png';

interface DraftArticleItemProps {
    status: string;
    articleContent : any;
    numberOfReferences : number;
    localHtml1: string;
    commentaries: any[];
    setFlag: () => void;
    deleteTextEditor: (id: number) => void;
    addTextEditorAfter: (id: number) => void;
    addTextEditorBefore: (id: number) => void;
    addCopiedTextEditor: (instance: ITextEditorData) => void;
    handleTextEditor: (instance: Node[] , id: number) => void;
    handleReferenceData: (data: string, id: number, numberOfReferences: number) => void;
}

export const DraftArticleItem: FC<DraftArticleItemProps> = (props) => {

    const {commentaries, localHtml1, status, articleContent, numberOfReferences, setFlag, deleteTextEditor, addTextEditorAfter, addTextEditorBefore, addCopiedTextEditor, handleTextEditor, handleReferenceData} = props;

    const [active, setActive] = useState(false);
    const [data, setData] = useState<Node[]>([]);
    const [localHtml, setLocalHtml] = useState("");
    const [comments, setComments] = useState<any[]>([]);
    const [isShown, setIsShown] = useState(false);
    
    const onToggle = () => {
        setActive(!active);
        setFlag();
    }

    useEffect(() => {
        setActive(!!articleContent.activeBlock && articleContent.activeBlock);
    },[articleContent]);

    

    useEffect(() => {
        const arr = commentaries?.filter((item) => articleContent.blockId === +item.blockId);
        setComments(arr);
    }, [commentaries, articleContent.blockId])

    useEffect(() => {
        setLocalHtml(localHtml1);

    },[localHtml1])

    useEffect(() => {
        setData([articleContent.data]);
    }, [articleContent.blockId, articleContent.data])

    const deleteTextEditorBlock = (id: number) => {
        setActive(false);
        deleteTextEditor(id);
    }

    return (
    <div style={{position: "relative"}}>
        {!active || status ==="ON_REVIEW" || status === "APPROVED" || status === "REJECTED" || status === "ON_EDITING" || status === "ON_CORRECTING" ? 
            <div dangerouslySetInnerHTML={{__html : localHtml}} onClick={onToggle}/> :
            <>
            <RichTextEditor 
                id={articleContent.blockId} 
                numberOfReferences={numberOfReferences}
                handleReferenceData={handleReferenceData}
                handleTextEditor={handleTextEditor}
                editorData={data}
                isParagraphProp={true}
                isListProp={true}
                iconsItemProp={"iconParagraph"}
                textAlignIconProp="iconTextAlignRight"
                deleteTextEditor={deleteTextEditorBlock} 
                addTextEditorBefore={addTextEditorBefore} 
                addTextEditorAfter={addTextEditorAfter} 
                addCopiedTextEditor={addCopiedTextEditor}
            />
            <SpacerVertical height={"1.143em"}/>
            </>
        }
        {comments.length !== 0 && 
            <div 
            className="commentDivDraft" 
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}>
            <img src={articleSvg} alt="commentSvg"/>
            <div className="hoverComment" style={isShown ? {display: "block"} : {display: "none"}}>
                {comments.map((item, indx) => {
                    return (
                        <span key={indx} style={{marginRight: "5px"}}> {indx+1}.{item.info.comment}<br/></span>
                    )
                })}
            </div>
        </div>
        }
    </div>
    )
}