import React, { FunctionComponent, useState, useRef } from "react";

import { View } from "../view/view.component";


import './dropdown.scss';
import { activeClass } from "../../shared/utils";
import { useOnClickOutside } from "../../shared/hooks/click-outside.hook";
import { Icon } from "../icons/icon.component";

interface DropDownProps {
  idFormData?: number;
  placeholder?: string;
  name?: string;
  errors?: any;
  register?: any;
  errorMessage?: string;
  isLastWordBold?: boolean;
  subLabel?: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  label: string;
  value: string;
  options: string[];
  onChange: (value: string, idFormData?: number) => void;
}

export const DropDown: FunctionComponent<DropDownProps> = (props) => {
  const { value, options, onChange, disabled, label, className, subLabel, isLastWordBold, errorMessage, register, errors, required, name, placeholder, idFormData } = props;

  const [active, setActive] = useState(false);
  const onToggle = () => setActive(!active);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setActive(false));

  const onChangeValue = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
     
    onChange(e.currentTarget.value, idFormData);
    setActive(false);
  }

  const labelSplit = (label: string) => {
    const arr = label.split(" ");
    let labelSpan: any = [];
    arr.forEach((item, i) => {
      if( i === arr.length-2) {
        labelSpan.push(<span key={Math.random() * 1000000} className="lastWordBold">{item + " "}</span>);
      } else {
        labelSpan.push(item + " ");
      }
    });
    return labelSpan
  }
  return (
    <div className="DropDown" ref={ref}>
        <label>{isLastWordBold ? labelSplit(label) : label}{required ? <Icon className="iconRedStar" name="iconRedStar" /> : null}<span>{subLabel}</span></label>
      <View className={"DropDownLabel " + className} onClick={onToggle}>
        <input name={name} className="DropDownLabelInput" value={value} placeholder={placeholder} type="text" readOnly ref={register ? register({required}) : null}/>
        <Icon name="iconArrowBottom" />
      </View>
      <View className={activeClass('DropDownOptions', disabled ? !disabled : active)}>
        {options?.map((v, i) => (
          <input name="inputDropDown" placeholder={v} value={v} className="DropDownOptionsLink" onClick={(e) => onChangeValue(e)} key={i} readOnly/>
        ))}
      </View>
      {register ? 
       errors[`${name}`]?.type && <div className="ErrorText"><span>{errorMessage}</span></div> : null
       }
    </div>
  )
}