import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { View } from '../../components/view/view.component';
import { Col, Container, Row } from 'react-bootstrap';
import { Icon } from '../../components/icons/icon.component';
import { InputCheckBox, InputObligatory } from '../../components/inputs/input.component';
import { Btn } from '../../components/buttons/btn.component';
import { DragAndDropImages } from '../../components/drag-and-drop/drag-and-drop.component';
import { ArticleBlockContentV1, ArticleData, Highlights, ITextEditorData, References, sub_interests } from '../../shared/interfaces';
import { Node } from 'slate';
import { apiService } from '../../shared/services/api.service';
import { useHistory, useLocation } from 'react-router-dom';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { DraftArticleItem } from './nested/draft-article-item.component';
import { Loader } from '../../components/loader/loader.component';
import { AxiosError } from 'axios';
import { getMinutesForReading, getUserId, objToRange, serialize, serializeToText } from '../../shared/utils';
import { useModal } from '../../components/modal/modal.component';
import { BeforePublishingModal } from '../../components/dashboard-article-modals/before-publishing-modal/before-publishing-modal.component';
import articleSvg from '../../assets/images/ArticleReview/commentAltLines.png';
import articleSvgGray from '../../assets/images/ArticleReview/commentAltLinesGray.png';
import articleSvgGreen from '../../assets/images/ArticleEditor/commentAltLines.png';
import { ChatModalWriter } from '../../components/chat-modal-writer/chat-modal-writer';
import { ChatModalWriterEditor } from '../../components/chat-modal-writer-editor/chat-modal-writer-editor';
import { DraftArticleTitle } from './nested/draft-article-title.component';
import { WriterWelcomeModal } from '../../components/writer-welcome-modal/writer-welcome.modal';


export const DraftArticlePage:FunctionComponent = () => {

    const location: any = useLocation();
    const history = useHistory();
    
    const {response, isLoading} = useFetch(() => apiService.articles.getMyArtilceById(location.pathname.split('/')[2]));
    const {response: timerResponse} = useFetch(() => apiService.articles.getTimerEndTimeForArticleApproving(location.pathname.split('/')[2]))
    const {response: userMainSpec} = useFetch(() => apiService.user.getUserSpecialisations(getUserId()));
    
    useEffect(() => {
      if(!isLoading) {
        apiService.articles.getCorrectionHighlightsByArticleId(location.pathname.split('/')[2])
        .then(r => {
          setCorrectionHighlights(r.data);
        })
      }
    }, [isLoading, location.pathname])

    useEffect(() => {
      apiService.comments.getReviewsHighlights(location.pathname.split('/')[2])
      .then(r => {
        setResponseHighlights(r?.data?.highlights);
        if(r.data.highlights?.length > 0) {
          setSecondHighlights(JSON.parse(r?.data?.highlights[0].content));
          apiService.comments.getCommentariesByHighlightId(r.data.highlights[0].id, 0, 50)
          .then((r) =>{
            if(r.data.correspondence?.length > 0) {
              setResponseComments(r.data.correspondence);
            }
          });
        }
      });
    }, [location.pathname]);
    
  
    const [responseHighlights, setResponseHighlights] = useState<any[]>([]);
    const [correctionHighlights, setCorrectionHighlights] = useState<Highlights | null>(null);
    const [responseComments, setResponseComments] = useState<any[]>([]);
    const [endTime, setEndTime] = useState("");
    const [commentaries, setCommentaries] = useState<any[]>([]);
    const [highlights, setHighlights] = useState<any[]>([]);
    const [secondHighlights, setSecondHighlights] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [articleSummary, setArticleSummary] = useState("");
    const [references, setReferences] = useState<References[]>([]);
    const [numberOfReferences, setNumberOfReferences] = useState(0);
    const [articleTitle, setArticleTitle] = useState("");
    const [articleTitleCopy, setArticleTitleCopy] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [subInterestsData, setSubInterestsData] = useState<any[]>([]);
    const [minutes_for_reading, setMinutes_for_reading] = useState(0);
    const [word_count, setWord_count] = useState(0);
    const [status, setStatus] = useState("");
    const [flag, setFlag] = useState(false);
    const [amountOfImages, setAmountOfImages] = useState(0);
    const [commentId] = useState<number | null>(null);
    const [rangeWidth, setRangeWidth] = useState(0);
    const [rangeData, setRangeData] = useState({
      HeaderImage: false,
      Title: false,
      SubInterests: false,
      HeadLine: false,
      Words: false,
      References: false,
      Images: false,
      Summary: false
    });
    const {showModal: showBeforePublisihing, RenderModal: RenderBeforePublishing, hideModal: hideBeforePublishing} = useModal();
    const {showModal: showModalChat, RenderModal: RenderModalChat, hideModal: hideModalChat } = useModal();
    const {showModal: showModalChatEditor, RenderModal: RenderModalChatEditor, hideModal: hideModalChatEditor } = useModal();
    const {RenderModal, hideModal} = useModal();
    
    useEffect(() => {
      setEndTime(timerResponse?.data.time);
    }, [timerResponse])
    
    useEffect(() => {
      const interval = setInterval(() => {
        
        const arr1 = data?.map((item) => {

          const nodeArr = item.data;
          
          if(nodeArr?.children?.length > 0) {
            return nodeArr.children.map((el : any) => serializeToText(el))
          }
          let elem 
          if(nodeArr?.children?.length > 0) {
            elem = serializeToText(nodeArr.children[0])
          }
          return elem
        });

        let arr2 = arr1.flat().toString().split(' ');        
        setWord_count(() => arr2?.length);
        setMinutes_for_reading(getMinutesForReading(arr2?.length)); 
      }, 1000);
      return () => clearInterval(interval);
  }, [data]);

    useEffect(() => {
      if(!isLoading && response?.data) {
        if(response?.data?.content && userMainSpec){
          setData(JSON.parse(response?.data?.content));
          setImageUrl(response?.data?.image_url);
          setArticleSummary(response?.data?.header);
          setArticleTitle(response?.data?.title);
          setArticleTitleCopy(response?.data?.title);
          setReferences(JSON.parse(response?.data?.references));
          setNumberOfReferences(JSON.parse(response?.data?.references).length);
          setMinutes_for_reading(response?.data?.minutes_for_reading)
          setWord_count(response?.data?.word_count);
          setStatus(response?.data?.status?.name);
          if(response?.data?.status?.name === "ON_REVIEW" || response?.data?.status?.name === "ON_CORRECTING") {
            history.push('/user_profile_auth/writer');
          }
          const arr = userMainSpec.data[0]?.sub_interests?.map((item : any) => {
            return {
              id: item.id,
              title: item.title,
              isChecked: false
            }
          });
          const arr1 = arr?.map((item : any) => {
            let a = false;
            response?.data?.sub_interests?.forEach((el : any) => {
              if(item.id === el.id) {
                a = true
              }
            })
            return {
              id: item.id,
              title: item.title,
              isChecked: a
            }
          })
          setSubInterestsData(arr1);
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, location.pathname, response, userMainSpec]);

    const handleSubInterests = (e : any) => {
      if(status !== "ON_REVIEW") {
        if(status !== "APPROVED" && status !== "REJECTED") {
          const arr = subInterestsData?.map((item: any) => {
            if(+e.currentTarget?.id === item.id) {
              return {id: item.id,
                title: item.title,
                isChecked: !item.isChecked}
            }
            return item});
          setSubInterestsData((arr && arr as any) || [{id: +e.currentTarget?.id, title : e.currentTarget?.name}]);
        }
      }
    }
    const getHighlighsFromRanges = (selStr : string) => {
          
      let selArr = JSON.parse(selStr as string);

      let sel = getSelection();
      selArr?.forEach(function(each: any) {
      sel?.removeAllRanges();
      const newRange = objToRange(each);
      if(newRange) {
        sel?.addRange(newRange);
        const range = sel?.getRangeAt(0);

      const span = document.createElement("mark");
      span.id = each.highlightId;
      span.classList.add("HighlitedText");
      if(each.newText) {
        span.style.backgroundColor = "#EDEDED"
      }
      if(sel?.toString()) {
          span.textContent = sel?.toString() ;          
      }
      if(range) {
          range.surroundContents(span);
      }
      }
      sel?.removeAllRanges();
    })
    }

    const handleArtilce = (isSaveWithPreview: boolean) => {

      if(commentId !== null){
        apiService.comments.deleteCommentaryById(commentId)
          .then(() => {
            setCommentaries([]);
            setHighlights([]);
          });
      }

        const arrData = data.map((item) => {
          return {
            blockId : item.blockId,
            data: item.data
          }
        });
        
        const sub_interests_arr = subInterestsData?.filter((item) =>  item.isChecked === true);

        const sub_interests = sub_interests_arr?.map((item) => {
          return {
            id: item.id,
            title: item.title
          }
        });

        const referencesStr = JSON.stringify(references);
        const content = JSON.stringify(arrData);
        const apiData : ArticleData = {
          title : articleTitle,
          content : content,
          header : articleSummary,
          minutes_for_reading : minutes_for_reading,
          image_url: imageUrl,
          references: referencesStr,
          sub_interests: sub_interests as sub_interests,
          word_count: word_count
        };

        

        apiService.articles.updateArticle(location.pathname.split('/')[2] , apiData)
          .then(r => {
            if(isSaveWithPreview) {
              const arr = arrData.map((item) => {
                return {
                  blockId: item.blockId,
                  data : item.data,
                  activeBlock: false
                }
              });
              setLocalHtml([]);
              setData(arr);
            }
          })
          .catch((err : AxiosError) => {
            //alert(err?.response?.data?.message);
        })
    }  

    const handleReferenceData = (data: string, id: number, numberOfReferences: number) => {
      setReferences([...references, {title: data, blockId: id, numberOfReferences: numberOfReferences}]);
      setNumberOfReferences(numberOfReferences+1);
    }

    const handleTextEditor = (instance: Node[], id: number) => {
      
      const arr = data.map((item) => {
        if(item.blockId === id) {
          return {
              blockId: id,
              data: instance[0],
              activeBlock: true
            }
        }
        return item
      });
      setData(arr);
    }

    const addCopiedTextEditor = (instance: ITextEditorData) => {
      let max = maxValue(data);
      setData([...data, {
        blockId: max+1,
        data: instance.data[0],
        activeBlock: true
      }]);
    };

    const deleteTextEditor = (id: number) => {
      const arr = data.filter((item) => item.blockId !== id);
      setData(arr);
    };

    const addTextEditorAfter = (id: number) => {
      let max = maxValue(data);
      const index = data.findIndex((item) => item.blockId === id);
      const arr = data.slice(0, index+1);
      const arr1 = data.slice(index+1, data.length);
      const res = [...arr, {
        blockId: max+1,
        data: "",
        activeBlock: true
      }, ...arr1];
      setData(res);
    };
    
    const addTextEditorBefore = (id: number) => {
      let max = maxValue(data);
      const index = data.findIndex((item) => item.blockId === id);
      const arr = data.slice(0, index);
      const arr1 = data.slice(index, data.length);
      const res = [...arr, {
        blockId: max+1,
        data: "",
        activeBlock: true
      }, ...arr1];
      setData(res);
    };

    const initReview = () => {
      if(commentaries.length === 0) {
        apiService.articles.initArticleReview(+location.pathname.split('/')[2]);
      }
    }
    
    // highights and comments logic

    const [localHtml, setLocalHtml] = useState<any[]>([]);

    // useEffect(() => {
     
    //   data.forEach((item) => {
    //     item?.data?.forEach((subitem: any) => {
    //     let idEL = 4000;
    //     const span = document.createElement('span');
    //     const blockId = item.blockId;
    //     span.innerHTML = serialize(subitem, item.blockId);
    //     if(span.children[0]?.childNodes.length){
    //       span.children[0].childNodes.forEach((item : any) => {
    //         if(item.childNodes.length > 1) {
    //           if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
    //             item.id = `${blockId}${idEL+1}`;
    //             idEL = idEL+1
    //           }
    //         }
    //         if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
    //           item.id = `${blockId}${idEL+1}`;
    //           idEL = idEL+1
    //         }
    //       })
    //     }
    //     setLocalHtml((prev) => [...prev, span.innerHTML]);
    //   })
    // })
    // }, [data]);

    useEffect(() => {
     
      data.forEach((item) => {
        let idEL = 4000;
        const span = document.createElement('span');
        const blockId = item.blockId;
        span.innerHTML = serialize(item.data, item.blockId);
        if(span.children[0]?.childNodes.length){
          span.children[0].childNodes.forEach((item : any) => {
            if(item.childNodes.length > 1) {
              if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
                item.id = `${blockId}${idEL+1}`;
                idEL = idEL+1
              }
            }
            if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
              item.id = `${blockId}${idEL+1}`;
              idEL = idEL+1
            }
          })
        }
        setLocalHtml((prev) => [...prev, span.innerHTML]);
      })
    }, [data]);
    //drawning comments 
    const newSpan = useMemo(() => {
      const span = document.createElement("span");
      span.id = "demo";
      span.style.zIndex = "10";
      span.style.display = "block";
      span.classList.add("HighlitedTextArticleReviewPopUp");
      span.innerHTML = `اضف تعليق`
      span.style.position = "absolute";
      span.style.top = `${45}px`;
      span.style.left = `40%`;
      return span
    },[]);
  
    const listenerClick =  useCallback((e: any) => {
      const highlight = highlights.filter((item) => item.highlightId === e.target?.parentNode?.id);
      const comment = responseComments.filter((item) => highlight[0]?.numberOfComment === item.highlight_number);
      const info = JSON.parse(comment[0]?.commentaries[0].content);
      const textEditorData = data.filter((item) => item.blockId === +info?.blockId);
      
      if(comment.length ) {
        showModalChat({highlight: highlight, highlight_id: comment[0]?.commentaries[0].highlight.id, data: [""], endTime: endTime, comment: comment});
      }
       if(comment.length && textEditorData.length) {
        showModalChat({highlight: highlight, highlight_id: comment[0]?.commentaries[0].highlight.id, data: [textEditorData[0].data], endTime: endTime, comment: comment});
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [highlights, responseComments]);

    const listenerEnter = useCallback((e : MouseEvent) => {
      newSpan.addEventListener("click", listenerClick);
      if(e.target instanceof Element) {
       const rect =  e.target.getBoundingClientRect();
        newSpan.style.top = `${rect.height}px`;
        e.target?.appendChild(newSpan);
      }  
    },[listenerClick, newSpan])

    const removeListeners = useCallback(() => {
      newSpan.firstChild?.removeEventListener("click", listenerClick);
      newSpan.remove();
    }, [listenerClick, newSpan]);

    useEffect(() => {
      
      if(highlights.length) {
        highlights.forEach((el : any) => {
          const mark : any = document.getElementById(el.highlightId);
            mark?.addEventListener("mouseenter", listenerEnter);
            mark?.addEventListener("mouseleave", removeListeners);
        })
      }
      return (() => {
        highlights.forEach((el: any) => {
          const mark : any = document.getElementById(el.highlightId);
          
          mark?.removeEventListener("mouseenter", listenerEnter);
          mark?.removeEventListener("mouseleave", removeListeners);
        })
      })
  }, [listenerEnter, removeListeners, responseHighlights, responseComments, highlights]);

  
  useEffect(() => {
    if(responseComments.length) {
      const sortResponseComments = responseComments.sort((a, b) => a.highlight_number - b.highlight_number);
      sortResponseComments.forEach((item) => {
        const content = JSON.parse(item.commentaries[0].content);
        const id = content.blockId;
        const block = document.getElementById(`${id}`);
        let divCount = 1;
        if(block?.children){
          for (let a of Object.keys(block.children)) {
            let b = block.children[+a];
            if(b.className === "CommentDiv") {
              divCount++
            }
          }
        }
        const img = document.createElement("img");
        img.style.objectFit = "cover";
        const div = document.createElement("div");
        div.style.backgroundColor = "#F5F5F6"
        div.style.width = "24px";
        div.style.borderRadius = "2px";
        div.textContent = item.commentaries[0].highlight_number.toString();
        div.style.textAlign = "center";
        div.style.marginRight = "5px";
        div.style.fontFamily = "Roboto";
        div.style.fontSize = "14px";
        div.style.lineHeight = "24px";
        if(item.processed) {
          img.src = articleSvgGray;
          div.style.color = "var(--secondaryHeader)";
        } else {
          img.src = articleSvg;
          div.style.color = "rgba(255,51,102,1)";
        }
        div.style.userSelect = "none"
        const commentDiv = document.createElement("div");
        commentDiv.id = content.info.commentId;
        commentDiv.classList.add("CommentDiv");
        commentDiv.style.width = "53px";
        commentDiv.style.height = "24px";
        commentDiv.style.position = "absolute";
        commentDiv.style.top = `${divCount * 25 - 25}px`;
        let styleRight = -65;
        if(block?.nodeName === "BLOCKQUOTE") {
          styleRight = styleRight - 50
        } 
        commentDiv.style.right = `${styleRight}px`;
        commentDiv.style.display = "flex"; 
        if (block) {
          commentDiv.appendChild(img);
          commentDiv.appendChild(div);
          const highlightsArray = JSON.parse(item.commentaries[0].highlight.content);
          const highlight = highlightsArray.filter((el : any) => el.numberOfComment === item.commentaries[0].highlight_number);
          const info = JSON.parse(item.commentaries[0].content);
          const textEditorData = data.filter((item) => item.blockId === +info.blockId);
          if(textEditorData.length) {
            commentDiv.addEventListener("click", () => {
              showModalChat({highlight: highlight, highlight_id: item.commentaries[0].highlight.id, data: [textEditorData[0].data], endTime: endTime, comment: [item]})
          });
          }
          block.style.position = "relative";
          block.appendChild(commentDiv);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseComments])

  useEffect(() => {
    if(responseHighlights?.length && responseHighlights[0]?.content && responseComments.length) {
      setHighlights(JSON.parse(responseHighlights[0]?.entries));
      getHighlighsFromRanges(responseHighlights[0]?.content);
    }
}, [responseHighlights, responseComments]);


  const getNewEdtiorData = (newDataEditor: any, blockId: number, textContent: string, highlightId: string, highlightsOfBlock: any[]) => {

    const arr = data.map((item) => {
      if(item.blockId === +blockId) {
        return {
          blockId: +blockId,
          data: newDataEditor}
      }
      return item
    })
    setData(arr);
    
    const sub_interests_arr = subInterestsData?.filter((item) =>  item.isChecked === true);

    const sub_interests = sub_interests_arr?.map((item) => {
      return {
        id: item.id,
        title: item.title
      }
    });

    const referencesStr = JSON.stringify(references);
    const content = JSON.stringify(arr);
    let apiData : ArticleData = {
      title : articleTitleCopy,
      content : content,
      header : articleSummary,
      minutes_for_reading : minutes_for_reading,
      image_url: imageUrl,
      references: referencesStr,
      sub_interests: sub_interests as sub_interests,
      word_count: word_count
    };

    if (+blockId === 1000) {
      setArticleTitleCopy(newDataEditor.children);
      apiData = {
        title : newDataEditor.children,
        content : content,
        header : articleSummary,
        minutes_for_reading : minutes_for_reading,
        image_url: imageUrl,
        references: referencesStr,
        sub_interests: sub_interests as sub_interests,
        word_count: word_count
      }
    }

    apiService.articles.updateArticle(location.pathname.split('/')[2] , apiData)
          .catch((err : AxiosError) => {
          })
        
        const a = highlightsOfBlock.map((item) => {
          const b = secondHighlights.filter((el) => item.highlightId === el.highlightId);
          if(item.highlightId === highlightId) {
            return {
              ...item,
              numberOfComment: b[0].numberOfComment,
              text: b[0].text,
              newText: textContent
            }
          }
          if(b[0].newText){
            return {
              ...b[0],
              numberOfComment: b[0].numberOfComment,
              text: b[0].text,
              newText: b[0].newText
            }
          }
          return {
            ...item,
            numberOfComment: b[0].numberOfComment,
            text: b[0].text,
          }
        })

        const final = secondHighlights.map((item) => {
          const f = highlightsOfBlock.filter((el) => item.highlightId === el.highlightId);
          if (f[0]) {
            return undefined
          }
          return item
        })

        const subFinalArr = final.filter(item => item !== undefined);
        const finalArr = subFinalArr.concat(a);

    setSecondHighlights(finalArr);
    apiService.comments.UpdateReviewHighlightByAuthor(responseHighlights[0].id , JSON.stringify(finalArr), JSON.stringify(finalArr));
  }


  useEffect(() => {
    let counterRange = 0;
    if(imageUrl !== ""){
      counterRange++
      setRangeData((prev) => {
        return {
          ...prev,
          HeaderImage: true
        }
      })
    }
    if(articleTitle !== ""){
      counterRange++
      setRangeData((prev) => {
        return {
          ...prev,
          Title: true
        }
      })
    }
    if(subInterestsData?.length >= 1){
      counterRange++
      setRangeData((prev) => {
        return {
          ...prev,
          SubInterests: true
        }
      })
    }
    if(data.length >=3){
      counterRange++
      setRangeData((prev) => {
        return {
          ...prev,
          HeadLine: true
        }
      })
    }
    if(word_count >= 250){
      counterRange++
      setRangeData((prev) => {
        return {
          ...prev,
          Words: true
        }
      })
    }
    if(references.length >= 3){
      counterRange++
      setRangeData((prev) => {
        return {
          ...prev,
          References: true
        }
      })
    }
    if(articleSummary !== ""){
      counterRange++
      setRangeData((prev) => {
        return {
          ...prev,
          Summary: true
        }
      })
    }
    if(amountOfImages >= 2){
      counterRange++
      setRangeData((prev) => {
        return {
          ...prev,
          Images: true
        }
      })
    }
    
    setRangeWidth(26 * counterRange);
  }, [articleSummary, articleTitle, data.length, imageUrl, references.length, subInterestsData?.length, word_count, amountOfImages]);

  useEffect(() => {
    let amount = 0;
    data.forEach((item) => {
      if(item.data?.type === "image") {
        amount++
      }
    })
    setAmountOfImages(amount);
  }, [data])

  //Editor Highlights 
  const getHighlighsFromRangesEditor = (selStr : string) => {
          
    let selArr = JSON.parse(selStr as string);

    let sel = getSelection();
    selArr?.forEach(function(each: any) {
    sel?.removeAllRanges();
    const newRange = objToRange(each);
    
    if(newRange) {
      sel?.addRange(newRange);
      const range = sel?.getRangeAt(0);

    const span = document.createElement("mark");
    span.id = each.highlightId;
    span.classList.add("HighlitedTextEditor"); 
    span.addEventListener("mouseenter", (e: MouseEvent) => {
      newSpan.addEventListener("click", () => {showModalChatEditor({highlight: [each]})});
      if(e.target instanceof Element) {
       const rect =  e.target.getBoundingClientRect();
        newSpan.style.top = `${rect.height}px`;
        e.target?.appendChild(newSpan);
      } 
    });
    span.addEventListener("mouseleave", (e: MouseEvent) => {
      newSpan.remove();
    });
    if(sel?.toString()) {
        span.textContent = sel?.toString() ;          
    }
    if(range) {
        range.surroundContents(span);
    }
    }
    sel?.removeAllRanges();
  })
  }
  useEffect(() => {
    if(correctionHighlights?.content) {
      getHighlighsFromRangesEditor(correctionHighlights.content);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [correctionHighlights]);

useEffect(() => {
  if(correctionHighlights && correctionHighlights.content) {
    const highlights = JSON.parse(correctionHighlights.content);
    highlights.forEach((item : any) => {
      const content = item; 
      const id = content.blockId;
      const block = document.getElementById(`${id}`);
      let divCount = 1;
      if(block?.children){
        for (let a of Object.keys(block.children)) {
          let b = block.children[+a];
          if(b.className === "CommentDiv") {
            divCount++
          }
        }
      }
      const img = document.createElement("img");
      img.src = articleSvgGreen;
      img.style.objectFit = "cover";
      const div = document.createElement("div");
      div.style.backgroundColor = "#F5F5F6"
      div.style.width = "24px";
      div.style.borderRadius = "2px";
      div.textContent = content.numberOfComment.toString();
      div.style.textAlign = "center";
      div.style.marginRight = "5px";
      div.style.fontFamily = "Roboto";
      div.style.fontSize = "14px";
      div.style.fontWeight = "700";
      div.style.lineHeight = "24px";
      div.style.color = "var(--success)";
      div.style.userSelect = "none"
      const commentDiv = document.createElement("div");
      commentDiv.id = content.highlightId;
      commentDiv.classList.add("CommentDiv");
      commentDiv.style.width = "53px";
      commentDiv.style.height = "24px";
      commentDiv.style.position = "absolute";
      commentDiv.style.top = `${divCount * 25 - 25}px`;
      let styleRight = -65;
      if(block?.nodeName === "BLOCKQUOTE") {
        styleRight = styleRight - 50
      } 
      commentDiv.style.right = `${styleRight}px`;
      commentDiv.style.display = "flex"; 
      if (block) {
        commentDiv.appendChild(img);
        commentDiv.appendChild(div);
        commentDiv.addEventListener("click", () => {showModalChatEditor({highlight: [item]})});
        block.style.position = "relative";
        block.appendChild(commentDiv);
      };
    })
  } 
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [correctionHighlights])

    return (
      <View className="DashboardArticle">
        <AppHeader/>
        <View className="RulesOfWriting">
            <Container className="RulesOfWritingHeaders">
              <View>
                  <Icon name="iconEditArticleBlack" />
                  كتابة مقال جديد
              </View>
              {/* <View className="Link" onClick={() => history.push("/rules")} onClick={() => {showModal()}}></View> */}
              <View className="Link" onClick={() => window.open("/rules", "_blank")}>
              شروط إنتاج المحتوى
              </View>
            </Container>
        </View>
        <Container>
            <Row>
                <Col lg={4}>
                    <View className="ArticleInfo">
                      <View className="MainInterest">
                        <View className="Header">
                          اهتمام رئيسي 
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"0.571em"}/>
                        <View>
                          <InputCheckBox label={userMainSpec?.data[0]?.title} name="interest" onChange={() => {}} checked readOnly/>
                        </View>
                      </View>
                      <View className="MainInterest"> 
                        <View className="Header">
                        اهتمامات فرعية 
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"1.143em"}/>
                        <View>
                          {subInterestsData?.map((item : any) => {
                            return <InputCheckBox key={item.id} checkBoxId={item.id.toString()} label={item.title} name={item.description} handlecheck={handleSubInterests} checked={item.isChecked}/>
                          })}
                        </View>
                      </View>
                      <View className="MainInterest">
                        <View className="Header">
                        الصورة البارزة
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"0.571em"}/>
                        <View className="DragAndDrop">
                        <DragAndDropImages text={"أضف الصورة البارزة المقال‎ +"} status={!!(status === "ON_CORRECTING") || !!(status === "ON_EDITING") || !!(status === "REJECTED") || !!(status === "ON_REVIEW") || !!(status === "APPROVED")} withInputUpload={false} handleImageUrl={(url) => {setImageUrl(url)}} defaultImage={imageUrl}/>
                        </View>
                      </View>
                      <View className="MainInterest"> 
                        <View className="Header">
                        قوة المقال 
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"1.143em"}/>
                        <div className="slidecontainer">
                            <div style={{width : `${rangeWidth}px`}}  className="rangeColored"></div>
                            <div style={{right: `${rangeWidth-16}px`}} className="Thumb">
                            </div>
                            <div className="rangeGray"></div>
                            <div className="sliderLine" style={{left : "39px"}}></div>
                            <div className="sliderLine" style={{left : "79px"}}></div>
                            <div className="sliderLine" style={{left : "119px"}}></div>
                            <div className="sliderLine" style={{right : "39px"}}></div>
                        </div>
                        <View className="SliderInfo">
                                <View>
                                  <Icon name={rangeData.HeaderImage ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={rangeData.HeaderImage ? {color : "var(--white)"} : {}}>صورة غلاف</span>
                                </View>
                                <View>
                                  <Icon name={rangeData.Title ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={rangeData.Title ? {color : "var(--white)"} : {}}>عنوان واضح</span>
                                </View>
                                <View>
                                  <Icon name={rangeData.SubInterests ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={rangeData.SubInterests ? {color : "var(--white)"} : {}}>1 اهتمامات فرعية او اكثر</span>
                                </View>
                                <View>
                                  <Icon name={rangeData.HeadLine ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={rangeData.HeadLine ? {color : "var(--white)"} : {}}>3 عنوانين او اكثر</span>
                                </View>
                                <View>
                                  <Icon name={rangeData.Words ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={rangeData.Words ? {color : "var(--white)"} : {}}>250 كلمة او اكثر</span>
                                </View>
                                <View>
                                  <Icon name={rangeData.References ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={rangeData.References ? {color : "var(--white)"} : {}}>3 مراجع او اكثر</span>
                                </View>
                                <View>
                                  <Icon name={rangeData.Images ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={rangeData.Images ? {color : "var(--white)"} : {}}>صور توضيحية</span>
                                </View>
                                <View>
                                  <Icon name={rangeData.Summary ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={rangeData.Summary ? {color : "var(--white)"} : {}}>مقتطف المقال</span>
                                </View>  
                              </View>
                      </View>
                      <View className="ArticleOptions">
                        {status !== "APPROVED" ?
                        <>
                       {(status !== "ON_EDITING" && status !== "ON_CORRECTING" && status !== "ON_REVIEW") &&
                        <View style={{display:"none"}} className="Flex">
                            <View onClick={() => {
                              if(status !== "ON_REVIEW"){
                                if(status !== "APPROVED" && status !=="REJECTED" && status !== "ON_EDITING" && status !== "ON_CORRECTING"){
                                  if(flag) {
                                    handleArtilce(false);
                                  }
                                }
                              }}}>
                            حفظ المسودة
                            </View>
                            <View style={{display:"none"}} onClick={() => {
                              if(status !== "ON_REVIEW"){
                                if(status !== "APPROVED" && status !=="REJECTED" && status !== "ON_EDITING" && status !== "ON_CORRECTING"){
                                  if(flag) {
                                    handleArtilce(true);
                                  }
                                }
                              }}}>
                            معاينة المسودة
                            </View>
                        </View>}
                          <Btn style={{background: "var(--blueColor)", color: "var(--white)"}} title="أرسل للمراجعة" onClick={() => {
                            if(status !== "APPROVED" && status !== "REJECTED" && status !== "ON_CORRECTING"){
                              showBeforePublisihing()
                            }
                          }}/>
                          </> : 
                          <>
                          <SpacerVertical height={"1.714em"}/>
                          <Btn style={{backgroundColor: "var(--success)", color: "var(--white)", marginBottom: "8px"}} 
                          className="ButtonApprove" 
                          title="انشر المقال" 
                          onClick={() => {apiService.articles.publishArticle(location.pathname.split('/')[2])
                            .then(r => {
                              history.push("/user_profile_auth/writer");
                            })}}/>
                          {/* <View className="Flex" style={{paddingTop: "0"}}>
                          ارسل ملاحظات 
                            </View> */}
                          </>}
                        <View className="Numbers">
                        {word_count} كلمة
                        <Icon name="iconGrayCircle"/>
                        {minutes_for_reading} دقيقة قراءة
                        </View>
                      </View>
                    </View>
                </Col>
                <Col lg={8} className="MainColumn">
                  {isLoading ? 
                  <Loader />
                 : 
                  <>
                  <DraftArticleTitle status={status} articleTitle={articleTitle} setArticleTitle={setArticleTitle}/>
                  <View id="textToSelect">
                  {data?.map((item : any, indx) => { 
                    return <DraftArticleItem
                    setFlag={() => setFlag(true)}
                    status={status}
                    commentaries={commentaries}
                    addCopiedTextEditor={addCopiedTextEditor}
                    key={item.blockId}
                    addTextEditorAfter={addTextEditorAfter}
                    deleteTextEditor={deleteTextEditor}
                    addTextEditorBefore={addTextEditorBefore}
                    handleTextEditor={handleTextEditor}
                    handleReferenceData={handleReferenceData}
                    articleContent={item} 
                    localHtml1={localHtml[indx]}
                    numberOfReferences={numberOfReferences+1} />
                  })}
                  {/* {data?.map((item : any) => { 
                    return (
                      item?.data?.map(( subitem :any , index : number) => {
                      return <DraftArticleItem
                      setFlag={() => setFlag(true)}
                      status={status}
                      commentaries={commentaries}
                      addCopiedTextEditor={addCopiedTextEditor}
                      key={item.blockId}
                      addTextEditorAfter={addTextEditorAfter}
                      deleteTextEditor={deleteTextEditor}
                      addTextEditorBefore={addTextEditorBefore}
                      handleTextEditor={handleTextEditor}
                      handleReferenceData={handleReferenceData}
                      articleContent={subitem} 
                      localHtml1={localHtml[index]}
                      numberOfReferences={numberOfReferences+1} />
                      })
                    )
                  })} */}
                  </View>
                  <View className="References"> 
                      <View className="Header">
                      المراجع
                      </View>
                      {references.length ? 
                      <ol>
                        {references.map((item) => {
                          return (
                            <li key={item.numberOfReferences}><a href={item.title} rel="noreferrer" target="_blank">{item.title}</a></li>
                          )
                        })}
                      </ol> :
                      <View className="Text">
                      لا يوجد مراجع
                      </View>
                      }
                  </View>
                  <View className="ArticleSummaryHeader">مقتطف المقال</View>
                  <InputObligatory disabled={!!(status === "ON_CORRECTING") || !!(status === "ON_EDITING") || !!(status === "REJECTED") || !!(status === "ON_REVIEW") || !!(status === "APPROVED")} value={articleSummary} className="ArticleSummary" name="ArticleSummary" placeholder="المقتطف هو ملخص المقال" onChange={(v) => {setArticleSummary(v.value)}}/>
                  </>}
                </Col> 
            </Row>
        </Container>
        <SpacerVertical height="11.643em"/>
        <AppFooter />
        <RenderBeforePublishing style={{padding: 0, width: "31.429em"}}>
            <BeforePublishingModal initReview={initReview} hideBeforePublishing={hideBeforePublishing} endTime={endTime}/>
        </RenderBeforePublishing>
        <RenderModalChat style={{width: "85%", padding: "0px"}}>
            <ChatModalWriter hideModal={hideModalChat} getNewEdtiorData={getNewEdtiorData} image_url={response?.data?.author?.image_url}/>
        </RenderModalChat>
        <RenderModalChatEditor style={{width: "85%", padding: "0px"}}>
            <ChatModalWriterEditor hideModal={hideModalChatEditor}/>
        </RenderModalChatEditor>
        <RenderModal style={{padding: "0px", width: "31.429em"}}>
            <WriterWelcomeModal hideModal={hideModal}/>
        </RenderModal>
      </View>
    )
}


function maxValue (data: ArticleBlockContentV1[]) {
  let maxValue = data[0]?.blockId || 0;
  for(let i = 0 ; i<data.length; i++){
    if(data[i]?.blockId > maxValue) {
      maxValue = data[i]?.blockId;
    }
  }
  return maxValue
}
