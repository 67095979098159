import React, { FunctionComponent } from "react"
import { Col, Row } from "react-bootstrap"
import { Btn } from "../../../../components/buttons/btn.component"
import { InputObligatory } from "../../../../components/inputs/input.component";
import { View } from "../../../../components/view/view.component"

import "./account-settings-form.scss";


export const PasswordSettingsForm: FunctionComponent = () => {


    return (
        <>
        <View className="AccountSettingsForm">
            <Row className="silverInput">
                <Col lg={12}>
                    <InputObligatory name="" label="اسم المستخدم" placeholder="ادخل كلمة السر" />
                </Col>
                <Col lg={12}>
                    <InputObligatory name="" label="أدخل كلمة السر الجديدة " placeholder="أدخل كلمة السر الجديدة" />
                </Col>
                <Col lg={12}>
                    <InputObligatory name="" label="" placeholder="أدخل كلمة السر الجديدة مره أخرى" />
                </Col>
            </Row>
        </View>
        <View className="AccountSettingsButton">
            <Btn title="حفظ التعديلات"/>
        </View>
        </>
    )
}