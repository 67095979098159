import React, { FunctionComponent, useEffect, useState } from "react";
import { deserialize } from "../../shared/utils";
import {unstable_batchedUpdates} from 'react-dom';
import { apiService } from "../../shared/services/api.service";
import { getDate, getUserId, getUserRole } from "../../shared/utils";
import { Btn } from "../buttons/btn.component";
import { Icon } from "../icons/icon.component";
import { Input, InputCheckBox, InputValue } from "../inputs/input.component";
// import { Timer } from "../timer/timer.component";
import { RichTextEditor } from "../rich-text-editor/rich-text-editor.component";
import { View } from "../view/view.component";

import "./chat-modal.scss";

interface ChatModalProps {
    hideModal: () => void;
    hadleNewHighlights: (parentInnerHtml: string, blockId: string, commentId: string, highlight_id: number, highlight_number: number) => void;
}

export const ChatModal: FunctionComponent<ChatModalProps> = (props : any) => {

    const [chatInfo, setChatInfo] = useState<any>();
    const [chatData, setChatData] = useState<any[]>([]);
    const [value, setValue] = useState("");
    const [userInfo] = useState(getUserId());
    const [blockId, setBlockId] = useState(0);
    const [commentId, setCommentId] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [date, setDate] = useState("");
    const [textEditorData, setTextEditorData] = useState<any[]>([]);

    useEffect(() => {
        if(props.newProp.highlight_id && props.newProp?.highlight[0].numberOfComment) {
            apiService.comments.getCommentariesByHighlightId(props.newProp.highlight_id, 0, 50, props.newProp?.highlight[0].numberOfComment)
                .then((r) => {
                    const info = JSON.parse(r.data.commentaries[0].content);
                    unstable_batchedUpdates(() => {
                        setChatData(r.data.commentaries);
                        setBlockId(info.blockId);
                        setCommentId(info.info.commentId);
                    })
                });
        }
        if(props.newProp?.highlight.length) {
            setChatInfo(props.newProp?.highlight[0]);
        }
    }, [props]);

    useEffect(() => {
        let textEditorData
        if(props.newProp?.highlight.length && props.newProp?.highlight[0].newText) {
            const myDocument = new DOMParser().parseFromString(props.newProp?.highlight[0].newText ? props.newProp?.highlight[0].newText : props.newProp?.highlight[0].text, 'text/html')
        textEditorData = [{
            type: 'paragraph',
            children: deserialize(myDocument.body),
          }]
        } else if (props.newProp?.highlight.length){
            const el =  document.getElementById(props.newProp?.highlight[0].highlightId);
            if(el?.textContent) {
                if (el.children.length > 0) {
                    if ( el.children[el.children.length-1].id === "demo") {
                        el.removeChild(el.children[el.children.length-1]);
                    }
                }
                const myDocument = new DOMParser().parseFromString(el.textContent, 'text/html');
                textEditorData = [{
                type: 'paragraph',
                children: deserialize(myDocument.body)
            }]
            }
        }
        if (textEditorData){
            setTextEditorData(textEditorData);
        }
         
        if(props.newProp?.highlight.length) {
            setChatInfo(props.newProp?.highlight[0]);
            setBlockId(props.newProp?.highlight[0].blockId);
        }
    }, [props]);

    const handleReferenceData = (data: string, id: number, numberOfReferences: number) => {
       
    }

    const handleChange = (item: InputValue) => {
        setValue(item.value);
    }
    const handleChatData = () => {

        const newComment = {
            blockId: blockId,
            info : {
              comment: value,
              commentId: commentId
            }
          }
        
        apiService.comments.createCommentaryByReviewHighlight(chatData[0].highlight.id, chatData[0].highlight_number, JSON.stringify(newComment), false)
          .then((r) => {
            setChatData([...chatData, r.data]);
          })
        setValue("");
    }

    const getCommentText = (item: string) => {
        const info = JSON.parse(item);
        return info.info.comment.toString();
    }
    

    const handleFixedComment = () => {
        
        if(isChecked && props.newProp.highlight[0].newText) {
           
            const mark = document.getElementById(commentId);
            const parent = document.getElementById(blockId.toString()) as Element;
            const findStr = `<mark id="${commentId}" class="HighlitedText" style="background-color: rgb(237, 237, 237);">`
            const a = parent.innerHTML;
            if(mark) {
                const b = a.indexOf(findStr);
                const f = a?.slice(0,b)
                const v = a?.slice(b+findStr.length,b+findStr.length+mark.innerHTML.length);
                const d = a?.slice(b+findStr.length+mark.innerHTML.length+7);
                const newStr = f+v+d;
                
                props.hadleNewHighlights(newStr, blockId, commentId, props.newProp.highlight_id, chatData[0].highlight_number);
            }
            props.hideModal();
        } else {
            props.hideModal();
        }
        
    }

    useEffect(() => {
        const date = new Date(props.newProp?.changedAt);
        setDate(`${date.getHours()+2}:${date.getMinutes()}`);
      }, [props.newProp])

    const handleTextEditor = (instance: any, id: number) => {
        setTextEditorData(instance);
      }

    return(
        <View className="ChatModal">
            <View className="ChatModalRightColumn">
                <View className="ChatModalHeader">
                    <View className="Comments">
                        <Icon name="iconCommentLinesBlack"/>
                        تعليق {chatInfo?.numberOfComment}  
                    </View>
                    {/* <View className="RemainigTime">
                    الوقت المتبقي للتعديلات:
                    <span><Timer endDate={props.newProp?.endTime}/> ساعة </span>
                    </View> */}
                </View>
                <View className="ChatModalSubHeaderTitle">            
                النص الآصلي
                </View>
                <View className="ChatModalSubHeader">
                {chatInfo?.text}
                </View>
                {chatInfo?.newText && 
                <View className="ChatModalSubHeaderTitle">            
                تعديل الكاتب
                </View>}
                {chatInfo?.newText && 
                <View style={{backgroundColor: "white", borderLeft:"1px solid #E8E8E8", paddingLeft:"35px",marginRight:"25px"}}>
                <View className="NewHighlightText">
                        {chatInfo.newText}
                </View>
                </View>}
                <View style={{backgroundColor: "white", padding: "1.714em 1.143em 0 1.143em",borderLeft:"1px solid #E8E8E8", margin:"0",paddingLeft:"35px",paddingTop:"32px",marginRight:"9px"}} className="DashboardArticle">
                {textEditorData.length !== 0 &&
                <RichTextEditor 
                    id={1} 
                    numberOfReferences={1}
                    handleReferenceData={handleReferenceData}
                    handleTextEditor={handleTextEditor}
                    editorData={textEditorData}
                    isParagraphProp={true}
                    isListProp={true}
                    iconsItemProp={"iconParagraph"}
                    textAlignIconProp="iconTextAlignRight"
                    isWithSettings={true}
                />}
                </View>
                {props.newProp?.changedAt &&
                        <View className="CommentHandled">
                            <InputCheckBox name="CommentHandled" handlecheck={(e) => {setIsChecked(e.target.checked);
                            }}/>
                        <span>تم تعديل التعليق في {getDate(props.newProp?.changedAt)} الساعة {date} مساءا</span>
                        </View>
                }
                <View className="ChatModalButtons">
                    <Btn className="colored" title="تأكيد" onClick={handleFixedComment}/>                
                </View>
            </View>
            <View className="ChatModalLeftColumn">
                <View className="ChatModalHeader">
                <View className="Comments">
                        <Icon name="iconReviewerHeaderChat"/>
                        للتحدّث مع الكاتب 
                </View>
                </View>
                <View className="ChatWrapper">
                    <View className="Chat">
                        {chatData.map((item) => {
                            if(getCommentText(item.content) !== "") {
                            if (userInfo === item.author.id) {
                                return (
                                    <View key={item.id} className="MessageWriter">
                                        <View className="Author">
                                            <View>
                                                <Icon src={item.author.image_url} name="iconProfilePlaceholder"/>
                                                <span>{item.author.last_name} {item.author.first_name}</span>
                                            </View>
                                            <span>{getUserRole(item.author.role)}</span>
                                        </View>
                                        <View className="comment">
                                            {getCommentText(item.content)}
                                        </View>
                                    </View>
                                )
                            } else {
                                return (
                                    <View key={item.id} className="MessageEditor">
                                        <View className="Editor">
                                            <span>{getUserRole(item.author.role)}</span>
                                            <View>
                                                {item.author.last_name} {item.author.first_name}
                                                <Icon src={item.author.image_url} name="iconProfilePlaceholder"/>
                                            </View>
                                        </View>
                                        <View className="comment">
                                        {getCommentText(item.content)}
                                        </View>
                                    </View>
                                )
                            }
                        }
                        return undefined})}
                    </View>
                </View>
                {/* {chatInfo?.newText && 
                <View style={{backgroundColor: "white", paddingTop: "24px"}}>
                <View className="NewHighlightText">
                        {chatInfo.newText}
                </View>
                </View>} */}
                {/* <View className="ChatModalSubHeaderTitle">
                <Icon name="iconNoteMark"/>
                للحديث مع الكاتب حول التعليق المُرسل، يرجى الكتابة داخل الصندوق ثم الضغط على زر إرسال.
                </View> */}
                <View className="MessageInput">
                    <Icon src={chatData.length ? chatData[0].author.image_url : ""} name="iconProfilePlaceholder"/>
                    <Input name="msg" placeholder="اكتب تعليقاً واضغط إرسال" value={value} onChange={handleChange} onKeyPress={handleChatData} style={{width:"100%"}}/>
                    <button onClick={handleChatData}>
                        <Icon name="iconSendMessage"/>
                    </button>
                </View>                
                {/* <View className="ChatModalButtons"> */}
                    {/* <Btn className="colored" title="تعديل" onClick={handleFixedComment}/> */}
                    {/* <BtnColorless className="colorless" title="إغلاق" onClick={props.hideModal}/> */}
                {/* </View> */}
            </View>
        </View>
    )
}