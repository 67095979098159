import React, { FunctionComponent, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
// import { Btn } from '../../../../components/buttons/btn.component';
import { Loader } from '../../../../components/loader/loader.component';
import { SpacerVertical } from '../../../../components/spacer/spacer.component';
import { View } from '../../../../components/view/view.component';
import { useFetch } from '../../../../shared/hooks/fetch.hook';
import { ArtcileInfo } from '../../../../shared/interfaces';
import { apiService } from '../../../../shared/services/api.service';
import { UserProfileAuthNoContent } from '../user-profile-auth-no-content/user-profile-auth-no-content.component';
import { UserProfileAuthEditorItem } from './nested/user-profile-auth-editor-item.component';

import "./user-profile-auth-editor.scss";

export const UserProfileAuthEditor: FunctionComponent = () => {

    const {response: ArtcilesResponse, isLoading: isLoadingArticles} = useFetch(() => apiService.articles.getArticlesToCorrect());
 
    const [articlesToCorrectData, setArticlesToCorrectData] = useState<ArtcileInfo[]>([]);

    useEffect(() => {
        // setArticlesToCorrectData(ArtcilesResponse?.data?.articles.slice(0,10));
        setArticlesToCorrectData(ArtcilesResponse?.data?.articles);
    }, [ArtcilesResponse]);

    // const getMore = () => {
    //     if(ArtcilesResponse?.data?.articles !== undefined && ArtcilesResponse?.data?.articles !== null) {
    //         setArticlesToCorrectData([...articlesToCorrectData , ...ArtcilesResponse.data.articles.slice(articlesToCorrectData.length,10+articlesToCorrectData.length)]);
    //     }
    // }   

    return(
        <View className="UserProfileAuthWriter">
           {isLoadingArticles ? <Loader/> :
           articlesToCorrectData?.length ?
            <View>
            <Tabs defaultActiveKey="all" transition={false} id="noanim-tab-example">
                <Tab eventKey="all" title="كل المقالات">
                    <>
                    <SpacerVertical height={"2.357em"}/>
                    {articlesToCorrectData?.map((item) => {
                        return <UserProfileAuthEditorItem key={item.id} endTime={item.correction?.end_correction_at} status={item.status.name} articleInfo={item}/>
                    })}
                    </>
                </Tab>
                <Tab eventKey="waitingedit" title="اتخذ إجراءً">
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {articlesToCorrectData?.map((item) => {
                        if(item.status.name === "ON_CORRECTING") {
                            return <UserProfileAuthEditorItem key={item.id} endTime={item.correction?.end_correction_at} status={item.status.name} articleInfo={item}/>
                        }
                        return undefined
                    })}
                    </>
                </Tab>
                
                {/* <Tab eventKey="approved" title="المقالات الموثوقة">
                <>
                    <SpacerVertical height={"2.357em"}/>
                    {articlesToCorrectData?.map((item: ArtcileInfo) => {
                        if(item.status.name === "PUBLISHED") {
                            return (
                                <UserProfileAuthEditorItem 
                                key={item.id} 
                                status={item.status.name} 
                                articleInfo={item}/>
                            )
                        }
                        return undefined
                    })}
                    </>
                </Tab> */}
                </Tabs>
                {/* <Btn title="استكشف أكثر" className="searchPageContentBtn" onClick={getMore}/> */}
            </View> : <UserProfileAuthNoContent />}
        </View>
    )
}