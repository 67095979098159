import React, { FunctionComponent, useEffect, useState } from 'react';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { View } from '../../components/view/view.component';
import { Col, Container, Row } from 'react-bootstrap';
import { RichTextEditor } from '../../components/rich-text-editor/rich-text-editor.component';
import { Icon } from '../../components/icons/icon.component';
import { InputCheckBox, InputObligatory} from '../../components/inputs/input.component';
import { Btn } from '../../components/buttons/btn.component';
import { DragAndDropImages } from '../../components/drag-and-drop/drag-and-drop.component';
import { ArticleData, ITextEditorData, sub_interests } from '../../shared/interfaces';
import { Node } from 'slate';

import './dashboard-article.scss';
import { apiService } from '../../shared/services/api.service';
import { useHistory } from 'react-router-dom';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { getMinutesForReading, getUserId, serializeToText } from '../../shared/utils';
import { AxiosError } from 'axios';
import { useModal } from '../../components/modal/modal.component';
import { WriterWelcomeModal } from '../../components/writer-welcome-modal/writer-welcome.modal';
import { AlertModal } from '../../components/alert-modal/alert-modal.component';
import { BeforePublishingModal } from '../../components/dashboard-article-modals/before-publishing-modal/before-publishing-modal.component';
// import { AlertModalConfirmation } from '../../components/alert-modal-confiramtion/alert-modal-confirmation.component';
// import { Prompt } from "react-router-dom";
type References = {
    blockId : number,
    numberOfReferences: number,
    title: string
    refId: any;
}


export const DashboardArticlePage:FunctionComponent = () => {
    
    const history = useHistory();

    const {RenderModal, hideModal} = useModal();
    const {showModal: showModalFullData, RenderModal: RenderModalFullData, hideModal: hideModalFullData} = useModal();
    const {showModal: showBeforePublisihing, RenderModal: RenderBeforePublishing, hideModal: hideBeforePublishing} = useModal();
    const {showModal: showModalPreviewData, RenderModal: RenderModalPreviewData, hideModal: hideModalPreviewData} = useModal();
    
    const {response: userMainSpec} = useFetch(() => apiService.user.getUserSpecialisations(getUserId()));

    const [counter, setCounter] = useState<ITextEditorData[]>([{
      id: 0,
      data: [],
      isParagraph: true,
      isList: true,
      iconsItem: "iconParagraph",
      textAlignIcon: "iconTextAlignRight"
    }]);
    const [deletedRef, setDeletedRef] = useState("");
    const [articleSummary, setArticleSummary] = useState("");
    const [references, setReferences] = useState<References[]>([]);
    const [numberOfReferences, setNumberOfReferences] = useState(1);
    const [articleTitle, setArticleTitle] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [subInterests, setSubInterests] = useState<any[]>([]);
    const [articleLength, setArticleLength] = useState(0);
    const [minutes_for_reading, setMinutes_for_reading] = useState(0);
    const [rangeWidth, setRangeWidth] = useState(0);
    const [amountOfImages, setAmountOfImages] = useState(0);
    const [randeData, setRangeData] = useState({
      HeaderImage: false,
      Title: false,
      SubInterests: false,
      HeadLine: false,
      Words: false,
      References: false,
      Images: false,
      Summary: false
    });
    // const [showDialog, setShowDialog] = useState<boolean>(false)

    useEffect(() => {
      const interval = setInterval(() => {
        const arr1 = counter.map((item) => {
          if(item.data.length > 0) {
            return item.data.map((el) => serializeToText(el))
          }
          return serializeToText(item.data[0])
        });
        let arr2 = arr1.flat().toString().split(' ');
        setArticleLength(() => arr2.length);
        setMinutes_for_reading(getMinutesForReading(arr2.length)); 
      }, 1000);
      return () => clearInterval(interval);
    }, [counter]);
   
    const handleSaveAndInitReview = () => {

      let c  = 0 ;
      const arrData2 = counter.map((item) => {
        return (
          item?.data?.map((subitem : any) =>{
              return {
                blockId : c++,
                data: subitem      
              }
          }) 
        )
      });
      const arrData = arrData2.flat();
      
      const referencesStr = JSON.stringify(references);
      const content = JSON.stringify(arrData);
      const apiData : ArticleData = {
        title : articleTitle,
        content : content,
        header : articleSummary,
        minutes_for_reading : minutes_for_reading,
        image_url: imageUrl,
        references: referencesStr,
        sub_interests: subInterests as sub_interests,
        word_count: articleLength
      };
      if(articleSummary !== "" && articleSummary.length >=20 && imageUrl !== ""  && subInterests.length >= 1) {
        apiService.articles.createNewArticle(apiData)
        .then(r => {
          // apiService.articles.initArticleReview(r.data.id);
          apiService.articles.publishArticle(r.data.id).then(r => {
            history.push("/user_profile_auth/writer");
          });
        })
        .catch((err : AxiosError) => {
          //alert(err?.response?.data?.message);
      })
      } else {
        showModalFullData();
      }
      
  } 

    const handleArtilce = (isPreview: boolean) => {      
        let c  = 0 ;  
        const arrData2 = counter.map((item) => {
          return {
            blockId :c++,
            data: item.data
          }
        });
        
        const arrData = arrData2[0];
        const referencesStr = JSON.stringify(references);
        const content = JSON.stringify(arrData);
        const apiData : ArticleData = {
          title : articleTitle,
          content : content,
          header : articleSummary,
          minutes_for_reading : minutes_for_reading,
          image_url: imageUrl,
          references: referencesStr,
          sub_interests: subInterests as sub_interests,
          word_count: articleLength
        };
        
        if(articleSummary !== "" && articleSummary.length >=20 && imageUrl !== "" && subInterests.length >= 1) {
          apiService.articles.createNewArticle(apiData)
          .then(r => {
            isPreview && history.push(`/draft_article/${r.data.id}`);
          })
          .catch((err : AxiosError) => {
            //alert(err?.response?.data?.message);
        })
        } else {
          // showModalFullData();
          if(isPreview ){
            showModalPreviewData();
          }else{
            showModalPreviewData();
          }
          
        }
        
    }  

    const handleReferenceData = (data: string, id: number, numberOfReferences: number, refId: any) => {
      setReferences([...references, {title: data, blockId: id, numberOfReferences: numberOfReferences, refId: refId}]);
      setNumberOfReferences(numberOfReferences+1);
    }

    const handleTextEditor = (instance: Node[], id: number) => {
      const arr = counter.map((item) => {
        if(item.id === id) {
          return {
              id: id,
              data: instance,
              isParagraph: true,
              isList: true,
              iconsItem: "iconParagraph",
              textAlignIcon: "iconTextAlignRight"
            }
        }
        return item
      });
      setCounter(arr);
    }

    const handleSubInterests = (e : any) => {
      const arr = subInterests?.find((item) => item.id === +e.currentTarget?.id);
      if(arr){
        let a = subInterests?.filter((item) => item.id !== +e.currentTarget?.id);
        setSubInterests((a && a as any) || []);
      }else {
        setSubInterests([...subInterests as any,{id: +e.currentTarget?.id, title : e.currentTarget?.name}]);
      }
    }

    const addTextEditor = () => {
        let max = maxValue(counter);
        setCounter([...counter, {
          id: max+1,
          data: [],
          isParagraph: true,
          isList: true,
          iconsItem: "iconParagraph",
          textAlignIcon: "iconTextAlignRight"
        }]);
        
    };

    const addCopiedTextEditor = (instance: ITextEditorData) => {
      let max = maxValue(counter);
      setCounter([...counter, {
        id: max+1,
        data: instance.data,
        isParagraph: instance.isParagraph,
        isList: instance.isList,
        iconsItem: instance.iconsItem,
        textAlignIcon: instance.textAlignIcon
      }]);
    };

    const deleteTextEditor = (id: number) => {
      const arr = counter.filter((item) => item.id !== id);
      setCounter(arr);
    };

    const addTextEditorAfter = (id: number) => {
      let max = maxValue(counter);
      const index = counter.findIndex((item) => item.id === id);
      const arr = counter.slice(0, index+1);
      const arr1 = counter.slice(index+1, counter.length);
      const res = [...arr, {
        id: max+1,
        data: [],
        isParagraph: true,
        isList: true,
        iconsItem: "iconParagraph",
        textAlignIcon: "iconTextAlignRight"
      }, ...arr1];
      setCounter(res);
    };

    const addTextEditorBefore = (id: number) => {
      let max = maxValue(counter);
      const index = counter.findIndex((item) => item.id === id);
      const arr = counter.slice(0, index);
      const arr1 = counter.slice(index, counter.length);
      const res = [...arr, {
        id: max+1,
        data: [],
        isParagraph: true,
        isList: true,
        iconsItem: "iconParagraph",
        textAlignIcon: "iconTextAlignRight"
      }, ...arr1];
      setCounter(res);
    };

    useEffect(() => {
      let counterRange = 0;
      if(imageUrl !== ""){
        counterRange++
        setRangeData((prev) => {
          return {
            ...prev,
            HeaderImage: true
          }
        })
      }else {
        setRangeData((prev) => {
          return {
            ...prev,
            HeaderImage: false
          }
        })
      }
      if(articleTitle !== ""){
        counterRange++
        setRangeData((prev) => {
          return {
            ...prev,
            Title: true
          }
        })
      }else {
        setRangeData((prev) => {
          return {
            ...prev,
            Title: false
          }
        })
      }
      if(subInterests.length >= 1){
        counterRange++
        setRangeData((prev) => {
          return {
            ...prev,
            SubInterests: true
          }
        })
      }else {
        setRangeData((prev) => {
          return {
            ...prev,
            SubInterests: false
          }
        })
      }
      if(counter.length >=3){
        counterRange++
        setRangeData((prev) => {
          return {
            ...prev,
            HeadLine: true
          }
        })
      }else {
        setRangeData((prev) => {
          return {
            ...prev,
            HeadLine: false
          }
        })
      }
      if(articleLength >= 250){
        counterRange++
        setRangeData((prev) => {
          return {
            ...prev,
            Words: true
          }
        })
      }else {
        setRangeData((prev) => {
          return {
            ...prev,
            Words: false
          }
        })
      }
      if(references.length >= 3){
        counterRange++
        setRangeData((prev) => {
          return {
            ...prev,
            References: true
          }
        })
      }else {
        setRangeData((prev) => {
          return {
            ...prev,
            References: false
          }
        })
      }
      if(articleSummary !== "" && articleSummary.length >=20 ){
        counterRange++
        setRangeData((prev) => {
          return {
            ...prev,
            Summary: true
          }
        })
      }else {
        setRangeData((prev) => {
          return {
            ...prev,
            Summary: false
          }
        })
      }
      if(amountOfImages >= 1){
        counterRange++
        setRangeData((prev) => {
          return {
            ...prev,
            Images: true
          }
        })
      }else {
        setRangeData((prev) => {
          return {
            ...prev,
            Images: false
          }
        })
      }
      
      setRangeWidth(26 * counterRange);
    }, [articleLength, articleSummary, articleTitle, counter.length, imageUrl, references.length, subInterests, amountOfImages, rangeWidth]);

    useEffect(() => {
      let amount = 0;
      counter.forEach((item) => {
        if(item.data[0]?.type === "image") {
          amount++
        }
      })
      setAmountOfImages(amount);
    }, [counter])

    return (
      <View className="DashboardArticle">
        {/* <Prompt
          message={() => 'ملاحظة: في حال خروجك من الصفحة لن يتم حفظ المقال. هل أنت متأكد من الخروج؟'}
        />       */}
        <AppHeader/>
        <View className="RulesOfWriting">
            <Container className="RulesOfWritingHeaders">
              <View>
                  <Icon name="iconEditArticleBlack" />
                  كتابة مقال جديد
              </View>
              {/* <View className="Link" onClick={() => showModal()}> */}
              <View className="Link" onClick={() => window.open("/rules", "_blank")}>
              شروط إنتاج المحتوى
              </View>
            </Container>
        </View>
        <Container>
            <Row>
                <Col lg={4}>
                    <View className="ArticleInfo">
                    <View className="MainInterest"> 
                        <View className="Header">
                        قوة المقال 
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"1.143em"}/>
                        <div className="slidecontainer">
                            <div style={{width : `${rangeWidth}px`}}  className="rangeColored"></div>
                            <div style={{right: `${rangeWidth-16}px`}} className="Thumb">
                              
                              </div>
                            <div className="rangeGray"></div>
                            <div className="sliderLine" style={{left : "39px"}}></div>
                            <div className="sliderLine" style={{left : "79px"}}></div>
                            <div className="sliderLine" style={{left : "119px"}}></div>
                            <div className="sliderLine" style={{right : "39px"}}></div>
                        </div>
                        <View className="SliderInfo">
                                <View>
                                  <Icon name={randeData.HeaderImage ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={randeData.HeaderImage ? {color : "var(--white)"} : {}}>صورة بارزة *</span>
                                </View>
                                <View>
                                  <Icon name={randeData.Title ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={randeData.Title ? {color : "var(--white)"} : {}}>عنوان المقال *</span>
                                </View>
                                <View>
                                  <Icon name={randeData.SubInterests ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={randeData.SubInterests ? {color : "var(--white)"} : {}}>اهتمام فرعي على الأقل *</span>
                                </View>
                                <View>
                                  <Icon name={randeData.HeadLine ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={randeData.HeadLine ? {color : "var(--white)"} : {}}>3 أقسام أو اكثر</span>
                                </View>
                                <View>
                                  <Icon name={randeData.Words ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={randeData.Words ? {color : "var(--white)"} : {}}>250 كلمة أو اكثر *</span>
                                </View>
                                <View>
                                  <Icon name={randeData.References ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={randeData.References ? {color : "var(--white)"} : {}}>3 مراجع أو اكثر *</span>
                                </View>
                                <View>
                                  <Icon name={randeData.Images ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={randeData.Images ? {color : "var(--white)"} : {}}>صورة توضيحية</span>
                                </View>
                                <View>
                                  <Icon name={randeData.Summary ? "iconSliderCheck" : "iconSliderCheckGray"}/>
                                  <span style={randeData.Summary ? {color : "var(--white)"} : {}}>مقتطف المقال *</span>
                                </View>  
                              </View>
                      </View>
                      <View className="MainInterest">
                        <View className="Header">
                          اهتمام رئيسي 
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"0.571em"}/>
                        <View>
                          <InputCheckBox label={userMainSpec?.data[0]?.title} name="interest" onChange={() => {}} checked readOnly/>
                        </View>
                      </View>
                      <View className="MainInterest"> 
                        <View className="Header">
                          اهتمامات فرعية
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"1.143em"}/>
                        <View>
                          {userMainSpec?.data[0]?.sub_interests?.map((item : any) => {
                            return <InputCheckBox key={item.id} checkBoxId={item.id.toString()} label={item.title} name={item.description} handlecheck={handleSubInterests}/>
                          })}
                        </View>
                      </View>
                      <View className="MainInterest">
                        <View className="Header">
                        الصورة البارزة
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"0.571em"}/>
                        <View className="DragAndDrop">
                        <DragAndDropImages text={"أضف صورة بارزة للمقال.      حجم الصورة أقل من ٢ ميجابايت."} withInputUpload={false} defaultImage={""} handleImageUrl={(url) => {setImageUrl(url)}}/>
                        </View>
                      </View>                     
                      <View className="ArticleOptions">
                        <View className="Flex">
                            <View style={{display:"none"}} onClick={() => handleArtilce(false)}>
                            حفظ المسودة
                            </View>
                            <View style={{display:"none"}} onClick={() => handleArtilce(true)}>
                            معاينة المسودة
                            </View>
                        </View>
                          <Btn title="أرسل للمراجعة" onClick={() => {
                            if(articleSummary !== "" && articleSummary.length >=20 && imageUrl !== "" && subInterests.length >= 1 && references.length >= 3) {
                              showBeforePublisihing()
                            }else {
                              showModalFullData();
                            }
                          }} style={{background: "var(--blueColor)", color: "var(--white)"}}/>
                        <View className="Numbers">
                        {articleLength} كلمة
                        <Icon name="iconGrayCircle"/>
                        {minutes_for_reading} دقيقة قراءة
                        </View>
                      </View>
                    </View>
                </Col>
                <Col lg={8} className="MainColumn">
                  <>
                  <InputObligatory value={articleTitle} name="title" placeholder="إضافة عنوان للمقال" onChange={(e) => setArticleTitle(e.value)}/>
                  {counter.map((item) => {
                    return (
                      <View key={item.id} style={{position: "relative"}}>
                        <RichTextEditor 
                          id={item.id} 
                          deletedRef={deletedRef}
                          numberOfReferences={numberOfReferences}
                          handleReferenceData={handleReferenceData}
                          handleTextEditor={handleTextEditor}
                          editorData={item.data}
                          isParagraphProp={item.isParagraph}
                          isListProp={item.isList}
                          iconsItemProp={item.iconsItem}
                          textAlignIconProp={item.textAlignIcon}
                          deleteTextEditor={deleteTextEditor} 
                          addTextEditorBefore={addTextEditorBefore} 
                          addTextEditorAfter={addTextEditorAfter} 
                          addCopiedTextEditor={addCopiedTextEditor}/>
                        <SpacerVertical height={"2.286em"}/>
                        <button className="AddTextEditor" onClick={addTextEditor}>
                          <Icon name="iconAddTextEditor" />
                        </button>
                      </View>
                    )
                  })} 
                  <SpacerVertical height={"15.286em"}/>
                  <View className="References"> 
                      <View className="Header">
                      المراجع 
                      <span>(إذا رغبت بتعديل أو حذف أي مرجع أو فقرة تتضمن مرجعاً، يرجى إجراء ذلك من خلال قسم المراجع)</span>
                      </View>
                      {references.length ? 
                      <ol>
                        {references.map((item) => {
                          return (
                            <li className="ReferencesItem" key={item.numberOfReferences}>{item.title}
                            <div className="DeleteReference" onClick={() => {
                              const ref = references.filter((el) => el.numberOfReferences !== item.numberOfReferences)
                              setReferences(ref)
                              setDeletedRef(item.refId);
                              setNumberOfReferences(numberOfReferences-1);
                            }}><Icon name="iconCloseRejected"></Icon></div>
                            </li>
                          )
                        })}
                      </ol> :
                      <View className="Text">
                      لا يوجد مراجع
                      </View>
                      }
                  </View>
                  <SpacerVertical height={"2.286em"}/>
                  <View className="References">
                    <View className="Header">
                    مقتطف المقال (۲۰ حرفاً على الأقل)
                    </View>
                    <SpacerVertical height={"0.571em"}/>
                    <InputObligatory                  
                    required
                    minLength={20}
                     errorMessage="يجب أن يتكون مقتطف المقال من ٢٠ حرف على الأقل"
                     inputPrompt="يجب أن يتكون مقتطف المقال من ٢٠ حرف على الأقل"
                     pattern={/(?=.*[0-9])(?=.*[!@#$%^&\]\\*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&\]\\*]{8,}/}
                     errorMessagePattern="يجب أن يتكون مقتطف المقال من ٢٠ حرف على الأقل"
                     value={articleSummary}
                    className="ArticleSummary" 
                    name="ArticleSummary" 
                    placeholder="ملخص المقال الذي سيظهر لجذب القرّاء" 
                    onChange={(v) => {setArticleSummary(v.value)}}/>
                  </View>
                  </> 
                </Col> 
            </Row>
        </Container>
        <SpacerVertical height="11.643em"/>
        <AppFooter />
        <RenderModal style={{padding: "0px", width: "31.429em"}}>
            <WriterWelcomeModal hideModal={hideModal}/>
        </RenderModal>
        <RenderBeforePublishing style={{padding: 0, width: "31.429em"}}>
            <BeforePublishingModal initReview={handleSaveAndInitReview} hideBeforePublishing={hideBeforePublishing} endTime={"0"}/>
        </RenderBeforePublishing>
        <RenderModalFullData>
                <AlertModal alertText="
 الرجاء تحقيق 5 شروط على الأقل من شروط قوة المقال لتستطيع إرسال المقال للمراجعة" alertHeader="" hideModal={hideModalFullData}/>
        </RenderModalFullData>
        <RenderModalPreviewData>
                <AlertModal alertText="الرجاء تعبئة عنوان المقال والصورة البارزة ومقتطف المقال واهتمام فرعي قبل حفظ المقال كمسودة" alertHeader="" hideModal={hideModalPreviewData}/>
        </RenderModalPreviewData>
      </View>
    )
}


function maxValue (counter: ITextEditorData[]) {
  let maxValue = counter[0]?.id || 0;
  for(let i = 0 ; i<counter.length; i++){
    if(counter[i]?.id > maxValue) {
      maxValue = counter[i]?.id;
    }
  }
  return maxValue
}
