import React,  { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";
import { Btn } from "../../../../../components/buttons/btn.component";
import { Icon } from "../../../../../components/icons/icon.component";
import { SpacerVertical } from "../../../../../components/spacer/spacer.component";
import { SuggestedArticlesItem } from "../../../../../components/suggested-articles/nested/suggested-articles-item.component";
// import { Timer } from "../../../../../components/timer/timer.component";
import { Header3 } from "../../../../../components/typo/header.component";
import { View } from "../../../../../components/view/view.component";
import { ArtcileInfo } from "../../../../../shared/interfaces";

interface UserProfileAuthReviewerItemProps {
    status: string;
    onClick?: () => void;
    articleInfo: ArtcileInfo;
    rejectedReason?: string;
    rejectedComment?: string;
    endTime?: string | undefined;
}

export const UserProfileAuthReviewerItem: FunctionComponent<UserProfileAuthReviewerItemProps> = (props) => {

    const history = useHistory();

    // const {onClick, articleInfo, status, rejectedReason, rejectedComment, endTime} = props;
    const {onClick, articleInfo, status, rejectedReason, rejectedComment} = props;

    return(
        <View onClick={onClick}>
        {/* {status === "DRAFT" &&
        <View className="editing">
            <View className="editingTitle">
                <Icon name="iconEditorCornerBlack"/>
                المسودة
                <BtnColorless title="تحرير"/>
            </View>
        </View>} */}
        {status === "ON_EDITING" && 
        <View className="editing">
            <View className="editingTitle">
                <Icon name="iconEditorCornerBlack"/>
                بانتظار تعديلات الكاتب 
                {/*<BtnColorless title=" تعديل"/>*/}
            </View>
            {/* <View className="editingInfo">
            الوقت المتبقي للتعديلات
            <span> 16 تعديل</span>
            </View> */}
        </View>}
        {status === "REJECTED" &&
        <View className="rejected">
            <View className="rejectedTitle">
                <Icon name="iconCloseRejected"/>
                مقال مرفوض
                {/* <BtnColorless title="تحرير"/> */}
            </View>
            <SpacerVertical height={"0.571em"}/>
             <View className="rejectedInfo"> 
                <Header3 style={{marginBottom: "8px"}} title={rejectedReason ? rejectedReason : ""}/>
                {rejectedComment ? rejectedComment : ""}
            </View>
            <SpacerVertical height={"0.643em"}/>
        </View>
        }
        {status === "ON_REVIEW" && 
        <View className="reviewing">
            <View className="reviewingTitle">
                <Icon name="iconReviewerCornerOrange"/>
                بانتظار المراجعة
                <Btn title="راجع المقال" onClick={() => {history.push(`/article_review/${articleInfo.id}`)}}/>
            </View>
            {/* <View className="reviewingTime">
            الوقت المقدر للمراجعة 

            <span> <Timer endDate={endTime ? endTime : ""}/> ساعة</span>
            </View> */}
        </View>}
        {status === "APPROVED" && 
        <View className="approved">
        مقال موثق   
            <Icon name="iconVerifiedGreen"/>
        </View>}
        {status === "ON_CORRECTING" && 
        <View className="reviewing">
            <View className="reviewingTitle">
                <Icon name="iconReviewerCornerOrange"/>
                قيد التحرير
            </View>
            {/* <View className="reviewingTime">
            الوقت المقدر للتحرير

            <span> <Timer endDate={endTime ? endTime : ""}/> ساعة</span>
            </View> */}
        </View>}
        <SuggestedArticlesItem 
            opacity={(status === "ON_EDITING" || status === "ON_CORRECTING") ? 0.4 : 1}
            title={articleInfo.title}
            header={articleInfo.header}
            image_url={articleInfo.image_url}
            interest={articleInfo.interest}
            author={articleInfo.author}
            created_at={articleInfo.created_at}
            minutes_for_reading={articleInfo.minutes_for_reading}
            articleId={articleInfo.id}
            status={articleInfo.status.name}
            updated_at={articleInfo.updated_at}/>
        </View>
    )
}