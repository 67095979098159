import React, { FunctionComponent } from 'react';
import { BtnColorless } from '../buttons/btn.component';
import { View } from '../view/view.component';

interface AlertModalProps {
    alertHeader: string;
    alertText: string;
    hideModal: () => void;
}

export const AlertModal:FunctionComponent<AlertModalProps> = (props) => {

    const {alertText, alertHeader, hideModal} = props;
    
    return (
        <View className="WriterWelcomeModal">
           <View className="Header">
           {alertHeader}
            </View> 
            <View className="ResetPasswordSubTitle" style={{padding: "35px"}}>
            {alertText}
            </View>
            <View className="Buttons">
                <BtnColorless title="إغلاق" onClick={hideModal}/>
            </View>
        </View>
    )
}