import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { AlertModal } from '../../components/alert-modal/alert-modal.component';
import { Btn } from '../../components/buttons/btn.component';
import { InputObligatory } from '../../components/inputs/input.component';
import { useModal } from '../../components/modal/modal.component';
import { View } from '../../components/view/view.component';
import { apiService } from '../../shared/services/api.service';

import "./reset-password.scss";


export const ResetPasswordPage: FunctionComponent = () => {

    const location : any = useLocation();
    const history = useHistory();

    const access_token = new URLSearchParams(location.search).get('access_token');

    const [pass, setPass] = useState("");
    const [secondPass, setSecondPass] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const {showModal: showModalSuccess, RenderModal: RenderModalSuccess, hideModal: hideModalSuccess} = useModal();

    const createNewPassword = () => {
        if(pass === secondPass){
            apiService.auth.UpdatePassword(access_token as string, pass)
            .then((r) => {
                showModalSuccess();
                history.push("/login");
            })
        } else {
            setErrorMessage("الرجاء إعادة كتابة كلمة السركلمة سر غير مطابقة");
        }
        
    }

    return (
        <View className="ResetPassword">
             <View className="AccountSettingsForm">
                <Row className="silverInput">
                    <Col lg={12}>
                        <InputObligatory type="password" name="" value={pass} onChange={(e) => {setPass(e.value)}} label="أدخل كلمة السر الجديدة " placeholder="أدخل كلمة السر الجديدة" />
                        <InputObligatory type="password" name="" value={secondPass} onChange={(e) => {setSecondPass(e.value)}} label="" placeholder="أدخل كلمة السر الجديدة مره أخرى" />
                        <View className="ResetPasswordErrorMessage">{errorMessage}</View>
                        <View className="ResetPasswordButton">
                      <Btn title="حفظ التعديلات" onClick={createNewPassword}/>
                    </View>
                    </Col>
                </Row>
            </View>
            <RenderModalSuccess>
                    <AlertModal alertText="Your password changed successfully" alertHeader="" hideModal={hideModalSuccess}/>
            </RenderModalSuccess>
        </View>
    )
}