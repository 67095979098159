import React, { FC, useState } from "react";
import { apiService } from "../../shared/services/api.service";
import { Icon } from "../icons/icon.component";
import { Loader } from "../loader/loader.component";
import { View } from "../view/view.component";

import "./drag-and-drop.scss";


interface DnDProps {
  withInputUpload: boolean;
  handleImageUrl ?: (url : string) => void;
  defaultImage : string;
  status ?: boolean;
  text: string;
  withoutPlaceHolder?: boolean;
}

const DragAndDrop = (props : any) => {
  const { data, dispatch, withInputUpload , handleImageUrl, defaultImage, status, text, withoutPlaceHolder} = props;

  const handleDragEnter = (event : React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      if(!status) {
      dispatch({ type: "AddToDropZone", inDropZone: true });
    } 
   
  };

  const handleDragOver = (event : React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if(!status) {
    event.dataTransfer.dropEffect = "move";
    dispatch({ type: "AddToDropZone", inDropZone: true });
    }
  };

  const [isLoading, setisLoading] = useState(false);

  const handleDrop = (event : React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if(!status) {
    let files = [event.dataTransfer.files];
    let files_with_preview = [];
    files.forEach((file : any) => {
      setisLoading(true);
      apiService.files.uploadNewImage(file[0])
        .then(r => {
          console.log(r);
          file["preview"] = r.data.file_url
          files_with_preview.push(file);
          setisLoading(false);
        })
    });

    if (files.length) {
      dispatch({ type: "AddToList", files });
      dispatch({ type: "AddToDropZone", inDropZone: false });
    }}
  };

  const handleFile = (e : React.ChangeEvent<HTMLInputElement>) => {
    let files = [e.target.files];
    let files_with_preview = [];
    files.forEach((file : any) => {
      setisLoading(true);
      apiService.files.uploadNewImage(file[0])
        .then(r => {
          console.log(r);
          file["preview"] = r.data.file_url;
          handleImageUrl(r.data.file_url)
          files_with_preview.push(file);
          setisLoading(false);
        })
    });

    if (files.length) {
      dispatch({ type: "AddToList", files });
    }
  }

  return (
    <>
      <div
        className={"drag-drop-zone"}
        onDrop={event => handleDrop(event)}
        onDragOver={event => handleDragOver(event)}
        onDragEnter={event => handleDragEnter(event)}
      >
          
            {!withoutPlaceHolder &&
             <View className="DnDPlaceholder">
                <label>
                    <Icon name={data.fileList.length || defaultImage ? "iconInsertPhotoWhite" : "iconInsertPhoto"}/>
                    <View style={data.fileList.length || defaultImage ? {color: "#FFFFFF"} : {}}>{text}</View>
                    <input type="file" accept="image/png, image/jpeg, image/svg, image/jpg, image/WebP" onChange={handleFile}/>
               </label>
               {withInputUpload && 
               <label className="inputFileUpload">
                    <input type="file" accept="image/png, image/jpeg, image/svg, image/jpg, image/WebP" onChange={handleFile}/>
                    تحميل الصورة  
               </label>}
            </View>}
          
          {(defaultImage && !data.fileList.length) &&
            <img
              draggable="false"
              src={defaultImage}
              alt={defaultImage}
              style={{ width: "100%", height: "100%", objectFit: "cover"}}
          />
          }
          {!isLoading ? data.fileList.map((file : any) => {
            return (
                <img
                  onChange={handleImageUrl(file.preview)}
                  draggable="false"
                  key={file.preview}
                  src={file.preview}
                  alt={file.preview}
                  style={{ width: "100%", height: "100%", objectFit: "cover"}}
                />
            );
          }) : <Loader/>}
      </div>
    </>
  );
};

export const DragAndDropImages:FC<DnDProps> = (props) => {

  const { withInputUpload, handleImageUrl, defaultImage, status, text, withoutPlaceHolder } = props;

  const state = {
    inDropZone: false,
    fileList: [] as any
  };

  const reducer = (state : any, action: any) => {
    switch (action.type) {
      case "AddToDropZone":
        return { ...state, inDropZone: action.inDropZone };
      case "AddToList":
        return { ...state, fileList: action.files };
      default:
        return state;
    }
  };

  const [data, dispatch] = React.useReducer(reducer, state);

  return (
    <div className="DragAndDropImage">
      <DragAndDrop data={data} dispatch={dispatch} withInputUpload={withInputUpload} defaultImage={defaultImage} handleImageUrl={handleImageUrl} status={status} text={text} withoutPlaceHolder={withoutPlaceHolder}/>
    </div>
  );
}
