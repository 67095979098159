import React, { FunctionComponent } from 'react';
import { Icon } from '../../icons/icon.component';
import { SpacerVertical } from '../../spacer/spacer.component';
import { View } from '../../view/view.component';

import "../recommended-interests.scss"

interface RecommendedInterestsItemProps {
    title: string;
    followers_count?: number;
    articles_count?: number;
    authors_count?: number;
}

export const RecommendedInterestsItem: FunctionComponent<RecommendedInterestsItemProps> = (props) => {

    const {title, articles_count, followers_count, authors_count} = props;
    
    return (
        <View className="RecommendedInterestsItem">
            <View className="interest">{title}</View>
            <SpacerVertical height={"0.571em"}/>
            <View className="numbers">
                <span>{articles_count}</span> مقال
                <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                <span>{authors_count}</span> كاتب
                <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                <span>{followers_count}</span> متابَع
                
            </View>
        </View>
    )
}    