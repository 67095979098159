import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { WriterInfo } from '../../../shared/interfaces';
import { Icon } from '../../icons/icon.component';
import { View } from '../../view/view.component';

import "../suggested-articles.scss"

interface SuggestedArticlesWritersProps {
    userInfo ?: WriterInfo
}

export const SuggestedArticlesWriters: FunctionComponent<SuggestedArticlesWritersProps> = (props) => {

    const {userInfo} = props;

    const history = useHistory();

    return(
        <View style={{cursor: "pointer"}} className="Flex" onClick={() => {history.push(`/user_profile/${userInfo?.id}`)}}>
            <View className="iconPhoto">
                <Icon src={userInfo?.image_url} name="iconProfilePlaceholder" alt="author_profile_photo"/>
            </View>
            <View>
                <View className="Flex">
                <View className="item1"><span>{userInfo?.first_name} {userInfo?.last_name}</span></View>
                <Icon name="iconVerifiedSmall" />
                </View>
                <View onClick={(e) => {
                    e.stopPropagation();
                    history.push(`/interests_tabs/${userInfo?.specialization?.id}`)}}><span>{userInfo?.specialization?.title}</span></View>
            </View>
        </View>
    )
}