import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { Btn, BtnSmallOpacity } from '../../components/buttons/btn.component';
import { Icon } from '../../components/icons/icon.component';
import { Loader } from '../../components/loader/loader.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { SuggestedArticlesItem } from '../../components/suggested-articles/nested/suggested-articles-item.component';
import { SuggestedArticlesWriters } from '../../components/suggested-articles/nested/suggested-articles-writers.component';
import { Header1 } from '../../components/typo/header.component';
import { View } from '../../components/view/view.component';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { ArtcileInfo, WriterInfo } from '../../shared/interfaces';
import { apiService } from '../../shared/services/api.service';
import { activeClass } from '../../shared/utils';
import { ContentNotFound } from './nested/content-not-found.component';
import { WritersSearchItem } from './nested/writers-item.search-page';

import "./search.page.scss";


export const SearchPage: FunctionComponent = () => {

    const {response: responseRandomWriters, isLoading: randomWritersIsLoading} = useFetch(() => apiService.user.getRandomWriters(5));
    const {response: responseRandomArticles, isLoading: randomArticlesIsLoading} = useFetch(() => apiService.articles.getRandomArticles(9, apiService.auth.isAuthenticated()));


    const [isArticles, setIsArticles] = useState(true);
    const [isWriters, setIsWriters] = useState(false);
    const [dataArticles, setDataArticles] = useState<ArtcileInfo[]>([]);
    const [isAuth] = useState(apiService.auth.isAuthenticated());
    const [dataWriters, setDataWriters] = useState<any[]>([]);
    const [randomWriters, setRandomWriters] = useState<WriterInfo[]>([]);
    const [randomArticles, setRandomArticles] = useState<ArtcileInfo[]>([]);
    const [isNoContent, setIsNoContent] = useState(true);
    

    const params : any = useLocation();

    useEffect(() => {
        if(params?.state?.results) {
            if(params?.state?.string === "isArticles") {
                setIsArticles(true);
                setIsWriters(false);
            }
            if(params?.state?.string === "isWriters") {
                setIsWriters(true);
                setIsArticles(false);
            }
            const middleData = JSON.parse(params?.state?.results);
            setIsNoContent(false);
            setDataArticles(middleData?.articles_data?.articles);
            setDataWriters(middleData?.authors_data?.authors);
        }
    }, [params]);

    useEffect(() => {
        if(responseRandomWriters) {
            setRandomWriters(responseRandomWriters.data?.authors);
        }
        if(responseRandomArticles) {
            setRandomArticles(responseRandomArticles.data?.articles);
        }
    }, [responseRandomWriters, randomWritersIsLoading, responseRandomArticles, randomArticlesIsLoading])

    const getSearchResults = useCallback((searchResults: any) => {
        if(searchResults?.data?.articles_data?.articles.length === 0 && searchResults?.data?.authors_data?.authors.length === 0) {
            setIsNoContent(true);
            setIsWriters(false);
            setIsArticles(true);
            setDataArticles(randomArticles);
            setDataWriters(randomWriters);
        }
        
        if(searchResults?.data?.articles_data?.articles.length > 0) {
            setIsNoContent(false);
            setDataArticles(searchResults?.data?.articles_data?.articles);
            setIsWriters(false);
            setIsArticles(true);
        }
        if(searchResults?.data?.authors_data?.authors.length > 0) { 
            setIsNoContent(false);
            setDataWriters(searchResults?.data?.authors_data?.authors);
            setIsWriters(true);
            setIsArticles(false);
        }
        
    }, [randomArticles, randomWriters])   

    const getMore = () => {
        
    }


    return (
        <View className="App">
            <View className="searchPage">
            <AppHeader getSearchResults={getSearchResults}/> 
            <Container>
                <Row>
                    <Col lg={4}>
                        {!isAuth &&
                        <View className="whyMawsuah">
                            <View className="whyMawsuahTitle">لماذا موسوعة؟</View>
                            <SpacerVertical height={"1em"}/>
                            <View className="whyMawsuahText">                            
                            موسوعة نظام تحفيزي مبني على تقنية البلوكتشين، وهي المنصة العربية المفضلة لمنتجي المحتوى المختّص في كتابة مواضيع موثوقة لتصلهم بملايين الباحثين والقرّاء.
                            </View>
                            <SpacerVertical height={"1.714em"}/>
                            <View className="whyMawsuahBtn">
                                <Btn className="registerBtn" title="أنشئ حساباً"/>
                            </View>
                        </View>}
                            <View className="SuggestedArticlesListMostReadInterests"> 
                                    <BtnSmallOpacity className="MostReadInterestsTitle" title="الكتّاب الأكثر متابعة"  disabled={true}/>
                                    <SpacerVertical height={"2.286em"}/>
                                    <View className="MostFollowedWritersLists">
                                    {!randomWritersIsLoading ? randomWriters?.map((item) => {
                                            return (
                                                <View key={item.id}>
                                                    <SuggestedArticlesWriters userInfo={item}/>
                                                    <SpacerVertical height={"1.714em"}/>
                                                </View>
                                            )
                                        }) : <Loader/>}
                                   </View>
                            </View> 
                    </Col>
                    <Col lg={8}>
                    <View className="searchPageHeader">
                        <Icon name="iconSearch"/>
                        <Header1 title={params?.state?.searchTerm ? params?.state?.searchTerm : "الذكاء الاصطناعي "}/>
                    </View>
                    {isNoContent &&
                            <>
                            <ContentNotFound />
                            <Header1 className="ContentNotFoundHeader" title="الاهتمامات الأكثر قراءةً"/>
                            </>
                    }
                    <SpacerVertical height={"2.286em"} />
                    <View className="searchPageMenu">
                        <View className={activeClass("searchPageMenuItem", isArticles)}
                            onClick={() => {
                                setIsArticles(true);
                                setIsWriters(false);
                            }}>
                        مقالات  
                        </View>
                        <View className={activeClass("searchPageMenuItem", isWriters)}
                                onClick={() => {
                                    setIsArticles(false);
                                    setIsWriters(true);
                                }}>
                        كتّاب
                        </View>
                    </View>
                    <SpacerVertical height={"2em"} />
                    {/* <View className="searchPageFilter">
                        <View className="searchPageFilterItem Active" >
                        مقالات  
                        </View>
                        <View className="searchPageFilterItem" >
                        كتّاب
                        </View>
                        <View className="searchPageFilterItem" >
                        {!isArticles ? "الأكثر متابعة" : "اهتمامات ذات صله"}
                        </View>
                    </View> */}
                    <SpacerVertical height={"3.143em"} />
                    <View className="searchPageContent">
                        {isArticles && 
                        <>
                            {dataArticles?.map((item) => {
                                return (
                                    <SuggestedArticlesItem 
                                    created_at={item.created_at}
                                    toSave={isAuth}
                                    key={item.id}
                                    title={item.title}
                                    header={item.header}
                                    image_url={item.image_url}
                                    interest={item.interest}
                                    author={item.author}
                                    published_at={item.published_at}
                                    minutes_for_reading={item.minutes_for_reading}
                                    articleId={item.id}
                                    status={item.status?.name}
                                    isSaved={item.is_saved}
                                    updated_at={item.updated_at}
                                    />
                                )
                            })}
                        </>}
                        {isWriters && 
                            <>
                            {dataWriters?.map((item) => {
                                return(
                                    <WritersSearchItem 
                                    key={item.id}
                                    writerInfo={item}
                                    />
                                )
                            })}
                                
                            </>}
                        <Btn title="استكشف أكثر" className="searchPageContentBtn" onClick={getMore}/>
                    </View>
                    </Col>
                </Row>
            </Container>
            <SpacerVertical height={"4.571em"} />
            <AppFooter />
            </View>
        </View>
    )
}