import React, { FunctionComponent, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { AppFooter } from '../../../components/app-footer/app-footer.component';
import { AppHeader } from '../../../components/app-header/app-header.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { Header1, Header3 } from '../../../components/typo/header.component';
import { View } from '../../../components/view/view.component';
import { Icon } from '../../../components/icons/icon.component';
import { BtnColorless } from '../../../components/buttons/btn.component';
import blogBG from '../../../assets/images/StaticPages/BlogPage/blogBG.png';
import Block1 from "../../../assets/images/StaticPages/BlogPage/Block1.png";
import Block2 from "../../../assets/images/StaticPages/BlogPage/Block2.png";
import Block3 from "../../../assets/images/StaticPages/BlogPage/Block3.png";
import Block4 from "../../../assets/images/StaticPages/BlogPage/Block4.png";
import Block5 from "../../../assets/images/StaticPages/BlogPage/Block5.png";
import Block6 from "../../../assets/images/StaticPages/BlogPage/Block6.png";
import Block7 from "../../../assets/images/StaticPages/BlogPage/Block7.png";
import Block8 from "../../../assets/images/StaticPages/BlogPage/Block8.png";
import Block9 from "../../../assets/images/StaticPages/BlogPage/Block9.png";
import Block10 from "../../../assets/images/StaticPages/BlogPage/Block10.png";
import Block11 from "../../../assets/images/StaticPages/BlogPage/Block11.png";

export const BlogPage: FunctionComponent = () => { 

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <View className="App">
        <AppHeader isWhite={true}/>
        <View className="CareersPage">
            <View className="CareersPageHeader" title="careers_photo" style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.88) -8.74%, rgba(0, 0, 0, 0) 58.58%), url(${blogBG})`}}>
                <Container style={{marginTop: "96px"}}>
                    <View className="CareersPageHeaderContent">
                        <Header1 title="نحن وأنتم بالموقع ذاته" className="header1"/>
                    </View>
                    <View className="CareersPageHeaderContent">
                        <Icon name="iconMawsuahLogo"/>
                        <span className="bigText">موسوعة</span>
                        <Icon name="iconVerifiedSmallWhiteBG"/>
                        <span className="smallText">196 ألف متابع</span>
                        <BtnColorless title="تابِع"/>
                    </View>
                </Container>
            </View>
            <SpacerVertical height={"4.5em"}/>
            <View className="CareersPageContent">
                <Container>
                    <Row>
                        <Col lg={3} className="CareersPageContentSecondColumn">
                            {/* <View className="CareersPageContentSecondColumnMenu" style={{borderBottom: "0px"}}>
                                <View className="Item">سياسية الخصوصية</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View>
                            <View className="CareersPageContentSecondColumnDisabled">
                                <View className="Item">المساعدة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المجتمعات</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">معتمدون لدى </View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الشركاء</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">المدونة</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">الوظائف</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">شروط الاستحدام</View>
                                <SpacerVertical height={"0.55em"}/>
                                <View className="Item">التواصل</View>
                                <SpacerVertical height={"0.55em"}/>
                            </View> */}
                        </Col>
                        <Col className="CareersPageContentFirstColumn" lg={9}>
                            <Header3 className="writersHeader" title="كل ما هو جديد"/>
                            <div className="smallLine"></div>
                            <View className="CareersPageContentFirstColumnContent">
                                <View className="smallHeader">
                                لنظلّ وإيّاكم على الصفحة نفسها، نتتبع أخبار مجتمعنا، وتتبعون تحديثات موسوعة، أنشأنا مدوّنة موسوعة التي تحرص على الشفافية، وتخاطب مجتمع موسوعة إلى جانب الجمهور المستهدف بكافة التفاصيل وأقرب ما يمكن.
                                </View>
                                <SpacerVertical height={"2.286em"}/>
                                <View style={{borderBottom: "1px solid var(--grayColorMain)"}}>
                                    <View style={{backgroundImage: `url(${Block1})`}} className="CareersPageContentFirstColumnContentFirstBlock">
                                    </View>
                                    <SpacerVertical height="2.143em"/>
                                    <View style={{display: "flex", justifyContent: "space-between"}}>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block2})`}}>
                                        </View>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block3})`}}> 
                                        </View>
                                    </View>
                                    <SpacerVertical height="2.143em"/>
                                    <View style={{display: "flex", justifyContent: "space-between"}}>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block4})`}}>
                                            
                                        </View>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block5})`}}>
                                            
                                        </View>
                                    </View>
                                    <SpacerVertical height="2.143em"/>
                                    <View style={{backgroundImage: `url(${Block6})`}} className="CareersPageContentFirstColumnContentFirstBlock">
                                    </View>
                                    <SpacerVertical height="2.143em"/>
                                    <View style={{display: "flex", justifyContent: "space-between"}}>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block7})`}}>
                                        </View>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block8})`}}> 
                                        </View>
                                    </View>
                                    <SpacerVertical height="2.143em"/>
                                    <View style={{backgroundImage: `url(${Block9})`}} className="CareersPageContentFirstColumnContentFirstBlock">
                                    </View>
                                    <SpacerVertical height="2.143em"/>
                                    <View style={{display: "flex", justifyContent: "space-between"}}>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block10})`}}>
                                        </View>
                                        <View className="CareersPageContentFirstColumnContentSubBlock" style={{backgroundImage: `url(${Block11})`}}> 
                                        </View>
                                    </View>
                                    <SpacerVertical height="2.143em"/>
                                </View>
                                <View className="moreCareers">
                                    <div>استكشاف المزيد</div>
                                    <Icon name="iconArrowLeft" />
                                </View>
                            </View>
                        </Col>
                    </Row>
                </Container>
                <SpacerVertical height={"3.3em"}/>
            </View>
            <AppFooter />
        </View>
    </View>
    )
}