import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthorData, InterestsData } from '../../shared/interfaces';
import { apiService } from '../../shared/services/api.service';
import { getDate } from '../../shared/utils';
import { Icon } from '../icons/icon.component';
import { SpacerVertical } from '../spacer/spacer.component';
import { View } from '../view/view.component';

import "./communitypicks.scss"

interface CommunityPicksProps {
    opacity?: number;
    toSave?: boolean;
    title?: string;
    header?: string;
    image_url?: string;
    interest?: InterestsData;
    author?: AuthorData;
    created_at?: string;
    published_at: string;
    minutes_for_reading?: number;
    articleId?: number;
    status: string;
    isSaved?: boolean;
}

export const CommunityPicks: FunctionComponent<CommunityPicksProps> = (props) => {

    const {created_at, published_at, toSave, author, title, interest, image_url, minutes_for_reading, articleId, isSaved} = props;

    const [saved, setSaved] = useState(isSaved);
    const [isAuth] = useState(apiService.auth.isAuthenticated());

    const handleSave = () => {
        if(articleId) {
            if(!saved) {
                apiService.articles.addArticleToSave(articleId)
                .then(r => {
                    setSaved(!saved);
                });
            } else {
                apiService.articles.removeArticleFromSaved(articleId)
                .then(r => {
                    setSaved(!saved);
                });
            }
            
        }
    }

    const history = useHistory();
    
    return (
        <View className="CommunityPicks">
            <Icon src={image_url} name="iconCommunityPicks" className="iconCommunityPicks" alt="community_picture" onClick={() => {history.push(`/article/${articleId}`)}}/>
            <SpacerVertical height={"1.143em"}/>
            <View className="interest" onClick={() => {history.push(`/article/${articleId}`)}}>{title}</View>
            <SpacerVertical height={"1.143em"}/>
            <View className="CommunityPicksAuthor"  onClick={() => history.push(`/user_profile/${author?.id}`)}>
                <Icon name="iconProfilePlaceholder" alt="author_photo_profile" className="CommunityPicksPhoto32"/>
                <span>{author?.first_name} {author?.last_name}</span>
                <Icon name="iconVerifiedSmall"/>
            </View>
            <SpacerVertical height={"0.714em"}/>
            <View className="numbers">
                <span>{interest?.title}</span>
                <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                {published_at ? getDate(published_at) : getDate(created_at)}
                <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                {minutes_for_reading} دقائق قراءة 
                {(toSave && isAuth) && <Icon name={saved ? "iconBookmarkRoundedBlack" : "iconBookmarkRounded"} alt="bookmark_picture" onClick={handleSave} />}
            </View>
        </View>
    )
}  