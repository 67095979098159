import React, { FunctionComponent } from "react"
import { SpacerVertical } from "../../../../components/spacer/spacer.component"
import { View } from "../../../../components/view/view.component"
import { UserNotificationsItemAccordion } from "./user-settings-notifications-accordion";

import "./user-settings-notifications.scss";
interface UserNotificationsItemProps {
    content: {
        title: string;
        text: string[];
    };
}


export const UserNotificationsItem: FunctionComponent<UserNotificationsItemProps> = (props) => {

    const {content} = props

    return (
        <>
        <View className="UserNotificationsTitle">
        {content.title}
        </View>
        <SpacerVertical height={"1.143em"}/>
        <View className="UserNotifications">
        {content.text.map((item, i) => {
            return (
                <View key={i}>
                    <UserNotificationsItemAccordion text={item} />
                </View>
            )
        })}
        </View>
        </>
    )
}