import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import { BtnColorless } from "../../../components/buttons/btn.component";
import { Icon } from "../../../components/icons/icon.component";
import { SpacerVertical } from "../../../components/spacer/spacer.component"
import { View } from "../../../components/view/view.component"
import { AuthorData } from "../../../shared/interfaces";
import { apiService } from "../../../shared/services/api.service";
import { getUserId, getUserRole } from "../../../shared/utils";

interface UserProfileSmallInfoProps {
    authorData?: AuthorData;
}


export const UserProfileSmallInfo: FunctionComponent<UserProfileSmallInfoProps> = (props) => {

    const {authorData} = props;

    const [isFollowed, setIsFollowed] = useState(authorData?.is_followed);

    const history = useHistory();

    const handleClick = () => {
        if (!apiService.auth.isAuthenticated()) {
            history.push("/login");
        } else if(!isFollowed){
            apiService.user.followAuthor(getUserId(), authorData?.id as string)
                .then(r => setIsFollowed(true));
            
        } else {
            apiService.user.unFollowAuthor(getUserId(), authorData?.id as string)
                .then(r => setIsFollowed(false));
        }
        
    }

    return (
        <>
        <SpacerVertical height={"4.571em"} />
            <View className="UserProfileGoBack" onClick={() => {history.goBack()}}>
                <Icon name="iconArrowRight" alt="iconArrowRight"/>
                رجوع إلى الخلف
            </View>
        <SpacerVertical height={"2.357em"} />
        <View className="UserProfileSmallInfo">
        <View className="Badges">
            {authorData?.badges?.map((item) => {
                return <Icon key={item.id} src={item.image_url} name="" alt={item.name}/>
            })}
        </View>
            <View className="UserProfileSmallInfoPhoto">
                    <Icon src={authorData?.image_url} name="iconProfilePlaceholder" alt="author_profile_photo"/>
            </View>
            <View className="UserProfileSmallInfoContent">
                    <View className="UserProfileSmallInfoContentTitle">
                        <span>{authorData?.first_name} {authorData?.last_name}</span>   
                        <Icon name="iconVerifiedLarge"/>
                        <BtnColorless
                        title={isFollowed ? "متابَع" : "تابِع"} 
                        style={isFollowed ? {backgroundColor: "var(--blueColor)", color:"var(--white)", border: "0px"} : {}} 
                        onClick={handleClick}
                    />
                    </View>
                    <SpacerVertical height={"0.571em"} />
                    <View className="WritersSearchNumbers">   
                        <View className="Flex">
                            {getUserRole(authorData?.role)} 
                            <span className="spanBold"style={{cursor: "pointer"}} onClick={() => {
                            if(authorData?.specializations !== undefined) {
                                history.push(`/interests_tabs/${authorData?.specializations[0].id}`)
                            }
                           }}>{authorData?.specializations !== undefined ? authorData?.specializations[0].title : ""}</span>  
                            <Icon className="iconGrayCircle displayNone" name="iconGrayCircle"/>
                            <span className="displayNone"> عضو منذ {authorData?.registration_year}</span> 
                            <Icon className="iconGrayCircle displayNone" name="iconGrayCircle"/>
                            <span className="displayNone">{authorData?.country}</span>
                        </View>
                    </View>
                    <SpacerVertical height={"1.143em"} />
                    <View className="UserProfileSmallInfoContentText">
                        {authorData?.bio}
                    </View>
                    <SpacerVertical height={"1.143em"} />
                    <View className="WritersSearchNumbers">   
                        <View className="Flex">
                            <Icon name="iconVideo"/>
                            <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                            {authorData?.articles_count} <span>مقال</span>
                            <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                            {authorData?.followers_count} <span>متابع </span>
                        </View>
                    </View>
            </View>
        </View>
    </>
    )
}