import React, { FunctionComponent, useEffect, useState } from 'react';
import { Btn } from '../../../../components/buttons/btn.component';
import { Icon } from '../../../../components/icons/icon.component';
import { useModal } from '../../../../components/modal/modal.component';
import { SpacerVertical } from '../../../../components/spacer/spacer.component';
import { Header1 } from '../../../../components/typo/header.component';
import { View } from '../../../../components/view/view.component';
import { WriterWelcomeModal } from '../../../../components/writer-welcome-modal/writer-welcome.modal';

import "./user-profile-auth-no-content.scss";

export const UserProfileAuthNoContent: FunctionComponent = () => {

    const {showModal, RenderModal, hideModal} = useModal();

    const [role, setRole] = useState("");

    useEffect(() => {
        setRole(JSON.parse(localStorage.getItem("mawsuah_user_data") as string).role.name);
    }, [])

    return(
        <View className="UserProfileAuthNoContent">
            <View className="UserProfileAuthNoContentItems">
                <Header1 title=" لا يوجد مقالات لديك"/>
                <SpacerVertical height={"1.714em"}/>
                <View className="UserProfileAuthNoContentItemsText">
               يستحسن أن تلقي نظرة على 
               {' '}
              <span>تعلميات وقواعد كتابة</span>
              المقالات قبل البدء بالكتابة
                </View>
                <SpacerVertical height={"2.286em"}/>
               {(role === "WRITER" || role === "WRITER_EDITOR" || role === "WRITER_REVIEWER") && <Btn title="اكتب مقال جديد" onClick={() => showModal()}/>}
            </View>
            <Icon className="iconRocket" name="iconRocket" alt="content_not_found_picture"/>
            <RenderModal style={{padding: "0px", width: "31.429em"}}>
                <WriterWelcomeModal hideModal={hideModal}/>
            </RenderModal>
        </View>
    )
}