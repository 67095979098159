import React, { FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { View } from '../../components/view/view.component';
import { Col, Container, Row } from 'react-bootstrap';
import { Icon } from '../../components/icons/icon.component';
import { InputCheckBox } from '../../components/inputs/input.component';
import { Btn } from '../../components/buttons/btn.component';
import { DragAndDropImages } from '../../components/drag-and-drop/drag-and-drop.component';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { cx, css } from '@emotion/css';

import './article-review-page.scss';
import { Header2 } from '../../components/typo/header.component';
import { apiService } from '../../shared/services/api.service';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { Loader } from '../../components/loader/loader.component';
import { References } from '../../shared/interfaces';
import { objToRange, rangeToObj, serialize } from '../../shared/utils';
import articleSvg from '../../assets/images/ArticleReview/commentAltLines.png';
import articleSvgGray from '../../assets/images/ArticleReview/commentAltLinesGray.png';
import { useModal } from '../../components/modal/modal.component';
import { ChatModal } from '../../components/chat-modal/chat-modal.component';
import { nanoid } from 'nanoid';
// import { Timer } from '../../components/timer/timer.component';
import { AlertModal } from '../../components/alert-modal/alert-modal.component';
import { ReportContentModal } from '../../components/report-content-modal/report-content.modal';

export const ArticleReviewPage:FunctionComponent = () => {

    const location: any = useLocation();
    const params : {id: string} = useParams();
    const history = useHistory();

    const {showModal, RenderModal, hideModal } = useModal();
    const {showModal: showModalReportContent, RenderModal: RenderModalReportContent} = useModal();
    const {showModal: showModalEmptyComments, RenderModal: RenderModalEmptyComments, hideModal: hideModalEmptyComments} = useModal();
    const {showModal: showModalCommentsConfirmation, RenderModal: RenderModalCommentsConfirmation, hideModal: hideModalCommentsConfirmation} = useModal();
    const {showModal: showModalApproved, RenderModal: RenderModalApproved, hideModal: hideModalApproved} = useModal();
    
    

    useEffect(() => {
      apiService.comments.getReviewsHighlights(location.pathname.split('/')[2])
      .then(r => {
        if (r?.data?.highlights?.length){
          setArticleHighlightId(r?.data?.highlights[0].id);
          setResponseHighlights(r?.data?.highlights);
        }
        if(r.data.highlights?.length > 0) {
          apiService.comments.getCommentariesByHighlightId(r.data.highlights[0].id, 0, 50)
          .then((r) =>{
            if(r.data.correspondence.length > 0) {
              setResponseComments(r.data.correspondence);
            }
          });
        }
      });
    }, [location.pathname]);

   
    

    const [responseHighlights, setResponseHighlights] = useState<any[]>([]);
    const [responseComments, setResponseComments] = useState<any[]>([]);
   
    const {response: responseArticle, isLoading: CurrentArticleLoading} = useFetch(() => apiService.articles.getArticleToReviewById(+location.pathname.split('/')[2]));
    const {response: responseAllReviewForArticle} = useFetch(() => apiService.articles.getReviewsByArticle(+location.pathname.split('/')[2]));
    const [articleHighlightId, setArticleHighlightId] = useState(0);
    const [reviewId, setReviewId] = useState(0);
    const [interestMain, SetInterestMain] = useState("");
    const [articleData, setArticleData] = useState<any[]>([]);
    const [articleTitle, setArticleTitle] = useState("");
    const [header, setHeader] = useState("");
    const [references, setReferences] = useState<References[]>([]);
    const [subInterests, setSubInterests] = useState<any[]>([]);
    const [imageUrl, setImageUrl] = useState("");
    const [minutes_for_reading, setMinutes_for_reading] = useState(0);
    const [word_count, setWord_count] = useState(0);
    const [endTime, setEndTime] = useState("");

    const [, setData] = useState([""]);
    const [highlight, setHighlight] = useState<any[]>([]);
   
    const [selectArray, setSelectArray] = useState<any[]>([]);
    const [comments, setComments] = useState<any[]>([]);
    const [numberOfComment, setNumberOfComment] = useState(1);

    useEffect(() => {
      window.scrollTo(0,0)
    }, [])

    useEffect(() => {
      setReviewId(responseAllReviewForArticle?.data?.reviews[0]?.id);
      setEndTime(responseAllReviewForArticle?.data?.reviews[0]?.end_review_at);
    }, [responseAllReviewForArticle])

    const newSpan = useMemo(() => {
      const span = document.createElement("span");
      span.id = "demo";
      span.style.display = "block";
      span.classList.add("HighlitedTextArticleReviewPopUp");
      span.innerHTML = `اضف تعليق`
      span.style.position = "absolute";
      span.style.top = `${45}px`;
      span.style.left = `40%`;
      return span
    },[]);


  
    const listenerClick = (e: any) => {
       const highlight = selectArray.filter((item) => item?.highlightId === e.target.parentNode.id);
       
       const comment = comments.filter((item) => highlight[0]?.numberOfComment === item.highlight_number);
       
       if(comment.length) {
        showModal({highlight: highlight, highlight_id: comment[0].commentaries[0].highlight.id, endTime: endTime, changedAt: comment[0].changedAt});
       }
    }

    const listenerEnter = useCallback((e : MouseEvent) => {
      newSpan.addEventListener("click", listenerClick);
      if(e.target instanceof Element) {
       const rect =  e.target.getBoundingClientRect();
        newSpan.style.top = `${rect.height}px`;
        e.target?.appendChild(newSpan);
      }  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectArray, responseComments, comments])

    const removeListeners = useCallback(() => {
      newSpan.firstChild?.removeEventListener("click", listenerClick);
      newSpan.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if(selectArray.length) {
        selectArray.forEach((el) => {
          const mark : any = document.getElementById(el?.highlightId);
            mark?.addEventListener("mouseenter", listenerEnter);
            mark?.addEventListener("mouseleave", removeListeners);
        })
      }
      return (() => {
        selectArray.forEach((el) => {
          const mark : any = document.getElementById(el?.highlightId);
          mark?.removeEventListener("mouseenter", listenerEnter);
          mark?.removeEventListener("mouseleave", removeListeners);
        })
      })
  }, [listenerEnter, removeListeners, selectArray, responseComments, comments]);

  
  useEffect(() => {
    if(responseComments.length) {
      const sortResponseComments = responseComments.sort((a, b) => a.highlight_number - b.highlight_number);
      sortResponseComments.forEach((item) => {
        const content = JSON.parse(item.commentaries[0].content);
        const id = content.blockId;
        const block = document.getElementById(`${id}`);
        let divCount = 1;
        if(block?.children){
          for (let a of Object.keys(block.children)) {
            let b = block.children[+a];
            if(b.className === "CommentDiv") {
              divCount++
            }
          }
        }
        const img = document.createElement("img");
        img.style.objectFit = "cover";
        const div = document.createElement("div");
        div.style.backgroundColor = "#F5F5F6"
        div.style.width = "24px";
        div.style.borderRadius = "2px";
        div.textContent = item.commentaries[0].highlight_number.toString();
        div.style.textAlign = "center";
        div.style.marginRight = "5px";
        div.style.fontFamily = "Roboto";
        div.style.fontSize = "14px";
        div.style.lineHeight = "24px";
        if(item.processed) {
          img.src = articleSvgGray;
          div.style.color = "var(--secondaryHeader)";
        } else {
          img.src = articleSvg;
          div.style.color = "rgba(255,51,102,1)";
        }
        div.style.userSelect = "none"
        const commentDiv = document.createElement("div");
        commentDiv.id = content.info.commentId;
        commentDiv.classList.add("CommentDiv");
        commentDiv.style.width = "53px";
        commentDiv.style.height = "24px";
        commentDiv.style.position = "absolute";
        commentDiv.style.top = `${divCount * 25 - 25}px`;
        let styleRight = -65;
        if(block?.nodeName === "BLOCKQUOTE") {
          styleRight = styleRight - 50
        } 
        commentDiv.style.right = `${styleRight}px`;
        commentDiv.style.display = "flex"; 
        if (block) {
          commentDiv.appendChild(img);
          commentDiv.appendChild(div);
          const highlightsArray = JSON.parse(item.commentaries[0].highlight.content);
          const highlight = highlightsArray?.filter((el : any) => el.numberOfComment === item.commentaries[0].highlight_number);
          commentDiv.addEventListener("click", () => showModal({highlight: highlight, highlight_id: item.commentaries[0].highlight.id, endTime: endTime, changedAt: item?.changedAt}));
          block.style.position = "relative";
          block.appendChild(commentDiv);
          setNumberOfComment(item.commentaries[0].highlight_number + 1);
        }
      
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseComments])
  

  useEffect(() => {
    if(comments.length !== responseComments.length) {
        const content = JSON.parse(comments[comments.length - 1].commentaries[0].content); 
        const id = content.blockId;
        const block = document.getElementById(`${id}`);
        let divCount = 1;
        if(block?.children){
          for (let a of Object.keys(block.children)) {
            let b = block.children[+a];
            if(b.className === "CommentDiv") {
              divCount++
            }
          }
        }
        const img = document.createElement("img");
        img.src = articleSvg;
        img.style.objectFit = "cover";
        const div = document.createElement("div");
        div.style.backgroundColor = "#F5F5F6"
        div.style.width = "24px";
        div.style.borderRadius = "2px";
        div.textContent = comments[comments.length - 1].commentaries[0].highlight_number.toString();
        div.style.textAlign = "center";
        div.style.marginRight = "5px";
        div.style.fontFamily = "Roboto";
        div.style.fontSize = "14px";
        div.style.lineHeight = "24px";
        div.style.color = "rgba(255,51,102,1)";
        div.style.userSelect = "none"
        const commentDiv = document.createElement("div");
        commentDiv.id = content.info.commentId;
        commentDiv.classList.add("CommentDiv");
        commentDiv.style.width = "53px";
        commentDiv.style.height = "24px";
        commentDiv.style.position = "absolute";
        commentDiv.style.top = `${divCount * 25 - 25}px`;
        let styleRight = -65;
        if(block?.nodeName === "BLOCKQUOTE") {
          styleRight = styleRight - 50
        } 
        commentDiv.style.right = `${styleRight}px`;
        commentDiv.style.display = "flex"; 
        if (block) {
          commentDiv.appendChild(img);
          commentDiv.appendChild(div);
          const highlightsArray = JSON.parse(comments[comments.length - 1].commentaries[0].highlight.content);
          const highlight = highlightsArray.filter((el : any) => el.numberOfComment === comments[comments.length - 1].commentaries[0].highlight_number);
          commentDiv.addEventListener("click", () => showModal({highlight: highlight, highlight_id: comments[comments.length - 1].commentaries[0].highlight.id, endTime: endTime, changedAt: undefined }));
          block.style.position = "relative";
          block.appendChild(commentDiv);
          setNumberOfComment(comments[comments.length - 1].commentaries[0].highlight_number + 1);
        };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments])
  

function getSelectionText(e : any) {
    let text ;
    const modalRoot = document.querySelector("#modal-root");
    const input = document.getElementsByClassName("Input");
    let parent = e.target;
    let flag = false;
    for(let i = 0; i < 100; i++) {
      
    if(parent?.parentElement?.className !== "Modal") {
      parent = parent?.parentElement
    } else {
      flag = true
      break;
    }
    }

    text = document?.getSelection()?.toString();
    
    if (document.getSelection() && document.getSelection()?.anchorNode !== modalRoot && !flag && document.getSelection()?.anchorNode !== input[2] && document.getSelection()?.type !== "None") {
       setData([""]);
    } 
    
    return text;
}

function replaceSelectedText(replacementText : HTMLSpanElement) {
    let sel, range : any;
    if (document.getSelection) {
        sel = document.getSelection();
        if (sel?.rangeCount) {
            range = sel?.getRangeAt(0);
            try {
                range?.surroundContents(replacementText);
               
            } catch (error) {
                console.log(error)
            }
            sel.removeAllRanges();
        }
    } 
}
    
    useEffect(() => {
      if(!CurrentArticleLoading && responseArticle?.data) {
        setArticleData(JSON.parse(responseArticle?.data?.content));
        setArticleTitle(responseArticle?.data?.title);
        setHeader(responseArticle?.data?.header);
        setReferences(JSON.parse(responseArticle?.data?.references));
        setSubInterests(responseArticle?.data?.sub_interests);
        SetInterestMain(responseArticle?.data?.interest.title);
        setImageUrl(responseArticle?.data?.image_url);
        setMinutes_for_reading(responseArticle?.data?.minutes_for_reading);
        setWord_count(responseArticle?.data?.word_count);
      }
      
    }, [responseArticle, CurrentArticleLoading])

        
    useEffect(() => {
            const func = function(e : MouseEvent){
              if(e && getSelectionText(e) !== "") {
                e.preventDefault();
              }
            }
            document.addEventListener("mouseup", func);
        return () => {
          document.removeEventListener("mouseup", func);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

        const handleComment = (response: any) => {

          setComments([...comments, {commentaries: [response], total_count: 1, highlight_number: response.highlight_number}]);
        }

        useEffect(() => {
          
          const el = document.getElementById('textToSelect');
          articleData?.forEach((item) => {
            let idEL = 4000;
            const span = document.createElement('span');
            span.innerHTML = serialize(item.data, item.blockId);
            const blockId = item.blockId;
            if(span?.children[0]?.childNodes?.length){
              span.children[0].childNodes.forEach((item : any) => {
                if(item.childNodes.length > 1) {
                  item.childNodes.forEach((item : any) => {
                    if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
                      item.id = `${blockId}${idEL+1}`;
                      idEL = idEL+1
                    }
                  })
                }
                if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
                  item.id = `${blockId}${idEL+1}`;
                  idEL = idEL+1
                }
              })
            }
            el?.insertAdjacentHTML('beforeend', span.innerHTML);          
          })
        }, [articleData]);
        
        // useEffect(() => {
          
        //   const el = document.getElementById('textToSelect');
        //   articleData?.forEach((item) => {
        //     item?.data?.forEach((subitem: any) => {
        //       let idEL = 4000;
        //       const span = document.createElement('span');
        //       span.innerHTML = serialize(subitem, item.blockId);
        //       const blockId = item.blockId;
        //       if(span?.children[0]?.childNodes?.length){
        //         span.children[0].childNodes.forEach((item : any) => {
        //           if(item.childNodes.length > 1) {
        //             item.childNodes.forEach((item : any) => {
        //               if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
        //                 item.id = `${blockId}${idEL+1}`;
        //                 idEL = idEL+1
        //               }
        //             })
        //           }
        //           if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
        //             item.id = `${blockId}${idEL+1}`;
        //             idEL = idEL+1
        //           }
        //         })
        //       }
        //       el?.insertAdjacentHTML('beforeend', span.innerHTML);  
        //     });        
        //   })
        // }, [articleData]);

        const changeArticleStatus = (status: string) => {
            apiService.articles.UpdateReviewStatus(reviewId, status)
            .then(r => {
              history.push("/user_profile_auth/reviewer");
            });
        }        


        useEffect(() => {
          if(responseHighlights[0]?.content && articleData && responseComments.length) {
            try {
            const oldHighlightsArray = JSON.parse(responseHighlights[0].content);
            setSelectArray(oldHighlightsArray);
            setComments(responseComments);
            setHighlight(JSON.parse(responseHighlights[0]?.entries));
            getHighlighsFromRanges(responseHighlights[0]?.content);
            } catch (error) {
              
            }
          }
        
      }, [responseHighlights, articleData, responseComments]);
      

        const getHighlighsFromRanges = (selStr : string) => {
          
          let selArr = JSON.parse(selStr as string);
        
          let sel = getSelection();
          selArr?.forEach(function(each: any) {
          sel?.removeAllRanges();
          const newRange = objToRange(each);
          if(newRange) {
            sel?.addRange(newRange);
            const range = sel?.getRangeAt(0);

          const span = document.createElement("mark");
          if(sel?.toString()) {
              span.textContent = sel?.toString() ;
              span.id = each.highlightId;
              span.classList.add("HighlitedText");
          }
          
          if(each.newText) {
            span.style.backgroundColor = "#EDEDED"
          }
          if(range) {
              range.surroundContents(span);
          }
          }
          sel?.removeAllRanges();
        })
      }

      const hadleNewHighlights = (parentInnerHtml: string, blockId: string, commentId: string, highlight_id: number, highlight_number: number) => {
        const parent = document.getElementById(blockId);
        if(parent) {
          parent.innerHTML = parentInnerHtml;
          const div = document.getElementById(commentId);
          div?.parentNode?.removeChild(div);
        }
        const allMarks = parent?.querySelectorAll(".HighlitedText");
        const allDivs = parent?.querySelectorAll(".CommentDiv");
        if(allMarks && allDivs) {
          const range : any = new Range();
          let arr : any[] = []
          allMarks.forEach((item : any, indx) => {



              // ==================================
            if(!!item.firstChild?.textContent?.length && item.firstChild?.textContent.length) {
              range.setStartBefore(item);
              range.setEndAfter(item);
            }
            
           const startTextIndex = Array.prototype.indexOf.call(range.startContainer.childNodes, item)
           let endTextIndex = Array.prototype.indexOf.call(range.endContainer.childNodes, item)
           
        
             let startOffset = item.previousSibling?.textContent?.length
             let endOffset = item.textContent?.length

             if(item.childNodes.length > 1) {
              endTextIndex = endTextIndex + item.childNodes.length - 1
              endOffset = item.childNodes[item.childNodes.length - 1].textContent?.length
           }

            if(startOffset && endOffset){
              if(indx === 0 && startTextIndex === 1){
                const obj = startTextIndex-1 === endTextIndex-1 ? {
                  highlightId : item.id,
                  startKey: range.startContainer.id,
                  startTextIndex: startTextIndex-1,
                  endKey: range.endContainer.id,
                  endTextIndex: endTextIndex - 1,
                  startOffset: startOffset,
                  endOffset: endOffset+startOffset
                 } : {
                  highlightId : item.id,
                  startKey: range.startContainer.id,
                  startTextIndex: startTextIndex-1,
                  endKey: range.endContainer.id,
                  endTextIndex: endTextIndex-1,
                  startOffset: startOffset,
                  endOffset: endOffset
                }
                 arr.push(obj);
              } else {
                const obj1 = startTextIndex-1 === endTextIndex-1 ? {
                  highlightId : item.id,
                  startKey: range.startContainer.id,
                  startTextIndex: startTextIndex-1,
                  endKey: range.endContainer.id,
                  endTextIndex: endTextIndex-1,
                  startOffset: startOffset,
                  endOffset: endOffset+startOffset
                } : {
                  highlightId : item.id,
                  startKey: range.startContainer.id,
                  startTextIndex: startTextIndex-1,
                  endKey: range.endContainer.id,
                  endTextIndex: endTextIndex-1,
                  startOffset: startOffset,
                  endOffset: endOffset
                }
                arr.push(obj1);
              }
            }

            // ==================================

          })
          
          const arrWithoutItem = selectArray.filter((item) => item?.highlightId !== commentId);
          
          const a = arr.map((item) => {
            const b = arrWithoutItem.filter((el) => item?.highlightId === el?.highlightId);
            if(b.length) {
              return {
                ...item,
                numberOfComment: b[0].numberOfComment,
                text: b[0].text,
                newText: b[0].newText
              }
            }
           return undefined
          })
  
          const final = arrWithoutItem.map((item) => {
            const f = arr.filter((el) => item?.highlightId === el?.highlightId);
            if (f[0]) {
              return undefined
            }
            return item
          })
  
          const subFinalArr = final.filter(item => item !== undefined);
          const finalArr = subFinalArr.concat(a);

          const finalArr1 = finalArr.filter((item) => item !== null);
          
          setSelectArray(finalArr1);
          allDivs.forEach((item : any) => {
            const highlight = selectArray.filter((el) => el?.highlightId === item.id);
            const comment = comments.filter((item) => highlight[0]?.numberOfComment === item.highlight_number);
            if(comment.length) { 
              item.addEventListener("click", () => showModal({highlight: highlight, highlight_id: comment[0].commentaries[0].highlight.id, endTime: endTime, changedAt: comment[0].changedAt}));
            }
          })
          
          const contentHighlights = JSON.stringify(finalArr1);
          apiService.comments.deleteCommentsByHighlightIdAndNumber(highlight_id, highlight_number)
            .then(() => {
              if(finalArr1.length) {
                apiService.comments.createReviewHighlight(+params.id, contentHighlights, contentHighlights)
              } else {
                apiService.comments.deleteHighlights(articleHighlightId)
              }
            })
        }
      }
      

    return (
      <View className="DashboardArticle">
        <AppHeader/>
        <View className="RulesOfWriting">
            <Container className="RulesOfWritingHeaders">
              <View>
                  <Icon name="iconArticleReview" />
                <span>مراجعة مقال</span>
              </View>
              <View className="Link">
              <span onClick={() => window.open("/rules", "_blank")}>شروط إنتاج المحتوى</span>              
              <Icon style={{width: "8px", height: "8px", marginLeft: "5px"}} name="iconGrayCircle"/>
              {/* <span className="ReviewArticleTimer"><span style={{color: "var(--black)"}}>الوقت المتبقي للمراجعة :</span> {endTime ? <Timer endDate={endTime}/> : "00:00:00"} ساعة</span> */}
              </View>
            </Container>
        </View>
        <Container>
            <Row>
                  <Col lg={4}>
                    <View className="ArticleInfo">
                      <View className="MainInterest">
                        <View className="Header">
                          اهتمام رئيسي 
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"0.571em"}/>
                        <View>
                          <InputCheckBox label={interestMain} name="interest" onChange={() => {}} checked readOnly/>
                        </View>
                      </View>
                      <View className="MainInterest"> 
                        <View className="Header">
                        اهتمامات فرعية 
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"1.143em"}/>
                        <View>
                          {subInterests?.map((item : any) => {
                            return <InputCheckBox key={item.id} checkBoxId={item.id.toString()} label={item.title} name={item.description} handlecheck={() => {}} checked={true}/>
                          })}
                        </View>
                      </View>
                      <View className="MainInterest">
                        <View className="Header">
                        الصورة البارزة
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"0.571em"}/>
                        <View className="DragAndDrop">
                        <DragAndDropImages text={"أضف الصورة البارزة المقال‎ +"} withoutPlaceHolder={true} status={true} withInputUpload={false} handleImageUrl={(url) => {setImageUrl(url)}} defaultImage={imageUrl}/>
                        </View>
                      </View>
                      <View className="ArticleReviewOptions">
                          <Btn className="ButtonReject" title="رفض المقال" onClick={() =>  showModalReportContent()}/>
                          <SpacerVertical height={"0.571em"}/>
                          <Btn className="ButtonEdit" title="إرسال التعليقات للكاتب" onClick={() =>{ 
                             if( selectArray.length ===  0 ){
                              showModalEmptyComments()
                             }else{
                              changeArticleStatus("AMENDMENTS_REQUESTED")
                              showModalCommentsConfirmation()
                             }
                            }
                            
                            }/>
                          {selectArray.length === 0 && 
                          <>
                          <SpacerVertical height={"0.571em"}/>
                          <Btn className="ButtonApprove" title="توثيق المقال" onClick={() => {
                            changeArticleStatus("APPROVED")
                            showModalApproved()
                            
                            }}/>
                          </>}
                      </View>
                      <SpacerVertical height={"1.714em"}/>
                      <View className="ReviewNumbers">
                        {word_count} كلمة
                        <Icon name="iconGrayCircle"/>
                        {minutes_for_reading}  دقائق قراءة
                      </View>
                    </View>
                </Col>
                    <Col lg={8}>
                        <View id="ArticleContent" className="ArticleContent ArticleReview">
                          <HoveringToolbar 
                              location={location.pathname.split('/')[2]}
                              highlight={highlight} 
                              setHighlight={setHighlight} 
                              selectArray={selectArray} 
                              replaceSelectedText={replaceSelectedText} 
                              setSelectArray={setSelectArray} 
                              handleComment={handleComment}
                              rangeToObj={rangeToObj}
                              comId={numberOfComment}
                              />
                            {!CurrentArticleLoading ?
                            <>
                            
                            
                            <View id="textToSelect" className="ArticleContentText textToSelect">
                            <h1 id="1000">{articleTitle}</h1>
                            <SpacerVertical height={"2em"}/>
                            </View>
                            <SpacerVertical height={"2.286em"}/>
                            {references?.length !== 0 && <Header2 style={{color: "var(--secondaryHeader)"}} title="المراجع"/>}
                            <View className="NumberedList" style={{fontSize: "1em"}}>
                                <ol>
                                  {references?.map((item) => {                                    
                                    return <li key={item.numberOfReferences}><a href={item.title} id={'['+item.numberOfReferences + ']References'} target="_blank" rel="noreferrer" >{item.title}</a></li>
                                  })}
                                </ol>
                            </View>
                            <SpacerVertical height={"2.286em"}/>
                            <View className="ArticleSummaryHeader">
                            مقتطف المقال
                            </View>
                            <SpacerVertical height={"0.571em"}/>
                            <p className="ArticleParagraph">{header}</p>
                            {/* <InputObligatory disabled={true} value={header} name="ArticleSummary" placeholder="المقتطف هو ملخص المقال" onChange={() => {}}/> */}
                            </> : 
                            <Loader/>}
                        </View>
                    </Col>
            </Row>
        </Container>
        <SpacerVertical height="11.643em"/>
        <RenderModal style={{width: "85%", padding: "0px", height: "60%", minHeight:"547px", display:"block"}}>
          <ChatModal hideModal={hideModal} hadleNewHighlights={hadleNewHighlights}/>
        </RenderModal>
        <RenderModalReportContent style={{width: "440px", padding: "0px"}}>
          <ReportContentModal reviewId={reviewId}/>
        </RenderModalReportContent>
        <RenderModalEmptyComments>
                <AlertModal alertText="إذا لم يكن لديك تعليقات على المقال، يرجى الضغط على توثيق المقال." alertHeader="" hideModal={hideModalEmptyComments}/>
        </RenderModalEmptyComments>
        <RenderModalCommentsConfirmation>
                <AlertModal alertText="تم إرسال التعليقات إلى الكاتب بنجاح" alertHeader="" hideModal={hideModalCommentsConfirmation}/>
        </RenderModalCommentsConfirmation>
        <RenderModalApproved>
                <AlertModal alertText="تم توثيق المقال بنجاح" alertHeader="" hideModal={hideModalApproved}/>
        </RenderModalApproved>
        <AppFooter />
      </View>
    )
}

interface HoveringToolbarProps {
  replaceSelectedText: (replacementText : HTMLSpanElement) => void;
  strcon?: (givenString: string) => HTMLSpanElement;
  handleComment: (response: any) => void;
  comId: number;
  location: string;
  highlight: string[];
  setHighlight: any;
  selectArray: any[];
  setSelectArray: any;
  rangeToObj: any;
}

const HoveringToolbar:FunctionComponent<HoveringToolbarProps> = (props) => {

  const {selectArray, setSelectArray, rangeToObj, replaceSelectedText, handleComment, comId} = props;

  const params : {id: string} = useParams();
  
  const ref = useRef<any>();
  let range = document.createRange();

  useEffect(() => {
    const el = ref.current;
   
    const domSelection = document.getSelection();
    
    if (!el) {
      return
    }
    
    

    if (
      !domSelection?.anchorNode || 
      domSelection?.type === "Caret" ||
       !range.collapsed 
     ){
      el.removeAttribute('style')
      return
    }

    const domRange = domSelection?.getRangeAt(0);
    
    const startNode = domRange?.startContainer?.parentNode;
    const endNode = domRange?.endContainer?.parentNode;
    if(endNode) {
      if (!startNode?.isSameNode(endNode)) {
        el.removeAttribute('style')
        return
      }
    }
    
    const rect = domRange?.getBoundingClientRect();
    
    if(rect) {
      el.style.display = 'block'
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
      el.style.left = `${rect.left +
        window.pageXOffset -
        el.offsetWidth / 2 +
        rect.width / 2}px`;
    }
  })
  

  return (
    <Portal>
      <Menu
        ref={ref}
        className={css`
          position: absolute;
          z-index: 1;
          margin-top: -15px;
          width: 150px;
          height: 38px;
          display: none;
          background-color: #FFFFFF;
          border: 1px solid #CACACA;
          border-radius: 4px;
          transition: opacity 0.75s;
        `}
      >
          <View className="PortalReviewHighlight" style={{padding: "0.778em"}} onClick={() => {
                
                const id = nanoid(4);
                let block = "0"
                let range = document?.getSelection()?.getRangeAt(0);
                let domSelection = document?.getSelection();
                let element = domSelection?.anchorNode?.parentElement
                if(domSelection) {
                  for(let i = 0; i < 20; i++) { //finding parent id, 20 just rnadom number of childs
                    if(element?.parentElement?.id !== "textToSelect") {
                      element = element?.parentElement;
                    } else {
                      break;
                    }
                    if(!element) {
                      break;
                    }
                  }
                  if(element?.id) {
                    block = element?.id
                  }
                }
                const data = rangeToObj(range, id);
                setSelectArray([...selectArray, {text: `${document?.getSelection()?.toString()}`, numberOfComment: comId, ...data}]);
                const contentHighlights = JSON.stringify([...selectArray, {text: `${document?.getSelection()?.toString()}`, numberOfComment: comId, ...data}]);
                const contentComments = JSON.stringify({
                  blockId: block.toString(),
                  info : {
                    comment: [""],
                    commentId: id
                  }
                });
                const b = document.createElement("mark");
                b.id = id
                b.classList.add("HighlitedText");
                replaceSelectedText(b);
                apiService.comments.createReviewHighlight(+params.id, contentHighlights, contentHighlights)
                  .then(r => {
                    if(r.data) {
                      apiService.comments.createCommentaryByReviewHighlight(r.data.id, comId, contentComments, false)
                        .then((r) => {
                          handleComment(r.data);
                        });
                    }
                  });
              }}>
                أضف تعليق
          </View>
      </Menu>
    </Portal>
  )
}

export const Portal = ({ children } : any) => {
  return ReactDOM.createPortal(children, document.body)
}

export interface BaseProps {
  className: string
  [key: string]: unknown
}

export const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: any
  ) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          & > * {
            display: flex;
          }
          & > * + * {
            margin-left: 15px;
          }
        `
      )}
    />
  )
)