import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Btn } from '../../../components/buttons/btn.component';
import { Icon } from '../../../components/icons/icon.component';
import { SpacerVertical } from '../../../components/spacer/spacer.component';
import { View } from '../../../components/view/view.component';
import { apiService } from '../../../shared/services/api.service';
import { getUserId, getUserRole } from '../../../shared/utils';
interface WritersSearchItemProps {
    writerInfo: any;
}

export const WritersSearchItem: FunctionComponent<WritersSearchItemProps> = (props) => {

    const {writerInfo} = props;

    const history = useHistory();

    const [isFollowed, setIsFollowed] = useState(writerInfo?.is_followed);
    const [userId, setUserId] = useState("");

    useEffect(() => {
        setUserId(getUserId());
    },[])

    const handleClick = () => {
        if(!isFollowed){
            apiService.user.followAuthor(userId, writerInfo.id)
                .then(r => setIsFollowed(true));
            
        } else {
            apiService.user.unFollowAuthor(userId, writerInfo.id)
                .then(r => setIsFollowed(false));
        }
    }

    return(
        <View className="WritersSearchItem">
            <View className="SuggestedArticlesItem">
                <View className="SuggestedArticlesItemContent">
                    <View className="SuggestedArticlesItemTitle" onClick={() => {history.push(`/user_profile/${writerInfo.id}`)}}>
                        <span>{writerInfo?.first_name} {writerInfo?.last_name}</span> 
                        <Icon name="iconVerifiedLarge"/>
                    </View>
                    <SpacerVertical height={"0.571em"} />
                    <View className="WritersSearchNumbers">   
                        <View className="Flex">
                            {getUserRole(writerInfo?.role)}
                            <span className="spanBold" onClick={(e) => {
                                e.stopPropagation();
                                history.push(`/interests_tabs/${writerInfo?.specialization?.id}`);
                            }}>{writerInfo?.specialization?.title || "تكنولوجيا"}</span>
                            <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                            {writerInfo?.articles_count} <span>مقال</span>
                            <Icon className="iconGrayCircle" name="iconGrayCircle"/>
                            {writerInfo?.followers_count} <span>متابَع </span>
                        </View>
                    </View>
                    <SpacerVertical height={"0.571em"} />
                    <View className="WritersSearchText">
                        {writerInfo?.bio ? writerInfo?.bio : 
                            writerInfo?.bio
                        }</View>
                    <SpacerVertical height={"0.571em"} />
                    <Btn className="WritersSearchBtn" 
                        title={ isFollowed ? "متابَع" : "تابِع"} 
                        style={isFollowed ? {backgroundColor: "var(--blueColor)", color:"var(--white)", border: "0px"} : {}} 
                        onClick={handleClick}
                    />
                </View> 
                <View onClick={() => {history.push(`/user_profile/${writerInfo.id}`)}}>
                    <Icon style={{borderRadius: "50%"}} src={writerInfo?.image_url} className="SuggestedArticlesIcons" name="iconProfilePlaceholder" alt="author_photo_profile"/>
                </View>    
            </View>
        </View>
    )
}