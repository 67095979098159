import React, { FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { View } from '../../components/view/view.component';
import { Col, Container, Row } from 'react-bootstrap';
import { Icon } from '../../components/icons/icon.component';
import { InputCheckBox, InputObligatory } from '../../components/inputs/input.component';
import { Btn } from '../../components/buttons/btn.component';
import { DragAndDropImages } from '../../components/drag-and-drop/drag-and-drop.component';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { cx, css } from '@emotion/css';
import { Header2 } from '../../components/typo/header.component';
import { apiService } from '../../shared/services/api.service';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { Loader } from '../../components/loader/loader.component';
import { Highlights, References } from '../../shared/interfaces';
import { objToRange, rangeToObj, serialize } from '../../shared/utils';
import articleSvg from '../../assets/images/ArticleEditor/commentAltLines.png';
import { useModal } from '../../components/modal/modal.component';
import { nanoid } from 'nanoid';
// import { Timer } from '../../components/timer/timer.component';
import { ChatModalEditor } from '../../components/chat-modal-editor/chat-modal-editor';

import "./article-editor.scss";
import { AxiosError } from 'axios';



export const ArticleEditorPage:FunctionComponent = () => {

    const location: any = useLocation();
    const params : {id: string, correction_id: string} = useParams();
    
    const history = useHistory();

    const {showModal, RenderModal, hideModal } = useModal();

    useEffect(() => {
      apiService.articles.getCorrectionHighlightsByArticleId(params.id)
      .then(r => {
        setResponseHighlights(r?.data);
      });
    }, [params.id]);

    const [responseHighlights, setResponseHighlights] = useState<Highlights | null>(null);
    
    const {response: responseCorrection, isLoading: CurrentArticleLoading} = useFetch(() => apiService.articles.getCorrectionById(params.correction_id));

    
    const [interestMain, SetInterestMain] = useState("");
    const [articleData, setArticleData] = useState<any[]>([]);
    const [localData, setLocalData] = useState<any[]>([]);
    const [articleTitle, setArticleTitle] = useState("");
    const [header, setHeader] = useState("");
    const [references, setReferences] = useState<References[]>([]);
    const [subInterests, setSubInterests] = useState<any[]>([]);
    const [imageUrl, setImageUrl] = useState("");
    const [minutes_for_reading, setMinutes_for_reading] = useState(0);
    const [word_count, setWord_count] = useState(0);
    const [endTime, setEndTime] = useState("");
    const [articleTitleCopy, setArticleTitleCopy] = useState("");
    const [, setData] = useState([""]);
    // const [blockId, setBlockId] = useState(0);
    const [selectArray, setSelectArray] = useState<any[]>([]);
    const [numberOfComment, setNumberOfComment] = useState(1);

    useEffect(() => {
      window.scrollTo(0,0)
    }, [])

    useEffect(() => {
      if (!CurrentArticleLoading && responseCorrection?.data) {
        setArticleTitle(responseCorrection?.data?.article?.title);
        setArticleTitleCopy(responseCorrection?.data?.article?.title);
        setHeader(responseCorrection?.data?.article?.header);
        setReferences(JSON.parse(responseCorrection?.data?.article?.references));
        setSubInterests(responseCorrection?.data?.article?.sub_interests);
        SetInterestMain(responseCorrection?.data?.article?.interest.title);
        setImageUrl(responseCorrection?.data?.article?.image_url);
        setMinutes_for_reading(responseCorrection?.data?.article?.minutes_for_reading);
        setWord_count(responseCorrection?.data?.article?.word_count);
        setEndTime(responseCorrection.data.end_correction_at);
        // setCorrectionData(responseCorrection.data);
        setArticleData(JSON.parse(responseCorrection.data.article.content));
        setLocalData(JSON.parse(responseCorrection.data.article.content));
      }
    }, [responseCorrection, CurrentArticleLoading])


    // const newSpan = useMemo(() => {
    //   const span = document.createElement("span");
    //   span.id = "demo";
    //   span.style.display = "block";
    //   span.classList.add("HighlitedTextArticleReviewPopUp");
    //   span.innerHTML = `اضف تعليق`
    //   span.style.position = "absolute";
    //   span.style.top = `${45}px`;
    //   span.style.left = `40%`;
    //   return span
    // },[]);


  
    // const listenerClick = useCallback((e: any) => {
    //   if(e.target.parentElement) {
    //     const highlight = selectArray.filter((item) => item.highlightId === e.target.parentElement.id);
    //     showModal({highlight: highlight, endTime: endTime});
    //   }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectArray])

    // const listenerEnter = useCallback((e : MouseEvent) => {
    //   newSpan.addEventListener("click", listenerClick);
    //   if(e.target instanceof Element) {
    //    const rect =  e.target.getBoundingClientRect();
    //     newSpan.style.top = `${rect.height}px`;
    //     e.target?.appendChild(newSpan);
    //   }  
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[selectArray])

    // const removeListeners = useCallback(() => {
    //   newSpan.removeEventListener("click", listenerClick);
    //   newSpan.remove();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [selectArray]);

  //   useEffect(() => {
  //     if(selectArray.length) {
  //       selectArray.forEach((el) => {
  //         const mark : any = document.getElementById(el.highlightId);
  //           mark?.addEventListener("mouseenter", listenerEnter);
  //           mark?.addEventListener("mouseleave", removeListeners);
  //       })
  //     }
  //     return (() => {
  //       selectArray.forEach((el) => {
  //         const mark : any = document.getElementById(el.highlightId);
  //         mark?.removeEventListener("mouseenter", listenerEnter);
  //         mark?.removeEventListener("mouseleave", removeListeners);
  //       })
  //     })
  // }, [listenerEnter, removeListeners, selectArray]);    

  useEffect(() => {
          const func = function(e : any){
            if(e && getSelectionText() !== "") {
              e.preventDefault();
            }
          }
          document.addEventListener("mouseup", func);
      return () => {
        document.removeEventListener("mouseup", func);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
          
    const el = document.getElementById('textToSelect');
    articleData?.forEach((item) => {
      let idEL = 4000;
      const span = document.createElement('span');
      span.innerHTML = serialize(item.data, item.blockId);
      const blockId = item.blockId;
      if(span?.children[0]?.childNodes?.length){
        span.children[0].childNodes.forEach((item : any) => {
          if(item.childNodes.length > 1) {
            item.childNodes.forEach((item : any) => {
              if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
                item.id = `${blockId}${idEL+1}`;
                idEL = idEL+1
              }
            })
          }
          if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
            item.id = `${blockId}${idEL+1}`;
            idEL = idEL+1
          }
        })
      }
      el?.insertAdjacentHTML('beforeend', span.innerHTML);          
    })
  }, [articleData]);   
  
  // useEffect(() => {
          
  //   const el = document.getElementById('textToSelect');
  //   articleData?.forEach((item) => {
      
  //     item?.data?.forEach((subitem: any) => {
      
  //       let idEL = 4000;
  //       const span = document.createElement('span');
  //       span.innerHTML = serialize(subitem, item.blockId);
  //       const blockId = item.blockId;
  //       if(span?.children[0]?.childNodes?.length){
  //         span.children[0].childNodes.forEach((item : any) => {
  //           if(item.childNodes.length > 1) {
  //             item.childNodes.forEach((subitem : any) => {
  //               if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
  //                 subitem.id = `${blockId}${idEL+1}`;
  //                 idEL = idEL+1
  //               }
  //             })
  //           }
  //           if(item.nodeName !== "MARK" && item.nodeName !== "DIV" && item.nodeName !== "#text") {
  //             item.id = `${blockId}${idEL+1}`;
  //             idEL = idEL+1
  //           }
  //         })
  //       }
  //       el?.insertAdjacentHTML('beforeend', span.innerHTML);  
  //     })        
  //   })
  // }, [articleData]);     

  useEffect(() => {
    if(responseHighlights?.content && articleData) {
      try {
      const oldHighlightsArray = JSON.parse(responseHighlights.content);
      setNumberOfComment(oldHighlightsArray.length+1);
      setSelectArray(oldHighlightsArray);
      getHighlighsFromRanges(responseHighlights?.content);
      } catch (error) {
        
      }
    }
  
}, [responseHighlights, articleData]);

  useEffect(() => {
  
    if(selectArray.length) {
      selectArray.forEach((item) => {
        const content = item; 
        const id = content.blockId;
        const block = document.getElementById(`${id}`);
        let hasChild = false
        let divCount = 1;
        if(block?.children){
          for (let a of Object.keys(block.children)) {
            let b = block.children[+a];
            if(b.className === "CommentDiv" && b.id === content.highlightId) {
              hasChild = true
            }
            if(b.className === "CommentDiv") {
              divCount++
            }
          }
        }
        const img = document.createElement("img");
        img.src = articleSvg;
        img.style.objectFit = "cover";
        const div = document.createElement("div");
        div.style.backgroundColor = "#F5F5F6"
        div.style.width = "24px";
        div.style.borderRadius = "2px";
        div.textContent = content?.numberOfComment?.toString();
        div.style.textAlign = "center";
        div.style.marginRight = "5px";
        div.style.fontFamily = "Roboto";
        div.style.fontSize = "14px";
        div.style.fontWeight = "700";
        div.style.lineHeight = "24px";
        div.style.color = "var(--success)";
        div.style.userSelect = "none"
        const commentDiv = document.createElement("div");
        commentDiv.id = content.highlightId;
        commentDiv.classList.add("CommentDiv");
        commentDiv.style.width = "53px";
        commentDiv.style.height = "24px";
        commentDiv.style.position = "absolute";
        commentDiv.style.top = `${divCount * 25 - 25}px`;
        let styleRight = -65;
        if(block?.nodeName === "BLOCKQUOTE") {
          styleRight = styleRight - 50
        } 
        commentDiv.style.right = `${styleRight}px`;
        commentDiv.style.display = "flex"; 
        if (block) {
          commentDiv.appendChild(img);
          commentDiv.appendChild(div);
          console.log(item);
          commentDiv.addEventListener("click", () => {showModal({highlight: [item], endTime: endTime})});
          block.style.position = "relative";
          if (!hasChild){
            block.appendChild(commentDiv);
          }
          setNumberOfComment(selectArray.length + 1);
        };
      })
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectArray])

  function getSelectionText() {
      let text ;
      const modalRoot = document.querySelector("#modal-root");
      let parent = document.getSelection()?.anchorNode;
      let flag = false;
      for(let i = 0; i < 100; i++) {
      if(parent?.parentElement?.className !== "Modal") {
        parent = parent?.parentElement
      } else {
        flag = true
        break;
      }
      }
      if (document.getSelection() && document.getSelection()?.anchorNode !== modalRoot && document.getSelection()?.type !== "None" && !flag) {
          text = document?.getSelection()?.toString();
        setData([""]);
      } 
      
      return text;
  }

  function replaceSelectedText(replacementText : HTMLSpanElement) {
      let sel, range : any;
      if (document.getSelection) {
          sel = document.getSelection();
          if (sel?.rangeCount) {
              range = sel?.getRangeAt(0);
              try {
                  range?.surroundContents(replacementText);
                
              } catch (error) {
                  console.log(error)
              }
              sel.removeAllRanges();
          }
      } 
  }

        const getHighlighsFromRanges = (selStr : string) => {
          
          let selArr = JSON.parse(selStr as string);
          let sel = getSelection();
          selArr?.forEach(function(each: any) {
          sel?.removeAllRanges();
          const newRange = objToRange(each);
          if(newRange) {
            sel?.addRange(newRange);
            const range = sel?.getRangeAt(0);

          const span = document.createElement("mark");
          if(sel?.toString()) {
              span.textContent = sel?.toString();
              span.id = each.highlightId;
              span.classList.add("HighlitedText");
          }
          if(range) {
              range.surroundContents(span);
          }
          }
          sel?.removeAllRanges();
        })
      }

      const getNewEdtiorData = (newDataEditor: any, blockId: number, textContent: string, highlightId: string, highlightsOfBlock: any[]) => {
     
        const arr = localData.map((item) => {
          if(item.blockId === +blockId) {
            return {
              blockId: +blockId,
              data: newDataEditor}
          }
          return item
        })
    
        const content = JSON.stringify(arr);
        let apiData = {
          title : articleTitleCopy,
          content : content,
          header : header
        };

        if (+blockId === 1000) {
          setArticleTitleCopy(newDataEditor.children);
          apiData = {
            title : newDataEditor.children,
            content : content,
            header : header
          }
        }
    
        apiService.articles.correctArticleContentByEditor(location.pathname.split('/')[2] , apiData)
          .then(() => {
            setLocalData(arr);
          })
          .catch((err : AxiosError) => {
            console.log(err);
            
          })

        
            
            const a = highlightsOfBlock.map((item) => {
              const b = selectArray.filter((el) => item.highlightId === el.highlightId);
              if(item.highlightId === highlightId) {
                return {
                  ...item,
                  numberOfComment: b[0].numberOfComment,
                  text: b[0].text,
                  newText: textContent,
                  blockId: blockId.toString()
                }
              }
              if(b.length && b[0].newText){
                return {
                  ...b[0],
                  numberOfComment: b[0].numberOfComment,
                  text: b[0].text,
                  newText: b[0].newText,
                  blockId: blockId.toString()
                }
              }
              return {
                ...item,
                numberOfComment: b[0].numberOfComment,
                text: b[0].text,
                blockId: blockId.toString()
              }
            })
    
            const final = selectArray.map((item) => {
              const f = highlightsOfBlock.filter((el) => item.highlightId === el.highlightId);
              if (f[0]) {
                return undefined
              }
              return item
            })
    
            const subFinalArr = final.filter(item => item !== undefined);
            const finalArr = subFinalArr.concat(a);
    
        setSelectArray(finalArr);
        
        if(responseHighlights) {
           apiService.comments.createCorrectionHighlight(+params.id , JSON.stringify(finalArr), JSON.stringify(finalArr), params.correction_id);
        }
      }

      const sendArticleToWriter = () => {
          apiService.articles.endCorrection(params.correction_id)
            .then((r) => {
              history.push("/user_profile_auth/editor");
            })
      }
      
    return (
      <View className="DashboardArticle">
        <AppHeader/>
        <View className="RulesOfWriting">
            <Container className="RulesOfWritingHeaders">
              <View>
                  <Icon name="iconArticleReview" />
                <span>مراجعة مقال</span>
              </View>
              <View className="Link">
              <span> المراجعة في موسوعة </span>
              <Icon style={{width: "8px", height: "8px", marginLeft: "5px"}} name="iconGrayCircle"/>
              {/* <span className="ReviewArticleTimer"><span style={{color: "var(--black)"}}>الوقت المتبقي للمراجعة :</span> {endTime ? <Timer endDate={endTime}/> : "00:00:00"} ساعة</span> */}
              </View>
            </Container>
        </View>
        <Container>
            <Row>
                  <Col lg={4}>
                    <View className="ArticleInfo">
                      <View className="MainInterest">
                        <View className="Header">
                          اهتمام رئيسي 
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"0.571em"}/>
                        <View>
                          <InputCheckBox label={interestMain} name="interest" onChange={() => {}} checked readOnly/>
                        </View>
                      </View>
                      <View className="MainInterest"> 
                        <View className="Header">
                        اهتمامات فرعية 
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"1.143em"}/>
                        <View>
                          {subInterests?.map((item : any) => {
                            return <InputCheckBox  label={item.title} name={item.description} handlecheck={() => {}} checked={true}/>
                          })}
                        </View>
                      </View>
                      <View className="MainInterest">
                        <View className="Header">
                        الصورة البارزة
                          <Icon name="iconInfoOutline"/>
                        </View>
                        <SpacerVertical height={"0.571em"}/>
                        <View className="DragAndDrop">
                        <DragAndDropImages text={"أضف الصورة البارزة المقال‎ +"} withoutPlaceHolder={true} status={true} withInputUpload={false} handleImageUrl={(url) => {setImageUrl(url)}} defaultImage={imageUrl}/>
                        </View>
                      </View>
                      <View className="ArticleReviewOptions">
                          <Btn className="ButtonApprove" title="توثيق المقال" onClick={sendArticleToWriter}/>
                      </View>
                      <SpacerVertical height={"1.714em"}/>
                      <View className="ReviewNumbers">
                        {word_count} كلمة
                        <Icon name="iconGrayCircle"/>
                        {minutes_for_reading}  دقائق قراءة
                      </View>
                    </View>
                </Col>
                    <Col lg={8}>
                        <View id="ArticleContent" className="ArticleContent ArticleReview">
                          <HoveringToolbar 
                              location={location.pathname.split('/')[2]}
                              selectArray={selectArray} 
                              replaceSelectedText={replaceSelectedText} 
                              setSelectArray={setSelectArray} 
                              rangeToObj={rangeToObj}
                              comId={numberOfComment}
                              />
                            {!CurrentArticleLoading ?
                            <>
                            <View id="textToSelect" className="ArticleContentText textToSelectEditor">
                            <h1 id="1000">{articleTitle}</h1>
                            <SpacerVertical height={"2em"}/>
                            </View>
                            <SpacerVertical height={"2.286em"}/>
                            {references?.length !== 0 && <Header2 style={{color: "var(--secondaryHeader)"}} title="المراجع"/>}
                            <View className="NumberedList" style={{fontSize: "1em"}}>
                                <ol>
                                  {references?.map((item) => {                                    
                                    return <li key={item.numberOfReferences}><a href={item.title} id={'['+item.numberOfReferences + ']References'} target="_blank" rel="noreferrer" >{item.title}</a></li>
                                  })}
                                </ol>
                            </View>
                            <SpacerVertical height={"2.286em"}/>
                            <View className="ArticleSummaryHeader">
                            مقتطف المقال
                            </View>
                            <SpacerVertical height={"0.571em"}/>
                            <InputObligatory disabled={true} value={header} name="ArticleSummary" placeholder="المقتطف هو ملخص المقال" onChange={() => {}}/>
                            </> : 
                            <Loader/>}
                        </View>
                    </Col>
            </Row>
        </Container>
        <SpacerVertical height="11.643em"/>
        <RenderModal style={{width: "85%", padding: "0px", top: "25%"}}>
          <ChatModalEditor hideModal={hideModal} getNewEdtiorData={getNewEdtiorData}/>
        </RenderModal>
        <AppFooter />
      </View>
    )
}

interface HoveringToolbarProps {
  replaceSelectedText: (replacementText : HTMLSpanElement) => void;
  strcon?: (givenString: string) => HTMLSpanElement;
  comId: number;
  location: string;
  selectArray: any[];
  setSelectArray: any;
  rangeToObj: any;
}

const HoveringToolbar:FunctionComponent<HoveringToolbarProps> = (props) => {

  const {selectArray, setSelectArray, rangeToObj, replaceSelectedText, comId} = props;

  const params : {id: string, correction_id: string} = useParams();

  const ref = useRef<any>();
  let range = document.createRange();


  useEffect(() => {
    const el = ref.current;

    const domSelection = document.getSelection();
    
    if (!el) {
      return
    }
    
    

    if (
      !domSelection?.anchorNode || 
      domSelection?.type === "Caret" ||
       !range.collapsed 
     ){
      el.removeAttribute('style')
      return
    }

    const domRange = domSelection?.getRangeAt(0);
    
    const startNode = domRange?.startContainer?.parentNode;
    const endNode = domRange?.endContainer?.parentNode;
    if(endNode) {
      if (!startNode?.isSameNode(endNode)) {
        el.removeAttribute('style')
        return
      }
    }
    
    const rect = domRange?.getBoundingClientRect();
    
    if(rect) {
      el.style.display = 'block'
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
      el.style.left = `${rect.left +
        window.pageXOffset -
        el.offsetWidth / 2 +
        rect.width / 2}px`;
    }
  })
  

  return (
    <Portal>
      <Menu
        ref={ref}
        className={css`
          position: absolute;
          z-index: 1;
          margin-top: -15px;
          width: 150px;
          height: 38px;
          display: none;
          background-color: #FFFFFF;
          border: 1px solid #CACACA;
          border-radius: 4px;
          transition: opacity 0.75s;
        `}
      >
          <View className="PortalReviewHighlight" style={{padding: "0.778em"}} onClick={() => {
                const id = nanoid(4);
                let block = "0"
                let range = document?.getSelection()?.getRangeAt(0);
                let domSelection = document?.getSelection();
                let element = domSelection?.anchorNode?.parentElement
                if(domSelection) {
                  for(let i = 0; i < 20; i++) { //finding parent id, 20 just rnadom number of childs
                    if(element?.parentElement?.id !== "textToSelect") {
                      element = element?.parentElement;
                    } else {
                      break;
                    }
                    if(!element) {
                      break;
                    }
                  }
                  if(element?.id) {
                    block = element?.id
                  }
                }
                const data = rangeToObj(range, id);
                console.log(data);
                console.log(range);
                console.log(id);
                setSelectArray([...selectArray, {blockId: block, text: `${document?.getSelection()?.toString()}`, numberOfComment: comId, ...data}]);
                const contentHighlights = JSON.stringify([...selectArray, {blockId: block, text: `${document?.getSelection()?.toString()}`, numberOfComment: comId, ...data}]);
                const b = document.createElement("mark");
                b.id = id
                b.classList.add("HighlitedText");
                replaceSelectedText(b);
                apiService.comments.createCorrectionHighlight(+params.id, contentHighlights, contentHighlights, params.correction_id)
                  .then(r => {
                  });
              }}>
                تحرير النص
          </View>
      </Menu>
    </Portal>
  )
}

export const Portal = ({ children } : any) => {
  return ReactDOM.createPortal(children, document.body)
}

export interface BaseProps {
  className: string
  [key: string]: unknown
}

export const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: any
  ) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          & > * {
            display: flex;
          }
          & > * + * {
            margin-left: 15px;
          }
        `
      )}
    />
  )
)