import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { AlertModal } from '../../components/alert-modal/alert-modal.component';
import { AppFooter } from '../../components/app-footer/app-footer.component';
import { AppHeader } from '../../components/app-header/app-header.component';
import { Btn } from '../../components/buttons/btn.component';
import { DropDown } from '../../components/dropdown/dropdown.component';
import { Icon } from '../../components/icons/icon.component';
import { InputObligatory } from '../../components/inputs/input.component';
import { Loader } from '../../components/loader/loader.component';
import { useModal } from '../../components/modal/modal.component';
import { SpacerVertical } from '../../components/spacer/spacer.component';
import { View } from '../../components/view/view.component';
import { useFetch } from '../../shared/hooks/fetch.hook';
import { AuthorData } from '../../shared/interfaces';
import { apiService } from '../../shared/services/api.service';
import { getUserId } from '../../shared/utils';
import { country } from '../signup/reviewer-editor-signup/reviewer-editor-signup.page';
import { UserSettingsItem } from './nested/user-settings-item.component';
import { UserNotificationsItem } from './nested/user-settings-notifications/user-settings-notifications.component';

import "./user-settings.scss";

const content = {
    title: "إشعارات عامة",
    text: [" قيام احد المستخدمين بمشاركة مقالك", "قيام احد المستخدمين بالتعليق على مقالك", "قيام احد المستخدمين بحفظ مقالك",
     "قيام احد المستخدمين بإضاءة جزء من مقالك", "قيام احد المستخدمين بالإعجاب بمقالك", "قيام احد المستخدمين بمتابعة حسابك", "نشر مقاله جديد للكاتب المتابع"]
    
}
const content1 = {
    title: "إشعارات المحتوى",
    text: ["اهتمام جديد في موسوعة","اهتمامات الأكثر تفاعلًا","ملخصات موسوعة","الشائع الآن","مقالات موصى بها","اقتراحات كّتاب"]
    
}
const content2 = {
    title: "إشعارات الكاتب (عملية مراجعة)",
    text: ["المقال قيد المراجعة", "المقال بإنتظار تعديلاتك", "المقال تم الموافقة عليها من قبل مجتمع المراجعين", "المقال قيد التحرير", "المقال تم تحريره وجاهز للنشر", "المقال تم رفضه", "لديك مقال مسودة"]
    
}
const content3 = {
    title: "إشعارات المراجع (عملية مراجعة)",
    text: ["تعيين مقال جديد", "رد الكاتب", "تم رفض مقال", "تم نشر المقال"]
    
}
const content4 = {
    title: "إشعارات المحر (عملية مراجعة)",
    text: ["تعيين مقال جديد", "رد الكاتب", "تم نشر المقال"]
    
}

const settingsData = [
    {
        iconName: "iconAccountSettings",
        title: "إعدادات الحساب",
        subTitle: "تعديل البريد الإلكتروني، تاريخ الميلاد"
    },
    {
        iconName: "iconCV",
        title: "إعدادات السيرة الذاتية",
        subTitle: " تعديل و إضافة مجالات كتابة, حقل الخبرة"
    },
    {
        iconName: "iconContentPreferences",
        title: "تفضيلات المحتوى",
        subTitle: "إخفاء واظهار المحتوى حسب الرغبة"
    },
    {
        iconName: "iconConnections",
        title: "ارتباطات" ,
        subTitle: "الارتباط مع فيسبوك, توتير, لينكدإن"
    },
    {
        iconName: "iconSessions",
        title: "سجل الجلسات",
        subTitle: "تاريخ الجلسات ومواعيد الدخول"
    },
    {
        iconName: "iconPassword",
        title: "كلمة السر",
        subTitle: "إعادة تعيين كلمة السر"
    },
    {
        iconName: "iconDeactivate",
        title: "تعطيل الحساب",
        subTitle: "تعطيل وايقاف الحساب"
    },
    {
        iconName: "iconDeleteAccount",
        title: "حذف الحساب",
        subTitle: "حذف الحساب و الخروج من مجتمع موسوعة"
    },
]


export const UserSettingsPage: FunctionComponent = () => {

    const {response: currentUserResponse, isLoading: currentUserLoading} = useFetch(() => apiService.user.getCurrentUserProfile(getUserId()));
    const history = useHistory();

    const { register, handleSubmit, errors } = useForm();

    const [country1, setCountry1] = useState('');
    const [counter , setCounter] = useState(0);
    const [bio, setBio] = useState("");
    const [isEditProfile, setIsEditProfile] = useState(true);
    const [isSettings, setIsSettings] = useState(false);
    const [isNotifications, setIsNotifications] = useState(false);
    const [currentUserInfo, setCurrentUserInfo] = useState<AuthorData>();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [previewUrl, setPreviewUrl] = useState("");

    const {showModal, RenderModal, hideModal} = useModal();
  

    useEffect(() => {
        if(currentUserResponse !== null && currentUserResponse !== undefined) {
            setCurrentUserInfo(currentUserResponse.data);
            setCountry1(currentUserResponse.data?.country);
            setFirstName(currentUserResponse.data?.first_name);
            setLastName(currentUserResponse.data?.last_name);
            setBio(currentUserResponse.data?.bio);
            setCounter(() => {
                if(currentUserResponse?.data?.bio?.length) {
                    return currentUserResponse?.data?.bio?.length
                }
                return 0
            });
        }
    }, [currentUserResponse])

    const handleCounter = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if(e.currentTarget.value.length <= 150) {
            setBio(e.currentTarget.value);
            setCounter(e.currentTarget.textLength);
        }
    }

    const uploadProfilePhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.files && e.target.files.length !== 0) {
            const url = URL.createObjectURL(e.target.files[0]);
            apiService.user.UploadProfilePhoto(e.target.files[0], getUserId())
                .then((r) => {
                    setPreviewUrl(url);
                    history.push("/user_profile_auth/profile");
                })
        }
    }

    const onSubmit = () => {
        apiService.user.UpdateProfileData(getUserId(), firstName, lastName, country1, bio)
        .then(r => {
            showModal()
            history.push("/user_profile_auth/profile");
        })
    }

    return (
        <View className="App">
            <View className="UserSettings">
                <AppHeader />
                    <Container> 
                        <SpacerVertical height={"4.571em"}/>
                        <Row>
                            <Col lg={4}>
                                <View className="UserSettingsMenu">
                                    <View className="UserSettingsMenuItem" style={isEditProfile ? {color: "var(--blueColor)"} : {}} onClick={() => {
                                        setIsEditProfile(true);
                                        setIsSettings(false);
                                        setIsNotifications(false);
                                        }}>
                                        <Icon name={isEditProfile ? "iconEditProfileBlue" : "iconEditProfile"}/>
                                        تعديل الملف الشخصي
                                    </View>
                                    {/* <View className="UserSettingsMenuItem" style={isSettings ? {color: "var(--blueColor)"} : {}} onClick={() => {
                                        setIsEditProfile(false);
                                        setIsSettings(true);
                                        setIsNotifications(false);
                                        }}>
                                        <Icon name={isSettings ?"iconSettingsProfileBlue" : "iconSettingsProfile"}/>
                                        الإعدادات والخصوصية
                                    </View> */}
                                    {/* <View className="UserSettingsMenuItem" style={isNotifications ? {color: "var(--blueColor)"} : {}} onClick={() => {
                                        setIsEditProfile(false);
                                        setIsSettings(false);
                                        setIsNotifications(true);
                                        }}>
                                        <Icon name={isNotifications ? "iconNotificationsBlue" : "iconNotifications"}/>
                                        الإشعارات
                                    </View> */}
                                </View>
                            </Col>
                            <Col lg={8}>
                                {isEditProfile && 
                                <>
                                    <View className="UserSettingsTitle">
                                        تعديل الملف الشخصي  
                                    </View>
                                    <View className="UserSettingsSubTitle">
                                        سيتعرف عليك الأشخاص في مجتمع موسوعة بالمعلومات أدناه  
                                    </View>
                                    <SpacerVertical height={"2.571em"}/>
                                    {!currentUserLoading ? 
                                    <View className="UserSettingsProfile">
                                        <View className="UserSettingsProfileHeader">
                                            المعلومات الشخصية 
                                        </View>
                                        
                                        <View className="UserSettingsProfileContent">
                                        <View className="EditProfilePhoto">
                                                <Icon src={previewUrl ? previewUrl : currentUserInfo?.image_url} name="iconProfilePlaceholder" alt="author_profile_photo"/>
                                                <View className="text">
                                                    <label className="fileUploadInput">
                                                    تعديل الصورة
                                                    <input  type="file" id="myFile" name="filename"  accept="image/gif,image/jpg, image/jpeg, image/png" style={{display: "none"}} onChange={uploadProfilePhoto}/>
                                                    </label>
                                                    <View>
                                                        تستخدم الصور بجانب اسمك للتعريف عنك 
                                                    </View>
                                                </View>
                                        </View>
                                        <SpacerVertical height={"2.5em"}/>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                        <View className="EditProfileData">
                                            <View className="inlineFlex">
                                                <InputObligatory name="first_name"  
                                                label="الاسم الأول" 
                                                placeholder="الرجاء إدخال اسمك الأول" 
                                                maxLength={20} 
                                                minLength={3}
                                                pattern={/^[ \u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]*$/}  
                                                register={register} 
                                                errors={errors} 
                                                value={firstName}
                                                required 
                                                errorMessage="الرجاء إدخال اسمك الأول"
                                                errorMessagePattern="إسم غير صحيح. الرجاء إدخال اسم العائلة مع مراعاة الشروط"
                                                onChange={(e) => setFirstName(e.value)}/>
                                                <InputObligatory name="last_name" 
                                                label="اسم العائلة" 
                                                placeholder="الرجاء إدخال اسم العائلة" 
                                                minLength={3}
                                                maxLength={20}  
                                                value={lastName}
                                                pattern={/^[ \u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]*$/} 
                                                register={register} 
                                                errors={errors} 
                                                errorMessage="الرجاء إدخال اسم العائلة" 
                                                required
                                                onChange={(e) => setLastName(e.value)}
                                                errorMessagePattern="إسم غير صحيح. الرجاء إدخال اسم العائلة مع مراعاة الشروط"/>
                                            </View>
                                                <DropDown 
                                                className="DropDown" 
                                                value={country1} 
                                                options={country} 
                                                name="country" 
                                                placeholder="الاردن" 
                                                label="البلد" 
                                                onChange={setCountry1}
                                                />
                                        </View>
                                        <SpacerVertical height={"2.5em"}/>
                                        <View className="Bio">
                                                <View className="BioTitle">
                                                <View>نبذة عني </View>
                                                <View className="numbers">150/{counter}</View>
                                                </View>
                                                <SpacerVertical height={"0.571em"}/>
                                                <textarea value={bio} className="textArea" onChange={(e) => {handleCounter(e)}}/>
                                        </View>
                                        <SpacerVertical height={"2.5em"}/>
                                        <Btn className="btnProfile" title="حفظ التعديلات" />
                                        <SpacerVertical height={"2.5em"}/>
                                        </form>
                                        </View>
                                      
                                    </View> : <Loader/>}
                                    <SpacerVertical height={"100em"}/>
                                </>}
                                {isSettings && 
                                <>
                                    <View className="UserSettingsTitle">
                                    الإعدادات والخصوصية  
                                    </View>
                                    <View className="UserSettingsSubTitle">
                                    إعدادات الخصوصية وضبطها
                                    </View>
                                    {settingsData.map((item) => {
                                        return (
                                            <View key={item.iconName}>
                                            <UserSettingsItem iconName={item.iconName} title={item.title} subTitle={item.subTitle} />
                                            </View>
                                        )
                                    })}
                                    <SpacerVertical height={"100em"}/>
                                </>}
                                {isNotifications && 
                                <>
                                     <View className="UserSettingsTitle">
                                     لإشعارات 
                                    </View>
                                    <View className="UserSettingsSubTitle">
                                    تفعيل الإشعارات أو إيقافها
                                    </View>
                                    <SpacerVertical height={"2.143em"}/>
                                    <UserNotificationsItem content={content}/>
                                    <SpacerVertical height={"2.857em"}/>
                                    <UserNotificationsItem content={content1}/>
                                    <SpacerVertical height={"2.857em"}/>
                                    <UserNotificationsItem content={content2}/>
                                    <SpacerVertical height={"2.857em"}/>
                                    <UserNotificationsItem content={content3}/>
                                    <SpacerVertical height={"2.857em"}/>
                                    <UserNotificationsItem content={content4}/>
                                    <SpacerVertical height={"18.500em"}/>
                                </>}
                            </Col>
                        </Row>
                    </Container>
                    <RenderModal>
                        <AlertModal alertText="التغييرات التي تم حفظها بنجاح" alertHeader="" hideModal={hideModal}/>
                    </RenderModal>
                <AppFooter />
            </View>
        </View>
    )
}