import React,  { FunctionComponent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AlertModal } from "../../../../../components/alert-modal/alert-modal.component";
import { Btn, BtnColorless } from "../../../../../components/buttons/btn.component";
import { Icon } from "../../../../../components/icons/icon.component";
import { useModal } from "../../../../../components/modal/modal.component";
import { SpacerVertical } from "../../../../../components/spacer/spacer.component";
import { SuggestedArticlesItem } from "../../../../../components/suggested-articles/nested/suggested-articles-item.component";
// import { Timer } from "../../../../../components/timer/timer.component";
import { Header3 } from "../../../../../components/typo/header.component";
import { View } from "../../../../../components/view/view.component";
import { ArtcileInfo } from "../../../../../shared/interfaces";
import { apiService } from "../../../../../shared/services/api.service";

interface UserProfileAuthWriterItemProps {
    status: string;
    articleInfo: ArtcileInfo;
    rejectedReason?: string;
    rejectedComment?: string;
    endTime?: string | undefined;
}

export const UserProfileAuthWriterItem: FunctionComponent<UserProfileAuthWriterItemProps> = (props) => {

    // const {articleInfo, status, rejectedReason, rejectedComment, endTime} = props;
    const {articleInfo, status, rejectedReason, rejectedComment} = props;

    const history = useHistory();

    const {showModal: showModalSuccess, RenderModal: RenderModalSuccess, hideModal: hideModalSuccess} = useModal();

    const [statusLocal, setLocalStatus] = useState(status);
    const [userStatus, seUserStatus] = useState("");

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem("mawsuah_user_data") as string);
        seUserStatus(data.role.name);
    }, [])
   
    const publishArticle = () => {
        apiService.articles.publishArticle(articleInfo.id)
            .then(r => {
                console.log(r);
            setLocalStatus("PUBLISHED");
            showModalSuccess();
            });
    }
    
    return(
        <View>
        {/* {statusLocal === "DRAFT" &&
        <View className="editing">
            <View className="editingTitle">
                <Icon name="iconEditorCornerBlack"/>
                مسودة
                <BtnColorless title="تحرير" onClick={() => {history.push(`/draft_article/${articleInfo.id}`)}}/>
            </View>
        </View>} */}
        {statusLocal === "ON_EDITING" && 
        <View className="editing">
            <View className="editingTitle">
                <Icon name="iconEditorCornerBlack"/>
                بانتظار تعديلاتك
                <BtnColorless title=" تعديل" onClick={() => {
                    if(userStatus !== "WRITER" && userStatus !== "REVIEWER" && userStatus !== "WRITER_REVIEWER"){
                        
                    }
                    history.push(`/draft_article/${articleInfo.id}`)
                }}/>
            </View>
            {/* <View className="editingInfo">
            الوقت المتبقي للتعديلات
            <span> 16 تعديل</span>
            </View> */}
        </View>}
        {statusLocal === "REJECTED" &&
        <View className="rejected">
            <View className="rejectedTitle">
                <Icon name="iconCloseRejected"/>
                مقال مرفوض
            </View>
            <SpacerVertical height={"0.571em"}/>
             <View className="rejectedInfo"> 
                <Header3 style={{marginBottom: "8px"}} title={rejectedReason ? rejectedReason : ""}/>
                {rejectedComment ? rejectedComment : ""}
            </View>
            <SpacerVertical height={"0.643em"}/>
        </View>
        }
        {statusLocal === "ON_REVIEW" && 
        <View className="reviewing">
            <View className="reviewingTitle">
                <Icon name="iconReviewerCornerOrange"/>
                قيد المراجعة
            </View>
            {/* <View className="reviewingTime">
            الوقت المقدر للمراجعة 

            <span> <Timer endDate={endTime ? endTime : ""}/> ساعة</span>
            </View> */}
        </View>}
        {statusLocal === "ON_CORRECTING" && 
        <View className="reviewing">
            <View className="reviewingTitle">
                <Icon name="iconReviewerCornerOrange"/>
                قيد  التحرير
            </View>
            {/* <View className="reviewingTime">
            الوقت المقدر للمراجعة 

            <span> <Timer endDate={endTime ? endTime : ""}/> ساعة</span>
            </View> */}
        </View>}
        {statusLocal === "APPROVED" && 
        <View className="approved">
        مقال موثق   
            <Icon name="iconVerifiedGreen"/>
            {userStatus !== "REVIEWER" &&
                <Btn title="جاهز للنشر" onClick={publishArticle}/>
            }
        </View>}
        <SuggestedArticlesItem 
            opacity={(status === "ON_REVIEW" || status === "DRAFT" || status === "ON_CORRECTING") ? 0.4 : 1}
            title={articleInfo.title}
            header={articleInfo.header}
            image_url={articleInfo.image_url}
            interest={articleInfo.interest}
            author={articleInfo.author}
            created_at={articleInfo.created_at}
            minutes_for_reading={articleInfo.minutes_for_reading}
            articleId={articleInfo.id}
            status={statusLocal}
            updated_at={articleInfo.updated_at}/>
             <RenderModalSuccess>
                    <AlertModal alertText="مبروك لقد تم نشر المقال بنجاح" alertHeader="" hideModal={hideModalSuccess}/>
            </RenderModalSuccess>
        </View>
    )
}