import React, { FunctionComponent } from "react";

interface ViewProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string;
}

export const View: FunctionComponent<ViewProps> = (props) => {
  const { className } = props;
  return (
    <div className={className} {...props}>
      {props.children}
    </div>
  )
}

export const InlineView: FunctionComponent<ViewProps> = (props) => {
  const { className } = props;
  return (
    <span className={className} {...props}>
      {props.children}
    </span>
  )
}

export const FlexView: FunctionComponent<ViewProps> = (props) => {
  const { className } = props;
  return (
    <div className={className} {...props}>
      {props.children}
    </div>
  )
}