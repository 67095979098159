import React, { FunctionComponent, useState } from "react"
import { Icon } from "../../../../components/icons/icon.component";
import { InputCheckBox } from "../../../../components/inputs/input.component";
import { View } from "../../../../components/view/view.component"

import "./user-settings-notifications.scss";
interface UserNotificationsItemAccordionProps {
    text: string;
    
}


export const UserNotificationsItemAccordion: FunctionComponent<UserNotificationsItemAccordionProps> = (props) => {

    const {text} = props;
    const [isChecked, setIsChecked] = useState(false);
    const [isSwitch , setIsSwitch] = useState(false);

    return (
        <>
            <View className="UserNotificationsItem" onClick={() => {setIsChecked(!isChecked)}}>
                <View>
                {text}
                </View>
                <Icon name="iconArrowBottomBlue"/>
            </View>
            {isChecked && 
            <View>
                <View className="UserNotificationsSwitch">
                    <View className="label1" style={isSwitch ? {color: "var(--black)"} : {color: "var(--secondaryHeader)"}}>لمعي</View>
                    <label className="switch">
                        <input type="checkbox" onClick={() => {setIsSwitch(!isSwitch)}}/>
                        <span className="slider1 round"></span>
                    </label>
                    <View className="label2" style={isSwitch ? {color: "var(--secondaryHeader)"} : {color: "var(--black)"}}>لمعيال</View>
                </View>
                <View className="UserNotificationsCheckbox">
                    <InputCheckBox name="123" label="عبر لإشعارات الفورية"/>
                    <InputCheckBox name="123" label="عبر البريد الإلكتروني"/>
                </View>
            </View>}
        </>
    )
}