import React, { FunctionComponent, useState, useRef, useEffect } from "react";

import { View } from "../view/view.component";


import './dropdown.scss';
import { activeClass } from "../../shared/utils";
import { useOnClickOutside } from "../../shared/hooks/click-outside.hook";
import { Icon } from "../icons/icon.component";

interface DropDownMultipleProps {
  placeholder?: string;
  name?: string;
  errors?: any;
  register?: any;
  errorMessage?: string;
  subLabel?: string;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  label: string;
  value: string[];
  options: string[];
  onChange: (value: string[]) => void;
}

export const DropDownMultiple: FunctionComponent<DropDownMultipleProps> = (props) => {
  const { value, options, onChange, disabled, label, className, subLabel, errorMessage, register, errors, required, name, placeholder } = props;

  const [active, setActive] = useState(false);
  const [data, setData] = useState([""]);

    useEffect(() => {
        setData(value);
    }, [value]);

  const onToggle = () => setActive(!active);

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setActive(false));

  const onChangeValue = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if(!data.includes(e.currentTarget.value)) {
      onChange([...data, e.currentTarget.value]);
    }
  }

  const handleDelete = (item: string) => {
        const arr = data.filter((el) => el !== item);
        onChange(arr);
  }

  return (
    <div className="DropDown" ref={ref}>
        <label>{label}{required ? <Icon className="iconRedStar" name="iconRedStar" /> : null}<span>{subLabel}</span></label>
      <View className={"DropDownLabel " + className} onClick={onToggle}>
        <View className="Flex" style={{flexWrap: "wrap"}}>
        {data.length !== 0 ? data.map((item) => {
            return(
                <View key={item} className="DropDownLabelMultipleInput">
                  <div>{item}</div>
                  <div className="IconDeleteItemDropDown"><Icon name="iconClose" onClick={() => handleDelete(item)}/></div>
                </View>
            )
        }) : 
          <div style={{display: "flex", alignItems: "center", paddingRight: "0.929em"}}>{placeholder}</div>
        }
        </View>
        <div>
        <Icon name="iconArrowBottom" />
        </div>
      </View>
      <View className={activeClass('DropDownOptions', disabled ? !disabled : active)}>
        {options.map(v => (
          <input name="inputDropDown" placeholder={placeholder} value={v} className="DropDownOptionsLink" onClick={(e) => onChangeValue(e)} key={Math.random() * 10000} readOnly/>
        ))}
      </View>
      {register ? 
       errors[`${name}`]?.type && <div className="ErrorText">{errorMessage}</div> : null
       }
      
    </div>
  )
}