import { AxiosError } from 'axios';
import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { AlertModal } from '../../components/alert-modal/alert-modal.component';
import { Btn } from '../../components/buttons/btn.component';
import { DropDown } from '../../components/dropdown/dropdown.component';
import { Icon } from '../../components/icons/icon.component';
import { InputObligatory, InputValue } from '../../components/inputs/input.component';
import { InterestsModal } from '../../components/interests-modal/interests-modal';
import { useModal } from '../../components/modal/modal.component';
import { SpacerHorizontal, SpacerVertical } from '../../components/spacer/spacer.component';
import { View } from '../../components/view/view.component';
import { IFormValues } from '../../shared/interfaces';
import { apiService } from '../../shared/services/api.service';
import { setCookie } from '../../shared/services/cookie.service';
import { country } from './reviewer-editor-signup/reviewer-editor-signup.page';

import "./signup.scss"

export const SignupPage: FunctionComponent = () => { 
 
    const history = useHistory();

    const { showModal, RenderModal } = useModal();
    const {showModal: showModalError, RenderModal: RenderModalError, hideModal: hideModalError} = useModal();

    const [errorText, setErrorText] = useState("Something went wrong...");
    const [value,setValue] = useState('');
    const [value1,setValue1] = useState('');
    const [password, setPassword] = useState('');
    
    
    const handlePassword = (value: InputValue) => {
        setPassword(value.value);
    }

    const handleChange = (str: string) => {
        setValue(str);
    }
    const handleChange1 = (str: string) => {
        setValue1(str);
    }
    const { register, handleSubmit, errors } = useForm<IFormValues>();
    
    const onSubmit = (data: any) => {
        if(data.gender === "أنثى") {
            data.gender = "FEMALE"
        } else {
            data.gender = "MALE"
        }
        data["role"] = "READER";
        apiService.auth.signup(data)
            .then(r => {
                setCookie('mawsuah_access_token',r.data.access_token, 1);
                setCookie('mawsuah_refresh_token',r.data.refresh_token, 7);
                const myStorage = window.localStorage;
                myStorage.setItem("mawsuah_user_data", JSON.stringify(r.data.user));
                showModal();
            })
            .catch((err : AxiosError) => {
                if(err?.response?.data?.message.indexOf(`User with '${data.email}' email already exists`) === 0) {
                    setErrorText("لقد تم التسجيل بهذا البريد الإلكتروني من قبل. الرجاء إدخال بريد إلكتروني مختلف");
                    showModalError();
                } else if (err?.response?.data?.message.indexOf(`User with '${data.username}' username already exists`) === 0) {
                    setErrorText("اسم مستخدم غير متاح. الرجاء إدخال اسم مستخدم آخر");
                    showModalError();
                } else {
                    setErrorText(err?.response?.data?.message);
                    showModalError();
                }
            })
        
    };

    return (
        <View className="App">
            <View className="signup">
                <View className="signupContent">
                    <View className="signupContentLogos" onClick={() => {history.push("/homepage")}}>
                        {/* <View className="Logo">
                            <Icon name="iconLogoBig1" alt="logo_picture"/>
                        </View> */}
                        <Icon name="iconGroupBlack" alt="logo_picture"/>
                    </View>
                    <View className="signupContentText">
                        {/* <View className="subText"><span>معاً نبني أكبر مجتمع معرفيّ موثوق عربياً</span></View> */}
                        <SpacerVertical height={"2.222em"}/>
                        <View className="textTitle"><span>أنشئ حساباً في موسوعة</span></View>
                        <SpacerVertical height={"0.8em"}/>
                        <View className="textTitleSecondary"><span>هل لديك حساب؟<span style={{color : "var(--blueColor)", cursor: "pointer"}} onClick={() => {history.push('/login')}}> تسجيل دخول </span></span></View>
                    </View>
                    <SpacerVertical height={"2.2em"}/>
                    {/* <button className="googleButton" style={{opacity: "0.6"}}>
                        <View className="btnContent">
                        <Icon name="iconGoogleLogo" alt="logo_picture"/>
                        <span>
                        أنشئ الحساب عبر جوجل
                        </span>
                        </View>
                    </button>
                    <SpacerVertical height={"1.2em"}/>
                    
                    <button className="facebookButton" style={{opacity: "0.6"}}>
                        <View className="btnContent">
                        <Icon name="iconFacebookLogoButton" alt="logo_picture"/> 
                        <span>
                        أنشئ الحساب عبر فيبسوك
                        </span>
                        </View>
                    </button>
                    <SpacerVertical height={"2.7em"}/> 
                    */}
                    <View className="signupContentInputs">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <View className="inlineFlex">
                                <InputObligatory name="first_name"  
                                label="الاسم الأول" 
                                placeholder="الرجاء إدخال اسمك الأول" 
                                maxLength={20} 
                                minLength={3}
                                pattern={/^[ \u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]*$/}  
                                register={register} 
                                errors={errors} 
                                required 
                                inputPrompt="الرجاء إدخال اسمك الأول. يجب أن يتكوّن الاسم من حروف عربية من دون فواصل  أو رموز"
                                errorMessage="الاسم الأول غير صحيح، الرجاء إدخال الاسم الأول مع مراعاة الشروط.
                                يجب أن يتكوّن من حروف عربية من دون فواصل أو رموز"
                                errorMessagePattern="الاسم الأول غير صحيح، الرجاء إدخال الاسم الأول مع مراعاة الشروط.
                                يجب أن يتكوّن من حروف عربية من دون فواصل أو رموز"/>
                                <InputObligatory name="last_name" 
                                label="الاسم الأخير" 
                                placeholder="الرجاء إدخال اسمك الأخير" 
                                maxLength={20}  
                                minLength={3}
                                pattern={/^[ \u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FF]*$/} 
                                register={register} 
                                errors={errors} 
                                errorMessage="الاسم الأخير غير صحيح، الرجاء إدخال الاسم الأخير مع مراعاة الشروط.
                                يجب أن يتكوّن من حروف عربية من دون فواصل أو رموز" 
                                required
                                inputPrompt="الرجاء إدخال اسمك الأخير. يجب أن يتكوّن الاسم من حروف عربية من دون فواصل أو رموز"
                                errorMessagePattern="الاسم الأخير غير صحيح، الرجاء إدخال الاسم الأخير مع مراعاة الشروط.
                                يجب أن يتكوّن من حروف عربية من دون فواصل أو رموز" />
                            </View>
                            <InputObligatory 
                            name="username" 
                            autocomplete="off" 
                            label="اسم المستخدم" 
                            placeholder="الرجاء إدخال اسم المستخدم" 
                            icon="iconExclamation" 
                            minLength={4}
                            errorMessage="اسم مستخدم غير صحيح، يجب أن يتكوّن اسم المستخدم من أحرف إنجليزية فقط من دون فواصل أو فراغات أو رموز وعلى الأقل 4 أحرف. تأكد من استخدام اسم واضح وسهل للقراءة يستمر معك طيلة الرحلة"
                            inputPrompt="يجب أن يتكوّن اسم المستخدم من أحرف إنجليزية فقط من دون فواصل أو فراغات أو رموز وعلى الأقل 4 أحرف. تأكد من استخدام اسم واضح وسهل للقراءة يستمر معك طيلة الرحلة" 
                            register={register} 
                            errors={errors} 
                            required
                            pattern={/^[a-zA-Z]+$/}
                            errorMessagePattern="اسم مستخدم غير صحيح، يجب أن يتكوّن اسم المستخدم من أحرف إنجليزية فقط من دون فواصل أو فراغات أو رموز وعلى الأقل 4 أحرف. تأكد من استخدام اسم واضح وسهل للقراءة يستمر معك طيلة الرحلة"
                            />
                            <InputObligatory 
                            name="email" 
                            label="البريد الإلكتروني" 
                            placeholder="الرجاء إدخال البريد الإلكتروني" 
                            register={register} 
                            errors={errors} 
                            errorMessage="الرجاء التحقق من البريد الإلكتروني"
                            required
                            pattern={/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/}
                            errorMessagePattern="الرجاء التحقق من البريد الإلكتروني"/>
                            <InputObligatory 
                            name="password" 
                            type="password"
                            autocomplete="off" 
                            label="كلمة السر" 
                            placeholder="الرجاء إدخال كلمة السر" 
                            register={register} 
                            errors={errors} 
                            inputPrompt="يجب أن تتكوّن على الأقل من 8 أحرف إنجليزية، حرف كبير واحد،  رقم واحد، و رمز خاص"
                            required
                            errorMessage="كلمة سر غير مقبولة، يجب أن تتكوّن على الأقل من 8 أحرف إنجليزية، حرف كبير واحد و رقم واحد ورمز خاص"
                            pattern={/(?=.*[0-9])(?=.*[!@#$%^_\-.&\]\\*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^_\-.&\]\\*]{8,}/}
                            errorMessagePattern="كلمة سر غير مقبولة، يجب أن تتكوّن على الأقل من 8 أحرف إنجليزية، حرف كبير واحد و رقم واحد ورمز خاص"
                            onChange={handlePassword}/>
                            <InputObligatory 
                            name="confirm_password" 
                            type="password"
                            autocomplete="off" 
                            label="تأكييد كلمة السر" 
                            placeholder="الرجاء إعادة كتابة كلمة السر" 
                            register={register}  
                            errors={errors} 
                            errorMessage="كلمة سر غير مطابقة، الرجاء إعادة الكتابة"
                            required
                            password={password}
                            errorMessagePattern="كلمة سر غير مطابقة، الرجاء إعادة الكتابة"
                            />
                            <DropDown 
                            name="country" 
                            placeholder='الرجاء اختيار البلد' 
                            value={value} 
                            options={country} 
                            label={"البلد"} 
                            onChange={handleChange} 
                            register={register}
                            errors={errors} 
                            errorMessage="الرجاء اختيار البلد" 
                            required/>
                            <SpacerVertical height={"2em"}/>
                            <DropDown 
                            name="gender" 
                            placeholder='الرجاء اختيار الجنس' 
                            value={value1} 
                            options={['ذكر','أنثى']} 
                            label="الجنس" 
                            onChange={handleChange1} 
                            register={register} 
                            errors={errors} 
                            errorMessage="الرجاء اختيار الجنس" 
                            required/>
                            <SpacerVertical height={"2em"}/>
                            <Btn className="loginContentBtn" title="انتهاء"/>
                            <SpacerVertical height={"21.8em"}/>
                        </form>
                    </View>
                </View>
                <View className="signupPicture" title="signup_picture">
                    <View className="signupPictureContent">
                        <View className="icon">
                            <Icon name="iconLogoBigLogin" alt="logo_picture"/>
                        </View>
                        <SpacerHorizontal width={"1.8em"}/>
                        <View className="text">
                            <span>
                            أهلا بك في "موسوعة"
                            <br/>
                            معاً نبني أكبر مجتمع عربي معرفي مختّص وموثوق
                            </span>	

                        </View>
                    </View>
                </View>
            </View>
            <RenderModal>
                <InterestsModal/>
            </RenderModal>
            <RenderModalError>
                <AlertModal alertText={errorText} alertHeader="" hideModal={hideModalError}/>
            </RenderModalError>
        </View>
    )
}